import React, { FC, useState } from "react";
import { Tabs, Card } from "@fle-ui/next";
import { CommonStore } from "@/store/index";
import { observer } from "mobx-react";
import Manage from "./manage";
import { SettingTabs, SettingThemes } from "./SaasH5/index";
import { withRouter } from "react-router-dom";

import "./index.scss";

const { TabPane } = Tabs;

const OwnStore: FC<any> = (props) => {
  const { appId } = CommonStore;
  const [tabStatus, setTabStatus] = useState(1);
  const onTabsChange = (e: any) => {
    setTabStatus(e);
  };
  return (
    <Card className="saas-card-wrapper">
      <Tabs
        onChange={onTabsChange}
        defaultActiveKey="1"
        destroyInactiveTabPane={true}
      >
        <TabPane tab="微页面管理" key="1">
          <Manage />
        </TabPane>
        {appId !== 1 && (
          <>
            <TabPane tab="底部导航设置" key="2">
              <SettingTabs />
            </TabPane>
            <TabPane tab="全局风格设置" key="3">
              <SettingThemes />
            </TabPane>
          </>
        )}
      </Tabs>
    </Card>
  );
};

export default withRouter(observer(OwnStore));
