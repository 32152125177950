import React, { FC } from "react";
import { Space, Col } from "@fle-ui/next";

type Props = {
  // 删格化布局
  span: number;
};
const OrderSource: FC<Props & any> = (props:any) => {
  const { span, siteId, siteName } = props;
  return (
    <Col span={span} className="table_vertical_left">
      <Space direction={"vertical"} align="start">
        <span>{siteName}</span>
        <span style={{ color: "#4C4C4C" }}>站点ID：{siteId}</span>
      </Space>
    </Col>
  );
};

export default OrderSource;
