import React, { FC, useState } from "react";
import { Tree, Button, Modal, Tooltip, Popover } from "@fle-ui/next";
import "./index.scss";
import {
  DownOutlined,
} from '@ant-design/icons';

// 树的数据相关
export interface TreeNodeDataType {
  key: React.Key;
  title: string;
  isLeaf: boolean;
  id: string;
  children?: TreeNodeDataType[];
}
export type ElementEvent = (
  e: React.MouseEvent<HTMLElement, MouseEvent>
) => void;
// modal相关属性
export interface AddTreeModalType {
  title: string;
  visible?: boolean;
  selectTreeKey?: any;
  name?: string;
  options?: any;
  parentId?: any;
  handleOk: ElementEvent;
  cancelText?: any;
  siteId?: any;
  handleCancel: any;
  form?: any;
  confirmLoading?: boolean | undefined;
}
// 父组件传入的值相关
interface TreePropsType {
  treeData: TreeNodeDataType[];
  btnName: string;
  groupName: string;
  onExpand?: any;
  expandedKeys?: string[];
  selectedKeys?: any[];
  addTreeModal: AddTreeModalType;
  handleClick: ElementEvent;
  onSelect: (selectedKeys: any, e: any) => void;
  onClick: (e: React.MouseEvent, node: any) => void;
  renderForm: () => JSX.Element;
  titleRender: (nodeData: any) => JSX.Element;
  handleEdit: any;
  handleAddChild: any;
}

const AddGroupTree: FC<TreePropsType> = (props) => {
  const {
    treeData,
    addTreeModal,
    handleClick,
    renderForm,
    titleRender,
    selectedKeys,
    expandedKeys,
    onExpand,
    onSelect,
    onClick,
    handleEdit,
    handleAddChild,
  } = props;
  const { title, confirmLoading, visible, handleOk, handleCancel } =
    addTreeModal;
  const [disabled, setDisabled] = useState<boolean>(true);

  const tooltipTitle = () => {
    return (
      <>
         <div className="btn-item">
          <Button className="add-btn" type="link" onClick={handleClick} style={{ color: '#262626' }} block>
            添加新分组
          </Button>
        </div>
        <div className="btn-item">

          <Button
            className="add-btn"
            type="link"
            disabled={disabled}
            onClick={handleEdit}
            style={{ color: '#262626' }}
            block
          >
            编辑分组
          </Button>
        </div>
        <div  className="btn-item">
          <Button
            className="add-btn"
            type="link"
            disabled={disabled}
            onClick={handleAddChild}
            style={{ color: '#262626' }}
            block
          >
            添加子分组
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="add-group-tree-wrap">
      <div className="add-btn-wrap">
        <span className="group-name">用户分组</span>
        <Popover content={tooltipTitle} color='#fff'placement="bottomLeft" overlayClassName="ant-popover"   overlayInnerStyle={{width: 176}} trigger="hover"> 
         <div className="popover-img">
          <img  src="https://oss.elebuys.com/tmpdir/202208291139510000205193.png" alt=""  />
         </div>
        </Popover>
      </div>
      <div className="tree-wrap">
        <Tree
          treeData={treeData}
          onSelect={onSelect}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          onExpand={onExpand}
          switcherIcon={<DownOutlined />}
          onClick={(event,node) => {
            onClick(event,node)
            setDisabled(false)
          }}
          titleRender={titleRender}
        />
      </div>
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        style={{zIndex: 9999}}
      >
        <div>{renderForm()}</div>
      </Modal>
    </div>
  );
};
export default AddGroupTree;
