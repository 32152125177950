import React, { useState, FC, useEffect } from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { getAreaList } from "./index";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Cascader,
  TreeSelect,
  InputNumber,
} from "@fle-ui/next";
import { observer } from "mobx-react";
import { CommonStore } from "@/store/index";
import "./SearchForm.scss";
import { fitmentStore } from "@/store/index";
const { Item } = Form;
const { TreeNode } = TreeSelect;
const { Option } = Select;

const sortMap = new Map([
  ["desc", "max"],
  ["asc", "min"],
]);

//顺序倒序判断选择（按市场价、按销售价、按总销量、按利润率）字段逻辑
const descSearch = [
  "search_market_price",
  "pool_saas_search_site_price",
  "pool_saas_sales_num",
  "pool_saas_search_site_profit",
];

const purchaseSearch = [
  "search_purchase_market_price",
  "pool_saas_search_purchase_site_price",
  "pool_saas_sales_num",
  "pool_saas_search_purchase_site_profit",
];

const SearchForm = (props: any) => {
  const { goodsSearchStatus, handleChangeGoodsSearchStatus } = fitmentStore;
  const {
    onFinish,
    initialValues,
    handleResetClick,
    groups,
    defaultOptions,
    defaultCategorys,
    form,
    siteId,
  } = props;
  const { appId } = CommonStore;
  // const appId = 1;

  // 跳转到我的商品
  const handleLactionCommodityClick = () => {
    window.open("/" + siteId + "/mineCommodity/group");
  };
  const [options, setOptions] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [goodsSearch, setGoodsSearch] = useState("name");
  const [selectValue, setSelectValue] = useState<string>("");
  const [purchaseValue, setPurchaseValue] = useState<string>("1");
  const [selectKey, setSelectKey] = useState<string>("1");
  const [radioValue, setRadioValue] = useState<string>("desc");
  const [composePriceAndSort, setComposePriceAndSort] = useState<string[]>([]);

  const [priceSearchMin, setPriceSearchMin] =
    useState<string>("priceSearchMin");
  const [priceSearchMax, setPriceSearchMax] =
    useState<string>("priceSearchMax");
  const [profitSearchMin, setProfitSearchMin] =
    useState<string>("profitSearchMin");
  const [profitSearchMax, setProfitSearchMax] =
    useState<string>("profitSearchMax");

  let profit: any = null;
  let profitWrap: any = null;

  // 是否显示高级搜索
  const [isShowAdvanceSearch, setIShowAdvanceSearch] = useState<boolean>(false);

  const handleShowAdvanceSerachClick = () => {
    setIShowAdvanceSearch(!isShowAdvanceSearch);
  };

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    setCategorys(defaultCategorys);
  }, [defaultCategorys]);

  useEffect(() => {
    if (goodsSearchStatus === 1) {
      handleSearchFormClick();
      handleChangeGoodsSearchStatus(0);
    }
  }, [goodsSearchStatus]);

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    const { code } = targetOption;
    targetOption.loading = true;
    getAreaList(code).then((children) => {
      targetOption.children = children;
      targetOption.loading = false;
      setOptions([...options]);
    });
  };

  // 按商品名称||按商品id搜索逻辑
  const handleChange = (value: any) => {
    if (value == 1) {
      setGoodsSearch("name");
    } else {
      setGoodsSearch("goodsId");
    }
  };

  // 价格筛选逻辑
  const handleChangePrice = (value: any) => {
    if (value == 1) {
      setPriceSearchMin("purchaseMarketPriceMin");
      setPriceSearchMax("purchaseMarketPriceMax");
    } else if (value == 2) {
      setPriceSearchMin("onceMarketPriceMin");
      setPriceSearchMax("onceMarketPriceMax");
    } else if (value == 3) {
      setPriceSearchMin("purchaseSalePriceMin");
      setPriceSearchMax("purchaseSalePriceMax");
    } else if (value == 4) {
      setPriceSearchMin("onceSalePriceMin");
      setPriceSearchMax("onceSalePriceMax");
    } else if (value == 5) {
      setPriceSearchMin("purchaseSitePriceMin");
      setPriceSearchMax("purchaseSitePriceMax");
    } else if (value == 6) {
      setPriceSearchMin("onceSitePriceMin");
      setPriceSearchMax("onceSitePriceMax");
    }
  };

  // 利润率筛选逻辑
  const handleChangeProfit = (value: any) => {
    if (value == 1) {
      setProfitSearchMin("purchaseSiteProfitMin");
      setProfitSearchMax("purchaseSiteProfitMax");
    } else {
      setProfitSearchMin("onceSiteProfitMin");
      setProfitSearchMax("onceSiteProfitMax");
    }
  };

  // 顺序倒序判断选择（按市场价、按销售价、按总销量、按利润率）字段逻辑
  const onChangeRadio = (e: any) => {
    setRadioValue(e.target.value);
  };

  const handleChangeDesc = (value: any, e: any) => {
    setSelectValue(value);
    setSelectKey(e.key);
  };

  const handleChangePurchase = (value: any) => {
    setPurchaseValue(value);
    if (value == "3") {
      setSelectValue(purchaseSearch[Number(selectKey) - 1]);
    } else {
      setSelectValue(descSearch[Number(selectKey) - 1]);
    }
  };

  useEffect(() => {
    if (appId === 1) {
      form.setFieldsValue({ selectProfit: "1" });
      setProfitSearchMin("purchaseSiteProfitMin");
      setProfitSearchMax("purchaseSiteProfitMax");
      profit = 120;
      profitWrap = 336;
    } else {
      form.setFieldsValue({ selectProfit: "2" });
      setProfitSearchMin("onceSiteProfitMin");
      setProfitSearchMax("onceSiteProfitMax");
      profit = 94;
      profitWrap = 310;
    }
  }, [appId]);

  useEffect(() => {
    if (purchaseValue == "3") {
      const sortResult =
        purchaseSearch[2] === selectValue
          ? [selectValue]
          : [selectValue, sortMap.get(radioValue) as string];
      setComposePriceAndSort(sortResult);
    } else {
      const sortResult =
        descSearch[2] === selectValue
          ? [selectValue]
          : [selectValue, sortMap.get(radioValue) as string];
      setComposePriceAndSort(sortResult);
    }
  }, [selectValue, radioValue, purchaseValue]);

  const handleSearchFormClick = () => {
    form
      .validateFields()
      .then((values: any) => {
        const data = composePriceAndSort[0]
          ? { ...values, orderName: composePriceAndSort.join("_") }
          : values;
        onFinish(data);
        if (isShowAdvanceSearch === false) {
          form.setFieldsValue({
            categoryTree: undefined,
            groupId: undefined,
          });
        }
      })
      .catch(() => {
        console.error("提交失败");
      });
  };

  return (
    <div style={{ display: "flex" }} className="search_form">
      <Form
        name="basic"
        style={{ flex: 1 }}
        form={form}
        initialValues={initialValues}
      >
        <div style={{ display: "flex" }}>
          <Row gutter={16} style={{ flex: 1 }}>
            <Col span={8}>
              <Item name="thirdType" label="商品渠道" style={{ marginBottom: 0 }}>
                <Select placeholder="选择商品渠道">
                  <Option value="">全选</Option>
                  <Option value="0">精选商品</Option>
                  <Option value="10">京东商品</Option>
                  <Option value="1000">自有商品</Option>
                  <Option value="40">鲜花蛋糕</Option>
                  <Option value="50">猫超商品</Option>
                </Select>
              </Item>
            </Col>
            {appId === 1 && (
              <Col span={8}>
                <Item name="type" label="销售类型" style={{ marginBottom: 0 }}>
                  <Select
                    onChange={handleChangePurchase}
                    placeholder="输入销售类型"
                  >
                    <Option value="3">全部</Option>
                    <Option value="1">集采批发</Option>
                    <Option value="2">一件代发</Option>
                  </Select>
                </Item>
              </Col>
            )}

            {appId === 2 && (
              <Col span={8}>
                <Item label="销售价格" style={{ marginBottom: 0 }}>
                  <Input.Group compact>
                    <Item noStyle name="onceSitePriceMin">
                      <Input
                        placeholder="最低价"
                        min={0}
                        style={{ width: "50%" }}
                      />
                    </Item>
                    <Item noStyle name="onceSitePriceMax">
                      <Input
                        placeholder="最高价"
                        min={0}
                        style={{ width: "50%" }}
                      />
                    </Item>
                  </Input.Group>
                </Item>
              </Col>
            )}
            <Col span={8}>
              <Item label="商品搜索" style={{ marginBottom: 0 }}>
                <Item name="keyword">
                  <Input placeholder="输入商品名称/商品id" />
                </Item>
              </Item>
            </Col>
          
            <Col span={8}>
              <Item name="cityCodeList" label="&nbsp;&nbsp;&nbsp;&nbsp;发货地">
                <Cascader
                  options={options}
                  loadData={loadData}
                  changeOnSelect
                  placeholder="选择发货地"
                />
              </Item>
            </Col>
            <Col span={8}>
              <Item name="categoryTree"  label="商品分类">
                <TreeSelect
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="选择商品分类"
                  allowClear
                  treeDefaultExpandAll={false}
                >
                  {(defaultCategorys || []).map((item: any, idx: number) => {
                    return (
                      <TreeNode
                        value={item.categoryTree}
                        title={item.categoryName}
                        key={item.categoryTree}
                      >
                        {item.childNodes &&
                          (item.childNodes || []).map(
                            (item: any, idx: number) => {
                              return (
                                <TreeNode
                                  value={item.categoryTree}
                                  title={item.categoryName}
                                  key={item.categoryTree}
                                />
                              );
                            }
                          )}
                      </TreeNode>
                    );
                  })}
                </TreeSelect>
              </Item>
            </Col>

            {appId === 1 && (
              <Col span={8}>
                <Item label="价格筛选">
                  <Input.Group compact>
                    <Item noStyle name="selectPrice">
                      <Select
                        onChange={handleChangePrice}
                        placeholder="选择价格筛选方式"
                        style={{width:'34%'}}
                      >
                        <Option value="1">集采市场价</Option>
                        <Option value="2">代发市场价</Option>
                        <Option value="3">集采价</Option>
                        <Option value="4">代发价</Option>
                        <Option value="5">集采销售价</Option>
                        <Option value="6">代发销售价</Option>
                      </Select>
                    </Item>
                    <Item noStyle name={priceSearchMin}>
                      <Input style={{width:'33%'}} placeholder="最低价" />
                    </Item>
                    <Item noStyle name={priceSearchMax}>
                      <Input style={{width:'33%'}} placeholder="最高价" />
                    </Item>
                  </Input.Group>
                </Item>
              </Col>
            )}

            {appId === 2 && (
              <Col span={8}>
                <Item label="&nbsp;&nbsp;&nbsp;&nbsp;市场价">
                  <Input.Group compact>
                    <Item noStyle name="onceMarketPriceMin">
                      <Input placeholder="最低价" style={{ width: "50%" }} />
                    </Item>
                    <Item noStyle name="onceMarketPriceMax">
                      <Input placeholder="最高价" style={{ width: "50%" }} />
                    </Item>
                  </Input.Group>
                </Item>
              </Col>
            )}
            {isShowAdvanceSearch && <>
            {appId === 2 && (
              <Col span={8}>
                <Item label="&nbsp;&nbsp;&nbsp;&nbsp;会员价">
                  <Input.Group compact>
                    <Item noStyle name="onceSalePriceMin">
                      <Input
                        placeholder="最低价"
                        min={0}
                        style={{ width: "50%" }}
                      />
                    </Item>
                    <Item noStyle name="onceSalePriceMax">
                      <Input
                        placeholder="最高价"
                        min={0}
                        style={{ width: "50%" }}
                      />
                    </Item>
                  </Input.Group>
                </Item>
              </Col>
            )}

            <Col span={8}>
              <Item label="利率筛选">
                <Input.Group compact>
                  <Item noStyle name="selectProfit">
                    <Select
                      onChange={handleChangeProfit}
                      placeholder="选择利润率筛选方式"
                      style={{ width: "34%" }}
                    >
                      {appId === 2 ? (
                        <Option value="2">利润率</Option>
                      ) : (
                        <>
                          <Option value="1">集采利润率</Option>
                          <Option value="2">代发利润率</Option>
                        </>
                      )}
                    </Select>
                  </Item>
                  <Item name={profitSearchMin} noStyle={true}>
                    <InputNumber
                      className="profit"
                      step={0.01}
                      formatter={(value) => `${value}%`}
                      precision={2}
                      style={{ width: "33%" }}
                    />
                  </Item>
                  <Item name={profitSearchMax} noStyle={true}>
                    <InputNumber
                      className="profit"
                      step={0.01}
                      formatter={(value) => `${value}%`}
                      precision={2}
                      style={{ width: "33%" }}
                    />
                  </Item>
                </Input.Group>
              </Item>
            </Col>
            <Col span={8}>
              <Item
                name="siteGoodsGroupIdList"
                label="选择分组"
                normalize={(value) => [value]}
              >
                <Select placeholder="选择分组">
                  {groups.length ? (
                    groups
                      .filter((item: any) => item.type === 10)
                      .map((item: any) => {
                        const { groupName, id } = item;
                        return (
                          <Option value={id} key={id}>
                            {groupName}
                          </Option>
                        );
                      })
                  ) : (
                    <Option value="" disabled>
                      未创建分组
                      <Button type="link" onClick={handleLactionCommodityClick}>
                        请新增分组
                      </Button>
                    </Option>
                  )}
                </Select>
              </Item>
            </Col>
            </>}
          </Row>
        </div>
        <div className="order-btn">
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSearchFormClick}
          >
            查询
          </Button>
          <Button onClick={handleResetClick} style={{ marginLeft: "8px" }}>
            重置
          </Button>
          <div className="search-btn" onClick={handleShowAdvanceSerachClick}>
            高级搜索
            <span style={{ color: "#2C68FF", marginLeft: "4px" }}>
              {isShowAdvanceSearch ? <UpOutlined /> : <DownOutlined />}
            </span>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default observer(SearchForm);
