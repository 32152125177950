import React, { FC, useState, useEffect } from "react";
import "./index.scss";
import { Button, Row, Col} from "@fle-ui/next";
import ProImage from "@fle-ui/pro-image";
import { getAllCount, getSiteGoodsCount } from "./store";
import { CommonStore } from "@/store/index";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
interface SaleGoodsInfo {
  putaway?: number;
  soldout?: number;
}
const Produce: FC<{ [key: string]: any }> = (props) => {
  const { siteId } = props;
  const categoryId = toJS(CommonStore).appId;

  const [todayOrderTotal, setTodayOrderTotal] = useState<any>(0);
  const [saleGoodsCount, setSaleGoodsCount] = useState<SaleGoodsInfo>({});

  const history = useHistory();

  // 获取待发货和进入合计数量
  const getOrderCount = async (siteId: string) => {
    const { data, success } = (await getAllCount(siteId)) as any;
    if (success) {
      setTodayOrderTotal(data);
    }
  };

  // 获取上架数目
  const getSaleGoodsCount = async (siteId: string) => {
    const { data, success } = (await getSiteGoodsCount(siteId)) as any;
    if (success) {
      setSaleGoodsCount(data);
    }
  };

  useEffect(() => {
    getOrderCount(siteId);
    getSaleGoodsCount(siteId);
  }, [siteId]);

  return (
    <div className="fx-produce-wrapper">
      <div className="fx-produce">
        <header className="fx-produce-title">待办事项</header>
        <div className="fx-produce-content-wrapper" style={{justifyContent:categoryId != 1 ?'space-between':'flex-start'}}>
          <div
            className="fx-produce-content-detail"
            style={{marginRight: categoryId != 1 ?'0':'120px'}}
            onClick={() => history.push("/mineCommodity/commodity",['10'])}
          >
            <div className="detail-text">{saleGoodsCount?.putaway}</div>
            <div>
              <ProImage
                width="16px"
                height="16px"
                preview={false}
                src="https://oss.elebuys.com/tmpdir/202206301443460000182355.png"
              />
              <span className="pl8">上架商品</span>
            </div>
          </div>
          <div
            className="fx-produce-content-detail"
            onClick={() => history.push("/mineCommodity/commodity",['-10'])}
          >
            <div className="detail-text">{saleGoodsCount?.soldout}</div>
            <div>
              <ProImage
                width="16px"
                height="16px"
                preview={false}
                src="https://oss.elebuys.com/tmpdir/202206301444000000182356.png"
              />
              <span className="pl8">下架商品</span>
            </div>
          </div>
          {categoryId != 1 && (
            <div
              className="fx-produce-content-detail"
              onClick={() => history.push("/order/orderManage?tab=2")}
            >
              <div className="detail-text">{todayOrderTotal?.waitDeliver}</div>
              <div>
                <ProImage
                  width="16px"
                  height="16px"
                  preview={false}
                  src="https://oss.elebuys.com/tmpdir/202206301537290000182640.png"
                />
                <span className="pl8">待发货订单</span>
              </div>
            </div>
          )}
          {categoryId != 1 && (
            <div
              className="fx-produce-content-detail"
              onClick={() => history.push("/order")}
            >
              <div className="detail-text">{todayOrderTotal?.todaySum}</div>
              <div>
                <ProImage
                  width="16px"
                  height="16px"
                  preview={false}
                  src="https://oss.elebuys.com/tmpdir/202206301537570000182641.png"
                />
                <span className="pl8">今日合计订单</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Produce;
