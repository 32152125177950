import { action, makeObservable, observable } from 'mobx'

interface ShopType {
    getType(): Type;
    setType(type: Type): void
};

type Type = 0 | 1;
type TypeStatus = "1" | "2" | "3";

class ShopTypeStore implements ShopType {
    @observable
    private type: Type;
    private typeStatus: TypeStatus;
    constructor() {
      makeObservable(this);
      this.type = 0;
      this.typeStatus = "2";
    };

    getType(): Type {
        return this.type;
    };

    @action
    setType(type: Type): void {
        this.type = type;
    };

    getTypeStatus(): TypeStatus {
        return this.typeStatus;
    };

    @action
    setTypeStatus(typeStatus: TypeStatus): void {
        this.typeStatus = typeStatus;
    };
};

export default new ShopTypeStore();