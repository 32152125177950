import { Button, Card, Col, Empty, Pagination, Row, Space, Spin, Tabs } from "@fle-ui/next";
import { observer } from "mobx-react";
import useUrlState from "@ahooksjs/use-url-state";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPagination } from "@/tools/getPagination";
import { PurWholesaleOrderStates } from "@/tools/selectOption";
import { WhiteSpace } from "../../../components";
import OrderListStore, { GetOrderListParams, OrderItem } from "@/store/AfterSaleListStore";
import GoodDesc from "./components/GoodDesc";
import OrderListForm from "./components/OrderListForm";
import OrderPrice from "./components/OrderPrice";
import OrderSource from "./components/OrderSource";
import OrderState from "./components/OrderState";
import OrderType from "./components/OrderType";
import "./index.scss";
import OrderDeal from "./components/OrderDeal";
import { PageContainer } from "@fle-ui/pro-layout";

type Props = {};
const { TabPane } = Tabs;

const PurWholesale: FC<Props> = () => {
  const history = useHistory() as any;
  const siteId = Number(window.location.pathname.split("/")[1]);
  const { list, pageNo, pageSize, total } = OrderListStore.orderList;
  const { waitRefundCount } = OrderListStore;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({ pageNo: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<GetOrderListParams>>();
  const [renderList, setRenderList] = useState<OrderItem[]>(
    list.map((item) => ({
      select: false,
      ...item,
    }))
  );

  const [params, setParams] = useUrlState({ tab: "default" }, { navigateMode: "replace" });

  useEffect(() => {
    setLoading(true);
    OrderListStore.ajaxGetPurWholesaleOrderList({
      condition: { ...formData, ...page },
      siteId,
      source: 2,
    }).finally(() => setLoading(false));
    OrderListStore.ajaxGetOrderStatusCount({
      siteId,
    }).catch((err) => {
      console.log(err);
    });
  }, [page]);

  useEffect(() => {
    const _list = list.map((item) => ({
      select: false,
      ...item,
    }));
    setRenderList(_list);
  }, [list]);

  return (
    <PageContainer className="after_sale_order" margin={0} padding={0} background="#F8F8F8">
        <Spin spinning={loading}>
          <Tabs
            className="after_sale_order_tabs"
            defaultActiveKey={params.tab}
            activeKey={params.tab}
            onChange={(tab: any) => {
              setParams({ tab });
              tab !== params.tab && setLoading(true);
            }}
          >
            <TabPane tab={"全部"} key={"default"} />
            <TabPane
              tab={
                <>
                  待付款
                  {Number(waitRefundCount) > 0 && (<span>{waitRefundCount}</span>)}
                </>
              }
              key={"45"}
            />
          </Tabs>
          <OrderListForm
            page={page}
            setPage={setPage}
            onSearch={setFormData}
            orderStatsList={PurWholesaleOrderStates}
          />
          <div className="order_table_wrap">
          <Row className="order_table_header">
            <Col span={5} style={{ paddingLeft: 28 }}>
              <Row>
                <Col span={8} className="table_vertical_left" style={{ textAlign: "left" }}>
                  商品图片
                </Col>
                <Col span={2} />
                <Col span={14} className="table_vertical_left" style={{ textAlign: "left" }}>
                  商品名称
                </Col>
              </Row>
            </Col>
            <Col className="table_vertical_left" span={2} flex="auto">
              销售单价
            </Col>
            <Col className="table_vertical_left" span={2} flex="auto">
              申请数量
            </Col>
            <Col span={3} className="table_vertical_left">
              站点名称
            </Col>
            <Col span={3} className="table_vertical_left">
              用户应付
            </Col>
            <Col span={3} className="table_vertical_left">
              售后类型
            </Col>
            <Col span={3} className="table_vertical_left">
              售后状态
            </Col>
            <Col span={2} className="table_vertical_left">
              操作
            </Col>
          </Row>

          {renderList?.map((order: any, index: any) => (
            <div key={order.afterSaleOrderNo + "_" + index} className="order_table_div">
              <Row
                className="order_table_list_header"
                style={{
                  background: "#FAFAFA",
                  color: "#4C4C4C",
                  padding: "8px 28px",
                  textAlign: "center",
                  border: "1px solid #eeeeee",
                }}
              >
                <Col span={24} className="table_vertical_left">
                  <Space size={"large"} align="start">
                    <span style={{ color: "#4C4C4C" }}>售后单号：{order.afterSaleOrderNo}</span>
                    <span style={{ color: "#4C4C4C" }}>申请人：{order.applicant}</span>
                    <span style={{ color: "#4C4C4C" }}>申请时间：{order.afterSaleApplyTime}</span>
                  </Space>
                </Col>
              </Row>
              <Row className="order_table_box row-con">
                {/* 商品描述 */}
                <Col span={5} style={{ textAlign: "left" }}>
                  <GoodDesc {...order} />
                </Col>
                {/* 销售单价 */}
                <Col span={2} className="table_vertical_left" flex="auto">
                  ￥{(order.afterSaleGoodsPrice / 100).toFixed(2)}
                </Col>
                {/* 申请数量 */}
                <Col span={2} className="table_vertical_left" flex="auto">
                  {order.applyNum}个
                </Col>
                {/* 站点名称 */}
                <OrderSource span={3} {...order} />
                {/* 用户应付 */}
                <OrderPrice
                  span={3}
                  fright={order.freightFee}
                  mount={order.customerPayment}
                  afterSaleType={order.afterSaleType}
                />
                {/* 售后类型 */}
                <OrderType span={3} {...order} />
                {/* 售后状态 */}
                <OrderState span={3} {...order} />
                {/* 操作 */}
                {<OrderDeal span={3} {...order} page={page} setPage={setPage} />}
              </Row>
            </div>
          ))}

          <WhiteSpace height={16} />
          {total > 0 && (
            <Row justify={"end"}>
              <Col>
                <Pagination
                  {...getPagination({
                    total,
                    current: pageNo,
                    pageSize,
                    onChange: (pageNo: number, pageSize: number) => setPage({ pageNo, pageSize }),
                    onShowSizeChange: (pageNo: number, pageSize: number) => setPage({ pageNo: 1, pageSize }),
                  })}
                />
              </Col>
            </Row>
          )}
          {total === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </div>
        </Spin>
    </PageContainer>
  );
};

export default observer(PurWholesale);
