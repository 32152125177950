import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './sentry'
import { isInIcestark, setLibraryName } from "@ice/stark-app";

export function mount(props: any) {
  ReactDOM.render(
    <App {...props.customProps} />,
    props.container
  );
}

export function unmount(props: any) {
  ReactDOM.unmountComponentAtNode(props.container);
}

setLibraryName("siteApp");

if (!isInIcestark()) {
  console.log('!isInIcestark')

  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
