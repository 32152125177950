import React, { useState, FC, useEffect } from "react";
import "./index.scss";
import WangEditor from "./wangEditor";
import { Form, Radio, Button, Input, message,Image } from "@fle-ui/next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { getExtInfoConfig, getProtocol } from "./store";
import { inject, observer } from "mobx-react";

const { Item } = Form;

const RenderForm: FC<{ [key: string]: any }> = (props: any) => {
  const { siteId } = props;
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState<number>(1);
  const [displayTabValue, setDisplayTabValue] = useState<boolean>(true);
  const [extraDisplayValue, setExtraDisplayValue] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [customerData, setCustomerData] = useState<any>({});

  useEffect(() => {
    getProtocolData();
  }, []);

  // radio切换
  const onChange = (e: any) => {
    setRadioValue(e.target.value);
    if (e.target.value === 1) {
      setDisplayTabValue(true);
    } else {
      setDisplayTabValue(false);
    }
  };
  // 点击查看完整协议
  const extraCallback = () => {
    setExtraDisplayValue(false);
  };
  // 折叠
  const foldCallback = () => {
    setExtraDisplayValue(true);
  };
  // 保存富文本内容
  const saveExtInfoConfig = async () => {
    try {
      setLoading(true);
      const res: any = await getExtInfoConfig({
        id: siteId,
        operate: 2,
        protocolType: 1,
      });
      if (res.success) {
        message.success("保存成功");
      } else {
        message.error("保存失败");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      message.error("保存失败");
      setLoading(false);
    }
  };
  // 回显客户基本信息内容
  const getProtocolData = async () => {
    try {
      const res: any = await getProtocol(siteId);
      if (res.success) {
        const { customerName, siteName, shortName, protocolType } = res.data;
        form.setFieldsValue({ customerName, siteName, shortName });
        setCustomerData({ customerName, siteName, shortName });
        setRadioValue(protocolType);
        setDisplayTabValue(protocolType == 1 ? true : false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 10 },
  };

  return (
    <>
      <Form form={form} {...layout}>
        <div className="title">用户使用协议</div>
        <Item label="协议内容">
          <Radio.Group onChange={onChange} value={radioValue}>
            <Radio value={1}>默认协议</Radio>
            <Radio value={2}>自定义协议</Radio>
          </Radio.Group>
        </Item>
        <Item
          style={{ display: displayTabValue ? "flex" : "none" }}
          label="客户名称"
          name="customerName"
          rules={[{ required: true, message: "客户名称必填" }]}
        >
          <Input style={{ width: "240px" }} disabled />
        </Item>
        <Item
          style={{ display: displayTabValue ? "flex" : "none" }}
          label="商城名称"
          name="siteName"
          rules={[{ required: true, message: "商城名称必填" }]}
        >
          <Input style={{ width: "240px" }} disabled />
        </Item>
        <Item
          style={{ display: displayTabValue ? "flex" : "none" }}
          label="商城简称"
          name="shortName"
          rules={[{ required: true, message: "商城简称必填" }]}
        >
          <Input style={{ width: "240px" }} disabled />
        </Item>
        <Item label="协议预览" style={{ display: displayTabValue ? "flex" : "none" }}>
          <div className="con_main">
            <div>
              <p>
                本协议是您与“{customerData?.siteName}”的所有者“
                {customerData?.customerName}
                ”（以下简称为“{customerData?.shortName}”）之间就
                {customerData?.shortName}
                所提供的服务及相关事宜所立订的契约，请您仔细阅读本注册协议，在您确认阅读并选择注册后，本协议即构成对双方有约束力的法律文件。
              </p>
            </div>
            <h5>第一条 {customerData?.shortName}服务条款的确认和接纳</h5>
            <div>
              <p>
                1.{customerData?.shortName}的各项电子服务的所有权和运作权归“
                {customerData?.shortName}
                ”所有。客户同意所有注册协议条款并完成注册程序，才能成为
                {customerData?.shortName}
                的正式客户。客户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
              </p>
              <p>
                2.客户点击同意本协议的，即视为客户确认自己具有享受
                {customerData?.shortName}
                服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
              </p>
              <p>
                3.如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用
                {customerData?.shortName}。
              </p>
              <p>
                4.“{customerData?.shortName}
                ”保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭客户账户、清除或编辑内容或取消订单的权利。
              </p>
            </div>
            <h5>第二条 法律管辖和适用</h5>
            <div>
              <p>
                本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。
                如发生{customerData?.shortName}
                服务条款与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。
                如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
              </p>
            </div>
            <div className="continue-btn" style={{ display: extraDisplayValue ? "flex" : "none" }} onClick={extraCallback}>
              查看完整协议
              <Image width="16px" src="https://oss.elebuys.com/tmpdir/202207111539360000185759.png" preview={false}></Image>
            </div>
            <div
              className="extra_con"
              style={{ display: extraDisplayValue ? "none" : "block" }}
            >
              <h5>第三条 {customerData?.shortName}服务</h5>
              <div>
                <p>
                  1.{customerData?.shortName}
                  通过互联网依法为客户提供互联网信息等服务，客户在完全同意本协议及
                  {customerData?.shortName}规定的情况下，方有权使用
                  {customerData?.shortName}的相关服务。
                </p>
                <p>
                  {" "}
                  2.客户必须自行准备如下设备和承担如下开支：
                  （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
                  （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
                </p>
              </div>
              <h5>第四条 客户信息</h5>
              <div>
                <p>
                  1.客户应自行诚信向{customerData?.shortName}
                  提供注册资料，客户同意其提供的注册资料真实、准确、完整、合法有效，客户注册资料如有变动的，应及时更新其注册资料。如果客户提供的注册资料不合法、不真实、不准确、不详尽的，客户需承担因此引起的相应责任及后果，并且“
                  {customerData?.shortName}”保留终止客户使用“
                  {customerData?.shortName}”各项服务的权利。
                </p>
                <p>
                  2.客户在{customerData?.shortName}
                  进行浏览、下单购物等活动时，涉及客户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，
                  {customerData?.shortName}
                  将予以严格保密，除非得到客户的授权或法律另有规定，
                  {customerData?.shortName}不会向外界披露客户隐私信息。
                </p>
                <p>
                  3.客户注册成功后，将产生客户名和密码等账户信息，您可以根据
                  {customerData?.shortName}
                  规定改变您的密码。客户应谨慎合理的保存、使用其客户名和密码。客户若发现任何非法使用客户账号或存在安全漏洞的情况，请立即通知
                  {customerData?.shortName}并向公安机关报案。
                </p>
                <p>
                  4.客户同意，“{customerData?.shortName}
                  ”拥有通过邮件、短信电话等形式，向在{customerData?.shortName}
                  注册、购物客户、收货人发送订单信息、促销活动等告知信息的权利。
                </p>
                <p>
                  5.客户不得将在{customerData?.shortName}
                  注册获得的账户借给他人使用，否则客户应承担由此产生的全部责任，并与实际使用人承担连带责任。
                </p>
              </div>
              <h5>第四条 客户信息</h5>
              <div>
                <p>
                  1.客户应自行诚信向{customerData?.shortName}
                  提供注册资料，客户同意其提供的注册资料真实、准确、完整、合法有效，客户注册资料如有变动的，应及时更新其注册资料。如果客户提供的注册资料不合法、不真实、不准确、不详尽的，客户需承担因此引起的相应责任及后果，并且“
                  {customerData?.shortName}”保留终止客户使用“
                  {customerData?.shortName}”各项服务的权利。
                </p>
                <p>
                  2.客户在{customerData?.shortName}
                  进行浏览、下单购物等活动时，涉及客户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，
                  {customerData?.shortName}
                  将予以严格保密，除非得到客户的授权或法律另有规定，
                  {customerData?.shortName}不会向外界披露客户隐私信息。
                </p>
                <p>
                  3.客户注册成功后，将产生客户名和密码等账户信息，您可以根据
                  {customerData?.shortName}
                  规定改变您的密码。客户应谨慎合理的保存、使用其客户名和密码。客户若发现任何非法使用客户账号或存在安全漏洞的情况，请立即通知
                  {customerData?.shortName}并向公安机关报案。
                </p>
                <p>
                  4.客户同意，“{customerData?.shortName}
                  ”拥有通过邮件、短信电话等形式，向在{customerData?.shortName}
                  注册、购物客户、收货人发送订单信息、促销活动等告知信息的权利。
                </p>
                <p>
                  5.客户不得将在{customerData?.shortName}
                  注册获得的账户借给他人使用，否则客户应承担由此产生的全部责任，并与实际使用人承担连带责任。
                </p>
              </div>
              <h5>第五条 客户依法言行义务</h5>
              <div>
                <p>
                  本协议依据国家相关法律法规规章制定，客户同意严格遵守以下义务：
                </p>
                <p>
                  {" "}
                  1.不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
                </p>
                <p> 2.从中国大陆向境外传输资料信息时必须符合中国有关法规； </p>
                <p>
                  3.不得利用{customerData?.shortName}
                  从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
                </p>
                <p>
                  4.不得干扰{customerData?.shortName}的正常运转，不得侵入
                  {customerData?.shortName}及国家计算机信息系统；
                </p>
                <p>
                  {" "}
                  5.不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
                </p>
                <p>
                  6.不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
                </p>
                <p>7.不得教唆他人从事本条所禁止的行为；</p>
                <p>
                  8.不得利用在{customerData?.shortName}
                  注册的账户进行牟利性经营活动；
                </p>
                <p>
                  9.不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
                </p>
                <p>
                  {customerData?.shortName}
                  保有删除站内各类不符合法律政策或不真实的信息内容而无须通知客户的权利。
                </p>
                <p>
                  若客户未遵守以上规定的，{customerData?.shortName}
                  有权作出独立判断并采取暂停或关闭客户帐号等措施。客户须对自己在网上的言论和行为承担法律责任。
                </p>
              </div>
              <h5>第六条 商品信息</h5>
              <p>
                {customerData?.shortName}
                上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，
                {customerData?.shortName}
                不作特别通知。由于网站上商品信息的数量极其庞大，虽然
                {customerData?.shortName}
                会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，
                {customerData?.shortName}
                网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；“
                {customerData?.shortName}
                ”欢迎纠错，并会视情况给予纠错者一定的奖励。
              </p>
              <h5>第七条 订单</h5>
              <div>
                <p>
                  {" "}
                  1.在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与客户本人不一致的，收货人的行为和意思表示视为客户的行为和意思表示，客户应对收货人的行为及意思表示的法律后果承担连带责任。
                </p>
                <p>
                  {" "}
                  2.除法律另有强制性规定外，双方约定如下：
                  {customerData?.shortName}
                  上销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到该订单信息后，已将货物实际直接向您发出时，方视为您与销售方之间就发出的货物建立了合同关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际向您发出的商品建立了合同关系。
                </p>
                <p>
                  {" "}
                  3.由于市场变化及各种以合理商业努力难以控制的因素的影响，
                  {customerData?.shortName}
                  无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。
                </p>
              </div>
              <h5>第八条 配送</h5>
              <div>
                <p>
                  {" "}
                  1.{customerData?.shortName}
                  将会把商品（货物）送到您所指定的收货地址，所有在
                  {customerData?.shortName}
                  上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。
                </p>
                <p>
                  {" "}
                  2.因如下情况造成订单延迟或无法配送等，
                  {customerData?.shortName}
                  将无法承担延迟配送的责任：
                  <br />
                  （1）客户提供的信息错误、地址不详细等原因导致的；
                  <br />
                  （2）货物送达后无人签收，导致无法配送或延迟配送的；
                  <br />
                  （3）情势变更因素导致的；
                  <br />
                  （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
                </p>
                <h5>第九条 责任限制及不承诺担保</h5>
                <div>
                  <p>
                    1.除非另有明确的书面说明,{customerData?.shortName}
                    及其所包含的或以其它方式通过{customerData?.shortName}
                    提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。
                  </p>
                  <p>
                    2.除非另有明确的书面说明,“{customerData?.shortName}”不对
                    {customerData?.shortName}
                    的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
                  </p>
                  <p>
                    {" "}
                    3.“{customerData?.shortName}”不担保{customerData?.shortName}
                    所包含的或以其它方式通过{customerData?.shortName}
                    提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从
                    {customerData?.shortName}
                    发出的电子信件、信息没有病毒或其他有害成分。
                  </p>
                  <p>
                    4.如因不可抗力或其它{customerData?.shortName}
                    无法控制的原因使
                    {customerData?.shortName}
                    销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，“
                    {customerData?.shortName}”会合理地尽力协助处理善后事宜。
                  </p>
                </div>
                <h5>第十条 所有权及知识产权条款</h5>
                <div>
                  <p>
                    1. 8.1客户一旦接受本协议，即表明该客户主动将其在任何时间段在
                    {customerData?.shortName}
                    发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给“
                    {customerData?.shortName}”所有，客户同意“
                    {customerData?.shortName}”有权就任何主体侵权而单独提起诉讼。
                  </p>
                  <p>
                    {" "}
                    2.
                    8.2本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权转让书面协议，其效力及于客户在“
                    {customerData?.shortName}
                    ”上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
                  </p>
                  <p>
                    3. 8.3客户同意并已充分了解本协议的条款，承诺不将已发表于
                    {customerData?.shortName}
                    的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。
                  </p>
                  <p>
                    4. 8.4“{customerData?.shortName}”是{customerData?.shortName}
                    的制作者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及
                    {customerData?.shortName}的内容进行修改，并在
                    {customerData?.shortName}
                    张贴，无须另行通知客户。在法律允许的最大限度范围内，“
                    {customerData?.shortName}”对本协议及
                    {customerData?.shortName}
                    内容拥有解释权。
                  </p>
                  <p>
                    5. 8.5除法律另有强制性规定外，未经“{customerData?.shortName}
                    ”明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用
                    {customerData?.shortName}的信息内容，否则，“
                    {customerData?.shortName}”有权追究其法律责任。
                  </p>
                  <p>
                    {" "}
                    6. 8.6{customerData?.shortName}
                    所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是“
                    {customerData?.shortName}
                    ”或其内容提供者的财产，受中国和国际版权法的保护。
                    {customerData?.shortName}上所有内容的汇编是“
                    {customerData?.shortName}
                    ”的排他财产，受中国和国际版权法的保护。
                    {customerData?.shortName}上所有软件都是“
                    {customerData?.shortName}
                    ”或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
                  </p>
                </div>
                <h5>第十一条 协议更新及客户关注义务</h5>
                <div>
                  <p>
                    1.“{customerData?.shortName}
                    ”尊重客户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为客户和消费者提供服务。同时
                    {customerData?.shortName}
                    也会根据国家法律法规变化、网站运营需要及客户建议适时修改本协议及
                    {customerData?.shortName}上的各类规则。
                  </p>

                  <p>
                    2.协议修改后一旦被张贴在{customerData?.shortName}
                    上即生效，并代替原来的协议。客户可随时登录查阅最新协议；客户有义务不时关注并阅读最新版的协议及网站公告。如客户不同意更新后的协议，可以且应立即停止接受
                    {customerData?.shortName}
                    网站依据本协议提供的服务；如客户继续使用本网站提供的服务的，即视为同意更新后的协议。
                    {customerData?.shortName}建议您在使用
                    {customerData?.shortName}
                    之前阅读本协议及{customerData?.shortName}的公告。
                  </p>

                  <p>
                    如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
                  </p>
                </div>
                <h5>第十二条 跨境购</h5>
                <div>
                  <p>
                    1.购物方式：您在{customerData?.shortName}
                    下单、支付货款并提供相关个人信息后，
                    {customerData?.shortName}
                    为您提供代购优质境外商品，并以您的名义以个人自用物品邮包方式代为向海关报关、缴纳税费等服务，
                    {customerData?.shortName}
                    与您之间是委托合同关系。
                    <br />
                    商品从大陆以外的国家或地区通过国际物流方式发出或从中国保税区发出，且有物流公司提供的从商家发货地到消费者目的地之间完整物流状态信息。
                    <br />
                    您使用本网站购买境外商品，就视为您同意
                    {customerData?.shortName}
                    的购物方式。您如果不同意上述购物方式，应立即终止在本产品专区的交易。
                  </p>
                  <p>
                    2.商品标识的价格为商品售价，不含相关税费以及运费、保险费。
                  </p>
                  <p>
                    3.{customerData?.shortName}
                    承诺所售商品均为海外原装正品，并承诺提供"正品保障"服务。
                  </p>
                  <p>
                    4.实名认证：{customerData?.shortName}
                    将根据政策对您订单里的商品进行订购人实名或收件人实名认证，并配合提供海关所需的身份证信息进行个人物品入境申报。
                    {customerData?.shortName}
                    保证您的实名认证信息仅作清关用途，将被严格保密。请您确保提供的个人身份证信真实、完整、有效，否则由此产生的一切后果由您承担。
                  </p>
                  <p>
                    5.通关税费:
                    <br />
                    1）征收方式：根据政策规定，跨境电子商务零售进口商品按照货物征收关税和进口环节增值税、消费税。您作为购买跨境电子商务零售进口商品的个人为纳税义务人，
                    {customerData?.shortName}
                    作为代收代缴义务人，接受您的委托向海关办理通关申报和税款缴纳手续。
                    <br />
                    2）征收内容:依据政策规定，实际交易价格（包括货物零售价格、运费和保险费）作为完税价格。跨境电子商务零售进口商品的单次交易限值为人民币2000元，个人年度交易限值为人民币20000元。在限值以内进口的跨境电子商务零售进口商品，关税税率暂设为0%；进口环节增值税、消费税取消免征税额，暂按法定应纳税额的70%征收。超过单次限值、累加后超过个人年度限值的单次交易，以及完税价格超过2000元限值的单个不可分割商品，均按照一般贸易方式全额征税。
                    <br />
                    3）下单支付后，订单一旦提交至海关申报及纳税，您将不能修改订单信息（收货人、收货地址、电话等）。
                  </p>
                  <p>
                    6.消费者须知：
                    <br />
                    在您选购境外商品前，请您认真阅读此文，同意本条款所告知内容后再进行下单购买：
                    <br />
                    1）您在本网站上购买的境外商品等同于原产地直接购买。
                    <br />
                    2）您须承诺所购境外商品为个人自用，不作二次销售使用。
                    <br />
                    3）请您充分了解所购境外商品的品质、性能以及健康、安全、卫生、环保等标准。您购买的境外商品适用的品质、健康、安全、卫生、环保、标识等项目可能与我国质量等标准不同，所以在使用过程中由此可能产生的危害或损失以及其他风险，将由您个人承担如需购买符合中国产品标准的商品请至其他产品专区购买。
                    <br />
                    4）您所购买的境外商品从大陆（不含港澳台）以外的地区发出，可能未加贴中文标签。
                  </p>
                </div>
                <div className="final">
                  <p>
                    请您务必认真阅读本协议并充分理解协议中相关条款内容，尤其是以下划线标识的重要条款。如您对协议有任何疑问，可向平台客服咨询。阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。
                  </p>
                </div>
                <div className="continue-btn fold-btn" style={{ display: extraDisplayValue ? "none" : "flex" }} onClick={foldCallback}>
                  折叠内容
                  <Image className="fold-btn-img" width="16px" src="https://oss.elebuys.com/tmpdir/202207111539360000185759.png" preview={false}></Image>
                </div>
              </div>
            </div>
          </div>
        </Item>
        <Item {...tailLayout} style={{ display: displayTabValue ? "flex" : "none" }}>
          <Button
            htmlType="submit"
            type="primary"
            onClick={saveExtInfoConfig}
            loading={loading}
          >
            保存
          </Button>
        </Item>
      </Form>

      {/* <div
        className="con"
        style={{ display: displayTabValue ? "block" : "none" }}
      >
        <h4 className="con_tit">协议预览：</h4>
        <div className="con_main">
          <div>
            <p>
              本协议是您与“{customerData?.siteName}”的所有者“
              {customerData?.customerName}
              ”（以下简称为“{customerData?.shortName}”）之间就
              {customerData?.shortName}
              所提供的服务及相关事宜所立订的契约，请您仔细阅读本注册协议，在您确认阅读并选择注册后，本协议即构成对双方有约束力的法律文件。
            </p>
          </div>

          <h5>第一条 {customerData?.shortName}服务条款的确认和接纳</h5>
          <div>
            <p>
              1.{customerData?.shortName}的各项电子服务的所有权和运作权归“
              {customerData?.shortName}
              ”所有。客户同意所有注册协议条款并完成注册程序，才能成为
              {customerData?.shortName}
              的正式客户。客户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。
            </p>
            <p>
              2.客户点击同意本协议的，即视为客户确认自己具有享受
              {customerData?.shortName}
              服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。
            </p>
            <p>
              3.如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用
              {customerData?.shortName}。
            </p>
            <p>
              4.“{customerData?.shortName}
              ”保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭客户账户、清除或编辑内容或取消订单的权利。
            </p>
          </div>
          <h5>第二条 法律管辖和适用</h5>
          <div>
            <p>
              本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。
              如发生{customerData?.shortName}
              服务条款与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。
              如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有管辖权的中华人民共和国大陆地区法院提起诉讼。
            </p>
          </div>
          <div
            className="button_wrap"
            style={{
              marginTop: 20,
              display: extraDisplayValue ? "block" : "none",
            }}
          >
            <div className="save_wrap">
              <Button
                type="primary"
                onClick={saveExtInfoConfig}
                loading={loading}
              >
                保存
              </Button>
            </div>
            <div className="look_all" onClick={extraCallback}>
              <span>
                <DownOutlined />
              </span>
              查看完整协议
            </div>
          </div>

          <div
            className="extra_con"
            style={{ display: extraDisplayValue ? "none" : "block" }}
          >
            <h5>第三条 {customerData?.shortName}服务</h5>
            <div>
              <p>
                1.{customerData?.shortName}
                通过互联网依法为客户提供互联网信息等服务，客户在完全同意本协议及
                {customerData?.shortName}规定的情况下，方有权使用
                {customerData?.shortName}的相关服务。
              </p>
              <p>
                {" "}
                2.客户必须自行准备如下设备和承担如下开支：
                （1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；
                （2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。
              </p>
            </div>

            <h5>第四条 客户信息</h5>
            <div>
              <p>
                1.客户应自行诚信向{customerData?.shortName}
                提供注册资料，客户同意其提供的注册资料真实、准确、完整、合法有效，客户注册资料如有变动的，应及时更新其注册资料。如果客户提供的注册资料不合法、不真实、不准确、不详尽的，客户需承担因此引起的相应责任及后果，并且“
                {customerData?.shortName}”保留终止客户使用“
                {customerData?.shortName}”各项服务的权利。
              </p>
              <p>
                2.客户在{customerData?.shortName}
                进行浏览、下单购物等活动时，涉及客户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，
                {customerData?.shortName}
                将予以严格保密，除非得到客户的授权或法律另有规定，
                {customerData?.shortName}不会向外界披露客户隐私信息。
              </p>
              <p>
                3.客户注册成功后，将产生客户名和密码等账户信息，您可以根据
                {customerData?.shortName}
                规定改变您的密码。客户应谨慎合理的保存、使用其客户名和密码。客户若发现任何非法使用客户账号或存在安全漏洞的情况，请立即通知
                {customerData?.shortName}并向公安机关报案。
              </p>
              <p>
                4.客户同意，“{customerData?.shortName}
                ”拥有通过邮件、短信电话等形式，向在{customerData?.shortName}
                注册、购物客户、收货人发送订单信息、促销活动等告知信息的权利。
              </p>
              <p>
                5.客户不得将在{customerData?.shortName}
                注册获得的账户借给他人使用，否则客户应承担由此产生的全部责任，并与实际使用人承担连带责任。
              </p>
            </div>

            <h5>第五条 客户依法言行义务</h5>
            <div>
              <p>
                本协议依据国家相关法律法规规章制定，客户同意严格遵守以下义务：
              </p>
              <p>
                {" "}
                1.不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；
              </p>
              <p> 2.从中国大陆向境外传输资料信息时必须符合中国有关法规； </p>
              <p>
                3.不得利用{customerData?.shortName}
                从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
              </p>
              <p>
                4.不得干扰{customerData?.shortName}的正常运转，不得侵入
                {customerData?.shortName}及国家计算机信息系统；
              </p>
              <p>
                {" "}
                5.不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；
              </p>
              <p>
                6.不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
              </p>
              <p>7.不得教唆他人从事本条所禁止的行为；</p>
              <p>
                8.不得利用在{customerData?.shortName}
                注册的账户进行牟利性经营活动；
              </p>
              <p>
                9.不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；
              </p>
              <p>
                {customerData?.shortName}
                保有删除站内各类不符合法律政策或不真实的信息内容而无须通知客户的权利。
              </p>
              <p>
                若客户未遵守以上规定的，{customerData?.shortName}
                有权作出独立判断并采取暂停或关闭客户帐号等措施。客户须对自己在网上的言论和行为承担法律责任。
              </p>
            </div>

            <h5>第六条 商品信息</h5>
            <p>
              {customerData?.shortName}
              上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，
              {customerData?.shortName}
              不作特别通知。由于网站上商品信息的数量极其庞大，虽然
              {customerData?.shortName}
              会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，
              {customerData?.shortName}
              网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；“
              {customerData?.shortName}
              ”欢迎纠错，并会视情况给予纠错者一定的奖励。
            </p>
            <h5>第七条 订单</h5>
            <div>
              <p>
                {" "}
                1.在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与客户本人不一致的，收货人的行为和意思表示视为客户的行为和意思表示，客户应对收货人的行为及意思表示的法律后果承担连带责任。
              </p>
              <p>
                {" "}
                2.除法律另有强制性规定外，双方约定如下：
                {customerData?.shortName}
                上销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到该订单信息后，已将货物实际直接向您发出时，方视为您与销售方之间就发出的货物建立了合同关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际向您发出的商品建立了合同关系。
              </p>
              <p>
                {" "}
                3.由于市场变化及各种以合理商业努力难以控制的因素的影响，
                {customerData?.shortName}
                无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。
              </p>
            </div>
            <h5>第八条 配送</h5>
            <div>
              <p>
                {" "}
                1.{customerData?.shortName}
                将会把商品（货物）送到您所指定的收货地址，所有在
                {customerData?.shortName}
                上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。
              </p>
              <p>
                {" "}
                2.因如下情况造成订单延迟或无法配送等，{customerData?.shortName}
                将无法承担延迟配送的责任：
                <br />
                （1）客户提供的信息错误、地址不详细等原因导致的；
                <br />
                （2）货物送达后无人签收，导致无法配送或延迟配送的；
                <br />
                （3）情势变更因素导致的；
                <br />
                （4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。
              </p>
            </div>

            <h5>第九条 责任限制及不承诺担保</h5>
            <div>
              <p>
                1.除非另有明确的书面说明,{customerData?.shortName}
                及其所包含的或以其它方式通过{customerData?.shortName}
                提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。
              </p>
              <p>
                2.除非另有明确的书面说明,“{customerData?.shortName}”不对
                {customerData?.shortName}
                的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
              </p>
              <p>
                {" "}
                3.“{customerData?.shortName}”不担保{customerData?.shortName}
                所包含的或以其它方式通过{customerData?.shortName}
                提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从
                {customerData?.shortName}
                发出的电子信件、信息没有病毒或其他有害成分。
              </p>
              <p>
                4.如因不可抗力或其它{customerData?.shortName}无法控制的原因使
                {customerData?.shortName}
                销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，“
                {customerData?.shortName}”会合理地尽力协助处理善后事宜。
              </p>
            </div>
            <h5>第十条 所有权及知识产权条款</h5>
            <div>
              <p>
                1. 8.1客户一旦接受本协议，即表明该客户主动将其在任何时间段在
                {customerData?.shortName}
                发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给“
                {customerData?.shortName}”所有，客户同意“
                {customerData?.shortName}”有权就任何主体侵权而单独提起诉讼。
              </p>
              <p>
                {" "}
                2.
                8.2本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权转让书面协议，其效力及于客户在“
                {customerData?.shortName}
                ”上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。
              </p>
              <p>
                3. 8.3客户同意并已充分了解本协议的条款，承诺不将已发表于
                {customerData?.shortName}
                的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类网站、媒体上使用）。
              </p>
              <p>
                4. 8.4“{customerData?.shortName}”是{customerData?.shortName}
                的制作者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及
                {customerData?.shortName}的内容进行修改，并在
                {customerData?.shortName}
                张贴，无须另行通知客户。在法律允许的最大限度范围内，“
                {customerData?.shortName}”对本协议及{customerData?.shortName}
                内容拥有解释权。
              </p>
              <p>
                5. 8.5除法律另有强制性规定外，未经“{customerData?.shortName}
                ”明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用
                {customerData?.shortName}的信息内容，否则，“
                {customerData?.shortName}”有权追究其法律责任。
              </p>
              <p>
                {" "}
                6. 8.6{customerData?.shortName}
                所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是“
                {customerData?.shortName}
                ”或其内容提供者的财产，受中国和国际版权法的保护。
                {customerData?.shortName}上所有内容的汇编是“
                {customerData?.shortName}”的排他财产，受中国和国际版权法的保护。
                {customerData?.shortName}上所有软件都是“
                {customerData?.shortName}
                ”或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
              </p>
            </div>
            <h5>第十一条 协议更新及客户关注义务</h5>
            <div>
              <p>
                1.“{customerData?.shortName}
                ”尊重客户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为客户和消费者提供服务。同时
                {customerData?.shortName}
                也会根据国家法律法规变化、网站运营需要及客户建议适时修改本协议及
                {customerData?.shortName}上的各类规则。
              </p>

              <p>
                2.协议修改后一旦被张贴在{customerData?.shortName}
                上即生效，并代替原来的协议。客户可随时登录查阅最新协议；客户有义务不时关注并阅读最新版的协议及网站公告。如客户不同意更新后的协议，可以且应立即停止接受
                {customerData?.shortName}
                网站依据本协议提供的服务；如客户继续使用本网站提供的服务的，即视为同意更新后的协议。
                {customerData?.shortName}建议您在使用{customerData?.shortName}
                之前阅读本协议及{customerData?.shortName}的公告。
              </p>

              <p>
                如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。
              </p>
            </div>
            <h5>第十二条 跨境购</h5>
            <div>
              <p>
                1.购物方式：您在{customerData?.shortName}
                下单、支付货款并提供相关个人信息后，{customerData?.shortName}
                为您提供代购优质境外商品，并以您的名义以个人自用物品邮包方式代为向海关报关、缴纳税费等服务，
                {customerData?.shortName}
                与您之间是委托合同关系。
                <br />
                商品从大陆以外的国家或地区通过国际物流方式发出或从中国保税区发出，且有物流公司提供的从商家发货地到消费者目的地之间完整物流状态信息。
                <br />
                您使用本网站购买境外商品，就视为您同意{customerData?.shortName}
                的购物方式。您如果不同意上述购物方式，应立即终止在本产品专区的交易。
              </p>
              <p>2.商品标识的价格为商品售价，不含相关税费以及运费、保险费。</p>
              <p>
                3.{customerData?.shortName}
                承诺所售商品均为海外原装正品，并承诺提供"正品保障"服务。
              </p>
              <p>
                4.实名认证：{customerData?.shortName}
                将根据政策对您订单里的商品进行订购人实名或收件人实名认证，并配合提供海关所需的身份证信息进行个人物品入境申报。
                {customerData?.shortName}
                保证您的实名认证信息仅作清关用途，将被严格保密。请您确保提供的个人身份证信真实、完整、有效，否则由此产生的一切后果由您承担。
              </p>
              <p>
                5.通关税费:
                <br />
                1）征收方式：根据政策规定，跨境电子商务零售进口商品按照货物征收关税和进口环节增值税、消费税。您作为购买跨境电子商务零售进口商品的个人为纳税义务人，
                {customerData?.shortName}
                作为代收代缴义务人，接受您的委托向海关办理通关申报和税款缴纳手续。
                <br />
                2）征收内容:依据政策规定，实际交易价格（包括货物零售价格、运费和保险费）作为完税价格。跨境电子商务零售进口商品的单次交易限值为人民币2000元，个人年度交易限值为人民币20000元。在限值以内进口的跨境电子商务零售进口商品，关税税率暂设为0%；进口环节增值税、消费税取消免征税额，暂按法定应纳税额的70%征收。超过单次限值、累加后超过个人年度限值的单次交易，以及完税价格超过2000元限值的单个不可分割商品，均按照一般贸易方式全额征税。
                <br />
                3）下单支付后，订单一旦提交至海关申报及纳税，您将不能修改订单信息（收货人、收货地址、电话等）。
              </p>
              <p>
                6.消费者须知：
                <br />
                在您选购境外商品前，请您认真阅读此文，同意本条款所告知内容后再进行下单购买：
                <br />
                1）您在本网站上购买的境外商品等同于原产地直接购买。
                <br />
                2）您须承诺所购境外商品为个人自用，不作二次销售使用。
                <br />
                3）请您充分了解所购境外商品的品质、性能以及健康、安全、卫生、环保等标准。您购买的境外商品适用的品质、健康、安全、卫生、环保、标识等项目可能与我国质量等标准不同，所以在使用过程中由此可能产生的危害或损失以及其他风险，将由您个人承担如需购买符合中国产品标准的商品请至其他产品专区购买。
                <br />
                4）您所购买的境外商品从大陆（不含港澳台）以外的地区发出，可能未加贴中文标签。
              </p>
            </div>
            <div className="final">
              <p>
                请您务必认真阅读本协议并充分理解协议中相关条款内容，尤其是以下划线标识的重要条款。如您对协议有任何疑问，可向平台客服咨询。阅读协议的过程中，如果您不同意相关协议或其中任何条款约定，您应立即停止注册程序。
              </p>
            </div>
            <div
              className="button_wrap"
              style={{
                marginTop: 20,
                display: extraDisplayValue ? "none" : "block",
              }}
            >
              <div className="save_wrap">
                <Button
                  type="primary"
                  onClick={saveExtInfoConfig}
                  loading={loading}
                >
                  保存
                </Button>
              </div>
              <div className="fold" onClick={foldCallback}>
                <span>
                  <UpOutlined />
                </span>
                折叠内容
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="wang_editor"
        style={{ display: displayTabValue ? "none" : "block" }}
      >
        <WangEditor siteId={siteId} />
      </div>
    </>
  );
};
const SiteAgreement: FC<{ [key: string]: any }> = (props) => {
  const { siteId } = props;
  // 渲染表单
  return (
    <div className="manage-form-wrap">
      <RenderForm siteId={siteId} />
    </div>
  );
};

export default inject("siteId")(observer(SiteAgreement));
