import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Modal, Radio, Button, Input, Form, InputNumber } from "@fle-ui/next";
import { getSiteShareConfig } from "./store";
import { ShopTypeStore } from "@/store/index";

import "./marketAlter.scss";
interface Props {
  [key: string]: any;
}

const AlterPrice: React.FC<Props> = (props) => {
  const isSelf = ShopTypeStore.getType();
  const {
    isKeepPrice,
    isKeepStatus,
    siteGoodsRule,
    siteId,
    visible,
    handleOk,
    handleCancel,
    confirmLoading,
  } = props;
  const [checkGroup, setCheckGroup] = useState<string>("1-2-3");
  const [checkOne, setCheckOne] = useState<string>("2-2-3");
  const [oldRate, setOldRate] = useState<any>("");
  const [formGroup] = Form.useForm();
  const [formOne] = Form.useForm();

  const alterPriceListGroup = [
    {
      modeType: 1,
      changeType: 1,
      operateType: 1,
      id: "1-1-1",
      label: "按照集采价上调",
      value: true,
      more: (
        <Form layout="inline" form={formGroup}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
      tips: "* 销售价=集采价*（1+a%) ; a为输入值，a越大，您的利润越高；",
    },
    {
      id: "1-2-2",
      modeType: 1,
      changeType: 2,
      operateType: 2,
      value: true,
      label: "按照集采市场价下调",
      tips: "* 销售价=市场价*（1-a%) ; a为输入值，a越大，您的利润越低",
      more: (
        <Form form={formGroup}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "1-2-3",
      modeType: 1,
      changeType: 2,
      operateType: 3,
      label: "等于集采市场价",
    },
    {
      id: "1-1-3",
      modeType: 1,
      changeType: 1,
      operateType: 3,
      label: "等于集采价",
    },
  ];
  const alterPriceListOne = [
    {
      modeType: 2,
      changeType: 1,
      operateType: 1,
      id: "2-1-1",
      label: "按照代发价上调",
      value: true,
      more: (
        <Form layout="inline" form={formOne}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
      tips: "* 销售价=代发价*（1+a%) ; a为输入值，a越大，您的利润越高；",
    },
    {
      id: "2-2-2",
      modeType: 2,
      changeType: 2,
      operateType: 2,
      value: true,
      label: "按照代发市场价下调",
      tips: "* 销售价=代发价*（1-a%) ; a为输入值，a越大，您的利润越低；",
      more: (
        <Form form={formOne}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "2-2-3",
      modeType: 2,
      changeType: 2,
      operateType: 3,
      label: "等于代发市场价",
    },
    {
      id: "2-1-3",
      modeType: 2,
      changeType: 1,
      operateType: 3,
      label: "等于代发价",
    },
  ];
  const handleRadioGroupChange = (e: any, item: any) => {
    setCheckGroup(item.id);
  };
  const handleRadioOneChange = (e: any, item: any) => {
    setCheckOne(item.id);
  };

  useEffect(() => {
    const getSiteInfo = async (siteId: number) => {
      if (isSelf === 1) {
        setCheckGroup(1 + "-" + 1 + "-" + 3);
        setCheckOne(2 + "-" + 1 + "-" + 3);
      } else {
        setCheckGroup(1 + "-" + 2 + "-" + 3);
        setCheckOne(2 + "-" + 2 + "-" + 3);
      }
    };
    getSiteInfo(siteId);
  }, [formOne, formGroup, siteGoodsRule, siteId, visible]);

  return (
    <>
      <Modal
        title="改价"
        visible={visible}
        onOk={() => handleOk()}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={846}
        footer={null}
        className="alter-price-market-modal-components"
        {...props}
      >
        <div className="alter-price-content">
          <div className="alter-price-content-title-wrap">
            <div className="alter-price-content-title">销售价设置</div>
            <div className="alter-price-content-sub-title">
              （销售价仅做展示作用，为避免商品销售支持类型调整导致价格缺失，请对集采价和代发价都进行设置）
            </div>
          </div>
          <div className="radio-content-wrap">
            <div className="radio-content-left">
              <div className="radio-content-left-tit">集采销售价</div>
              <Radio.Group name="price-one" value={checkGroup} className="radio-group-wrap">
                {alterPriceListGroup &&
                  alterPriceListGroup.map((item, index) => (
                    <div key={index} className="alter-price-item-radio-wrap">
                      <Radio
                        className="radio"
                        key={index}
                        checked={item.id === checkGroup}
                        value={item.id}
                        onChange={(e: any) => {
                          handleRadioGroupChange(e, item);
                          formGroup.setFieldsValue({ rate: "" });
                        }}
                      >
                        {item.label}
                        {item.id === checkGroup && item.more && item.more}
                      </Radio>
                      {item.tips && item.id === checkGroup && (
                        <div className="alter-price-radio-tips">
                          {item.tips}
                        </div>
                      )}
                    </div>
                  ))}
              </Radio.Group>
            </div>
            <div className="radio-content-right">
              <div className="radio-content-right-tit">代发销售价</div>
              <Radio.Group name="price-one" value={checkOne} className="radio-group-wrap">
                {alterPriceListOne &&
                  alterPriceListOne.map((item, index) => (
                    <div key={index} className="alter-price-item-radio-wrap">
                      <Radio
                        className="radio"
                        key={index}
                        checked={item.id === checkOne}
                        value={item.id}
                        onChange={(e: any) => {
                          handleRadioOneChange(e, item);
                          formOne.setFieldsValue({ rate: "" });
                        }}
                      >
                        {item.label}
                        {item.id === checkOne && item.more && item.more}
                      </Radio>
                      {item.tips && item.id === checkOne && (
                        <div className="alter-price-radio-tips">
                          {item.tips}
                        </div>
                      )}
                    </div>
                  ))}
              </Radio.Group>
            </div>
          </div>

          <div className="alter-price-submit-wrap">
            <Button
              onClick={() => {
                const currentGroup = alterPriceListGroup.find(
                  (item: any) => item.id === checkGroup
                );
                const currentOne = alterPriceListOne.find(
                  (item: any) => item.id === checkOne
                );
               
                // if (currentGroup != null && currentOne != null) {
                handleOk([
                  {
                    ...currentGroup,
                    rate: formGroup.getFieldValue("rate"),
                  },
                  { ...currentOne, rate: formOne.getFieldValue("rate") },
                  // oldRate,
                  { isKeepPrice, isKeepStatus },
                  //   formGroup,
                  //   formOne,
                ]);
                // }
              }}
              className="alter-price-submit-button"
              type="primary"
              loading={confirmLoading}
            >
              确认保存
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(AlterPrice);
