
import React  from 'react'
import { Result, Button } from "@fle-ui/next"

import { useHistory } from 'react-router-dom'

const Empty = () => {
  const history = useHistory()
  const handleClick = () => {
    history.push('/commodity')
  }
  return (
    <>
    <Result
    status="error"
    extra={[<Button type="primary" onClick={handleClick}>回到商品列表</Button>,
    ]}
    title="来晚了,商品已下架"/>
    </>
  )
}

export default Empty