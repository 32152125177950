import { makeAutoObservable } from 'mobx'
import httpClient from '@/utils/httpClient'
import Api from '@/config/api'
const { commodityNewGroup } = Api
class GoodsStore {

    constructor() {
        makeAutoObservable(this);
    }

    // 商品池商品列表
    async getList(params: any) {
        return await httpClient.post(commodityNewGroup.get_list, { ...params })
    }

    async updateGroup(params: any) {
        return await httpClient.post(commodityNewGroup.update_grup, { ...params })
    }
    async deleteGroup(params: any) {
        return await httpClient.post(commodityNewGroup.delete_group, { ...params })
    }
    async getSite(params: any) {
        return await httpClient.get(commodityNewGroup.get_site, { params })
    }

    async getGroupGoodsList(params: any) {
        return await httpClient.post(commodityNewGroup.get_group_goods_list, { ...params })
    }


    async addGroup(params: any) {
        return await httpClient.post(commodityNewGroup.add_group, { ...params })
    }


    async addGroupGoods(params: any) {
        return await httpClient.post(commodityNewGroup.add_group_goods, { ...params })
    }

    getScroll = async (params: any = {}) => {
        return httpClient.post('/elecard/admin/template/get', { ...params });
    }

    async getGroupInfo(params: any) {
        return await httpClient.post(commodityNewGroup.get_group_info, { ...params })
    }

    async getGroupAllGoodsList(params: any) {
        return await httpClient.post(commodityNewGroup.get_group_all_goods_list, { ...params })
    }
}

export default new GoodsStore()