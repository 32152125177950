import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";

export const getSitebusinessConfig = (data: any) => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/merchant/site/businessConfig",
    data: JSON.stringify(data),
  });
};

export const getSiteShareConfig = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getSite",
    params: { siteId },
  });
};

export const getExtInfoConfig = (data: any) => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/merchant/site/extInfoConfig",
    data: JSON.stringify(data),
  });
};

export const getProtocol = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getProtocol",
    params: { siteId },
  });
};
