import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./index.scss";
import SiteSetting from "../SiteSetting/index";
import SiteManage from "../SiteManage/index";
import SiteInterface from "../SiteInterface/index";
import SiteAgreement from "../SiteAgreement/index";
import { PageContainer } from "@fle-ui/pro-layout";
import { withRouter } from "react-router-dom";

const SiteList = (props: any) => {

  return (
    <PageContainer>
      <Switch>
        <Route path="/site/setting" exact component={SiteSetting} />
        <Route path="/site/manage" exact component={SiteManage} />
        <Route path="/site/interface" exact component={SiteInterface} />
        <Route path="/site/agreement" exact component={SiteAgreement} />

        {/* 重定向 */}
        <Redirect from="/site" exact to="/site/setting" />
      </Switch>
    </PageContainer>
  );
};

export default withRouter(SiteList);
