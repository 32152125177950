
import { ENV,mainHost } from '@/config/index'
import request from '@/utils/httpClient'
import { update } from 'js-md5';
// 获取h5站点商城url
export const getSiteUrl = (id: string | number, tinyId: number) => {
  let _url = "";
  switch (ENV) {
    case "dev":
      _url = `http://${id}.dev-shop.fleshopy.com/tiny/${tinyId}`;
      break;
    case "test":
      _url = `http://${id}.qa-shop.fleshopy.com/tiny/${tinyId}`;
      break;
    default:
      _url = `http://${id}.shop.fleshopy.com/tiny/${tinyId}`;
  }
  
  return _url;
};


export const getSitePreview = (
  siteId: string | number, 
  type: string = 'saas/preview',
  tinyId: number,
  ) => {
  // 获取站点装修地址 fitment /saas/preview
  let _url = ""
  const href: string = window.location.origin+'/'+siteId+'/survey'
  switch (ENV) {
    case "dev":
      _url = `http://dev-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site&tinyId=${tinyId}`;
      break;
    case "qa":
      _url = `https://qa-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site&tinyId=${tinyId}`;
      break;
    default:
      _url = `https://deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site&tinyId=${tinyId}`;
  }
  const loginLink = localStorage.getItem('loginLink')
  if (loginLink) {
    _url += `&loginLink=${loginLink}`
  }
  return _url;
};

export const getSiteShareConfig = (siteId: number) => {
  return request({
    method: 'get',
    url: mainHost + '/fleshopy/merchant/site/getSite',
    params: {siteId}
  })
}

export  const updateSiteNameUpdate = (siteId: number, siteName: string)=> {
  return request({
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: mainHost + '/fleshopy/merchant/site/siteNameUpdate',
    data: JSON.stringify({ siteId, siteName })
  })
}


// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'get',
    url: mainHost + '/elebuys/member/employee/member-info',
  })
}
