import React, { FC, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import {  Modal, Row, Col, Form, Radio, Button, Select } from "@fle-ui/next"
import { type } from 'os'
const { Item, useForm } = Form
const { Group } = Radio
const { Option } = Select

export interface EditGroupModalProps {
  visible: boolean,
  handleSubmitForm?: (values: any) => void
  handleCancelClick?: React.MouseEventHandler<HTMLElement>,
  okText?: string,
  title?: string,
  selectRows: any[],
  groups: any[],
  siteId?: any,
  handleHidden: (visible: boolean) => void
}

const AddGroupModal:FC<EditGroupModalProps> = (props) => {

  const { 
    handleSubmitForm, 
    visible, 
    handleCancelClick, 
    okText, title, 
    selectRows, 
    groups,
    siteId,
    handleHidden,
  } = props

  const [form] = useForm()
  const [addGroupVisible,setAddGroupVisible] =  useState<boolean>(false)

  // 提交表单
  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (handleCancelClick) {
      handleCancelClick(e)
    }
  }

  // 取消提交表单
  const onOk = (e: React.MouseEvent<HTMLElement>) => {
    form.validateFields().then((values: any) => {
      const { groupId } = values
      if (handleSubmitForm) {
        handleSubmitForm({...values, goodsIdList: selectRows, })
      }
    })
  }
    // 跳转到我的商品
    const handleLactionCommodityClick = () => {
      setAddGroupVisible(false)
      if (handleHidden) {
        handleHidden(false)
      }
      window.open('/' + siteId +'/mineCommodity/group')
    }

    // 渲染modal内容
    type Params = Record<string, any[]>
    const modalContentRender = ({ selectRows,groups }:Params) => {
      if (selectRows.length === 0) {
        return (
          <>
            <p>您未选择商品，请勾选商品后 再分组商品</p>
            <p>您也可以进入点击<Button type='link' onClick={handleLactionCommodityClick}>
              商品分组管理</Button>
            新增商品分组名称</p>
          </>
        )
      }
      
      if (groups.length === 0) {
        return ( 
          <p>您还未创建分组， 请移步 
            <Button type='link' onClick={handleLactionCommodityClick}>新增分组名称</Button>
          </p>
        )
      }
    }

  useEffect(() => {
    // 情况数据
    if (visible === true) {
      form.setFieldsValue({
        reserve: true,
        groupIdList: undefined
      })
      form.resetFields(['groupIdList'])
    }
    
  }, [visible, form])

  useEffect(()=> {
    setAddGroupVisible(visible)
  },[visible])

  return (
    <>
      <Modal
        title={title}
        visible={addGroupVisible}
        okText={okText}
        onCancel={onCancel}
        footer={
          selectRows.length===0 || groups.length===0
          ?(<Button type='primary' onClick={onCancel}>取消</Button>)
          :(<>
          <Button type='primary' onClick={onOk}>确定</Button><Button type='primary' onClick={onCancel}>取消</Button>
          </>)
        }
        onOk={onOk}
      >
        {
          selectRows.length && groups.length
            ?(<Form
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol = {{ span: 12 }}>
            <Row gutter={24}>
            <Col span={24}>
                <Item
                  label="是否保留商品原有分组"
                  name="reserve">
                    <Group>
                      <Radio value={true}>保留原有分组</Radio>
                      <Radio value={false}>不保留, 移入全新的分组</Radio>
                    </Group>
                </Item>
                </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
              <div style={{float: 'right'}}>
                  <Button type='link' onClick={handleLactionCommodityClick}>
                    新增分组名称
                  </Button>
                </div>
                <Item
                  label="添加到分组"
                  labelCol={{ span: 6 }}
                  wrapperCol = {{ span: 18 }}
                  rules={
                    [{ required: true, message: '分组名称必填' }]
                  }
                  name="groupIdList">
                  <Select
                  placeholder='请选择分组' 
                  mode="multiple">
                    {
                      groups
                        .filter((item: any) => item.type === 10)
                        .map((item: any) => {
                              const {groupName, id } = item
                              return (
                                <Option value={id} key={id}>{groupName}</Option>
                              ) 
                            })
                    }
                  </Select>
                </Item>
                </Col>
            </Row>
            </Form>)
            : modalContentRender({selectRows,groups})
        }
      </Modal>
    </>
  )
}

export default AddGroupModal