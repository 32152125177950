export const ENV = process.env.__fle_env__ || "prod";

const javaHost = {
  dev: 'https://dev-gateway.fxqifu.net/pangu', // 测试环境dev
  qa: 'https://qa-gateway.fxqifu.net/pangu', // 测试环境qa
  prod: 'https://gateway.fxqifu.net/pangu', // 线上
}
export const mainHost = javaHost[ENV]

export const DATA_CODE = {
  SUCCESS: 2000,
  QUERY_PARAMS_ERROR: 3000,
  LOGIN_ERROR: '100003',
  OTHER_ERROR: 5000,
}

export const getPreviewH5Url = (siteId,tinyId) => {
  const previewH5Map = new Map([
    ["dev", `http://dev-preview.fleshopy.com/index?id=${siteId}&tinyId=${tinyId}&genre=site`],
    ["qa", `https://qa-preview.fleshopy.com/index?id=${siteId}&tinyId=${tinyId}&genre=site`],
    ["prod", `https://preview.fleshopy.com/index?id=${siteId}&tinyId=${tinyId}&genre=site`]
  ])
  return previewH5Map.get(ENV);
};

export const getSiteH5Url = (id,tinyId) => {
  const siteUrlMap = new Map([
    ["dev", `http://${id}.dev-shop.fleshopy.com/tiny/${tinyId}?type=disable_link`],
    ["qa", `https://${id}.qa-shop.fleshopy.com/tiny/${tinyId}?type=disable_link`],
    ["prod", `https://${id}.shop.fleshopy.com/tiny/${tinyId}?type=disable_link`]
  ])
  return siteUrlMap.get(ENV);
};


const yunyingHostMap = {
  dev: 'http://dev-yunying.fxqifu.com/login', // 测试环境dev
  qa: 'https://qa-yunying.fxqifu.com/login', // 测试环境qa
  prod: 'https://yunying.fxqifu.com/login', // 线上
}
export const yunyingHost = yunyingHostMap[ENV]

export const getLoginAdminHost = (str = 'fle') => {
  let LOGIN_HOST = null
  if (/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(str)) {
    LOGIN_HOST = new Map([
      ['dev', `http://${str}`],
      ['qa', `https://${str}`],
      ['prod', `https://${str}`],
    ])
  } else {
    LOGIN_HOST = new Map([
      ['dev', `http://dev-${str}.peliten.com`],
      ['qa', `https://qa-${str}.peliten.com`],
      ['prod', `https://${str}.peliten.com`],
    ])
  }
  return LOGIN_HOST.get(ENV)
}

export const DATA_CODE_PRO = new Map([
  ['SUCCESS', '2000'],
  ['QUERY_PARAMS_ERROR', '4000'],
  ['LOGIN_ERROR', '100003'],
  ['OTHER_ERROR', '5000'],
  ['GOODS_CHANGE_PERMIT_BY_ACTIVITY', '50000407'],
])

export const ORIGIN = window.location.origin;
// export const ORIGIN = "https://qa-site.fleshopy.com";
