import React from "react";
import "./index.scss";
import UserInfo from "./UserInfo/index";
import UserAdd from "./UserInfo/UserAdd/index";
import UserDetail from "./UserInfo/UserDetail/index";
import RechangeList from "./RechargeList/index";
import { Switch, Route, Redirect } from "react-router-dom";
import { PageContainer } from "@fle-ui/pro-layout";
import { withRouter } from "react-router-dom";

const User = (props: any) => {

  return (
    <PageContainer className="user-route-layout">
      <Switch>
        <Route
          path="/user/userManagement/userInfo"
          exact
          component={UserInfo}
        />
        <Route path="/user/userManagement/userAdd" exact component={UserAdd} />
        <Route
          path="/user/userManagement/userDetail"
          exact
          component={UserDetail}
        />
        <Route
          path="/user/userManagement/rechangeList"
          exact
          component={RechangeList}
        />

        {/* 重定向 */}
        <Redirect from="/user" exact to="/user/userManagement/userInfo" />
      </Switch>
    </PageContainer>
  );
};
export default withRouter(User);
