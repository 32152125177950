import useUrlState from "@ahooksjs/use-url-state";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
} from "@fle-ui/next";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import './OrderListForm.scss'

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  onSearch: (values: any) => void;
  orderStatsList: any[];
};

type GetOrderListParams = any;

const OrderListForm = (props: Props) => {
  const { onSearch } = props;
  const [form] = Form.useForm();
  const [urlParams, setUrlParams] = useUrlState(
    { tab: "default" },
    { navigateMode: "replace" }
  );

  const onFinish = useCallback(async () => {
    try {
      const values: Partial<GetOrderListParams> = await form.validateFields();
      if (values.date) {
        const [start, end] = values.date;
        values.startTime = moment(start).format("YYYY-MM-DD HH:mm:ss");
        values.endTime = moment(end).format("YYYY-MM-DD HH:mm:ss");
        delete values.date;
      }
      values.status = urlParams.tab;
      if (urlParams.tab === "default") {
        values.status = undefined;
      }
      if (values.mallName) {
        let numJudge = /^\d+$/;
        if (numJudge.test(values.mallName)) {
          values.siteId = values.mallName;
          delete values.mallName;
        }
      }

      onSearch(values);
    } catch (e) {}
  }, [onSearch, form, urlParams]);

  const setForm = async () => {
    form.resetFields();
    // setUrlParams({ tab: "default" });
    await onFinish();
  };

  useEffect(() => {
    if (!urlParams.tab) {
      return;
    }
    onFinish();
  }, [urlParams, form, onFinish]);

  return (
    <div className="order-list-form-wrap">
      <Form form={form} name="searchOrder">
        <div style={{ display: "flex" }}>
          <Row gutter={16} style={{ flex: 1 }}>
            <Col span={8}>
              <Form.Item label="商品渠道" name="commodityChannel" style={{ marginBottom: 0 }}>
                <Select placeholder="请选择商品渠道">
                  <Option value="0">精选商品</Option>
                  <Option value="10">京东商品</Option>
                  <Option value="200">自有商品</Option>
                  <Option value="40">鲜花蛋糕</Option>
                  <Option value="50">猫超商品</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <FormItem label="下单时间" name={"date"} style={{ marginBottom: 0 }}>
                <RangePicker
                  placeholder={["请输入下单开始时间", "请输入下单结束时间"]}
                  style={{ width: "100%" }}
                  showTime
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <Form.Item label="搜索类型" name="searchType" style={{ marginBottom: 0 }}>
                <Select placeholder="请选择搜索类型">
                  <Option value="1">下单人账号</Option>
                  <Option value="2">商品名称</Option>
                  <Option value="3">收货人姓名</Option>
                  <Option value="4">收货人手机号</Option>
                  <Option value="5">使用卡号</Option>
                  <Option value="7">订单编号（用户）</Option>
                  <Option value="8">物流单号</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} >
              <Form.Item label="搜索内容" style={{ marginBottom: 0 ,marginTop:16}} name="searchContent">
                <Input placeholder="请输入内容" />
              </Form.Item>
            </Col>
            <Col span={8} ></Col>
            <Col span={8} >
            <div className="order-btn">
            <Button
              type={"primary"}
              htmlType={"submit"}
              onClick={async () => {
                await onFinish();
                // setUrlParams({ tab: "default" });
              }}
            >
              搜索
            </Button>
            <Button onClick={setForm} style={{ marginLeft: "8px" }}>
              重置
            </Button>
          </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};
export default observer(OrderListForm);
