import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Topbar } from "@/components/index";
import { CommonStore } from "@/store/index";
import "./index.scss";
import { ENV } from "@/config/index";
import { getUserByToken, getSiteShareConfig, getUserInfo } from "./store";
import SiteList from "@/views/Site/List";
import TemplateList from "@/views/Template/List";
import User from "@/views/user";
import OwnStore from "@/views/OwnStore/index";
import MineCommodity from "@/views/MineCommodity";
import OrderManage from "@/views/OrderManage";
import Empty from "@/views/Empty";
import Distribution from "@/views/Distribution";
// import GoodsDetail from "@/views/GoodsDetail";
import { parse } from "qs";
import commonStore from "@/store/commonStore";
import { isInIcestark } from "@ice/stark-app";
import { withRouter } from "react-router-dom";
import ProLayout from "@fle-ui/pro-layout";
import {
  routerAllList,
  routerType1List,
  routerType4List,
} from "@/router/routers";

const getSitePreview = (siteId: string | number, type: string = "fitment") => {
  // 获取站点装修地址
  let _url = "";
  const href: string = window.location.origin + "/" + siteId + "/survey";

  switch (ENV) {
    case "dev":
      _url = `http://dev-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site`;
      break;
    case "qa":
      _url = `http://qa-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site`;
      break;
    default:
      _url = `https://deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        "token"
      )}&origin=${encodeURIComponent(href)}&genre=site`;
  }
  return _url;
};
const Main = (props: any) => {
  const [userInfo, setUserInfo] = useState({});
  const [categoryId, setCategoryId] = useState<number>(1);
  const [siteName, setSiteName] = useState();
  const [customerName, setCustomerName] = useState();
  const siteId = Number(window.location.pathname.split("/")[1]);
  const [routers, setRouters] = useState<any>(routerType1List);

  const [adminPageLogo, setAdminPageLogo] = useState<string | null>(null);
  const history = useHistory();

  /* 判断营销号还是数字卡券权限 */
  useEffect(() => {
    const getSiteInfo = async (siteId: number) => {
      const { data, success } = (await getSiteShareConfig(siteId)) as any;
      if (success) {
        const {
          siteName,
          customerName,
          homepageDecorationId,
          adminPageLogo,
          app,
          cityId,
          provinceId,
          id
        } = data;
        setSiteName(siteName);
        setCustomerName(customerName);
        CommonStore.setAppId(app);
        CommonStore.setHomepageDecorationId(homepageDecorationId);
        setAdminPageLogo(adminPageLogo);
        setCategoryId(app);
        changeRouterList(app);
        commonStore.setCategoryId(app);
        commonStore.setProvinceId(provinceId);
        commonStore.setCityId(cityId);
        commonStore.setSiteId(id)
        // 通过遍历获取默认选中标签
      }
    };
    getSiteInfo(siteId);
  }, [siteId]);

  const changeRouterList = (type: any) => {
    let list = routerAllList
    if (type == 1) {
      // 商品展厅
      list = routerType1List
    }
    if (type == 4) {
      // 福利
      list = routerType4List
    }
    if ((ENV === 'qa' && siteId !== 103308) || (ENV === 'prod' && siteId !== 103935)) {
      list = list.filter(item => item.path !== '/distribution')
    }

    setRouters(list)
  };

  // 监听路由变化，添加新路由到历史栏
  useEffect(() => {
    if (history) {
      CommonStore.setHistoryRouter(history.location.pathname);
    }
    history.listen((router: any) => {
      if (router.pathname === "/store") {
        window.location.href = getSitePreview(siteId);
      }
      const arr = router.pathname.split("/").slice(1);
      if (arr.length && arr.length > 1) {
        const key = arr[0];
        CommonStore.updateState({ topMenu: key });
      }

      CommonStore.setHistoryRouter(router.pathname);
    });
  }, [history]);

  useEffect(() => {
    const getUserInfoByToken = async () => {
      const { data, success } = (await getUserByToken()) as any;
      if (success) {
        setUserInfo(data);
      }
    };
    getUserInfoByToken();
  }, []);

  // 不知道之前为什么要调 getUserByToken 这个接口 老代码了
  useEffect(() => {
    const getInfo = async () => {
      const { data, success } = (await getUserInfo()) as any;
      if (success) {
        if (data.loginLink) {
          localStorage.setItem("loginLink", data.loginLink);
          sessionStorage.setItem("userInfo", JSON.stringify(data));
        }
      }
    };
    getInfo();
  }, []);

  const searchParams = parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  // 是否显示顶部导航
  const showTopBar = () => {
    return !("goodsId" in searchParams) || !isInIcestark();
  };

  return (
    <div className="fx-main-wrap">
      {showTopBar() && (
        <Topbar
          userInfo={userInfo}
          siteName={siteName}
          logo={adminPageLogo}
          customerName={customerName}
          categoryId={categoryId}
        />
      )}
      <div className="fx-main-area fx-flexbox fx-flexbox-item">
        <div className="fx-main-content fx-flexbox-item">
          <div className="fx-main-router">
            <ProLayout
              className="pro-layout-wrap"
              siderWidth={192}
              menuList={routers}
              location={props.location}
              offsetTop={50}
            >
              <Switch>
                <Redirect exact from="/" to="/ownStore" />
                <Route path="/site" component={SiteList} />
                <Route path="/survey" exact component={TemplateList} />
                <Route path="/user" component={User} />
                <Route path="/ownStore" component={OwnStore}></Route>
                <Route path="/mineCommodity" component={MineCommodity} />
                {/* <Route path="/commodityDetail" component={GoodsDetail} /> */}
                <Route path="/order" component={OrderManage} />
                <Route path="/distribution" component={Distribution} />
                <Route path="/empty" exact component={Empty}></Route>
              </Switch>
            </ProLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Main);
