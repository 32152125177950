import request from '@/utils/httpClient'
import { mainHost } from '@/config/index'

// 获取用户信息
export const getUserByToken = () => {
  return request({
    method: 'post',
    url: mainHost + '/elebuys/right/user/getUserByToken',
  })
}

export const getSiteShareConfig = (siteId: number) => {
  return request({
    method: 'get',
    url: mainHost + '/fleshopy/merchant/site/getSite',
    params: { siteId },
  })
}

export const getCategoryInfo = (siteId: number) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/getCategoryInfo',
    method: 'get',
    params: {
      siteId,
    },
  })
}

// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'get',
    url: mainHost + '/elebuys/member/employee/member-info',
  })
}
