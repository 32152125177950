import "./index.scss";

import React, { useEffect, FC, useState } from "react";

import { Button, Table } from "@fle-ui/next";

export type OnChange = (page: number, pageSize: number) => void;

// 分页接口
export interface Pagination {
  pageSize?: number;
  total?: number;
  current?: number;
  pageSizeOptions: string[];
  showSizeChanger?: boolean;
  showQuickJumper?: boolean;
  showTotal?: (total: number) => React.ReactNode;
  onchange?: OnChange;
  onShowSizeChange?: OnChange;
}

export interface TableProps {
  tableData?: any[];
  pagination: false | Pagination;
  onEdit: (record: any) => void;
  onDelete: (id: string) => void;
  loading: boolean;
  onSortChange?: any;
}

const names = [
  { name: "新品上架", explain: "最新上架的50个商品，新上架的显示在前面" },
  { name: "待分组", explain: "既不在新品上架也不在具体分组中" },
];

const Tables: FC<TableProps> = (props) => {
  const { pagination, onEdit, onDelete, tableData, loading, onSortChange } =
    props;
  const columns = [
    {
      title: "分组名称",
      dataIndex: "groupName",
      align: "left",
      width: 600,
      render: (text: string, record: any, index: number) => {
        const { groupName } = record;
        const current = names.find((item) => item.name === groupName);
        return (
          <div>
            <span>
              {groupName}
              {current && (
                <span style={{ color: "#d9d9d9", paddingLeft: "10px" }}>
                  ({current.explain})
                </span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "商品数",
      dataIndex: "goodsCount",
      width: 600,
      align: "left",
      sorter: {
        compare: (a: any, b: any) => {
          if (a.type === 10 && b.type === 10) {
            return a.goodsCount - b.goodsCount;
          }
          return 0;
        },
        multiple: 2,
      },
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      align: "left",
      width: 200,
    },
    {
      title: "操作",
      align: "left",
      width: 180,
      render: (text: any, record: any, index: number) => {
        const { id, groupName, type } = record;
        return (
          <div className="handle-wrapper">
            <Button
              type="link"
              disabled={!(type === 10)}
              onClick={() => onEdit({ groupId: id, groupName })}
            >
              编辑
            </Button>
            <div className="handle-wrapper-line">|</div>
            <Button
              type="link"
              disabled={!(type === 10)}
              onClick={() => onDelete(id)}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="group-table-wrapper">
      <Table
        columns={columns}
        onChange={onSortChange}
        dataSource={tableData}
        loading={loading}
        pagination={pagination}
        rowKey="id"
      />
    </div>
  );
};
export default Tables;
