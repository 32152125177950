const sortBtnList: any = [
  {
    text: "浏览量",
    key: "pvCount",
    explain: "商城当日累计浏览页面数次数，1人1个页面浏览多次计算多次",
  },
  {
    text: "访客",
    key: "uvCount",
    explain: "商城当日累计浏览的人数，1人多次进入商城计算1人",
  },
];

const sortOrderList: any = [
  {
    text: "已拍下",
    key: "ordered",
    explain: "商城当日累计提交订单的订单总数",
  },
  {
    text: "已支付",
    key: "paid",
    explain: "商城当日累计提交订单并支付的订单数（含现金支付和积分支付订单）",
  },
];

const sortKnockdownList: any = [
  {
    text: "支付金额",
    key: "payment",
    explain: "商城用户当日累计支付金额",
  },
  {
    text: "消耗金额",
    key: "expend",
    explain: "商城当日累计消耗预存货款金额",
  },
  {
    text: "待扣款金额",
    key: "withhold",
    explain: "商城当日累计待扣预存货款金额",
  },
];

const sortIntegralList: any = [
  {
    text: "积分总数",
    key: "integralSum",
    explain: "商城当日累计使用积分消耗的积分总数",
  },
  {
    text: "积分人数",
    key: "IntegralPeople",
    explain: "商城当日累计使用积分消耗的总人数",
  },
];

export const accessings: any = {
  pvCount: "浏览量",
  uvCount: "访客",
};

export const orders: any = {
  createCount: "已拍下",
  payedCount: "已支付",
};
export const integrals: any = {
  name1: "积分总数",
  name2: "积分人数",
};

export const knockdowns: any = {
  customerPayed: "支付金额",
  memberPayed: "消耗金额",
  memberNotPayed: "待扣款金额",
};

export const sortRecord: any = {
  accessing: sortBtnList,
  order: sortOrderList,
  integral: sortIntegralList,
  knockdown: sortKnockdownList,
};

export const breakLineRecord: any = {
  accessing: accessings,
  order: orders,
  integral: integrals,
  knockdown: knockdowns,
};

// 初始化页面时默认选中的第一项
export const sortKeyRecord: any = {
  accessing: "pvCount",
  order: "payNum",
  integral: "integral",
  knockdown: "consumePrice",
};

export const color: string[] = [
  "#0090FF",
  "#36CE9E",
  "#FFC005",
  "#FF515A",
  "#8B5CFF",
  "#00CA69",
];
