import { DetailGoodItemProp, ThirdType } from "@/store/OrderDetailStore";
import { Col, Row, Space, Tag, Typography } from "@fle-ui/next";
import React, { FC } from "react";
const { Paragraph } = Typography;

const GoodDesc = (props: any) => {
  const { goodsImage, specList, goodsName, isSelf, thirdType, salePrice, quantity } = props;
  const data = JSON.parse(specList || "[]")
    .map((item: { name: string; value: string }) => `${item["name"]}:${item["value"]}`)
    .join("");
  return (
    <Row style={{ paddingLeft: 28, height: 96, width: "100%" }}>
      <Col span={3} className="table_vertical_left">
        <img width={46} src={goodsImage} alt="" style={{ height:46 }} />
      </Col>
      <Col span={2}></Col>
      <Col span={9} className="table_vertical_left" style={{ paddingRight: 16 }}>
        <div style={{ display: "flex", alignItems: "start", lineHeight: "100%" }}>
          <Tag
            color="#F53466"
            style={{
              width: 38,
              background: "#FF003C",
              borderRadius: 4,
              lineHeight: "18px",
              fontSize: 12,
            }}
          >
            {isSelf === 1 ? "自有" : ThirdType[thirdType]}
          </Tag>
          <Paragraph
            ellipsis={{
              rows: 3,
              tooltip: <>{goodsName}</>,
            }}
            style={{ width: "100%", padding: 0, margin: 0, lineHeight: "18px", color: "#4C4C4C", textAlign: "left" }}
          >
            {goodsName}
          </Paragraph>
        </div>

        <Paragraph
          ellipsis={{
            rows: 2,
            tooltip: <>{goodsName}</>,
          }}
          style={{ width: "100%", padding: 0, margin: 0, lineHeight: "18px", color: "#4C4C4C", textAlign: "left" }}
        >
          {data}
        </Paragraph>
      </Col>

      {/* 单价 */}
      <Col span={5} className="table_vertical_left">
        ￥{(salePrice / 100).toFixed(2)}
      </Col>
      {/* 数量 */}
      <Col span={5} className="table_vertical_left">
        {quantity}个
      </Col>
    </Row>
  );
};

export default GoodDesc;
