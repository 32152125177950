import { commodityNewGroupStore } from '@/store'
import { Button, Col, Form, Input, message, Row, Space, Modal } from '@fle-ui/next'
import Upload from "../components/Upload"

import React, { FC, useEffect, useState } from 'react'
import "./index.scss"
interface Iprops {
  createGroupModal: boolean
  setCreateGroupModal: any
  onReset: any
}
const index: FC<Iprops> = (props) => {
  const { createGroupModal, setCreateGroupModal, onReset } = props
  const [form] = Form.useForm()

  const handleCancel = () => {
    setCreateGroupModal(false)

  }
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const onSave = async() => {
    await form.validateFields()
    const {groupImage} = form.getFieldsValue()
    const params = {
      ...form.getFieldsValue(),
      siteId: Number(sessionStorage.getItem('siteId')),
      groupImage:groupImage&&groupImage[0].url
    }
    const res:any  = await commodityNewGroupStore.addGroup({...params})
    if(res?.success) {
      message.success('创建成功')
      setCreateGroupModal(false)
      onReset()
    }
  }

  return (
    <Modal title="新建分组"
      visible={createGroupModal}
      onOk={onSave}
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} className='form'>
        <Form.Item label={`分组名称`} name={`groupName`} rules={[{ required: true, message: '请输入!' }]}>
          <Input placeholder='如家居家纺' showCount maxLength={20}/>
        </Form.Item>
        <Form.Item label={`分组介绍`} name={`groupIntroduce`}>
          <Input.TextArea placeholder='请输入' maxLength={200} showCount />
        </Form.Item>
        <Form.Item label={`分组图片`} name={`groupImage`}>
          <Upload form={form} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default index