import React, { FC, useState, useEffect, useRef, useCallback } from "react";

import TableList from "./TableList/index";

import AlterFail from "@/components/alterFail";

import { observer, inject } from "mobx-react";
import EditGroupModal from "./EditGroupModal";
import SearchForm from "./SearchForm";
import { ShopTypeStore } from "@/store/index";
import { CommonStore } from "@/store/index";
import { toJS } from "mobx";

import {
  getGoodsListBySiteStatus,
  removeGoodsByGoodsId,
  batchUpdateByGoodsId,
  changePricePickGoods,
  getBasicArea,
  getCategory,
  getSiteShareConfig,
  getStockJdBatch,
  getGroupSelects,
  updateGroup,
  checkBeforeAddGoods,
} from "./store";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { getFakeTotal } from "@/utils/common";

import "./index.scss";

import { Form, Modal, message, Spin, Table, Empty } from "@fle-ui/next";
const { confirm } = Modal;

interface SearchParamsType {
  sortJudge: number;
  name?: string;
  goodsId?: string;
  thirdType?: string | undefined;
  orderName?: string | undefined;
  orderWay: string;
  selectGoods: string | undefined;
  isSelf: number | string;
  keyword?: string;
  cityCodeList?: any[];
  categoryTree?: string;
  poolSaasStatus: -10 | 10 | "";
  provinceCode?: any;
  cityCode?: any;
  siteGoodsGroupIdList?: any[];
  onceMarketPriceMax: string | undefined;
  onceMarketPriceMin: string | undefined;
  onceSalePriceMax: string | undefined;
  onceSalePriceMin: string | undefined;
  onceSitePriceMax: string | undefined;
  onceSitePriceMin: string | undefined;
  onceSiteProfitMax: string | undefined;
  onceSiteProfitMin: string | undefined;
  purchaseMarketPriceMax: string | undefined;
  purchaseMarketPriceMin: string | undefined;
  purchaseSalePriceMax: string | undefined;
  purchaseSalePriceMin: string | undefined;
  purchaseSitePriceMax: string | undefined;
  purchaseSitePriceMin: string | undefined;
  purchaseSiteProfitMax: string | undefined;
  purchaseSiteProfitMin: string | undefined;
  selectPrice: string | undefined;
  selectProfit: string | undefined;
  priceSearchMin: string | undefined;
  priceSearchMax: string | undefined;
  profitSearchMin: string | undefined;
  profitSearchMax: string | undefined;
  type: number | string | null | undefined;
}

export interface SearchFormType {
  initialValues?: SearchParamsType;
  onFinish: (values: any) => void;
  handleResetClick: () => void;
  defaultOptions: any;
  poolSaasStatus: any;
  groups: any[];
  form: any;
  defaultCategorys: any;
  siteId: number;
}

export const supplierTypeAndsourceMap: any = {
  2: "fx",
  10000: "jd",
  3: "self",
};

export const getAreaList = async (parentCode: number) => {
  const { data, success } = (await getBasicArea(parentCode)) as any;
  if (success) {
    return data.map((item: any) => {
      return {
        ...item,
        value: item.code,
        label: item.name,
        isLeaf: item.level > 1 ? true : false,
      };
    });
  }
};

// 获取分类id
const getCategoryList = async () => {
  /* const { data, success } = await getCategory(parentId) as any */
  const { data, success } = (await getCategory()) as any;
  if (success) {
    /*  return data.map((item: any) => {
      return {
        ...item,
        value: item.categoryId,
        label: item.categoryName,
        isLeaf: item.leaf === 1 ? false : true
      }
    }) */
    data.forEach((item: any) => {
      item.label = item.categoryName;
    });
  }
  return data;
};

// 获取地址
const getSiteAddress = async (list: any[]) => {
  const province = await getAreaList(0);
  const provinceCurent = province.find((item: any) => item.code === list[0]);
  const city = await getAreaList(list[0]);
  provinceCurent.children = city;
  return province;
};

// 筛选商品
// 默认的搜索筛选值
const searchFormparams: SearchParamsType = {
  sortJudge: 1,
  name: "",
  goodsId: "",
  thirdType: "",
  orderName: undefined,
  orderWay: "desc",
  selectGoods: undefined,
  isSelf: "",
  cityCodeList: [],
  keyword: "",
  siteGoodsGroupIdList: [],
  categoryTree: undefined,
  poolSaasStatus: "",
  onceMarketPriceMax: undefined,
  onceMarketPriceMin: undefined,
  onceSalePriceMax: undefined,
  onceSalePriceMin: undefined,
  onceSitePriceMax: undefined,
  onceSitePriceMin: undefined,
  onceSiteProfitMax: undefined,
  onceSiteProfitMin: undefined,
  purchaseMarketPriceMax: undefined,
  purchaseMarketPriceMin: undefined,
  purchaseSalePriceMax: undefined,
  purchaseSalePriceMin: undefined,
  purchaseSitePriceMax: undefined,
  purchaseSitePriceMin: undefined,
  purchaseSiteProfitMax: undefined,
  purchaseSiteProfitMin: undefined,
  selectPrice: undefined,
  selectProfit: undefined,
  priceSearchMin: undefined,
  priceSearchMax: undefined,
  profitSearchMin: undefined,
  profitSearchMax: undefined,
  type: "3",
};

const Optional: FC<{ [key: string]: any }> = (props) => {
  const { poolSaasStatus, siteId } = props;
  const app = toJS(CommonStore).appId;
  const provinceId = toJS(CommonStore).provinceId;
  const cityId = toJS(CommonStore).cityId;
  // let app = 2;
  const [form] = Form.useForm();
  const [initialSearchlValues, setInitialSearchlValues] =
    useState<SearchParamsType>(searchFormparams);
  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [pageNo, setPageNo] = useState<number | undefined>(1);
  const [total, setTotal] = useState<number>(0);
  const [realTotal, setRealTotal] = useState<number>(0);

  const [disabled, setDisabled] = useState<boolean>(true);
  const [rows, setRows] = useState<any>([]);
  const [rowsData, setRowsData] = useState<any>([]);
  const rowsRef = useRef(rows);

  const radioRef = useRef();

  const selectRowsRef = useRef(rows);
  const [visiblePrice, setVisiblePrice] = useState(false);
  const [visiblePriceMarket, setVisiblePriceMarket] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [options, setOptions] = useState([]);
  const [categorys, setCategorys] = useState([]);
  const [addGroupVisible, setAddGroupVisible] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const [currentData, setCurrentData] = useState({});
  const [groups, setGroups] = useState<any[]>([]);
  const [tableRset, setTableRset] = useState<boolean>(false);

  const [searchSortValue, setSearchSortValue] = useState<any>();

  // 修改定价
  const handlechangePricePickGoods = async (
    goodsIdList: any,
    isPickGoods: any,
    priceRuleParams: any[],
    siteId: any
  ) => {
    try {
      const { success } = (await changePricePickGoods(
        goodsIdList,
        isPickGoods,
        priceRuleParams,
        siteId
      )) as any;
      if (success) {
        setTimeout(() => {
          setConfirmLoading(false);
          message.success("改价成功");
          setPageNo(1);
          handleSubmitClick(initialSearchlValues, pageNo, pageSize);
          setVisiblePrice(false);
          setVisiblePriceMarket(false);
        }, 1000);
      } else {
        setConfirmLoading(false);
      }
    } catch (error) {
      setConfirmLoading(false);
    }
  };

  const handleOk = async (
    data: any,
    isPickGoods = 0,
    goodsIdList = selectRowsRef.current
  ) => {
    data.isPickGoods = 0;
    setCurrentData(data);
    const delKey = (obj: any, ...args: any) => {
      args.forEach((v: string | number) => {
        delete obj[v];
      });
      return obj;
    };
    // 营销云改价（集采批发/集采代发/一件代发）
    if (data[0].modeType == 1) {
      const priceRuleParams: any[] = [];
      delKey(data[0], "id", "label", "more", "tips", "value");
      delKey(data[1], "id", "label", "more", "tips", "value");
      priceRuleParams.push(data[0]);
      priceRuleParams.push(data[1]);

      setConfirmLoading(true);
      const result: any = await checkBeforeAddGoods(
        goodsIdList,
        isPickGoods,
        priceRuleParams,
        siteId
      );

      if (result.success && result?.data > 0) {
        Modal.confirm({
          title: "温馨提示",
          icon: <ExclamationCircleOutlined />,
          cancelButtonProps: { style: { display: "none" } },
          content: `*当前有${result.data}件商品存在亏本，请重新设置`,
          okText: "确认",
          cancelText: "我在想想",
          onOk: () => {
            setConfirmLoading(false);
          },
        });
      } else {
        console.log('else');
        handlechangePricePickGoods(
          goodsIdList,
          isPickGoods,
          priceRuleParams,
          siteId
        );
      }
    } else {
      // 数字卡券改价（一件代发）
      const { modeType, changeType, operateType, rate } = data[0];
      const priceRuleParams: any[] = [];
      delKey(
        data[0],
        "id",
        "label",
        "more",
        "tips",
        "value",
        "customTips",
        "tipSelf"
      );
      priceRuleParams.push(data[0]);

      setConfirmLoading(true);
      if ((rate == null || rate === undefined) && operateType !== 3) {
        setConfirmLoading(false);
        return;
      }
      const result: any = await checkBeforeAddGoods(
        goodsIdList,
        isPickGoods,
        priceRuleParams,
        siteId
      );
      const isSelf = ShopTypeStore.getType();

      if (changeType === 2 && operateType === 2 && rate == 100) {
        setConfirmLoading(false);
        Modal.confirm({
          title: "定价失败",
          icon: <ExclamationCircleOutlined />,
          content: "*销售价必须大于0",
          okText: null,
          cancelText: "我再想想",
        });
      } else if (
        result.data > 0 &&
        isSelf === 0 &&
        operateType === 2 &&
        (changeType === 2 || changeType === 3)
      ) {
        setConfirmLoading(false);
        Modal.confirm({
          title: "温馨提示",
          icon: <ExclamationCircleOutlined />,
          // content: (
          //   <div>
          //     <p style={{ textAlign: "left" }}>
          //       *存在商品低于会员价，将导致您亏本请确认是否继续执行定价
          //     </p>
          //   </div>
          // ),
          content: (
            <div>
              <p style={{ textAlign: "left" }}>
                *当前有${result.data}件商品存在亏本,请重新设置
              </p>
            </div>
          ),
          okText: "确认",
          onOk: () => {
            // handlechangePricePickGoods(
            //   goodsIdList,
            //   isPickGoods,
            //   priceRuleParams,
            //   siteId
            // );
            setConfirmLoading(false);
          },
          cancelText: "我再想想",
          cancelButtonProps: { style: { display: "none" } },
        });
      } else if (
        result.data > 0 &&
        isSelf === 1 &&
        operateType === 2 &&
        (changeType === 2 || changeType === 3)
      ) {
        setConfirmLoading(false);
        Modal.confirm({
          title: "温馨提示",
          icon: <ExclamationCircleOutlined />,
          // content: "*存在商品销售价小于采购价确认亏本销售吗？",
          content: `*当前有${result.data}件商品存在亏本，请重新设置`,
          okText: "确认",
          onOk: () => {
            // handlechangePricePickGoods(
            //   goodsIdList,
            //   isPickGoods,
            //   priceRuleParams,
            //   siteId
            // );
            setConfirmLoading(false);
          },
          cancelText: "我再想想",
          cancelButtonProps: { style: { display: "none" } },
        });
      } else if (result.data > 0) {
        setConfirmLoading(false);
        Modal.confirm({
          title: "温馨提示",
          icon: <ExclamationCircleOutlined />,
          content: `*当前有${result.data}件商品存在亏本，请重新设置`,
          okText: "确认",
          cancelButtonProps: { style: { display: "none" } },
          onOk: () => {
            setConfirmLoading(false);
          },
          cancelText: "我再想想",
        });
      } else {
        handlechangePricePickGoods(
          goodsIdList,
          isPickGoods,
          priceRuleParams,
          siteId
        );
      }
    }
  };

  const showModal = (e: any, goods: number, rules: any) => {
    if (goods) {
      selectRowsRef.current = [goods];
    }
    if (app == 1) {
      setVisiblePriceMarket(true);
    } else {
      setVisiblePrice(true);
    }
  };

  const handleCancelPrice = () => {
    setVisiblePrice(false);
    setVisiblePriceMarket(false);
  };

  // 设置分页
  const pagination = {
    pageSize,
    total,
    current: pageNo,
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: () => `共${realTotal}条,第${pageNo}页`,
  };
  // 获取表单搜素参数
  const handleSubmitClick = async (values: any, start = 1, pageSize = 10) => {
    setInitialSearchlValues({ ...values, ...searchSortValue });
    setTableLoading(true);
    setPageNo(start);

    if (app == 1) {
      ShopTypeStore.setTypeStatus(values?.type);
    } else {
      ShopTypeStore.setTypeStatus("2");
      values.type = "2";
    }
    selectRowsRef.current = [];
    const {
      purchaseMarketPriceMax,
      purchaseMarketPriceMin,
      purchaseSalePriceMax,
      purchaseSalePriceMin,
      purchaseSitePriceMax,
      purchaseSitePriceMin,
      onceMarketPriceMax,
      onceMarketPriceMin,
      onceSalePriceMax,
      onceSalePriceMin,
      onceSitePriceMax,
      onceSitePriceMin,
      thirdType,
      sortJudge,
    } = values;
    let sortValue = {};
    if (sortJudge) {
      sortValue = { ...searchSortValue };
    }
    const cityCodeList = values.cityCodeList || [];
    const cityCode = cityCodeList[1];
    const provinceCode = cityCodeList[0];
    const params = {
      ...values,
      purchaseMarketPriceMax: purchaseMarketPriceMax
        ? purchaseMarketPriceMax * 100
        : undefined,
      purchaseMarketPriceMin: purchaseMarketPriceMin
        ? purchaseMarketPriceMin * 100
        : undefined,
      purchaseSalePriceMax: purchaseSalePriceMax
        ? purchaseSalePriceMax * 100
        : undefined,
      purchaseSalePriceMin: purchaseSalePriceMin
        ? purchaseSalePriceMin * 100
        : undefined,
      purchaseSitePriceMax: purchaseSitePriceMax
        ? purchaseSitePriceMax * 100
        : undefined,
      purchaseSitePriceMin: purchaseSitePriceMin
        ? purchaseSitePriceMin * 100
        : undefined,

      onceMarketPriceMax: onceMarketPriceMax
        ? onceMarketPriceMax * 100
        : undefined,
      onceMarketPriceMin: onceMarketPriceMin
        ? onceMarketPriceMin * 100
        : undefined,
      onceSalePriceMax: onceSalePriceMax ? onceSalePriceMax * 100 : undefined,
      onceSalePriceMin: onceSalePriceMin ? onceSalePriceMin * 100 : undefined,
      onceSitePriceMax: onceSitePriceMax ? onceSitePriceMax * 100 : undefined,
      onceSitePriceMin: onceSitePriceMin ? onceSitePriceMin * 100 : undefined,
      pageNo: start,
      provinceCode,
      cityCode,
      pageSize,
      ...sortValue,
    };
    delete params.cityCodeList;
    // 删除非表单搜索字段，此字段为了排序功能区分重置与搜索
    delete params.sortJudge;

    // 删除非表单搜索字段，只为重置操作此item能够置空
    delete params.selectGoods;
    delete params.selectPrice;
    delete params.selectProfit;
    delete params.priceSearchMin;
    delete params.priceSearchMax;
    delete params.profitSearchMin;
    delete params.profitSearchMax;
    // 如果values.thirdType为1000，则为自有商品，增加isSelf字段，删除thirdType字段
    if (values.thirdType.length == 0) {
      delete params.isSelf;
    } else {
      if (values.thirdType == "1000") {
        delete params.thirdType;
        params.isSelf = 1;
      } else {
        params.isSelf = 0;
      }
    }

    // 如果是数字卡券站点，包含一件代发商品&集采
    if (app !== 1 ) {
      params.type = "0";
    }

    ShopTypeStore.setType(params.isSelf);

    const { data, success } = (await getGoodsListBySiteStatus({
      ...params,
      poolSaasStatus,
      siteId,
    })) as any;
    const { list = [], total = 0 } = data || {};
    if (success && list) {
      setDisabled(true);
      setTableLoading(false);
      setRows([]);
      setTotal(getFakeTotal(pageSize, total));
      setRealTotal(total);
    }

    const thirdSkuList = list
      .filter((item: any) => {
        return item.thirdType === 10;
      })
      .map(({ thirdSku, goodsId }: any, index: any) => {
        return {
          num: 1,
          goodsId,
        };
      });

    // 京东商品库存需要另外调接口匹配
    try {
      if (
        thirdSkuList.length &&
        thirdSkuList[0] !== null &&
        thirdSkuList[0] !== undefined
      ) {
        const { success, data } = (await getStockJdBatch(thirdSkuList)) as any;
        if (success) {
          for (let i = 0; i < list.length; i++) {
            const currenet = data.filter(
              (item: any) => item.thirdSku === list[i].thirdSku
            );
            if (currenet.length > 0) {
              list[i].stock = currenet[0].stockNum
                ? currenet[0].stockText
                : "无货";
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    setTableData(list);
  };

  const showConfirm = (
    text: string,
    content: string,
    cb: any,
    ...reset: any[]
  ) => {
    confirm({
      title: `确定${text}${text === "上架" ? "!" : "?"}`,
      icon: <ExclamationCircleOutlined />,
      content,
      cancelText: "我再想想",
      okText: `确认${text}`,
      async onOk() {
        // tslint:disable-next-line:no-invalid-this
        const { success } = (await cb.apply(this, reset)) as any;
        if (success) {
          message.success(`${text}成功`);
          setTimeout(() => {
            handleSubmitClick(initialSearchlValues, pageNo, pageSize);
          }, 1000);
        } else {
          message.error(`${text}失败, 请稍后再试`);
        }
      },
    });
  };

  // 移除商品
  const handleRemoveGoods = (goodsId: number) => {
    showConfirm(
      "移除",
      "商品移除后， 将从商城下架，商品不再显示在本页中",
      removeGoodsByGoodsId,
      siteId,
      [goodsId]
    );
  };

  // 上下架商品
  const handleSoldOutGoods = async (
    status: number,
    goodsId: number[] = rowsRef.current
  ) => {
    const text = status === 10 ? "下架" : "上架";
    const goodsStatus = status === 10 ? 2 : 1;
    goodsId = goodsId.map((item) => Number(item));
    if (text === "下架") {
      showConfirm(text, "", batchUpdateByGoodsId, siteId, goodsId, goodsStatus);
    } else {
      const { success } = (await batchUpdateByGoodsId(
        siteId,
        goodsId,
        goodsStatus
      )) as any;

      if (success) {
        message.success(`${text}成功`);
        setTimeout(() => {
          handleSubmitClick(initialSearchlValues, pageNo, pageSize);
        }, 1000);
      }
    }
  };

  // 重置表单
  const handleResetClick = async () => {
    setTableRset(true);
    let params = searchFormparams;
    params.type = "1";
    if (app === 2 ) {
      params.selectProfit = "2";
    }
    params.sortJudge = 0;
    setPageNo(1);
    setPageSize(10);
    setSearchSortValue({});
    form.setFieldsValue(params);
    await handleSubmitClick(params);
    setTableRset(false);
  };

  const getGrouopsBySiteId = async () => {
    form.setFieldsValue({ groupId: undefined });
    const { success, data } = (await getGroupSelects(
      siteId.toString(),
      supplierTypeAndsourceMap[poolSaasStatus]
    )) as any;
    if (success && Array.isArray(data)) {
      const filterList = data.filter((item: any) => item.type === 10);
      setGroups(filterList);
    }
  };
  const getGrouopsBySiteIdCallback = useCallback(getGrouopsBySiteId, [
    poolSaasStatus,
    siteId,
    form,
  ]);

  const listenDocumentVisibilityState = useCallback(() => {
    const cb = () => {
      if (document.visibilityState === "visible") {
        getGrouopsBySiteIdCallback();
      }
    };

    document.addEventListener("visibilitychange", cb);

    return () => {
      document.removeEventListener("visibilitychange", cb);
    };
  }, [getGrouopsBySiteIdCallback]);

  useEffect(() => {
    getGrouopsBySiteIdCallback();
  }, [getGrouopsBySiteIdCallback]);

  useEffect(() => {
    const cb = listenDocumentVisibilityState();
    return () => {
      cb();
    };
  }, [listenDocumentVisibilityState]);

  useEffect(() => {
    if (app <= 0) return
    setTableData([]);
    setTableLoading(true);
    const getSiteInfo = async (siteId: number) => {
      handleSubmitClick({
        ...searchFormparams,
      });
    };
    getAreaList(0).then((data) => {
      setOptions(data);
    });

    getCategoryList().then((data) => {
      setCategorys(data);
    });
    getSiteInfo(siteId);
  }, [siteId, poolSaasStatus, app]);

  // 获取选择表格的内容多选框
  const hanleSelectChange = (selectedRowKeys: any, selectedRows: any) => {
    rowsRef.current = selectedRowKeys;
    setRows(selectedRowKeys);
    setRowsData(selectedRows);
    selectRowsRef.current = selectedRows.map((item: any) => item.goodsId);
    if (selectedRowKeys.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // 获取选择表格的内容多选框的商品渠道数量
  const thirdTypeRow = useCallback(() => {
    // 批量改价时对于同一类的商品允许改价，不同类型商品弹窗提示终止操作
    // 批量改价时批量挑选商品需要传入isSelf字段，供改价弹窗判断是否是自有商品
    const dataRow = rowsData.map(
      (item: any) => item.isSelf + "_" + item.thirdType
    );
    const setData = new Set(dataRow);
    const isSelf: any = Array.from(setData)[0];

    return [setData.size, isSelf.slice(0, 1)];
  }, [rowsData]);

  const showeEditGroupModal = () => {
    setAddGroupVisible(true);
  };
  const handleEditModalCancel = () => {
    setAddGroupVisible(false);
  };

  const handleEditGroup = async (values: any) => {
    const { success } = (await updateGroup(siteId, {
      ...values,
      source: supplierTypeAndsourceMap[poolSaasStatus],
    })) as any;
    if (success) {
      form.submit();
      selectRowsRef.current = [];
      message.success("修改分组成功");
      setAddGroupVisible(false);
    }
  };

  // 箭头排序逻辑
  const sortMap = new Map([
    ["desc", "max"],
    ["asc", "min"],
  ]);

  const descSearch = [
    "search_once_market_price",
    "pool_saas_search_once_site_price",
    "pool_saas_sales_num",
    "pool_saas_search_once_site_profit",
    "search_once_sale_price",
  ];

  const purchaseSearch = [
    "search_purchase_market_price",
    "pool_saas_search_purchase_site_price",
    "pool_saas_sales_num",
    "pool_saas_search_purchase_site_profit",
    "search_purchase_sale_price",
  ];

  const handleSearchFormClick = (
    name: string,
    radioValue: "desc" | "asc" | undefined,
    pagination: any
  ) => {
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    form
      .validateFields()
      .then((values: any) => {
        let selectValue: any;
        let composePriceAndSort: any = [];
        if (radioValue) {
          switch (name) {
            case "onceMarketPriceMin":
              selectValue = descSearch[0];
              break;
            case "onceSitePriceMin":
              selectValue = descSearch[1];
              break;
            case "siteSalesNum":
              selectValue = descSearch[2];
              break;
            case "onceSiteProfitMin":
              selectValue = descSearch[3];
              break;
            case "onceSalePriceMin":
              selectValue = descSearch[4];
              break;
            case "purchaseMarketPriceMin":
              selectValue = purchaseSearch[0];
              break;
            case "purchaseSitePriceMin":
              selectValue = purchaseSearch[1];
              break;
            case "purchaseSiteProfitMin":
              selectValue = purchaseSearch[3];
              break;
            case "purchaseSalePriceMin":
              selectValue = purchaseSearch[4];
              break;
            default:
              break;
          }
          composePriceAndSort =
            descSearch[2] === selectValue
              ? [selectValue]
              : [selectValue, sortMap.get(radioValue) as string];
        }
        const data = composePriceAndSort[0]
          ? {
              ...values,
              orderName: composePriceAndSort.join("_"),
              orderWay: radioValue,
            }
          : values;
        const dataSort = {
          orderName: composePriceAndSort.join("_"),
          orderWay: radioValue,
        };
        setSearchSortValue(dataSort);
        console.log(data, "data");
        handleSubmitClick(data, pagination.current, pagination.pageSize);
      })
      .catch(() => {
        console.error("提交失败");
      });
  };
  return (
    <>
      <div className="search-form">
        <SearchForm
          form={form}
          groups={groups}
          onFinish={handleSubmitClick}
          initialValues={initialSearchlValues}
          handleResetClick={handleResetClick}
          defaultCategorys={categorys}
          poolSaasStatus={poolSaasStatus}
          siteId={siteId}
          defaultOptions={options}
        />
      </div>

      <Spin spinning={tableRset}>
        <div className="basic-search-form-table">
          {!tableRset ? (
            <TableList
              tableData={tableData}
              disabled={disabled}
              loading={tableLoading}
              poolSaasStatus={poolSaasStatus}
              siteId={siteId}
              rows={rows}
              showeEditGroupModal={showeEditGroupModal}
              onChange={hanleSelectChange}
              pagination={pagination}
              handleRemoveGoods={handleRemoveGoods}
              defaultRows={rows}
              handleOk={handleOk}
              showModal={showModal}
              confirmLoading={confirmLoading}
              visiblePrice={visiblePrice}
              visiblePriceMarket={visiblePriceMarket}
              handleCancelPrice={handleCancelPrice}
              handleSoldOutGoods={handleSoldOutGoods}
              ref={radioRef}
              handleSearchFormClick={handleSearchFormClick}
              thirdTypeRow={thirdTypeRow}
            />
          ) : (
            <Table
              columns={[
                {
                  title: "商品",
                },
                {
                  title: "市场价",
                },
                {
                  title: "会员价", // 销售价
                },
                {
                  title: "销售价", // 站点价
                },

                {
                  title: "销量",
                },
                {
                  title: "库存",
                },
                {
                  title: "状态",
                },
                {
                  title: "操作",
                },
              ]}
            />
          )}
        </div>
      </Spin>

      <EditGroupModal
        visible={addGroupVisible}
        groups={groups}
        handleHidden={(visible) => {
          setAddGroupVisible(visible);
        }}
        siteId={siteId}
        handleSubmitForm={handleEditGroup}
        selectRows={selectRowsRef.current}
        handleCancelClick={handleEditModalCancel}
        title="修改商品分组"
      />
    </>
  );
};

export default inject("siteId")(observer(Optional));
