import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Space, Col, Button, Typography, Modal, message } from "@fle-ui/next";
import OrderDetailStore, { statusMap } from "@/store/AfterSaleListDetailStore";
import { OrderItem } from "@/store/AfterSaleListStore";
import "./OrderDeal.scss";

type Props = {
  // 删格化布局
  span: number;
  afterSaleOrderNo: number;
  setPage: (values: any) => void;
  page: {
    pageNo: number;
    pageSize: number;
  };
};
const { Text } = Typography;
const OrderState: FC<Props & OrderItem> = (props) => {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { span, afterSaleOrderNo, afterSaleStatus, page, setPage } = props;
  const handleOk = async () => {
    try {
      const res: any = await OrderDetailStore.ajaxGetReturnAmount({
        afterSaleOrderNo,
      });
      console.log(res);
      if (res.success) {
        message.success("退款成功");
        setPage({ ...page, pageNo: 1 });
      }
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Col span={span} className="order-deal table_vertical_left">
        <Space align="start" size="large">
          {afterSaleStatus == 45 && (
            <Button style={{ margin: 0, padding: 0 }} type="link" onClick={() => setIsModalVisible(true)}>
              立即退款
            </Button>
          )}
          <Button
            style={{ margin: 0, padding: 0 }}
            type="link"
            onClick={() => history.push("/order/afterSaleManage/detail/" + afterSaleOrderNo)}
          >
            详情
          </Button>
        </Space>
      </Col>
      <Modal visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)} closable={false}>
        是否确定退款，点击确定后将会优先从会员代收账户扣除退款金额，不足部分将会从预付货款账户扣除
      </Modal>
    </>
  );
};

export default OrderState;
