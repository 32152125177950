// import { OrderApis } from 'api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import httpClient from "@/utils/httpClient";

export enum OrderCloseType {
  买家超时未支付 = 1,
  卖家主动取消,
  买家主动取消,
}

export enum ThirdType {
  平台 = 0,
  京东 = 10,
  苏宁 = 20,
  网易严选 = 30,
  鲜花蛋糕 = 40,
  猫超 = 50,
}

export enum OrderStatus {
  待付款 = 1,
  待发货,
  待收货,
  交易成功,
  交易关闭,
  交易部分成功,
  交易失败,
}

export enum PaymentMethod {
  预存货款扣款 = 1,
  对公汇款,
}

export enum DeductionOfInventory {
  非现货采购,
  现货采购,
}

export enum DeliveryStatus {
  默认状态,
  待发货,
  已发货,
}

export enum ReceiptStatus {
  默认状态,
  待收货,
  已收货,
}

export enum IsContainsTax {
  不含税,
  含税,
}

interface OrderPayInfoProps {
  // 实际需支付费用
  actualPaymentPrice: number;
  // 运费现价（订单含待确认商品时为0，订单无需支付运费时为0）
  currentFreightPrice: number;
  // 订单现价（不含运费）
  currentOrderPrice: number;
  // 支付方式：1、预存货款扣款，2、对公汇款
  paymentMethod: 1 | 2;
  // 支付时间
  paymentTime: string;
  // 收款凭证地址(只有对公汇款会有数据)
  receivePaymentImage: string;
  // 收款备注
  receivePaymentRemark: string;
  // 创建时间
  createTime: string;
  // 支付订单号
  payBillNo: string;
}

type OrderReceivingInfoProps = {
  // 详细地址
  address: string;
  // 地区码
  areaCode: string;
  // 备注
  buyerRemark: string;
  // 省
  province: string;
  // 市
  city: string;
  // 区
  district: string;
  // 镇
  town: string;
  // 收货人
  consignee: string;
  id: "";
  // 收货联系方式
  mobileNumber: string;
};

export interface DetailGoodItemProp
  extends Omit<DetailGroupDetailGoodItemProp, "goodsList"> {
  // 商品图片地址
  goodsImage: string;
  // 商品名称
  goodsName: string;
  // 商品 id
  id: string;
  // skuId
  skuId: string;
  //0、不含税，1、含税
  isContainsTax: 0 | 1;
  // 商品税率(不含税为0)
  taxRate: number;
  // 商品规格
  specList: string;
  // 购买总数
  quantity: number;
  // 0、不扣减库存 非现货，1、扣减库存 现货
  isDeductionOfInventory: 0 | 1;
  // 最终销售价 -> 小计
  finalSalePrice: number;
  // 是否改价过：0、未改价，1、已改价
  isChangePrice: 0 | 1;
  // 订单金额（最终销售价*购买数量）
  orderPrice: number;
  // 收货状态：0-默认状态，1-待收货，2-已收货
  receiptStatus: 0 | 1 | 2;
  // 发货状态：0-默认状态，1-待发货，2-已发货
  deliveryStatus: 0 | 1 | 2;
  // 发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  // 可发货数
  freeNum: number;
  // 已发货数
  deliveryNum: number;
  // 计量单位 1按个销售 2按箱销售
  measureUnit: 1 | 2;
  // 箱规
  boxNumber: number;
  // 单位
  unit: string;
  // orderNo
  orderNo: string;
  //
  shopId: "";
  //orderGoodsFreightId
  orderGoodsFreightId: string;
  // 快递号
  expressNo: string;
  expressCompany: any;

  freightRules: "";
  // 运费类型：1、运费模板运费，2、运费说明，3、卖家自定运费（卖家修改运费）
  freightType: 1 | 2 | 3 | null;
  // 运费说明
  freightDescription: string;

  // 0平台 10京东 20苏宁 30网易严选
  thirdType: 0 | 10 | 20 | 30;
  // 是否自有商品 1是 0否
  isSelf: 1 | 0;

  rowSpan?: number;
  // 运费 id  为 null 说名未确认运费
  freightId?: string | null;
  NoPrice: boolean;
}

export type DetailGroupDetailGoodItemProp = {
  goodsList: DetailGoodItemProp[];
  // 最终运费
  finalFreightPrice: number;
  // 运费 ID
  id: string;
  //运费 是否改价过：0、未改价，1、已改价
  isChange: 0 | 1;

  freight: number;
};

export type OrderDetail = {
  orderNo: string | number;
  orderStatus: string | number;
  receiveInfo: {
    name: string | number;
    mobile: string | number;
    address: string;
    province: string;
    city: string;
    district: string;
    town: string | null;
  };
  cardInfos: [];
  orderGoods: [
    {
      skuId: string | number;
      goodsName: string;
      salePrice: string | number;
      quantity: string | number;
      specList: string | number;
      goodsImage: string;
      isDelivery: string | number;
      deliveryCode: string | number;
      thirdType: string | number;
      isSelf: string | number;
      deliveryMethod: any;
      expressCompany: any;
    }
  ];
  createTime: string | number;
  payMethod: string | null;
  payBillId: string | number | null;
  payTime: string | number | null;
  remark: string | number | null;
  remainTime: string | number | null;
  account: string | number;
  actualAccount: string | number;
  actualFreight: string | number;
  couponPayment: string | number;
  scoreCard: string | number;
  discountPayment: string | number;
  siteId: string | number;
  siteName: string;
  couponId: string | number | null;
  couponName: string | number | null;
  discountName: string | number | null;
  closeReason: string | number | null;
  closeType: any;
  extra: string | number | null;
  orderShip: any
};

interface GetOrderDetailProps {
  orderId: string | number;
  source: string | number;
}

interface OrderDetailStoreProps {
  orderDetail: OrderDetail;
  // 所有商品详情都用这个
  // ajaxGetOrderDetail: (params: GetOrderDetailProps) => void;
}

class OrderDetailStore implements OrderDetailStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderDetail: OrderDetail = {
    orderNo: "",
    orderStatus: 2,
    receiveInfo: {
      name: "",
      mobile: "",
      address: "",
      province: "",
      city: "",
      district: "",
      town: null,
    },
    cardInfos: [],
    orderGoods: [
      {
        skuId: "",
        goodsName: "",
        salePrice: 0,
        quantity: 1,
        specList: 0,
        goodsImage: "",
        isDelivery: 0,
        deliveryCode: "",
        thirdType: 0,
        isSelf: 0,
        deliveryMethod: 1,
        expressCompany: "",
      },
    ],
    createTime: "",
    payMethod: null,
    payBillId: null,
    payTime: null,
    remark: null,
    remainTime: null,
    account: 0,
    actualAccount: 0,
    actualFreight: 0,
    couponPayment: 0,
    scoreCard: 0,
    discountPayment: 0,
    siteId: 0,
    siteName: "",
    couponId: null,
    couponName: null,
    discountName: null,
    closeReason: null,
    closeType: 0,
    extra: null,
    orderShip: {}
  };

  @action
  async ajaxGetOrderDetail(params: GetOrderDetailProps) {
    const res: any = await httpClient.get(
      "/fleshopy/cuser/order/v1/order/detail",
      { params }
    );
    if (res?.success) {
      runInAction(() => {
        // res.data.orderShip = {
        //   shipType: 2,
        //   shipDate: '2022-07-14',
        //   shipTime: '10:00-12:00',
        //   shopAddress: 'shopAddress',
        //   shopName: 'shopName',
        //   expectShipTime: '2022-07-14 10:10:10'
        // }
        this.orderDetail = res.data;
      });
    }
  }
}

export default new OrderDetailStore();
