import React, { FC } from 'react'
import { ListItemPropsType } from  './ListItem.interface'
import './index.scss'
const ListItem: FC<ListItemPropsType> =(props)=> {
  const {lable, content} = props
  return(
    <div className='list-wrap'>
      <div className='list-item'>
        <div className='list-item-title-wrapper'>
          <div className='list-item__label'>{lable}</div>
        </div>
        <div className='list-item__content'>{content}</div>
      </div>
    </div>
  )
}

export default ListItem