import axios from "axios";
import { mainHost } from "../config";
import Cookies from "js-cookie";
import { notification, message } from "@fle-ui/next";
import { formatTime } from "./filters";

/**
 * 导出文件
 * @param url 接口地址
 * @param data 参数
 * @returns {*|string}
 */
export function ExportFile(
  url: string = "",
  data: Object = {},
  type: any = "vnd.ms-excel",
  name: string = "",
  handel: Function = () => {},
  handel2: Function = () => {}
) {
  axios({
    method: "GET",
    baseURL: mainHost,
    headers: {
      authority_token: localStorage.getItem("token") || Cookies.get("token"),
    },
    url,
    params: data,
    responseType: "blob",
  })
    .then((data) => {
      const blob = new Blob([data.data], {
        type: `application/${type}`,
      });
      
      const fileReader = new FileReader();
      fileReader.readAsText(blob);
      fileReader.onload = function () {
        try {
          const result = JSON.parse(this.result as any);
          if (result && result.success === false) {
            notification.error({
              message: "提示",
              description: result.message,
            });
            handel2();
          } else {
            // reject();
            handel2();
          }
        } catch (e) {
          //TODO handle the exception
          // reject();
          if (data.status === 200) {
            // 截取文件名，这里是后端返回了文件名+后缀，如果没有可以自己拼接
            const FileName = window.decodeURI((data.headers['content-disposition'].split("=")[1]).replace(/%/g, '%25'));
            let fileName = `${FileName ? FileName : name + formatTime(new Date())}`;
            // let fileName = `${
            //   name
            //     ? name
            //     : formatTime(new Date(), "YYYYMMDDHHmmss") + "下载文件"
            // }`;
            // 将`blob`对象转化成一个可访问的`url`
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            handel();
          }
        }
      };
    })
    .catch(() => {
      message.error("导出失败");
    });
} 


/**
 * 下载接口返回的文件流
 * @param {*} data 文件流
 * @param {*} type 文件类型
 * @param {*} fileName 下载文件名
 */
 export function downloadFile(data:any, type = "vnd.ms-excel", fileName = "") {
  const blob = new Blob([data], { type: `application/${type};charset=utf-8` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download =
    fileName || formatTime(new Date(), "YYYYMMDDHHmmss") + "下载文件";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}