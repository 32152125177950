import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";
// 获取所有标签 1是商城模板标签 2是站点标签
export const getSiteTag = (source: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/tag/tag/show",
    params: {
      source,
      app: 2,
    },
  });
};
// 更新站点设置
export const updateSiteShareConfig = (data: any) => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/merchant/site/update",
    data: JSON.stringify(data),
  });
};
export const getSiteShareConfig = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getSite",
    params: { siteId },
  });
};
// 获取所有地域信息
export const getBasicArea = (parentCode: number) => {
  return request({
    method: "post",
    url: mainHost + "/basic/area/areas",
    data: JSON.stringify({
      parentCode,
    }),
  });
};
