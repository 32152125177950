import React, { FC, useEffect, useState } from "react";

import "./index.scss";

import { Switch, Route, useHistory, Redirect } from "react-router-dom";

import Order from "./Order";
import OrderDetail from "./Order/Detail";
import AfterSaleManage from "./AfterSaleManage";
import AfterSaleManageDetail from "./AfterSaleManage/Detail";
import { withRouter } from "react-router-dom";

const OrderManage = (props: any) => {

  return (
    <Switch>
      <Route path="/order/orderManage" exact component={Order} />
      <Route
        path="/order/orderManage/detail/:orderId"
        exact
        component={OrderDetail}
      />
      <Route path="/order/afterSaleManage" exact component={AfterSaleManage} />
      <Route
        path="/order/afterSaleManage/detail/:orderId"
        exact
        component={AfterSaleManageDetail}
      />
      <Redirect from="/order" exact to="/order/orderManage" />
    </Switch>
  );
};

export default withRouter(OrderManage);
