
import React,{ FC, useEffect, useRef, MutableRefObject } from 'react'

import echarts from 'echarts'

const BreakLineGraph: FC<{data: any[], color: any, breakMap: any}> = props => {
  const { data, color, breakMap } = props
  const domBreak: MutableRefObject<HTMLDivElement | null> = useRef(null)

  // const colorList = ["#0090FF", "#36CE9E", "#FFC005", "#FF515A", "#8B5CFF", "#00CA69"]
const config = {
    backgroundColor: '#fff',
    grid: {
      left: '3%',
      right:'3%',
      top: '10%',
      bottom: '10%'
    },
    legend: {
        top: 10,
        left: 10,
        itemGap: 20,
        textStyle: {
            color: '#556677'
        },
        show: false,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
            type: 'dashed'
        }
    }
  },
    xAxis: [{
      type: "category",
      boundaryGap: false,
      axisLabel: {
          textStyle: {
              color: "#333"
          }
      },
      axisLine: {
          lineStyle: {
              color: "#D9D9D9"
          }
      },
      // data: data.map(item => item.day.slice(5,))
      data: data.map(item => item.time.slice(0,))
  }],
    yAxis: [{
        type: 'value',
        axisTick: {
            show: false
        },
        axisLine: {
            show: false,
            lineStyle: {
                color: '#DCE2E8'
            }
        },
        axisLabel: {
            textStyle: {
                color: '#556677'
            }
        },
        splitLine: {
          lineStyle: {
              type: "dashed",
              color: "#E9E9E9"
          }
      },
    }],
    series: Object.keys(breakMap).map((value: any, index: number) => {
      return {
            name: breakMap[value],
            type: 'line',
            data: 'consumePrice' in breakMap ? 
              data.map(item => item[value] / 100) 
              : data.map(item => item[value]),
            lineStyle: {
                width: 2,
            },
            itemStyle: {
                normal: {
                  color: color[index],
                  borderColor: color[index],
                }
            }
        }
    })
}

useEffect(() => {
  const {current } = domBreak
  const breakChart = echarts.init(current as HTMLDivElement) // 排除null
  breakChart.setOption(config as any)
// eslint-disable-next-line react-hooks/exhaustive-deps
return ()=> {
  if (breakChart) {
    breakChart.dispose()
  }
}
},[data, breakMap])

return <div ref={domBreak} className='pie-wrap pie-line-wrapper'></div>
}
export default BreakLineGraph
