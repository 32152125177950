import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Modal, Radio, Button, Input, Form, InputNumber } from "@fle-ui/next";
import { getSiteShareConfig } from "./store";
import { ShopTypeStore } from "@/store/index";

import "./index.scss";
interface Props {
  [key: string]: any;
}
const AlterPrice: React.FC<Props> = (props) => {
  const {
    isKeepPrice,
    isKeepStatus,
    siteGoodsRule,
    siteId,
    visible,
    handleOk,
    handleCancel,
    confirmLoading,
  } = props;
  const [check, setCheck] = useState<string>("1-1");
  const [form] = Form.useForm();
  // isSelf来自于三种情况：ShopTypeStore.getType()来自于搜索条件筛选，siteGoodsRule?.isSelf来自于在筛选条件为全部商品时传入某个商品的record，最后一个Number(siteGoodsRule)来自于批量挑选改价时所挑选的这批商品是否是自有商品
  const isSelf =
    typeof ShopTypeStore.getType() !== "undefined"
      ? ShopTypeStore.getType()
      : typeof siteGoodsRule?.isSelf !== "undefined"
      ? siteGoodsRule?.isSelf
      : Number(siteGoodsRule);

  const alterPriceList = [
    {
      modeType: 2,
      changeType: isSelf === 1 ? 4 : 1,
      operateType: 1,
      id: "2-1-1",
      label: "按照会员价上调",
      value: true,
      more: (
        <Form layout="inline" form={form}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
      tips: "* 销售价=会员价*（1+a%) ; a为输入值，a越大，您的利润越高；",
      tipSelf: "* 销售价=采购价*（1+a%) ; a为输入值，a越大，您的利润越高；",
    },
    {
      id: "2-2-2",
      modeType: 2,
      changeType: 2,
      operateType: 2,
      value: true,
      label: "按照市场价下调",
      tips: "* 销售价=市场价*（1-a%) ; a为输入值，a越大，您的利润越低；",
      tipSelf: "* 销售价=市场价*（1-a%) ; a为输入值，a越大，您的利润越低；",
      more: (
        <Form form={form}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "2-3-1",
      modeType: 2,
      label: "按照商品利润率上调",
      tips: "* 销售价=会员价/[1-(利润率+a%)] ; a为输入值，a越大，您的利润越高",
      tipSelf:
        "* 销售价=采购价/[1-(利润率+a%)] ; a为输入值，a越大，您的利润越高",
      value: true,
      changeType: 3,
      operateType: 1,
      customTips: true,
      more: (
        <Form layout="inline" form={form}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "2-3-2",
      modeType: 2,
      label: "按照商品利润率下调",
      tips: "* 销售价=会员价/[1-(利润率-a%)]；a为输入值，a越大，您的利润越低；",
      tipSelf:
        "* 销售价=采购价/[1-(利润率-a%)]；a为输入值，a越大，您的利润越低；",
      changeType: 3,
      operateType: 2,
      value: true,
      customTips: true,
      more: (
        <Form layout="inline" form={form}>
          <Form.Item
            name="rate"
            rules={[{ required: true, message: "利率不可为空" }]}
          >
            <InputNumber
              className="alter-price-input-number ml-12"
              min={0}
              max={100}
              step={0.01}
              formatter={(value) => `${value}%`}
              precision={2}
            />
          </Form.Item>
        </Form>
      ),
    },
    {
      id: "2-2-3",
      modeType: 2,
      changeType: 2,
      operateType: 3,
      label: "等于市场价",
    },
    {
      id: "2-4-3",
      modeType: 2,
      changeType: 4,
      operateType: 3,
      label: "等于采购价",
      tips: "* 代表商品销售利润率为0；",
      tipSelf: "* 代表商品销售利润率为0；",
    },
    {
      id: "2-1-3",
      modeType: 2,
      changeType: 1,
      operateType: 3,
      label: "等于会员价",
      tips: "* 代表商品销售利润率为0；",
      tipSelf: "",
    },
  ];
  const handleRadioChange = (e: any, item: any) => {
    setCheck(item.id);
  };

  useEffect(() => {
    const getSiteInfo = async (siteId: number) => {
      if (isSelf === 1) {
        setCheck(2 + "-" + 1 + "-" + 3);
      } else {
        setCheck(2 + "-" + 2 + "-" + 3);
      }
    };
    getSiteInfo(siteId);
  }, [form, siteGoodsRule, siteId, visible]);

  return (
    <>
      <Modal
        title="改价"
        visible={visible}
        onOk={() => handleOk()}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={721}
        footer={null}
        className="alter-price-modal-components"
        {...props}
      >
        <div className="alter-price-content">
          <div className="alter-price-content-title">销售价</div>
          <div
            className="alter-price-content-sub-title"
            style={{
              display: isSelf === 0 ? "block" : "none",
            }}
          >
            商城销售价是商城对用户的销售价；该站点每销售一件商品，将从站点余额扣除一件会员价金额。
          </div>
          <div
            className="alter-price-content-sub-title"
            style={{
              display: isSelf === 1 ? "block" : "none",
            }}
          >
            商城销售价是商城对用户的销售价；会员价=您在录入商品填的一件代发销售价；
          </div>
          <div className="radio-content-wrap">
            {alterPriceList &&
              alterPriceList.map((item, index) => (
                <div key={index} className="alter-price-item-radio-wrap">
                  <Radio
                    className="radio"
                    key={index}
                    checked={item.id === check}
                    style={{
                      display:
                        item.id === "2-4-3"
                          ? isSelf === 1
                            ? "block"
                            : "none"
                          : "",
                      marginBottom: 10,
                    }}
                    value={item.id}
                    onChange={(e: any) => {
                      handleRadioChange(e, item);
                      form.setFieldsValue({ rate: "" });
                    }}
                  >
                    {item.label}
                    {item.id === check && item.more && item.more}
                  </Radio>
                  {item.tips && isSelf === 0 && item.id === check && (
                    <div className="alter-price-radio-tips">{item.tips}</div>
                  )}
                  {item.tips && isSelf === 1 && item.id === check && (
                    <div className="alter-price-radio-tips">{item.tipSelf}</div>
                  )}
                </div>
              ))}
          </div>
          <div className="alter-price-tips">
            注：
            <p>
              商品利润率=（市场价-
              {isSelf === 1 ? "采购价" : "会员价"}
              ）/市场价*100%
            </p>
            <p>
              销售利润率=（销售价-
              {isSelf === 1 ? "采购价" : "会员价"}
              ）/销售价*100%
            </p>
          </div>
          <div className="alter-price-submit-wrap">
            <Button
              onClick={() => {
                const current = alterPriceList.find(
                  (item: any) => item.id === check
                );
                if (current != null) {
                  handleOk([
                    { ...current, rate: form.getFieldValue("rate") },
                    // oldRate,
                    // form,
                    { isKeepPrice, isKeepStatus },
                  ]);
                }
              }}
              className="alter-price-submit-button"
              type="primary"
              loading={confirmLoading}
            >
              确认保存
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(AlterPrice);
