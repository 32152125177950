import React, { useEffect, useRef, useState } from 'react'
import ProLayout, { PageContainer } from '@fle-ui/pro-layout'
import "./index.scss"
import { Button, Col, Form, Input, message, Row, Space } from '@fle-ui/next'
import Upload from "../components/Upload"
import GoodsPreview from "../components/goodsPreview"
import MobilePriview from "../components/mobilePrivew"
import useUrlSearchParams from '@/tools/useUrlSearchParams'
import { commodityNewGroupStore } from "@/store"
import ChooseSiteGoods from '@fle-ui/pro-choose-site-goods';
import { ENV } from '@/config'
import { useHistory } from 'react-router-dom'

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 14 },
};
const Index = () => {
  const [form] = Form.useForm()
  const history = useHistory()

  const [isModalOpenGoods, setIsModalOpenGoods] = useState(false)
  const [mobileImg, setMobileImg] = useState<string>('')
  const [query] = useUrlSearchParams();
  const [app, setApp] = useState<number>(0)
  const [show, setShow] = useState<boolean>(false);
  const [goodsIds, setGoodsIds] = useState<string[]>([]); //挑选弹窗回显用的id
  const [list, setList] = useState<any>([]) //选择的商品 入参
  const [loading, setLoading] = useState(false)
  const mobilePewviewRef = useRef<any>()
  const goodspreviewRef = useRef<any>()

  const footer = () => {
    return <Space>
      <Button onClick={() => history.push('/mineCommodity/newGroup')}>取消</Button>

      <Button type="primary" onClick={onSave} loading={loading}>保存</Button>
    </Space>
  }


  //不分页商品
  const getGroupAllGoodsList = async () => {
    try {
      const res: any = await commodityNewGroupStore.getGroupAllGoodsList({
        groupId: Number(query?.id),
        siteId: Number(sessionStorage.getItem('siteId')),
      })
      if (res?.success) {
        const newarr = res.data.filter((item:any)=>item.status === 0)
        setList([...newarr])
        const ids = newarr.map((item:any) => item.goodsId)
        setGoodsIds([...ids])
      }
    } catch (err) {
      console.warn(err)
    }
  }


  const getDetails = async () => {
    try {
      const res: any = await commodityNewGroupStore.getGroupInfo({
        groupId: Number(query?.id)
      })
      if (res?.success) {
        form.setFieldsValue({
          ...res.data,
        })
        setMobileImg(res.data.groupImage)
        if(res.data.groupImage) {
          
          form.setFieldsValue({
            groupImage: [{
              uid: 1,
              name: 1,
              status: 'done',
              url: res.data.groupImage,
            }],
          })
        }
      }
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    if (query?.id) {
      getDetails()
      getGroupAllGoodsList()
    }
  }, [query])

  const onSave = async () => {
    goodspreviewRef?.current?.handleOk()
    await form.validateFields()
  
    let res: any = {}
    const { groupImage } = form.getFieldsValue()
    console.log(groupImage,'groupImage');
    
    const params = {
      ...form.getFieldsValue(),
      siteId: Number(sessionStorage.getItem('siteId')),
      groupImage:groupImage&&groupImage.length > 0  ? groupImage[0].url : undefined
    }
    setLoading(true)
    res = await commodityNewGroupStore.updateGroup({ ...params, groupId: query?.id })
    setLoading(false)
    if (res?.success) {
      message.success('保存成功')
      history.push('/mineCommodity/newGroup')
    }
  }

  const handleSelectOk = (ids: string[], goodsRows: any[]) => {
    const arr = [...list, ...goodsRows]
    const idsArr = [...goodsIds,...ids]
    if (arr.length > 500) {
      arr.splice(500, arr.length)
      idsArr.splice(500, idsArr.length)
      message.error('最多选500个商品')
    }
    setList([...arr])
    setGoodsIds([...idsArr]);
    setShow(false)
  };

  const getApp = async () => {
    const res: any = await commodityNewGroupStore.getSite({ siteId: sessionStorage.getItem('siteId') })
    if (res?.success) {
      setApp(res.data.app)
    }
  }

  useEffect(() => {
    getApp()
  }, [])

  return (
    <PageContainer className="mine-commodiy-container" footer={() => footer()}>
      <div className='commodity-new-group-create'>
        <Row style={{ height: '100%' }} gutter={[20, 20]}>
          <Col span={6}>
            <div className='col-right col-left'>
              <p className='title'>商品分组</p>
              <MobilePriview form={form} mobileImg={mobileImg} ref={mobilePewviewRef}/>
            </div>
          </Col>
          <Col span={18} >
            <div className='col-right'>
              <p className='title'>商品配置</p>
              <Form {...layout} form={form} className='form'>
                <Form.Item label={`分组名称`} name={`groupName`} rules={[{ required: true, message: '请输入!' }]}>
                  <Input placeholder='如家居家纺' maxLength={20} showCount/>
                </Form.Item>
                <Form.Item label={`分组介绍`} name={`groupIntroduce`}>
                  <Input.TextArea placeholder='请输入' maxLength={200} showCount/>
                </Form.Item>
                <Form.Item label={`分组图片`} name={`groupImage`}>
                  <Upload form={form} setMobileImg={setMobileImg} />
                </Form.Item>
                <Form.Item label={`分组商品`} rules={[{ required: true }]}>
                  {/* <Button onClick={() => setShow(true)}>添加</Button> */}
                  <Button type="link" onClick={() => setIsModalOpenGoods(true)}>添加商品{`已选择${list.length || 0}件商品`}</Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
        <GoodsPreview
          isModalOpenGoods={isModalOpenGoods}
          setIsModalOpenGoods={setIsModalOpenGoods}
          list={list}
          setList={setList}
          goodsIds={goodsIds}
          setGoodsIds={setGoodsIds}
          refresh={mobilePewviewRef?.current?.refresh}
          setShow={setShow}
          ref={goodspreviewRef}
        />
        
        {show && (
          <ChooseSiteGoods
            env={ENV}
            token={localStorage.getItem('token') || ''}
            app={app}
            siteId={sessionStorage.getItem('siteId') || ''}
            onClose={() => setShow(false)}
            onSelectOk={handleSelectOk}
            multiple
            defaultSelectedGoodsIds={goodsIds}
          />
        )}
      </div>
    </PageContainer>
  )
}

export default Index



