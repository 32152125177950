import request from "@/utils/httpClient";

import { mainHost } from "@/config/index";

export interface AddForm {
  groupName: string;
  id?: string;
}

// 获取分组列表
export const getGoodsGroupList = (
  pageNo: number,
  pageSize: number,
  siteId: string
) => {
  return request({
    url: mainHost + "/fleshopy/site/goods/group/queryPage",
    method: "post",
    data: JSON.stringify({ pageNo, pageSize, siteId }),
  });
};

// 添加分组
export const addGoodsGroup = (siteId: string, data: AddForm) => {
  return request({
    url: mainHost + "/fleshopy/site/goods/group/create",
    method: "post",
    data: JSON.stringify({ ...data, siteId }),
  });
};

// 修改分组
export const updateGoodsGroup = (siteId: string, data: AddForm) => {
  return request({
    url: mainHost + "/fleshopy/site/goods/group/update",
    method: "post",
    data: JSON.stringify({ ...data, siteId }),
  });
};

// 删除分组
export const deleteGoodsGroup = (siteId: string, groupId: string) => {
  return request({
    url: mainHost + "/fleshopy/site/goods/group/delete",
    method: "post",
    data: JSON.stringify({ siteId, groupId }),
  });
};
