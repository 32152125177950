import moment from 'moment';
import Router from '@/config/route';
import { toJS } from 'mobx';

/**
 * 格式化url参数
 */
export const parseQueryString = () => {
  const url = window.location.href
  const regUrl = /^[^\?]+\?([\w\W]+)$/ //eslint-disable-line
  const regPara = /([^&=]+)=([\w\W]*?)(&|$|#)/g
  const arrUrl = regUrl.exec(url)
  let ret = {}
  if (arrUrl && arrUrl[1]) {
    const strPara = arrUrl[1]
    let result
    while ((result = regPara.exec(strPara)) != null) {
      ret[result[1]] = result[2]
    }
  }
  return ret
}

/**
 * 时间戳转换
 */
export const formatTime = (time, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  return time ? moment(time).format(fmt) : '';
};

// 分转元
export const formatPrice = (fen) => {
  let num = fen;
  num = fen * 0.01;
  num += '';
  let reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg, '$1');
  num = toDecimal2(num)
  return num;
}

const toDecimal2 = (x) => {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

/**
 * JSON 克隆
 * @param {Object | Json} jsonObj json对象
 * @return {Object | Json} 新的json对象
 */
export function objClone(jsonObj) {
  var buf;
  if (jsonObj instanceof Array) {
    buf = [];
    var i = jsonObj.length;
    while (i--) {
      buf[i] = objClone(jsonObj[i]);
    }
    return buf;
  } else if (jsonObj instanceof Object) {
    buf = {};
    for (var k in jsonObj) {
      buf[k] = objClone(jsonObj[k]);
    }
    return buf;
  } else {
    return jsonObj;
  }
}

/**
 * 下载接口返回的文件流
 * @param {*} data 文件流
 * @param {*} type 文件类型
 * @param {*} fileName 下载文件名
 */
export function downloadFile(data, type = 'vnd.ms-excel', fileName = '') {
  const blob = new Blob([data], { type: `application/${type};charset=utf-8` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || formatTime(new Date(), 'YYYYMMDDHHmmss') + '下载文件';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

// 获取关键字
let routerKey = ''
export function getKey(path, name = 'key', routers) {
  const routersArr = routers || Router

  for (let i = 0; i < routersArr.length; i++) {
    let child = routersArr[i]

    if (child.router && path === child.router) {
      routerKey = child[name]
      break;
    }
    if (child.route) {
      if (path === child.route) {
        routerKey = child[name]
        break;
      }

      const pathArr =  path.split('/')
      const childRouteArr = child.route.split('/')
      const setArr = new Set([...pathArr, ...childRouteArr])
      const checkItemSize = pathArr.length + childRouteArr.length - setArr.size

      if (checkItemSize > 4) {
        routerKey = child[name]
        break;
      }
    }
    if (child.children) {
      getKey(path, name, child.children)
    }

  }

  return routerKey
}


// 根据某一个节点的过滤条件，获取其在一个森林的路径。
export function getPathByKey(value, key, arr) {
  let temppath = [];
  try {
      function getNodePath(node){
        // 这里可以自定义push的内容，而不是整个node,而且这里node也包含了children
        temppath.push(node.id);
        //找到符合条件的节点，通过throw终止掉递归
        if (node[key] === value) {
          throw ("GOT IT!");
        }
        if (node.children && node.children.length > 0) {
          for (var i = 0; i < node.children.length; i++) {
            getNodePath(node.children[i]);
          }

          //当前节点的子节点遍历完依旧没找到，则删除路径中的该节点
          temppath.pop();
        }
        else {

          //找到叶子节点时，删除路径当中的该叶子节点
          temppath.pop();
        }
      }
      for (let i = 0; i < arr.length; i++) {
          getNodePath(arr[i]);
      }
  } catch (e) {
    return temppath;
  }
}


export const maxTotal = 10000; // 最大total限制

// 最大不得超过10000
const getBorderTotal = (total) => Math.min(maxTotal, total); 

// 是否超出边界
const getIsOvertop = (pageSize) => (Math.ceil(getBorderTotal() / pageSize) * pageSize) > maxTotal; 

// 取真实边界值
export const getFakeTotal = (pageSize, total) => {
  return getBorderTotal(total) - (getIsOvertop(pageSize) ? (getBorderTotal(total) % pageSize) : 0); 
}

export const mobxToJavaScript = (originMobx) => {
  return JSON.parse(JSON.stringify(toJS(originMobx)));
}

export const removePropertyOfNull = (obj) => {
  Object.keys(obj).forEach(item => {
    if (obj[item] !== 0 && !obj[item]) delete obj[item]
  })
  return obj;
}
