import request from '@/utils/httpClient'
import { mainHost } from '@/config/index'

// 获取站点分组
export const getUserGroups = (siteId: number) => {
  let params ={}
  return request({
    url: mainHost + '/fleshopy/merchant/group/groups',
    method: 'get',
    params: {
      siteId
    }
  })
} 