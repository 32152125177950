import React, { FC } from "react";
import { TopbarTypes } from "@/types/components/topbar.interface";
// import Router from "@/config/route";
import { observer } from "mobx-react";
import "./index.scss";
import ConsoleHeader from "@fle-ui/pro-console-header";

const defaultLogo = "https://oss.elebuys.com/tmpdir/202208301007510000205302.png";

const Topbar: FC<TopbarTypes> = ({
  userInfo,
  siteName,
  logo,
  customerName,
  categoryId,
}: TopbarTypes) => {
  return (
    <ConsoleHeader
      className="fx-topbar"
      applyLogo={logo ? logo : defaultLogo}
      currentApplyName={"电商场景SaaS"}
      fastApply={[]}
      exitText={""}
      isLoading={false}
      userInfo={{
        logoName: customerName,
        name: userInfo.userName,
      }}
    />
  );
};

export default observer(Topbar);
