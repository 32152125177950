import { Spin, Card, Tabs, Timeline, Table, Button, Modal, message, Image } from "@fle-ui/next";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "@fle-ui/pro-layout";
import WhiteSpace from "@/components/WhiteSpace";
import { useHistory, useParams } from "react-router-dom";
import AfterSaleListStore, { OrderItem } from "@/store/AfterSaleListStore";
import AfterSaleListDetailStore, { OrderCloseType, statusMap } from "@/store/AfterSaleListDetailStore";
import { formatPrice, formatTime } from "@/utils/common";
import "./index.scss";

type Props = {};
const AfterSaleManageDetail: FC<Props> = () => {
  const history = useHistory() as any;
  const { orderId } = useParams();
  const siteId: any = window.location.pathname.split("/")[1];
  const list = AfterSaleListDetailStore.orderDetail;
  const [loading, setLoading] = useState<any>(false);
  const [logistic, setLogistic] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [flag, setFlag] = useState<number>(0); //立即退款完成后需重新请求接口获取新的订单状态

  const columns = [
    {
      title: "商品图片",
      dataIndex: "afterSaleGoodsImage",
      key: "afterSaleGoodsImage",
      render: (text: any, record: any) => {
        return <img style={{ width: 90 }} src={text} alt="" />;
      },
    },
    {
      title: "商品名称",
      dataIndex: "afterSaleGoodsName",
      key: "afterSaleGoodsName",
    },
    {
      title: "申请数量",
      dataIndex: "applyNum",
      key: "applyNum",
      align: "left" as any,
    },
    {
      title: "售后类型",
      dataIndex: "afterSaleType",
      key: "afterSaleType",
      align: "left" as any,
      render: (text: any, record: any) => {
        return (
          <span>
            {text == 10 && "退货"}
            {text == 20 && "换货"}
            {text == 30 && "维修"}
            {text == 40 && "退款"}
          </span>
        );
      },
    },
    {
      title: "销售价",
      dataIndex: "afterSaleGoodsPrice",
      key: "afterSaleGoodsPrice",
      align: "left" as any,
      render: (text: any, record: any) => {
        return <span>￥{formatPrice(record.afterSaleGoodsPrice)}</span>;
      },
    },
    {
      title: "应付金额",
      dataIndex: "customerPayment",
      key: "customerPayment",
      align: "left" as any,
      render: (text: any, record: any) => {
        if (record?.afterSaleType == 10 || record?.afterSaleType == 40) {
          return (
            <div>
              <div>￥{formatPrice(record.customerPayment)}</div>
              <div>运费：￥{formatPrice(record.freightFee)}</div>
            </div>
          );
        } else {
          return "---";
        }
      },
    },
    {
      title: "优惠内容",
      dataIndex: "discountWays",
      key: "discountWays",
      align: "left" as any,
      width: 160,
      render: (text: any, record: any) => preferentialShare(record),
    },
    {
      title: "退款方式",
      dataIndex: "returnPaymentInfos",
      key: "returnPaymentInfos",
      align: "left" as any,
      render: (text: any, record: any) => returnPaymentInfosCallback(text, record),
    },
  ];

  useEffect(() => {
    getListData();
    afterSaleProgress();
  }, [orderId, flag]);

  const afterSaleProgress = () => {
    const afterSaleNo = orderId;
    AfterSaleListStore.ajaxQueryLogisticByOrderNo({ afterSaleNo })
      .then((res) => {
        res && setLogistic(res);
      })
      .catch((error) => {
        setLogistic([{}]);
      });
  };

  const getListData = () => {
    setLoading(true);
    AfterSaleListDetailStore.ajaxGetOrderDetail({
      afterSaleOrderNo: orderId,
      source: 2,
      siteId,
    }).finally(() => {
      setLoading(false);
    });
  };

  // 立即退款
  const handleOk = async () => {
    try {
      const res: any = await AfterSaleListDetailStore.ajaxGetReturnAmount({
        afterSaleOrderNo: orderId,
      });
      console.log(res);
      if (res.success) {
        message.success("退款成功");
        setFlag(flag + 1);
      }
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
      setIsModalVisible(false);
    }
  };

  // 优惠内容
  const preferentialShare = (record: any) => {
    const { discountWays } = record;
    let priceArry: number[] = [];
    discountWays?.forEach((item: any, index: any) => {
      priceArry.push(item?.value);
    });
    if (record?.afterSaleType == 10 || record?.afterSaleType == 40) {
      return (
        <>
          <span>
            优惠总额：￥
            {priceArry.length !== 0
              ? formatPrice(
                  priceArry.reduce(function (prev, cur, index, arr) {
                    return prev + cur;
                  })
                )
              : "---"}
          </span>
          {discountWays?.map((item: any, index: any) => {
            return (
              <div key={item.id + "_" + index}>
                {item.name}：-￥{formatPrice(item.value)}
              </div>
            );
          })}
        </>
      );
    } else {
      return "---";
    }
  };
  // 退款方式逻辑
  const returnPaymentInfosCallback = (params: any, record: any) => {
    const { totalReturnAmount } = record;
    if (record?.afterSaleType == 10 || record?.afterSaleType == 40) {
      return (
        <>
          <div>退款总额：￥{formatPrice(totalReturnAmount)}</div>
          {params?.map((item: any, index: any) => {
            return (
              <div key={item.type + "_" + index}>
                <div>{item.type == 1 && <>余额：￥{formatPrice(item.value)}</>}</div>
                <div>{item.type == 2 && <>微信：￥{formatPrice(item.value)}</>}</div>
                <div>
                  {item.type == 3 && (
                    <>
                      {item.name}：￥{formatPrice(item.value)}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return "---";
    }
  };

  // 售后类型逻辑
  const payMethodCallback = (params: any) => {
    if (params == 10) {
      return "退货";
    } else if (params == 20) {
      return "换货";
    } else if (params == 30) {
      return "维修";
    } else if (params == 40) {
      return "退款";
    } else {
      return "---";
    }
  };
  // 取件方式
  const freightWayCallback = (params: any) => {
    if (params == 4) {
      return "上门取件";
    } else if (params == 7) {
      return "客户送货";
    } else if (params == 40) {
      return "客户发货";
    } else {
      return "---";
    }
  };

  // 预约取件时间逻辑
  const expectTimeCallback = (params: any) => {
    let arr = [];
    if (params) {
      arr = params?.split("&");
    }
    return (
      <span>
        {arr[0]}~{arr[1]}
      </span>
    );
  };

  return (
    <PageContainer margin={0} vesselStyle={{ background: "#eff1f6" }} background='#eff1f6'>
      <Spin spinning={loading}>
        <div className="after_sale_manage_detail">
          <Card>
            <div className="order_detail">
              <p className="order_detail_status">
                <span
                  style={{
                    color: statusMap.get(list?.afterSaleStatus)?.color,
                    fontSize: 20,
                  }}
                >
                  {statusMap.get(list?.afterSaleStatus)?.icon}
                  &nbsp;&nbsp;
                  {statusMap.get(list?.afterSaleStatus)?.parentText}
                </span>
              </p>
              <p className="order_detail_tips">{statusMap.get(list?.afterSaleStatus)?.text}</p>
              {list?.afterSaleStatus == 45 && (
                <p className="immediate_refund">
                  <Button type="primary" onClick={() => setIsModalVisible(true)}>
                    立即退款
                  </Button>
                </p>
              )}
            </div>
          </Card>

          <Card>
            <div className="order_infor">
              <div className="order_infor_tit">
                <h4>工单信息</h4>
              </div>
              <div className="order_infor_con_wrap">
                <div className="order_infor_con order_infor_con_left">
                  <p>
                    <span>申请人账号：</span>
                    {list?.applicantAccount}
                  </p>
                  <p>
                    <span>售后类型：</span>
                    {payMethodCallback(list?.afterSaleType)}
                  </p>
                  <p>
                    <span>联系人：</span>
                    {list?.contacter ? list?.contacter : "---"}
                  </p>
                  <p>
                    <span>应用订单号：</span>
                    {list?.orderNo}
                  </p>
                </div>
                <div className="order_infor_con order_infor_con_center">
                  <p>
                    <span>来源站点：</span>
                    {list?.siteName}
                  </p>
                  <p>
                    <span>申请原因：</span>
                    {list?.applyReason}
                  </p>
                  <p>
                    <span>联系方式：</span>
                    {list?.phoneNum ? list?.phoneNum : "---"}
                  </p>
                  <p>
                    <span>售后申请时间：</span>
                    {list?.afterSaleApplyTime}
                  </p>
                </div>
                <div className="order_infor_con order_infor_con_right">
                  <p>
                    <span>应用售后单号：</span>
                    {list?.afterSaleOrderNo}
                  </p>
                  <p>
                    <span>取件方式：</span>
                    {freightWayCallback(list?.freightWay)}
                  </p>
                  <p>
                    <span>联系地址：</span>
                    {list?.takeOverAddress ? list?.takeOverAddress : "---"}
                  </p>
                  <p>
                    <span>商品收货时间：</span>
                    {list?.confirmTime ? list?.confirmTime : "---"}
                  </p>
                </div>
              </div>
              <div className="order_infor_con_desc">
                <span>售后描述：</span>
                {list?.afterSaleReasonDesc ? list?.afterSaleReasonDesc : "---"}
              </div>
              <div className="order_infor_con_img">
                {list?.afterSaleImages?.map((item: any, index: any) => {
                  return (
                    <div className="img">
                      <Image width={90} src={item} alt="" key={item + "_" + index} />
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>

          <Card>
            <div className="goods_infor">
              <div className="goods_infor_tit">
                <h4>商品信息</h4>
              </div>
              <div className="table">
                <Table
                  columns={columns}
                  dataSource={[list?.goodsInfo]}
                  bordered
                  pagination={false}
                  rowKey="goodsName"
                />
              </div>
            </div>
          </Card>

          <Card>
            <div className="after_sale_progress">
              <div className="after_sale_progress_tit">
                <h4>售后进度</h4>
              </div>
              {logistic.length > 0 ? (
                <>
                  <WhiteSpace height={10} />
                  <div className="track_con">
                    <Timeline>
                      {logistic?.map((item: any, index: any) => (
                        <Timeline.Item key={item.afterSaleStatus + "_" + index}>
                          <div style={{ color: "#262626" }}>{statusMap.get(item.afterSaleStatus)?.text}</div>
                          <div style={{ color: "#a9a9a9" }}>{item.changeTime}</div>
                          {item.afterSaleStatus == 22 && (
                            <div className="sendInfo">
                              <div className="row first">
                                <span>{list.freightInfo?.freightCompanyName}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span>{list.freightInfo?.freightNo}</span>
                              </div>
                              <div className="row second">
                                <span>
                                  {formatTime(list.freightInfo?.sendOutTime)}
                                  &nbsp;寄出
                                </span>
                                <span>
                                  运费：￥
                                  {formatPrice(list.freightInfo?.freightFee)}元
                                </span>
                              </div>
                            </div>
                          )}
                          {item?.afterSaleStatus == 23 && (
                            <div className="sendInfoAddress">
                              <div className="row first">
                                <span>
                                  预约取件时间：
                                  {expectTimeCallback(list?.expectTime)}
                                </span>
                              </div>
                              <div className="row second">
                                <div>
                                  预约取件地址：
                                  {list.takeAddress?.consignee}
                                  &nbsp;&nbsp;&nbsp;
                                  {list.takeAddress?.mobileNumber}
                                </div>
                              </div>
                              <div className="row thirdly">{list?.takeAddress?.fullAddress}</div>
                            </div>
                          )}

                          {list?.afterSaleType == 10 && item?.afterSaleStatus == 21 && (
                            <div className="sendInfo">
                              <div className="row first">
                                <span style={{ fontSize: 14 }}>退货地址</span>
                              </div>
                              <div className="row second">
                                <span>
                                  {item?.detail?.recipient}
                                  &nbsp;&nbsp;&nbsp;
                                  {item?.detail?.recipientMobile}
                                </span>
                              </div>
                              <div className="row thirdly" style={{ marginLeft: 0 }}>
                                {item?.detail?.returnAddress}
                              </div>
                            </div>
                          )}

                          {(item?.afterSaleStatus == 91 || item?.afterSaleStatus == 96) && (
                            <div>{list?.closeReason}</div>
                          )}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                </>
              ) : (
                <div>暂无进度！</div>
              )}
            </div>
          </Card>
        </div>

        <Modal visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)} closable={false}>
          是否确定退款，点击确定后将会优先从会员代收账户扣除退款金额，不足部分将会从预付货款账户扣除
        </Modal>
      </Spin>
    </PageContainer>
  );
};

export default observer(AfterSaleManageDetail);
