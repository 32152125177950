import React, { FC } from "react";
import { Col, Statistic } from "@fle-ui/next";
import { OrderItem } from "../../../../store/OrderListStore";

type Props = {
  // 栅格
  span: number;
  // 运费
  fright:number
  // 金额
  mount:number

};
const OrderPrice: FC<Props> = (props) => {
  const {
    span,
    fright,
    mount,
  } = props;
  return (
    <Col
      span={span}
      className="table_vertical_left"
    >
      <Statistic
        valueStyle={{
          fontSize: 16,
          color: '#4C4C4C',
        }}
        value={(mount ) / 100}
        precision={2}
        prefix={'￥'}
      />
      {window.location.pathname !== "/shop_manage/order/pur_proxy" && (
        <span style={{ color: "#4C4C4C" }}>
             含运费：￥ { (fright / 100).toFixed(2) }
        </span>
      )}
    </Col>
  );
};

export default OrderPrice;
