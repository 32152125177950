import React, { FC } from 'react';

type WhiteSpaceProps = {
  height?: number,
  backgroundColor?: string
};

const WhiteSpace: FC<WhiteSpaceProps> = (props ) => {
  const { backgroundColor, height } = props
  return (
    <div style={ { backgroundColor, height: height + 'px' } }/>
  );
};

WhiteSpace.defaultProps  = {
  height: 20,
  backgroundColor: 'transparent',
}

export default WhiteSpace
