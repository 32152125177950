import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import AddBulkFormRender from "./addBulkUser";
import Detail from "./Detail/index";
import OneDetail from "./Detail/oneDetail";
import FailModal from "./Detail/failModal";
import SuccessModal from "./Detail/successModal";
import AddGroupTree, {
  TreeNodeDataType,
  AddTreeModalType,
} from "@/components/AddGroupTree/index";
import { formatPrice, formatTime } from "@/utils/common";
import {
  Spin,
  Card,
  Tabs,
  Timeline,
  Table,
  Space,
  Button,
  Row,
  Col,
  Form,
  Input,
  Modal,
  Cascader,
  message,
} from "@fle-ui/next";
import {
  batchList,
  cacheList,
  batchDelete,
  batchOneDelete,
  excelValidation,
  templateDownload,
  addUserByGroupId,
  getUserGroups,
  addGroupSubsByParentId,
  addGroup,
  updateGroupSubsById,
  getUserListByGroupId,
  sendMessageToUser,
} from "../store";

const { Item } = Form;

function UserAddIndex() {
  const [loading, setLoading] = useState<any>(false);
  const siteId = window.location.pathname.split("/")[1];
  // 首页列表
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  // 单次添加弹窗
  const [addUserFormEl] = Form.useForm();
  const [addSubsGroupFormEl] = Form.useForm();
  const [treeData, setTreeData] = useState<TreeNodeDataType[]>([]);
  const [selectTreeKey, setSelectTreeKey] = useState<any>([]);
  const [addUserFormVisible, setAddUserFormVisible] = useState<boolean>(false);
  const [selectedKeys, setSelectKeys] = useState<any>([]);
  // 批量添加用户弹窗
  const [addBulkFormVisible, setAddBulkFormVisible] = useState<boolean>(false);
  // 详情弹窗
  const [detailFormVisible, setDetailFormVisible] = useState<boolean>(false);
  const [batchId, setBatchId] = useState<any>();
  const [oneDetailFormVisible, setOneDetailFormVisible] =
    useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);

  const [failModalVisible, setFailModalVisible] = useState<boolean>(false);
  const [successModalVisible, setSuccessModalVisible] =
    useState<boolean>(false);
  const [failUrl, setFailUrl] = useState("");
  const [loadTotal, setLoadTotal] = useState("");
  /* ---首页列表业务模块--- */
  const columns = [
    {
      title: "创建批次",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      width:180,
      render: (text: any, record: any) => formatTime(text),
    },
    {
      title: "创建方式",
      dataIndex: "createType",
      key: "createType",
      render: (text: any, record: any) => createTypeCallback(text),
    },
    {
      title: "创建用户总计",
      key: "createUserCount",
      dataIndex: "createUserCount",
    },
    {
      title: "创建成功用户数",
      dataIndex: "createUserSuccess",
      key: "createUserSuccess",
    },
    {
      title: "成功添加现金账户余额",
      dataIndex: "addCashSuccess",
      key: "addCashSuccess",
      render: (text: any, record: any) => formatPrice(text),
    },
    {
      title: "成功添加积分账户余额",
      key: "addIntegralSuccess",
      dataIndex: "addIntegralSuccess",
    },
    {
      title: "创建人",
      dataIndex: "creatorName",
      key: "creatorName",
    },
    {
      title: "备注",
      key: "notes",
      dataIndex: "notes",
      textWrap: "word-break",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div>{record.notes || '-'}</div>
      ),
    },
    {
      title: "操作",
      key: "action",
      with: 80,
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button type="link" block onClick={() => handleDetailClick(record)}>
            详情
          </Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    if (siteId) {
      getBatchList(siteId, pageNo, pageSize).finally(() => {
        setLoading(false);
      });
      getUserGroupsBytreeId(siteId).then((data) => {
        setTreeData(data);
      });
    }
  }, [siteId]);
  const getBatchList = async (
    siteId: string | number,
    pageNo: number,
    pageSize: any
  ) => {
    const { success, data } = (await batchList(
      siteId,
      pageNo,
      pageSize
    )) as any;
    if (success) {
      setTotal(data?.total);
      setTableData(data?.list);
      setPageSize(data?.pageSize);
    }
  };
  const handleChangStart = (start: number, limit?: number) => {
    setPageNo(start);
    getBatchList(siteId, start, limit as number);
  };
  // 选择页数发生改变
  const handleChangSize = (start: number, limit?: number) => {
    setPageSize(limit as number);
  };
  const pagination = {
    pageSize,
    total,
    current: pageNo,
    pageSizeOptions: ["10", "20"],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共${total}条,第${pageNo}页`,
    onChange: handleChangStart,
    onShowSizeChange: handleChangSize,
  };

  // createType创建方式逻辑
  const createTypeCallback = (params: any) => {
    if (params == 0) {
      return "添加用户";
    } else {
      return "批量导入";
    }
  };

  /*  ---渲染新增用户表单业务模块--- */
  const AddUserFormRender = (props: AddTreeModalType) => {
    const { handleOk, handleCancel, title, options, visible, form } = props;
    return (
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户手机号"
                validateTrigger={["onSubmit"]}
                rules={[
                  { required: true, message: "手机号码必填" },
                  {
                    validator: (rule: any, value: any, callback: any) => {
                      if (/^1[3456789]\d{9}$/.test(value)) {
                        callback();
                      } else {
                        callback(new Error("手机号码格式不正确"));
                      }
                    },
                  },
                ]}
                name="mobile"
              >
                <Input placeholder="请输入用户手机号" />
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                validateTrigger={["onSubmit"]}
                label="用户姓名"
                rules={[
                  { required: true, message: "分组名称不能为空" },
                  { max: 20, message: "不能超过20个字符" },
                ]}
                name="name"
              >
                <Input placeholder="请输入用户姓名" />
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户分组"
                validateTrigger={["onSubmit"]}
                rules={[{ required: true, message: "用户分组必填" }]}
                name="groupName"
              >
                <Cascader
                  options={options}
                  placeholder="请选择用户分组"
                  changeOnSelect
                  allowClear={false}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="初始密码"
                validateTrigger={["onSubmit"]}
                rules={[
                  {
                    required: true,
                    message: "用户密码必填",
                  },
                  {
                    validator: (rule: any, value: string, callback: any) => {
                      if (/^[A-Za-z0-9]{6,20}$/.test(value)) {
                        callback();
                      } else {
                        callback(new Error("密码须为6-20位字母或数字"));
                      }
                    },
                  },
                ]}
                name="password"
              >
                <Input placeholder="请输入初始密码" />
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                validateTrigger={["onSubmit"]}
                label="填写备注"
                name="notes"
                rules={[{ max: 100, message: "不能超过100个字符" }]}
              >
                <Input placeholder="填写备注便于追踪查看" />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  };
  // 递归给树增加key
  const treeMap = (tree: any): any => {
    if (!Array.isArray(tree)) {
      return;
    }
    return tree.map((item: any) => {
      const { id, name, haveSub, tree } = item;
      return {
        ...item,
        key: id,
        value: id,
        label: name,
        children: treeMap(tree),
        title: name,
        isLeaf: haveSub === 1 ? false : true,
      };
    });
  };
  // 渲染组织树
  const getUserGroupsBytreeId = async (siteId: number | string) => {
    const { data, success } = (await getUserGroups(siteId)) as any;
    if (success) {
      return treeMap(data);
    }
  };
  // 添加用户按钮
  const handleAddUserClick = () => {
    setAddUserFormVisible(true);
    setSelectKeys([""]);
    addUserFormEl.setFieldsValue({
      name: "",
      password: "",
      groupName: [],
      mobile: "",
      isSendeMessage: false,
    });
    addUserFormEl.resetFields();
  };
  // 提交添加用户
  const handleCancelAddUserForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAddUserFormVisible(false);
  };
  const handleSubmitAddUserForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    addUserFormEl.validateFields().then(async (values: any) => {
      const { isSendeMessage, mobile, name, groupName } = values;
      const last = groupName[groupName.length - 1];
      addSubsGroupFormEl.submit();
      const { success } = (await addUserByGroupId(last, siteId, values)) as any;
      if (success) {
        const treeList = await getUserGroupsBytreeId(siteId);
        setTreeData(treeList);
        setAddUserFormVisible(false);
        getBatchList(siteId, pageNo, pageSize);
        message.success("新增用户成功");
        if (isSendeMessage === true) {
          const { success } = (await sendMessageToUser(siteId, mobile)) as any;
          if (success) {
            message.success(`已向${name}发送短信通知`);
          }
        }
      }
    });
  };

  // 提交批量导入添加用户、
  const handleAddBulkClick = () => {
    setAddBulkFormVisible(true);
  };
  const handleCancelAddBulkForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAddBulkFormVisible(false);
  };
  const handleSubmitAddBulkForm = (e: any) => {
    setAddBulkFormVisible(false);
    if (e) {
      setFailUrl(e?.data?.url);
      setLoadTotal(e?.data?.total);
      if (e.success) {
        setBatchId(e?.data?.batchId);
        setSuccessModalVisible(true);
      } else {
        if (e?.data == null) {
          message.error(e?.message);
          return;
        }
        setFailModalVisible(true);
      }
    }
  };

  /* -- 详情业务模块-- */
  // 批次详情弹窗
  const handleDetailClick = (record: any) => {
    if (record.createType == 1) {
      setBatchId(record?.id);
      setCounter((c) => c + 1);
      setDetailFormVisible(true);
    } else {
      setOneDetailFormVisible(true);
    }
  };
  const handleCancelDetailForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setDetailFormVisible(false);
  };
  const handleSubmitDetailForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setDetailFormVisible(false);
  };
  // 单次详情弹窗
  const handleCancelOneDetailForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOneDetailFormVisible(false);
  };
  const handleSubmitOneDetailForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setOneDetailFormVisible(false);
  };

  // 成功与失败弹窗
  const handleCancelFailModal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setFailModalVisible(false);
  };
  const handleSubmitFailModal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setFailModalVisible(false);
    window.open(failUrl);
  };

  const handleCancelSuccessModal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSuccessModalVisible(false);
    setAddBulkFormVisible(true);
  };
  const handleSubmitSuccessModal = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSuccessModalVisible(false);
    // setBatchId(record?.id);
    setDetailFormVisible(true);
    setDetailFormVisible(true);
  };

  return (
    <>
      <div>
        <Card>
          <Row>
              <Button type="primary" style={{marginRight:'8px'}} onClick={handleAddUserClick}>
                添加用户
              </Button>
              <Button  onClick={handleAddBulkClick}>
                批量导入
              </Button>
          </Row>
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            本表仅统计手动添加用户记录，不含商城主动注册用户
          </div>
          <Table
            columns={columns}
            pagination={pagination}
            loading={loading}
            dataSource={tableData}
            rowKey="id"
          />

          {/* 添加用户弹窗 */}
          <AddUserFormRender
            title="添加用户"
            form={addUserFormEl}
            siteId={siteId}
            options={treeData}
            selectTreeKey={selectTreeKey}
            visible={addUserFormVisible}
            handleCancel={handleCancelAddUserForm}
            handleOk={handleSubmitAddUserForm}
          />
          <AddBulkFormRender
            title="导入用户"
            visible={addBulkFormVisible}
            handleCancel={handleCancelAddBulkForm}
            handleOk={handleSubmitAddBulkForm}
            getBatchList={getBatchList}
            pageNo={pageNo}
            pageSize={pageSize}
          />

          {/* 详情弹窗 */}
          <Detail
            title="详情"
            visible={detailFormVisible}
            batchId={batchId}
            counter={counter}
            handleCancel={handleCancelDetailForm}
            handleOk={handleSubmitDetailForm}
          />

          <OneDetail
            title="详情"
            visible={oneDetailFormVisible}
            handleCancel={handleCancelOneDetailForm}
            handleOk={handleSubmitOneDetailForm}
          />

          <FailModal
            title=""
            visible={failModalVisible}
            handleCancel={handleCancelFailModal}
            handleOk={handleSubmitFailModal}
            loadTotal={loadTotal}
          />

          <SuccessModal
            title=""
            visible={successModalVisible}
            handleCancel={handleCancelSuccessModal}
            handleOk={handleSubmitSuccessModal}
            loadTotal={loadTotal}
          />
        </Card>
      </div>
    </>
  );
}

export default observer(UserAddIndex);
