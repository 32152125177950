import { makeAutoObservable, configure, runInAction } from 'mobx'
// import Request from '@/utils/httpClient'
// import { objClone } from '@/utils/common'
import { TemplateTypes } from '@/types/store/template.interface';
configure({ enforceActions: 'observed' })

class TemplateStore implements TemplateTypes {
  [key: string]: any
  constructor() {
    makeAutoObservable(this);
  }

  public storeName: string = 'template'

  // 更新state
  updateState = (params: TemplateTypes) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value
    }
  }
}

export default new TemplateStore()