// import { OrderApis } from 'api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { Moment } from "moment";
// import { DetailGoodItemProp } from 'stores/OrderDetailStore';
import httpClient from "@/utils/httpClient";
import { ListDetailProps, PageProps } from "../types/commom";
import { getFakeTotal } from "@/utils/common";

export interface GetOrderListParams extends PageProps {
  [key: string]: any;
}

export interface OrderItem {
  // 商品图片地址
  afterSaleGoodsImage: string;
  // 商品名称
  afterSaleGoodsName: string;
  // 运费
  freightFee: number;
  // 站点名称
  siteName: string;
  // 站点id
  siteId: string;
  // 用户应付
  customerPayment: number;
  // 退款总额
  returnedAmount: number;
  // 申请人
  applicant: string;
  // 售后订单编号
  afterSaleOrderNo: string;
  // 支持保修类型
  afterSaleType: string | number;
  // 售后订单状态
  afterSaleStatus: string | number;
  // 商品价格
  afterSaleGoodsPrice: number;
  // 售后申请时间
  afterSaleApplyTime: string;
  // 申请数量
  applyNum: string;
  // 订单取消时间
  canceLTime: string;
}

interface OrderStatusCountParams {
  companyId?: "";
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType?: 1 | 2 | 3;
  userId?: "";
  siteId?: number;
}

interface OrderStatusCountResult {
  // 待付款订单数量
  paymentCount: 0;
  // 待发货订单数量
  sendCount: 0;
  // 已发货订单数量
  shippedCount: 0;
}

export interface CancelOptionProps {
  key: string;
  value: string;
}

interface OrderListStoreProps {
  orderList: ListDetailProps<OrderItem>;
}

class OrderListStore implements OrderListStoreProps {
  constructor() {
    makeAutoObservable(this);
  }
  @observable
  orderList: ListDetailProps<OrderItem> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0,
  };

  @action
  async ajaxGetPurWholesaleOrderList(params: Partial<GetOrderListParams>) {
    const res: any = await httpClient.post(
      "/fleshopy/order/after/v1/list",
      params
    );
    if (res?.success) {
      runInAction(() => {
        this.orderList = {
          ...res.data,
          total: getFakeTotal(params.pageSize, res?.data?.total),
        };
      });
    }
  }

  @observable
  waitRefundCount = null;

  @action
  async ajaxGetOrderStatusCount(params: OrderStatusCountParams) {
    const res: any = await httpClient.get(
      "/fleshopy/order/after/v1/count",
      { params }
    );
    if (res?.success) {
      runInAction(() => {
        this.waitRefundCount = res?.data?.waitRefundCount;
      });
    }
  }

  @action
  async ajaxQueryLogisticByExpressNo(params: { expressNo: any }): Promise<any> {
    const res: any = await httpClient.get(
      "/fleshopy/cuser/order/v1/queryLogisticByExpressNo",
      { params }
    );
    return res?.success && res.data;
  }

  @action
  async ajaxQueryLogisticByOrderNo(params: { afterSaleNo: any }): Promise<any> {
    const res: any = await httpClient.get(
      "/fleshopy/order/after/v1/progress",
      { params }
    );
    return res?.success && res.data;
  }
}

export default new OrderListStore();
