import request from '@/utils/httpClient'

import { mainHost } from '@/config/index'
import { ENV } from '@/config/index'

export interface AddTinyPageForm {
  pageName: string
  title: string
}

export const getSitePreview = (
  siteId: string | number,
  tinyId: number,
  type: string = 'fitment'
) => {
  // 获取站点装修地址
  let _url = ''
  switch (ENV) {
    case 'dev':
      _url = `http://dev-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        'token'
      )}&origin=${encodeURIComponent(window.location.href)}&genre=site&tinyId=${tinyId}`
      break
    case 'qa':
      _url = `http://qa-deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        'token'
      )}&origin=${encodeURIComponent(window.location.href)}&genre=site&tinyId=${tinyId}`
      break
    default:
      _url = `https://deco.fleshopy.com/${type}/${siteId}?token=${localStorage.getItem(
        'token'
      )}&origin=${encodeURIComponent(window.location.href)}&genre=site&tinyId=${tinyId}`
  }
  const loginLink = localStorage.getItem('loginLink')
  if (loginLink) {
    _url += `&loginLink=${loginLink}`
  }
  return _url
}
// 获取h5微页面预览
export const getSiteUrl = (siteId: string, tinyId: number) => {
  let _url = ''
  switch (ENV) {
    case 'dev':
      _url = `http://${siteId}.dev-shop.fleshopy.com/tiny/${tinyId}`
      break
    case 'test':
      _url = `http://${siteId}.qa-shop.fleshopy.com/tiny/${tinyId}`
      break
    case 'qa':
      _url = `http://${siteId}.qa-shop.fleshopy.com/tiny/${tinyId}`
      break
    default:
      _url = `https://${siteId}.shop.fleshopy.com/tiny/${tinyId}`
  }
  return _url
}

// 获取微页面列表
export const getTinyPageList = (siteId: string) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/siteDecoration/listSiteDecorations',
    method: 'get',
    params: {
      siteId,
    },
  })
}

// 添加微页面
export const addTinyPage = (siteId: string, data: AddTinyPageForm) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/siteDecoration/add',
    method: 'post',
    data: JSON.stringify({ ...data, siteId }),
  })
}

// 将微页面设置为首页
export const updateSetupHomePage = (siteId: string, siteDecorationId: string) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/siteDecoration/setHomepage',
    method: 'post',
    data: JSON.stringify({ siteId, siteDecorationId }),
  })
}

// 删除微页面
export const deleteTinyPage = (siteId: string, siteDecorationId: string) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/siteDecoration/delete',
    method: 'post',
    data: JSON.stringify({ siteId, siteDecorationId }),
    /* params: {
      siteId,
      siteDecorationId,
    }, */
  })
}

// 设置微页面类型

export const updateSetTab = (siteId: string, siteDecorationId: string, useFlag: number) => {
  return request({
    url: mainHost + '/fleshopy/merchant/site/siteDecoration/setTab',
    method: 'post',
    data: JSON.stringify({
      siteId,
      siteDecorationId,
      useFlag,
    }),
  })
}

// 获取底部tab列表
export const getFootTab = () => {
  return request({
    url: mainHost + '/fleshopy/style/siteStyleConfig/getSiteConfig',
    method: 'get',
    params: { siteId: sessionStorage.getItem('siteId') },
  })
}

// 编辑底部tab
export const updateFootTab = (params: any) => {
  return request({
    url: mainHost + '/fleshopy/style/siteBottomConfig/updateBottomConfig',
    method: 'post',
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem('siteId'),
    }),
  })
}

// 更新底部导航样式
export const updateBottomStyle = (params: any) => {
  return request({
    url: mainHost + '/fleshopy/style/siteBottomConfig/updateBottomStyle',
    method: 'post',
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem('siteId'),
    }),
  })
}

// 获取新官网列表
export const getWebsiteList = (params: any) => {
  return request({
    url: mainHost + '/merchant/fleshopy/official/official-site/officialSitePage',
    method: 'post',
    data: JSON.stringify({
      ...params,
    }),
  })
}
