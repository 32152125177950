/*
 * @Author: your name
 * @Date: 2020-01-16 21:02:47
 * @LastEditTime : 2020-02-18 10:48:57
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /fx-operate-admin/src/config/api.js
 */
const api = {
  // 通用
  common_get_time: "/basic/common/system/datetime", // 获取服务器时间
  common_goods_recommend: "/elebuys/product/recommend/recommend", // 为你推荐
  common_goods_add_recommend: "/product/facade/recommend/addBrowseRecord", // 添加浏览记录
  common_getHotWords: "/elebuys/mobile/index-page/get-hot-words",
  common_getAreaList: "/basic/area/areas",
  common_upload: "/basic/new/file/upload", // 上传单个文件
  common_batchUpload: "/basic/new/file/batchUpload", // 批量上传文件
  common_sms_code: "/elebuys/member/company/sms-code", // 给会员管理员发送短信验证码
  common_sms_code_mobile: "/elebuys/basic/sms/sms/sms-code", // 发送短信验证码
  common_sms_code_check: "/elebuys/member/employee/old-mobile/check", // 校验短信验证码（校验后验证码仍有效）
  common_get_banner: "/elebuys/mall/channel/getBannerImageUrlById", // 查询频道横幅url
  common_wx_share_code: "/basic/wechat/miniapp/common/wxShareAcode", // 获取小程序二维码-通用
  common_share_to_short: "/basic/shareUrl/transToShort", // 分享链接(长码转为短码)
  common_share_to_long: "/basic/shareUrl/transToLong", // 分享链接(短码转为长码)
  common_getExpressList: "/basic/express/list", // 获取物流公司列表
  common_add_share_log: "/elebuys/activity/share/addShare", // 添加分享记录
  common_get_company: "/elebuys/member/employee/company-admin", // 查询企业信息
  common_add_company_info: "/elebuys/collect/company-info-collect/add", // 会员信息收集

  // 首页
  Home: {
    GET_CRAD_CASE: "/merchant/card/case/pageQueryList", // 获取首页卡券案例
    GET_RENOVATION: "/merchant/card/block/getDetail", // 获取首页或者制卡页面装修
    Add_BROWSE: "/merchant/card/case/browse", // 卡券案例浏览计数
  },

  // 用户相关
  User: {
    CHECK_HAS_SET_PASSWORD: "/card/user/code/judge", //判断用户是否设置了操作密码
    SET_PASSWORD: "/card/user/code/setCode", //储存操作密码
    CHECK_PASSWORD: "/card/user/code/check", //验证操作密码是否正确
    SEND_Verification_Code: "/card/user/code/sendMessage", //发送验证码
    GET_SMSCOUNT: "/card/batch/smsCount", //查询企业剩余短信包数量
  },

  //制卡相关
  Card: {
    GET_GOODS_TYPES: "/admin/product/category/getTreeList", //获取商品分类
    GET_CARD_MAIN_IMG: "/card/cover/query", //获取官方推荐封面

    GET_CARD_LABELS: "/card/label/show", //获取卡券模板标签
    GET_CARD_MUBANS: "/card/coupon/queryTemplatePage", //获取卡券模板列表
    GET_CARD_MUBAN_DETAIL: "/card/coupon/getTemplate", //获取卡券模板详情

    MAKE_CARD: "/card/coupon/create", //卡券制作
    UPDATE_CARD: "/card/coupon/update", //卡券更新

    GET_CARD_DETAIL: "/card/coupon/get", //卡券详情

    GET_CARD_LIST: "/card/coupon/queryPage", //卡券列表

    GET_CARD_NUM: "/card/coupon/sum", //卡券数量

    UPDATE_STATUS: "/card/coupon/updateStatus", //卡券上下架

    GET_NUM: "/card/batch/sum", //卡号和卡密 - 统计数量

    GET_BATCH: "/card/batch/queryBatchPage", //查询批次列表

    GET_BATCH_DETAIL: "/card/batch/queryCardPage", //批次详情页 - 查询卡片

    GET_BATCH_SELECT: "/card/coupon/selectList", //筛选卡名称 - 卡券下拉列表

    GET_ACTION_USER: "/card/operation/dropQuery", //操作人下拉框

    CARD_SEND: "/card/batch/distributeOnlineCard", //发放电子卡 - 手动添加 - 确认发放
    CARD_SEND_EXCEL_CHECK: "/card/batch/verifyImportOnlineCard", //发放电子卡 - 表格导入 - 验证文件
    CARD_SEND_EXCEL: "/card/batch/importOnlineCard", //发放电子卡 - 表格导入 - 确认发放

    CARD_PSD: "/card/batch/generateCard", //生成卡密
    CARD_PSD_EXCEL_CHECK: "/card/batch/verifyImportCard", //已有卡密导入 - 验证文件
    CARD_PSD_EXCEL: "/card/batch/importCard", //已有卡密导入 - 确认导入

    DOWNLOAD_PSD: "/card/batch/exportCard", //下载卡密

    UPDATE_CARD_STATUS: "/card/batch/updateCardStatus", //激活或取消激活卡片

    SEARCH_CARD: "/card/coupon/searchCard", //搜索具体卡券
  },

  Action: {
    DOWNLOAD: "/card/operation/downloadQuery", //下载记录
    LOG: "/card/operation/logQuery", //操作日志
  },

  Goods: {
    GET_LABEL: "/admin/product/goods/tag/getList", //一级标签列表
    GET_LABEL_CHILD: "/admin/product/goods/tag/getListByPage", //二级标签列表
    GET_LIST: "/card/coupon/search", //商品池商品列表
    GET_GOODS_DETAIL: "/fleshopy/merchant/site/site-goods/getGoodsDetail", //获取商品详情
    GET_FREIGHT_INFO: "/basic/freight-template/get", // 获取运费模板详情
    GET_FREIGHT: "/basic/freight-template/calculate", // 计算运费
    GET_ADDRESS: "/basic/area/areas", // 获取地址
    GET_DGSS_CITY: "/supplier/dgss/city/allList", // 获取蛋糕叔叔城市
  },

  Order: {
    GET_LIST: "/fleshopy/site/order/listByPage", //获取订单列表
    GET_LIST_NUM: "/web/card/order/listOrderStatus", //订单状态统计
    CONTINUE_ORDER: "/web/card/order/continueOrder", //继续下单,
    GET_ORDER_COUNT: "/fleshopy/site/order/listOrderStatus", // 获取订单数量
    GET_ORDER_DETAIL: "/fleshopy/site/order/getDetails", //订单详情
    GET_LOG_DETIAL: "/basic/express/queryLogisticByExpressNo", //物流信息
  },

  common: {
    get_address: "/basic/area/areas", // 获取地址
    get_common_tag: "/fleshopy/goods-tag/getList", // 获取标签
    get_select_goods_list: "/fleshopy/admin/template/goodsPageList", // 精选商品挑选分页列表
    get_categor_tree_list: "/fleshopy/category/getTreeList", // 获取分类树状
    get_select_special_list: "/fleshopy/miniprogram/site/site-topic/miniProgramPageList", // 站点/模板专题挑选分页
    get_special_parent_info: "/admin/fleshopy/special-topic/getInfoById", // 获取父专题信息
    get_select_goods_list_site: "/fleshopy/merchant/site/site-goods/pageList", // 站点商品列表
    get_goods_list: "/fleshopy/admin/template/goodsPageList", // 获取商品
    get_goods_list_site: "/fleshopy/merchant/site/site-goods/pageList", // 获取商品
    get_supplier_name: "/fleshopy/supplier/short-names/page", // 分页搜索供应商简称
    check_site_topic_goods: "/fleshopy/miniprogram/site/site-topic/siteContainTopicGoods", // 校验专题是否有商品已经存在于站点中
    get_jd_goods_stock: "/fleshopy/stock/getStockJdBatch", // 获取京东库存
    get_brand: "/fleshopy/brand/getListPage", // 获取品牌列表 - 分页

    get_tem_tiny_info: "/fleshopy/admin/templateDecoration/list", // 批量获取微页面相关信息 模版
    get_site_tiny_info: "/fleshopy/merchant/site/siteDecoration/list", // 批量获取微页面相关信息 站点

    get_site_group_info: "/fleshopy/site/goods/group/getList", // 根据id查询多个个分组

    get_common_category_info: "/fleshopy/category/getListByIds", // 根据分类ID列表获取列表

    get_common_brand_info: "/fleshopy/brand/getListByIds", // 品牌列表

    get_common_goods_info: "/fleshopy/goods/getListByIds", // 商品

    get_tem_special_info: "/admin/fleshopy/special-topic/selectTopic", // 专题
    get_site_special_info: "/fleshopy/specialtopic/special-topic/getInfoById", // 专题
    get_site_goods_group: "/fleshopy/site/goods/group/queryPage", // 分组
    get_site_goods_group_info: "/fleshopy/site/goods/group/getList",

    get_programs_by_name: "/program/goods/getProgramsByName", // 根据名称、类型模糊查询方案
    get_businessCard_list: "/fleshopy/businessCard/list", // 根据部门id查询名片列表

    get_organizational: "/elebuys/member/department/tree", // 获取组织架构
  },
  fitment: {
    get_fitment_tem_tiny_list: "/fleshopy/admin/templateDecoration/listTemplateDecorations", // 获取该模版下的微页面列表
    get_fitment_content: "/fleshopy/admin/templateDecoration/getDecorationContent", // 获取装修内容
    update_fitment_content: "/fleshopy/admin/templateDecoration/updateDecoration", // 模板装修更新
    add_goods_to_template: "/fleshopy/admin/template/addGoods", // 挑选商品到模版
    add_special_to_template: "/fleshopy/admin/template/addTopic", // 挑选专题到模版
    add_goods_to_site: "/fleshopy/merchant/site/site-goods/addGoods", // 添加商品到站点
    add_special_to_site: "/fleshopy/merchant/site/addTopic", // 站点添加专题
    get_special_goods_list: "/admin/fleshopy/special-topic/topicGoodsPageList", // 专题商品分页列表
    get_site_special_goods_list: "/merchant/fleshopy/special-topic/topicGoodsPageList", // 站点端专题详情

    get_site_content: "/fleshopy/merchant/site/siteDecoration/getDecorationContent", // 站点信息获取
    updata_site_content: "/fleshopy/merchant/site/siteDecoration/updateDecoration", // 装修保存、发布

    get_template_info: "/fleshopy/admin/template/getDetail", // 获取模板信息
    get_site_info: "/fleshopy/merchant/site/getSite", // 站点信息获取

    get_share_template: "/fleshopy/admin/template/getShare", // 模板分享信息获取
    update_share_config_template: "/fleshopy/admin/template/addUpdateTemplateShare", // 模版分享设置

    get_share_site: "/fleshopy/merchant/site/getShare", // 站点分享信息获取
    update_share_config_site: "/fleshopy/merchant/site/shareConfig", // 站点分享设置

    get_fitment_site_tiny_list: "/fleshopy/merchant/site/siteDecoration/listSiteDecorations", // 获取该站点下微页面列表

    get_pick_goods_to_site_status: "/fleshopy/merchant/site/site-goods/getPickGoodsToSiteStatus", // 挑选商品到站点状态查询

    add_tempalte_brand_goods: "/fleshopy/admin/template/addBrandGoods", // 添加品牌到模版
    get_tempalte_brand_goods: "/fleshopy/admin/template/brandIdList", // 获取品牌到模版

    add_tempalte_category_goods: "/fleshopy/admin/template/addCategoryGoods", // 添加分类到模版
    get_tempalte_category_goods: "/fleshopy/admin/template/categoryIdList", // 获取分类到模版

    site_check_before_goods: "/fleshopy/merchant/site/site-goods/checkBeforeAddGoods", // 添加商品之前 - 亏本校验
    site_check_before_special: "/fleshopy/merchant/site/checkBeforeAddGoods",

    get_member_shop: "/merchant/member/shop/shops/search/v2", // 搜索商铺列表V2
    get_select_pageList_for_mall: "/fleshopy/merchant/site/site-goods/selectPageListForMall", // 站点挑选外部商品

    add_topic_check_loss: "/fleshopy/miniprogram/site/site-topic/addTopicCheckLoss", // 站点添加专题亏本校验
    change_price_pick_goods: "/fleshopy/merchant/site/site-goods/addGoods", // 改价
    get_goods_all_flag_list: "/fleshopy/goods/flag/allList", // 商品标签列表
    get_goods_category: "/elebuys/goodsCategory/tree", // 新的商品品类
  },
  //商品分组类
  commodityNewGroup: {
    get_list: "/fleshopy/group/group/getGroupList", // 新的商品品类
    get_site: '/fleshopy/merchant/site/getSite', //获取site信息
    get_group_goods_list: "/fleshopy/group/group-goods/getGroupGoodsList", // 根据分组id 分页商品
    add_group: "/fleshopy/group/group/addGroup", // 
    update_grup: "/fleshopy/group/group/updateGroup", //
    delete_group: "/fleshopy/group/group/deleteGroup", //
    add_group_goods: "/fleshopy/group/group-goods/addGroupGoods", //
    get_group_info: "/fleshopy/group/group/getGroupInfo", //
    get_group_all_goods_list: "/fleshopy/group/group-goods/getGroupAllGoodsList", // 全部商品 用于挑选完 排序 删除 
  }
};

export default api;
