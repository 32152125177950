import React, { useEffect, useState } from 'react'

import './index.scss'
import { inject, observer } from 'mobx-react'

import MenuList from '@/components/MenuList'
import Tabale from './Table'
import AddGroupModal from './AddGroupModal'

import { ExclamationCircleOutlined, ArrowRightOutlined } from '@ant-design/icons'

import { useHistory } from 'react-router-dom'

import { getGoodsGroupList, addGoodsGroup, updateGoodsGroup, deleteGoodsGroup } from './store'

import { Button, Modal, message } from "@fle-ui/next"
import { PageContainer } from '@fle-ui/pro-layout'
const { confirm } = Modal

// 定义 menu
// const menus = [
//   {
//     text: '精选商品',
//     key: 'fx',
//   },
//   {
//     text: '京东商品',
//     key: 'jd',
//   },
//   {
//     text: '自有商品',
//     key: 'self',
//   }
// ]

// 是否确认删除
const showConfim = (successCb: Function) => confirm({
  title: `确定删除?`,
  icon: <ExclamationCircleOutlined />,
  content: '删除本商品分组后，未分组的商品将移入待分组中',
  cancelText: '我再想想',
  okText: `确认删除`,
  onOk() {
    successCb()
  }
})

const CommodityGroup = ({ siteId }: any) => {

  const history = useHistory()

  const [visible, setVisible] = useState<boolean>(false)
  const [modalOkText, setModalOkText] = useState<string>('确认创建')
  const [modalTitle, setModalTitle] = useState<string>('创建分组')

  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNo, setPageNo] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [tableData, setTableData] = useState<any[]>([])

  const [activeKey, setActiveKey] = useState<string>('fx')
  const [goods, setGoods] = useState<any>(null)

  // 主动控制menu
  // const handleChnageMenuClick = (e: any) => {
  //   const { key } = e
  //   setActiveKey(key)
  //   setPageNo(1)
  //   setPageSize(10)

  // }

  const getGoodsGroupListBySiteId = async (
    pageNo: number,
    pageSize: number,
  ) => {
    setLoading(true)
    const { data, success } = await getGoodsGroupList(pageNo, pageSize, siteId) as any
    if (success) {
      const { list = [], total = 0 } = data
      setTableData(list)
      setLoading(false)
      setTotal(total)
    }
  }

  useEffect(() => {
    getGoodsGroupListBySiteId(pageNo, pageSize)
  }, [pageNo, pageSize])

  const handleChangPageNo = (page: number, pageSize: number): void => {
    setPageNo(page)
  }

  const handleChangPageSize = (page: number, pageSize: number): void => {
    setPageSize(pageSize)
  }

  const pagination = {
    pageSize,
    total,
    current: pageNo,
    pageSizeOptions: ['10', '20'],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共${total}条,第${pageNo}页`,
    onChange: handleChangPageNo,
    onShowSizeChange: handleChangPageSize
  }

  // 编辑分组
  const handleEditGroupClick = (goods: any) => {
    setVisible(true)
    setModalOkText('确认保存')
    setModalTitle('编辑分组')
    setGoods(goods)
  }

  const handleDeleteGroupClick = (id: string) => {
    const cb = async () => {
      const { success } = await deleteGoodsGroup(siteId, id) as any
      if (success) {
        getGoodsGroupListBySiteId(pageNo, pageSize)
      }
    }
    showConfim(cb)
  }

  const handleSubmitForm = async (e: React.MouseEvent<HTMLElement>, values: any) => {
    let result: { success: boolean } = {
      success: true
    }
    const data: any = {
      ...values,
    }

    if (data.groupId == null) {
      result = await addGoodsGroup(siteId, data) as any
      message.success('添加分组成功')
      setPageNo(1)
      getGoodsGroupListBySiteId(1, pageSize)
    } else {
      result = await updateGoodsGroup(siteId, data) as any
      message.success('修改分组成功')
      getGoodsGroupListBySiteId(pageNo, pageSize)
    }

    const { success }: any = result
    if (!success) {
      return
    }
    setVisible(false)
  }

  // 取消提交
  const handleCancelClick = () => {
    setVisible(false)
  }

  // 新增分组按钮
  const handleAddGroupClick = () => {
    setGoods(null)
    setVisible(true)
    setModalOkText('确认创建')
    setModalTitle('创建分组')
    setGoods({ groupName: '' })
  }

  // 跳转到我的商品
  const handleLactionCommodityClick = () => {
    history.push('/mineCommodity/commodity')
  }

  return (
    <PageContainer className="mine-commodiy-container">
      <div className='commodity-group'>
        <div className='menu-list-wrapper'>

          {/* <MenuList menuList={menus} activeKey={activeKey} onClick={handleChnageMenuClick}/> */}

          <div className='add-group-wrapper'>
            <Button type='primary' onClick={handleAddGroupClick} >新增分组</Button>
          </div>
          <div className='go-comodity' onClick={handleLactionCommodityClick}>
            <span className='go-text'>{`前往分组商品`}
            </span>
          </div>
        </div>

        <Tabale
          pagination={pagination}
          tableData={tableData}
          loading={loading}
          onEdit={handleEditGroupClick}
          onDelete={handleDeleteGroupClick} />

        <AddGroupModal
          handleSubmitForm={handleSubmitForm}
          visible={visible}
          addFormContent={goods}
          groupInfo={goods}
          okText={modalOkText}
          title={modalTitle}
          handleCancelClick={handleCancelClick} />

      </div>
    </PageContainer>
  )
}

export default inject('siteId')(observer(CommodityGroup))