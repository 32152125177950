import { makeAutoObservable } from 'mobx'
import httpClient from '@/utils/httpClient'
import Api from '@/config/api'

class GoodsStore {

    constructor() {
        makeAutoObservable(this);
    }

    // 商品池商品列表
    async getList(params: any) {
        return await httpClient.post(Api.Goods.GET_LIST, { ...params })
    }

    // 获取商品一级标签
    async getLabelList(params: any) {
        return await httpClient.post(Api.Goods.GET_LABEL, { ...params })
    }

    // 获取商品二级标签
    async getLabelChildList(params: any) {
        return await httpClient.post(Api.Goods.GET_LABEL_CHILD, { ...params })
    }

    // 获取商品详情
    async getGoodsDetail(params: any) {
        return await httpClient.post(Api.Goods.GET_GOODS_DETAIL, { ...params })
    }

    // 获取运费模板信息
    async getSendAddr(params: any) {
        try {
            const { data } = await httpClient.post(Api.Goods.GET_FREIGHT_INFO, params);
            return data
        } catch (error) {
            return false
        }
    }

    // 获取运费
    async getExpressCost(params: any) {
        try {
            const { data } = await httpClient.post(Api.Goods.GET_FREIGHT, params);
            return data
        } catch (error) {
            return false
        }
    }

    async getAddress(params:any) {
        const { data, success } = await httpClient.post(Api.Goods.GET_ADDRESS, params) as any;

        if (success) {
            return data;
        }
    }

    // 获取蛋糕叔叔城市
    async getDgssCity() {
        return await httpClient.post(Api.Goods.GET_DGSS_CITY, {}) as any
    }
}

export default new GoodsStore()