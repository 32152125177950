import React, { useState, useEffect,useImperativeHandle } from 'react'
import {
    Select,
} from "@fle-ui/next";
import {
    getUserListByGroupId,
} from "@/views/user/UserInfo/store";

const SelectUser = React.forwardRef((props:any,ref:any) => {
    const siteId = window.location.pathname.split("/")[1];

    useImperativeHandle(ref, () => ({
        clear: () => {
          return setSelectValue('');
        },
      }));
    
    const [options, changeOptions] = useState([])
    const [searchData, changeSearchData] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            total: 0
        },
        searchValue: ''
    })
    const { pagination: { current, pageSize, total }, searchValue } = searchData
    useEffect(() => {
        // 页码，及搜索值变化时，发请求
        getUserList()
    }, [current, searchValue])
    const scrollEnd = (e: any) => {
        e.persist();
        const { target } = e;
        // 滚动 触底 看接口是否还有剩余的值没传过来
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (current * pageSize < total) {
                changeSearchData({
                    ...searchData,
                    pagination: {
                        ...searchData.pagination,
                        current: current + 1
                    },
                })
            }
        }
    }

    // 搜索条件变化时，current变成1，
    const searchDataset = (value: any) => {
        changeSearchData({
            pagination: {
                ...searchData.pagination,
                current: 1
            },
            searchValue: value
        })
    }

    const getUserList = async () => {
        const { data = {}, success } = (await getUserListByGroupId(
            "",
            siteId,
            current,
            pageSize,
            {status:'',mobile:searchValue}
        )) as any;
        if (success) {
            const pagination = {
                current: data.page,
                pageSize: data.pageSize,
                total: data.total
            }
            const options = data.list.map((item:any) => {
                return {
                    label:`${item.mobile}${item.name ? item.name : ''}`,
                    value:item.id
                }
            })
            changeOptions(options)
            changeSearchData({ ...searchData, pagination })
        }
    }

    const handleSelect = (value:any) => {
        setSelectValue(value)
        props.onClick && props.onClick(value)
    }

    const handleClear = (e:any) => {
        setSelectValue('')
    }


    const [selectValue,setSelectValue] = useState('')

    return (
        <Select
            value={selectValue}
            style={{ flex: 1 }}
            showSearch={true}
            options={options}
            allowClear
            // options的值 搜索需要filterOption return true 保证过滤的数据是从接口取过来的
            filterOption={() => { return true }}
            onPopupScroll={scrollEnd}
            onChange={handleSelect}
            onSearch={searchDataset}
        >
        </Select>
    )
})

export default SelectUser