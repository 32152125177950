import React from "react";
import { Button } from "@fle-ui/next";
import { useHistory } from "react-router-dom";
import "./index.less";

interface ErrorPageProps {}

const ErrorPage: React.FC<ErrorPageProps> = () => {
  const history = useHistory();

  const handleGoToCardClick = () => {
    history.go(0);
  };

  return (
    <div className="error-page">
      <div className="error-page-image-wrap">
        <img
          src="https://oss.elebuys.com/202107051502290005232329.png"
          className="error-page-image"
          alt="页面飞走了～"
        />
      </div>
      <div className="error-page-content-wrap">
        <div className="error-page-status">FAIL</div>
        <div className="error-page-content">
          抱歉！出了一点意外， 请您尝试刷新...
        </div>
        <div className="error-page-btn-wrap">
          <Button onClick={handleGoToCardClick} type="primary" ghost>
            点我刷新
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
