import { commodityNewGroupStore } from '@/store'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Modal, Space, Table, Tabs, Tooltip } from '@fle-ui/next'
import React, { FC, useEffect, useState } from 'react'

import "./index.scss"
interface Iprops {
  isGroupingModal: boolean
  setIsGroupingModal: any
  groupId: number
}

export const toDecimal2 = (x) => {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');
  if (rs < 0) {
    rs = s.length;
    s += '.';
  }
  while (s.length <= rs + 2) {
    s += '0';
  }
  return s;
}

export const formatPrice = (fen: any) => {
  let num = fen;
  num = fen * 0.01;
  num += '';
  let reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg, '$1');
  num = toDecimal2(num)
  return num;
}
const index: FC<Iprops> = (props) => {
  const { isGroupingModal, setIsGroupingModal, groupId } = props

  const [datasource, setDataSource] = useState([])
  const [total, setTotal] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const [loading, setLoading] = useState(false)

  const [app, setApp] = useState<number>(0)

  const getApp = async () => {
    const res: any = await commodityNewGroupStore.getSite({ siteId: sessionStorage.getItem('siteId') })
    if (res?.success) {
      setApp(res.data.app)
    }
  }

  useEffect(() => {
    getApp()
  }, [])

  const getList = async () => {
    try {
      const params = {
        siteId: sessionStorage.getItem('siteId'),
        pageNo,
        pageSize,
        groupId,
      }
      setLoading(true)
      const res = await commodityNewGroupStore.getGroupGoodsList({ ...params })
      setLoading(false)
      setDataSource(res.data.list)
      setTotal(res.data.total)
    } catch (err) {
      console.warn(err)
      setLoading(false)
    }

  }

  useEffect(() => {
    if (groupId) {
      getList();
    }
  }, [pageSize, pageNo, groupId]);

  const columns = [
    {
      title: '商品',
      dataIndex: 'goods',
      key: 'goods',
      with: 150,
      fixed: 'left',
      render: (_: any, record: any) => <div className='commodity-container'>
        <img src={record.saasPoolVo?.mainImage} alt="" />
        <div className='info'>
          <p className='text-over'>
            <Tooltip placement="topLeft" title={record.saasPoolVo?.name}>{record.saasPoolVo?.name}</Tooltip>
          </p>
          <p>商品ID:{record.saasPoolVo?.goodsId}</p>
        </div>
      </div>

    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      render: (_: any, record: any) => record.saasPoolVo.brandName

    },
    {
      title: '市场价',
      dataIndex: 'marketPriceMax',
      key: 'marketPriceMax',
      align: 'center',
      width: 98,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo?.marketPriceMin === record?.saasPoolVo?.marketPriceMax) {
          return <div>￥{formatPrice(record?.saasPoolVo?.marketPriceMax)}</div>
        }
        return (
          <>
            <div>￥{formatPrice(record?.saasPoolVo?.marketPriceMin)}</div>
            <div>~</div>
            <div>￥{formatPrice(record?.saasPoolVo?.marketPriceMax)}</div>
          </>
        )
      }
    },
    {
      title: '会员价', // 销售价
      dataIndex: 'salePriceMax',
      key: 'salePriceMax',
      align: 'center',
      width: 86,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo?.salePriceMin === record?.saasPoolVo?.salePriceMax) {
          return <div>￥{formatPrice(record?.saasPoolVo?.salePriceMax)}</div>
        }
        return (
          <>
            <div>￥{formatPrice(record?.saasPoolVo?.salePriceMin)}</div>
            <div>~</div>
            <div>￥{formatPrice(record?.saasPoolVo?.salePriceMax)}</div>
          </>
        )
      }
    },
    {
      title:'利润率',
      dataIndex: 'siteProfitMin',
      key: 'siteProfitMin',
      align: 'center',
      width: 130,
      render: (text: any, record: any) => {

        if (record?.saasPoolVo?.memberProfitMin === record?.saasPoolVo?.memberProfitMax) {
          return <div>{record?.saasPoolVo?.memberProfitMax}%</div>
        }

        return (
          <>
            <div>{record?.saasPoolVo?.memberProfitMin}%</div>
            <div>~</div>
            <div>{record?.saasPoolVo?.memberProfitMax}%</div>
          </>
        )
      }
    },
  ];

  const columnsYX = [
    {
      title: '商品',
      dataIndex: 'goods',
      key: 'goods',
      with: 150,
      fixed: 'left',
      render: (_: any, record: any) => <div className='commodity-container'>
        <img src={record.saasPoolVo?.mainImage} alt="" />
        <div className='info'>
          <p className='text-over'>
            <Tooltip placement="topLeft" title={record.saasPoolVo?.name}>{record.saasPoolVo?.name}</Tooltip>
          </p>
          <p>商品ID:{record.saasPoolVo?.goodsId}</p>
        </div>
      </div>

    },
    {
      title: '品牌',
      dataIndex: 'brandName',
      key: 'brandName',
      render: (_: any, record: any) => record.saasPoolVo.brandName

    },
    {
      title: '集采市场价',
      dataIndex: 'marketPriceMin',
      key: 'marketPriceMin',
      align: 'center',
      width: 98,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo?.marketPriceMin === record?.saasPoolVo?.marketPriceMax) {
          return <div>{formatPrice(record?.saasPoolVo?.marketPriceMin)}</div>
        }
        return (
          <>
            <div>{formatPrice(record?.saasPoolVo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{formatPrice(record?.saasPoolVo?.marketPriceMax)}</div>
          </>
        )
      }
    },
    {
      title: '代发市场价',
      dataIndex: 'marketPriceMin',
      key: 'marketPriceMin',
      align: 'center',
      width: 98,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo.onceInfo?.marketPriceMin === record?.saasPoolVo.onceInfo?.marketPriceMax) {
          return <div>{formatPrice(record?.saasPoolVo.onceInfo?.marketPriceMin)}</div>
        }
        return (
          <>
            <div>{formatPrice(record?.saasPoolVo.onceInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{formatPrice(record?.saasPoolVo.onceInfo?.marketPriceMax)}</div>
          </>
        )
      }
    },

    {
      title: '集采价',
      dataIndex: 'salePriceMin',
      key: 'salePriceMin',
      align: 'center',
      width: 200,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo.isPurchase === 1) {
          if (record?.saasPoolVo.purchaseInfo?.salesType === 2) {
            if (record?.saasPoolVo.purchaseInfo?.salePriceMin === record?.saasPoolVo.purchaseInfo?.salePriceMax) {
              return <div>{formatPrice(record?.saasPoolVo.purchaseInfo?.salePriceMin)}</div>
            }
            return (
              <>
                <div>{formatPrice(record?.saasPoolVo.purchaseInfo?.salePriceMin)}</div>
                <div>~</div>
                <div>{formatPrice(record?.saasPoolVo.purchaseInfo?.salePriceMax)}</div>
              </>
            )
          }

          if (record?.saasPoolVo.purchaseInfo?.salesType === 1) {
            return record?.saasPoolVo.purchaseInfo?.stairList.map((item: any, index: any) => {
              return (
                <div key={index}>
                  {
                    `¥${formatPrice(item.salePrice)}（${index === (record?.saasPoolVo.purchaseInfo?.stairList?.length - 1) ? `≥${item?.minNum}` : `${item.minNum}-${item.maxNum}`})`
                  }
                </div>

              )
            })
          }

          return null;
        }

        return null;
      }
    },
    {
      title: '代发价',
      dataIndex: 'supplyPriceMin',
      key: 'supplyPriceMin',
      align: 'center',
      width: 200,
      render: (text: any, record: any) => {
        if (record?.saasPoolVo.isOnce === 1) {
          if (record?.saasPoolVo.onceInfo?.salePriceMin === record?.saasPoolVo.onceInfo?.salePriceMax) {
            return <div>{formatPrice(record?.saasPoolVo.onceInfo?.salePriceMin)}</div>
          }
          return (
            <>
              <div>{formatPrice(record?.saasPoolVo.onceInfo?.salePriceMin)}</div>
              <div>~</div>
              <div>{formatPrice(record?.saasPoolVo.onceInfo?.salePriceMax)}</div>
            </>
          )
        }

        return null;
      }
    },
    {
      title: () => {
        return (
          <Tooltip title={'集采利润率=（集采市场价-集采价）/集采市场价*100%'}>
            <div>集采利润率<QuestionCircleOutlined className='ml-3' /></div>
          </Tooltip>
        )
      },
      dataIndex: 'siteProfitMin',
      key: 'siteProfitMin',
      align: 'center',
      width: 150,
      render: (text: any, record: any) => {


        if (record?.saasPoolVo.isPurchase === 1) {
          if (record?.saasPoolVo.purchaseInfo?.siteProfitMin === record?.saasPoolVo.purchaseInfo?.siteProfitMax) {
            return <div>{record?.saasPoolVo.purchaseInfo?.siteProfitMin}%</div>
          }

          return (
            <>
              <div>{record?.saasPoolVo.purchaseInfo?.siteProfitMin}%</div>
              <div>~</div>
              <div>{record?.saasPoolVo.purchaseInfo?.siteProfitMax}%</div>
            </>
          )
        }

        return null;
      }
    },
    {
      title: () => {
        return (
          <Tooltip title={'代发利润率=（代发市场价-代发价）/代发市场价*100%'}>
            <div>代发利润率<QuestionCircleOutlined className='ml-3' /></div>
          </Tooltip>
        )
      },
      dataIndex: 'siteProfitMin',
      key: 'siteProfitMin',
      align: 'center',
      width: 150,
      render: (text: any, record: any) => {


        if (record?.saasPoolVo.isOnce === 1) {
          if (record?.saasPoolVo.onceInfo?.siteProfitMin === record?.saasPoolVo.onceInfo?.siteProfitMax) {
            return <div>{record?.saasPoolVo.onceInfo?.siteProfitMin}%</div>
          }

          return (
            <>
              <div>{record?.saasPoolVo.onceInfo?.siteProfitMin}%</div>
              <div>~</div>
              <div>{record?.saasPoolVo.onceInfo?.siteProfitMax}%</div>
            </>
          )
        }

        return null;
      }
    },
    {
      title: '库存',
      dataIndex: 'stock',
      key: 'stock',
      align: 'center',
      width: 70
    },
    
  ];

  const handleOk = () => {
    setIsGroupingModal(false)
  }

  const handleCancel = () => {
    setIsGroupingModal(false)

  }

  // pageSize 变化的回调
  const onChange = (page: number) => {
    setPageNo(page);
  };

  const onShowSizeChange = (_: number, size: number): void => {
    setPageSize(size)
  };

  const showTotal = () => {
    return `共有${total}条记录，当前第${pageNo}页`;
  };

  return (
    <Modal title="商品列表" footer={false} visible={isGroupingModal} onOk={handleOk} onCancel={handleCancel} width={1000}>
      <Table
        dataSource={datasource}
        columns={app != 1 ? columns : columnsYX as any}
        bordered
        loading={loading}
        pagination={
          {
            showQuickJumper: true,
            showSizeChanger: true,
            total: total,
            current: pageNo,
            pageSize: pageSize,
            onChange,
            onShowSizeChange,
            showTotal,
          }
        }
        rowKey="goodsId"
        // rowSelection={rowSelection}
        scroll={{ x: 'max-content' }}
      />
    </Modal>
  )
}

export default index