import request from '@/utils/httpClient'

import {mainHost} from '@/config/index'

  // 获取订单数量统计
export const getAllCount = async (siteId: string) => {
    return request({
      method: 'get',
      url: mainHost + '/datacenter/site/quick/entrance/orderCount',
      params: {
        siteId,
      }
    })
  }

  // 上下架统计

export const getSiteGoodsCount = (siteId: string) => {
  return request({
    method: 'get',
    url: mainHost + '/datacenter/site/quick/entrance/goodsCount',
    params: {
      siteId
    }
  })
} 