import useUrlState from "@ahooksjs/use-url-state";
import { useDebounceEffect } from "ahooks";
import {
  Button,
  Card,
  Col,
  Empty,
  Pagination,
  Row,
  Space,
  Spin,
  Tabs,
} from "@fle-ui/next";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getPagination } from "@/tools/getPagination";
import { PurWholesaleOrderStates } from "@/tools/selectOption";
import { WhiteSpace } from "../../../components";
import OrderListStore, {
  GetOrderListParams,
  OrderItem,
} from "../../../store/OrderListStore";
import GoodDesc from "./components/GoodDesc";
import OrderListForm from "./components/OrderListForm";
import OrderPrice from "./components/OrderPrice";
import OrderSource from "./components/OrderSource";
import OrderState from "./components/OrderState";
import { toJS } from "mobx";
import "./index.scss";
import OrderDeal from "./components/OrderDeal";
import { PageContainer } from "@fle-ui/pro-layout";

const findNum = (list: { num: number; status: number }[], target: number) =>
  list.filter((item) => item.status === target)[0]?.num
    ? list.filter((item) => item.status === target)[0]?.num
    : null;

const { TabPane } = Tabs;

type Props = {};

const PurWholesale: FC<Props> = () => {
  const history = useHistory() as any;
  const siteId = Number(window.location.pathname.replace(/[^0-9]/gi, ""));
  const { list, pageNo, pageSize, total } = OrderListStore.orderList;
  const { orderStatusCount } = OrderListStore;

  console.log('orderStatusCount',orderStatusCount)

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({ page: 1, pageSize: 20 });
  const [formData, setFormData] = useState<Partial<GetOrderListParams>>({
    ...page,
  });
  const [renderList, setRenderList] = useState<OrderItem[]>(
    list.map((item) => ({
      select: false,
      ...item,
    }))
  );
  const [params, setParams] = useUrlState(
    { tab: "default" },
    { navigateMode: "replace" }
  );

  useDebounceEffect(
    () => {
      setLoading(true);

      OrderListStore.ajaxGetPurWholesaleOrderList({
        ...formData,
        ...page,
        siteId,
      }).finally(() => setLoading(false));
      OrderListStore.ajaxGetOrderStatusCount({
        orderType: undefined,
        siteId,
      }).catch((err) => {
        console.log(err);
      });
    },
    [formData, page],
    { wait: 100 }
  );

  useEffect(() => {
    console.log('params',params)
    setPage({ page: 1, pageSize: 20 });
  }, [params]);

  useEffect(() => {
    const _list = list.map((item) => ({
      select: false,
      ...item,
    }));
    setRenderList(_list);
  }, [list]);

  return (
    <PageContainer className="mall-order-list-wrap" margin={0} padding={0} background="#F8F8F8">
      <Spin spinning={loading}>
          <Tabs
            className="mall_order_tabs"
            defaultActiveKey={params.tab}
            activeKey={params.tab}
            onChange={(tab) => {
              console.log('tab',tab)
              setParams({ tab });
              tab !== params.tab && setLoading(true);
            }}
          >
            <TabPane tab={"全部"} key={"default"} />
            <TabPane
              tab={
                <>
                  待付款({findNum(orderStatusCount, 1) || 0})
                </>
              }
              key={"1"}
            />
            <TabPane
              tab={
                <>
                  待发货({findNum(orderStatusCount, 2) || 0})
                </>
              }
              key={"2"}
            />
            <TabPane
              tab={
                <>
                  待收货({findNum(orderStatusCount, 3) || 0})
                </>
              }
              key={"3"}
            />
            <TabPane
              tab={
                <>
                  交易成功 (
                  <span>
                    {Number(findNum(orderStatusCount, 4)) +
                      Number(findNum(orderStatusCount, 6))}
                  </span>
                  )
                </>
              }
              key={"4,6"}
            />
            <TabPane
              tab={
                <>
                  交易关闭 (
                  <span>
                    {Number(findNum(orderStatusCount, 5)) +
                      Number(findNum(orderStatusCount, 7))}
                  </span>
                  )
                </>
              }
              key={"5,7"}
            />
          </Tabs>

          <OrderListForm
            onSearch={setFormData}
            orderStatsList={PurWholesaleOrderStates}
          />
        <div className="order_table_wrap">
          <Row className="order_table_header">
            <Col span={9} style={{ paddingLeft: 28 }}>
              <Row>
                <Col
                  span={6}
                  className="table_vertical_left"
                  style={{ textAlign: "left" }}
                >
                  <div style={{ width: 60 }}>商品图片</div>
                </Col>
                <Col span={2} />
                <Col
                  span={6}
                  className="table_vertical_left"
                  style={{ textAlign: "center" }}
                >
                  商品名称
                </Col>
                <Col span={5} className="table_vertical_left">
                  单价（元）
                </Col>
                <Col span={5} className="table_vertical_left">
                  数量
                </Col>
              </Row>
            </Col>
            <Col span={3} className="table_vertical_left">
              订单来源
            </Col>
            <Col span={3} className="table_vertical_left">
              会员实付款
            </Col>
            <Col span={3} className="table_vertical_left">
              用户实付款
            </Col>
            <Col span={3} className="table_vertical_left">
              状态
            </Col>
            <Col span={3} className="table_vertical_left">
              操作
            </Col>
          </Row>
          <WhiteSpace height={8} />

          {renderList.map((order, index) => (
            <div key={order.orderNo + "_" + index} className="order_table_div">
              <Row
                className={`order_table_list_header`}
                style={{
                  background: "#FAFAFA",
                  color: "#4C4C4C",
                  padding: "8px 28px",
                  border: "1px solid #eeeeee",
                }}
              >
                <Col span={24}>
                  <Space size={"large"} align="start">
                    <span style={{ color: "#4C4C4C" }}>
                      订单号：{order.orderId}
                    </span>
                    <span style={{ color: "#4C4C4C" }}>
                      下单人：{order.createrId}
                    </span>
                    <span style={{ color: "#4C4C4C" }}>
                      下单时间：{order.createTime}
                    </span>
                  </Space>
                </Col>
              </Row>
              <Row className={`order_table_box row-con`}>
                <Col span={9}>
                  {order.goods.map((good, index) => (
                    <Row key={order.orderNo + "_" + index}>
                      {/* 商品描述 */}
                      <Col
                        span={24}
                        style={{ textAlign: "left" }}
                        className="table_vertical_left"
                      >
                        <GoodDesc {...good} />
                      </Col>
                    </Row>
                  ))}
                </Col>
                {/* 订单来源 */}
                <OrderSource span={3} {...order} />
                {/* 会员实付款 */}
                <OrderPrice
                  span={3}
                  fright={order.memberFreight}
                  mount={order.memberAmount}
                />
                {/* 用户实付款 */}
                <OrderPrice
                  span={3}
                  fright={order.freight}
                  mount={order.amount}
                />
                {/* 订单状态 */}
                <OrderState span={3} {...order} />
                {/* 操作 */}
                <OrderDeal span={3} {...order} />
              </Row>
            </div>
          ))}

          <WhiteSpace height={16}/>
          {total > 0 && (
            <Row justify={"end"}>
              <Col>
                <Pagination
                  {...getPagination({
                    total,
                    current: pageNo,
                    pageSize,
                    onChange: (page: number, pageSize: number) =>
                      setPage({ page, pageSize }),
                    onShowSizeChange: (pageNo: number, pageSize: number) =>
                      setPage({ page: 1, pageSize }),
                  })}
                />
              </Col>
            </Row>
          )}
          {total === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </div>
      </Spin>
    </PageContainer>
  );
};

export default observer(PurWholesale);
