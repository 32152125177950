import React, { useState, useEffect, FC } from "react";
import "./index.scss";
import { ENV } from "@/config/index";
import {
  Form,
  Input,
  Button,
  Radio,
  Cascader,
  Row,
  Col,
  Checkbox,
  message,
} from "@fle-ui/next";
import { inject, observer } from "mobx-react";
import {
  getSiteTag,
  updateSiteShareConfig,
  getBasicArea,
  getSiteShareConfig,
} from "./store";
// 渲染表单
// 遍历标签

// 获取h5站点商城url
type Segment = "." | "/";
export const getSiteUrl = (id: string | number, segment: Segment) => {
  let _url = "";
  switch (ENV) {
    case "dev":
      _url = `http://${id}${segment}dev-shop.fleshopy.com`;
      break;
    case "test":
      _url = `https://${id}${segment}qa-shop.fleshopy.com`;
      break;
    default:
      _url = `https://${id}${segment}shop.fleshopy.com`;
  }
  return _url;
};

const RenderForm: FC<any> = (props) => {
  const { siteId } = props;
  const [createTime, setCreateTime] = useState("");
  const [submitForm] = Form.useForm();

  interface storeLabelType {
    [key: string]: any;
  }

  const [tags, setTags] = useState<any>({});
  const [options, setOptions] = useState([]);
  const getAreaList = async (parentCode: number) => {
    const { data, success } = (await getBasicArea(parentCode)) as any;
    if (success) {
      return data.map((item: any) => {
        return {
          ...item,
          value: item.code,
          label: item.name,
          isLeaf: item.level > 2 ? true : false,
        };
      });
    }
  };

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    const { code } = targetOption;
    targetOption.loading = true;
    getAreaList(code).then((children) => {
      targetOption.children = children;
      targetOption.loading = false;
      setOptions([...options]);
    });
  };

  const onFinish = (values: any) => {
    const { area, siteLoginWay } = values;
    const districtId = area[2];
    const cityId = area[1];
    const provinceId = area[0];
    const data = {
      ...values,
      provinceId,
      cityId,
      districtId,
      id: siteId,
      loginWay: siteLoginWay,
    };
    delete data.area;
    delete data.siteLoginWay;

    updateSiteShareConfig(data).then(({ success }: any) => {
      if (success) {
        message.success("提交成功");
      }
    });
  };
  const cache: storeLabelType = {};
  let allCache: any = {};

  useEffect(() => {
    const getBaseSetting = async (source: number) => {
      const tagcahce: storeLabelType = {};
      const { data, success } = (await getSiteTag(source)) as any;
      if (success) {
        if (typeof data === "string") {
          allCache = JSON.parse(data);
          for (const key of Object.keys(JSON.parse(data))) {
            cache[key] = [];
            if (JSON.parse(data)[key].length === 0) {
              continue;
            }
            tagcahce[key] = (tagData: any): any[] => {
              cache[key] = tagData;
              let concatArr: any[] = [];
              for (const value of Object.values(cache)) {
                concatArr = concatArr.concat(value);
              }
              setTags(cache);
              return [];
            };
          }
        }
      }
    };
    const getSiteInfo = async (siteId: number) => {
      const { data, success } = (await getSiteShareConfig(siteId)) as any;
      if (success) {
        const {
          customerName,
          address,
          cityId,
          provinceId,
          townId,
          districtId,
          siteName,
          createTime,
          tagIdList,
          rights,
          allowRegister,
          siteLoginWay,
          shortName,
          customerServicePhone,
        } = data;

        setCreateTime(createTime);

        // 通过遍历获取默认选中标签
        for (const key of Object.keys(allCache)) {
          cache[key] = [];
          for (let i = 0; i < tagIdList.length; i++) {
            const current = allCache[key].find(
              (item: any) => item.id === tagIdList[i]
            );
            if (current != null) {
              cache[key].push(current.id);
            }
          }
        }
        setTags(cache);
        getSiteAddress([provinceId, cityId, townId]).then((values: any) => {
          setOptions(values);
        });
        submitForm.setFieldsValue({
          customerName,
          address,
          siteName,
          rights: `${rights}`,
          allowRegister,
          siteTag: "选中",
          area: [provinceId, cityId, districtId],
          siteLoginWay: siteLoginWay.split(","),
          shortName,
          customerServicePhone,
        });
      }
    };
    getBaseSetting(2).then(() => {
      getSiteInfo(siteId);
    });
  }, []);
  useEffect(() => {
    getAreaList(0).then((data) => {
      setOptions(data);
    });
  }, []);
  // 匹配地址list对应的id, 返回对应的索引
  const getSiteAddress = async (list: any[]) => {
    const province = await getAreaList(0);
    const provinceCurent = province.find((item: any) => item.code === list[0]);
    const city = await getAreaList(list[0]);
    provinceCurent.children = city;
    const cityCurrent = city.find((item: any) => item.code === list[1]);
    const town = await getAreaList(list[1]);
    cityCurrent.children = town;
    return province;
  };

  const copyWebsitUrl = (copyUrl: string) => {
    const range: Range = document.createRange();
    range.selectNode(document.getElementById(copyUrl) as HTMLSpanElement);
    const selection: Selection = getSelection() as Selection;
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }
    selection.addRange(range);
    document.execCommand("copy");
    message.success("复制成功");
  };

  const optionsSiteLoginWay = [
    { label: "账号+密码", value: "2", disabled: true },
    { label: "手机号+验证码（需购买短信条数）", value: "5" },
    { label: "微信授权登录", value: "6" },
  ];

  const { Item } = Form;
  const { Group } = Radio;
  const { TextArea } = Input;

  const layOut = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  return (
    <Form name="basic" form={submitForm} onFinish={onFinish} {...layOut}>
      <Item
        style={{marginBottom:'32px'}}
        label="商城名称"
        name="siteName"
        rules={[
          { required: true, message: "商城名称必填" },
          { max: 20, message: "最多20个字符" },
        ]}
        extra="显示给终端用户的名称，建议8个字符以内！"
      >
        <Input placeholder="例如：招商银行商城"></Input>
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="商城简称"
        name="shortName"
        rules={[
          { required: true, message: "商城简称必填" },
          { max: 20, message: "最多20个字符" },
        ]}
        extra="显示在协议的名称，建议5个字符以内！"
      >
        <Input placeholder="例如：优品汇"></Input>
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="版权声明"
        name="rights"
        rules={[{ max: 100, message: "最多100个字符" }]}
        extra="显示在网页底部"
      >
        <Input />
      </Item>

      <Item style={{marginBottom:'32px'}} label="创建时间">
        <span>{createTime}</span>
      </Item>

      <Item style={{marginBottom:'32px'}} label="前台站点网址">
        <div className="copy-url-wrap">
          <span id="copyFrontUrl">{getSiteUrl(siteId, ".")}</span>
          <Button
            type="link"
            onClick={() => copyWebsitUrl("copyFrontUrl")}
            size="small"
            style={{ marginLeft: "16px" }}
          >
            复制网址
          </Button>
        </div>
      </Item>

      <Item style={{marginBottom:'32px'}} label="登录方式" name="siteLoginWay">
        <Checkbox.Group
          options={optionsSiteLoginWay}
          style={{ marginTop: 6}}
        />
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="新用户注册"
        name="allowRegister"
        rules={[{ required: true, message: "新用户注册必选" }]}
      >
        <Group>
          <Radio value={true}>允许</Radio>
          <Radio value={false}>不允许</Radio>
        </Group>
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="商城地址"
        name="area"
        rules={[{ required: true, message: "地址必选" }]}
      >
        <Cascader
          placeholder="将用于商品详情页的默认商品地址"
          options={options}
          loadData={loadData}
          changeOnSelect
        />
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="详细地址"
        name="address"
        rules={[{ max: 50, message: "最多50个字符" }]}
      >
        <TextArea
          placeholder="请填写详细地址，如门牌号"
          showCount
          maxLength={50}
        />
      </Item>

      <Item
        style={{marginBottom:'32px'}}
        label="客服电话"
        name="customerServicePhone"
        rules={[{ required: true, message: "客服电话必填" }]}
      >
        <Input placeholder="将用于商城显示的联系电话" />
      </Item>

      <Item wrapperCol={{ offset: 8, span: 10 }}>
        <Button type="primary" htmlType="submit">
          保存
        </Button>
      </Item>
    </Form>
  );
};

const SiteSetting: FC<any> = (props) => {
  const { siteId } = props;
  return (
    <div>
      <div className="site-setting">
        <div className="site-setting-wrap">
          <header>
            <div className="site-setting-header">基础设置</div>
          </header>
          <div className="form-wrap">
            <RenderForm siteId={siteId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("siteId")(observer(SiteSetting));
