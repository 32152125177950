import React from "react";
import { PageContainer } from "@fle-ui/pro-layout";
import { ErrorPage, Loading } from "@/components";
import { AppRouter, AppRoute } from '@ice/stark';
import { ORIGIN } from "@/config";


const Distribution = (props: any) => {
  const siteId = Number(window.location.pathname.split("/")[1]);
  return (
    <PageContainer padding={0} margin={0}>
      <AppRouter ErrorComponent={ErrorPage} LoadingComponent={Loading}>
        <AppRoute
          activePath={`/${siteId}/distribution`}
          sandbox={true}
          umd={true}
          entry={`${ORIGIN}/distribution`}
        />
      </AppRouter>
    </PageContainer>
  );
};

export default Distribution;
