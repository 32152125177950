import React, { FC, useEffect } from 'react'

import {  Modal, Row, Col, Form, Input } from "@fle-ui/next"
const { Item, useForm } = Form

export interface AddTinyModalProps {
  visible: boolean,
  handleSubmitForm?: (e: React.MouseEvent<HTMLElement>, values: any) => void
  handleCancelClick?: React.MouseEventHandler<HTMLElement>,
  info?: any,
}

const AddTinyModal:FC<AddTinyModalProps> = (props) => {

  const { handleSubmitForm, visible, handleCancelClick } = props

  const [form] = useForm()

  // 提交表单
  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (handleCancelClick) {
      handleCancelClick(e)
    }
  }

  // 取消提交表单
  const onOk = (e: React.MouseEvent<HTMLElement>) => {
    form.validateFields().then((values: any) => {
      if (handleSubmitForm) {
        handleSubmitForm(e, values)
      }
    })
  }

  useEffect(() => {
    // 情况数据
    if (visible === true) {
      form.resetFields()
    }
  }, [visible, form])

  return (
    <>
      <Modal
        title='新增微页面'
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol = {{ span: 14 }}>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="标题名称"
                rules={[
                  {required: true, message: '标题名称必填'}, 
                  {max: 12, message: '最多不能超过12个字符'}
              ]}
                name="title">
                <Input  placeholder='请输入标题名称'/>
              </Item>
              <div className='name-suggest'><span>(仅后台可见名称，创建后不可修改)</span></div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="页面名称"
                rules={
                  [{ required: true, message: '页面名称必填'}, 
                  {max: 12, message: '最多不能超过12个字符'}]
                }
                name="pageName">
                <Input  placeholder='请输入页面名称'/>
              </Item>
              <div className='name-suggest'><span>(用户可见的页面标题，创建后可在装修页面修改)</span></div>
              </Col>
          </Row>
          </Form>
      </Modal>
    </>
  )
}

export default  AddTinyModal