import { Upload, Modal,message } from "@fle-ui/next";
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import {mainHost} from '@/config/index'
import React from 'react'
function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function listenState(cb: ()=> void) {
  return new Promise((resolve: any)=> {
    resolve(cb())
  })
}

const acceptList: string[] = ['image/png', 'image/gif', 'image/jpg', 'image/jpeg']
// 定义fileList的每一项
interface FileItem {
  uid?: string,
  name?: string,
  status?: string,
  url?: string,
}

interface Props {
  fileList: FileItem[],
  action: string,
  maxLength: number,
  handleChange: (urlList: any) => void,
  onSuccess: any,
}

interface State {
  previewVisible: boolean,
  previewImage: string,
  previewTitle: string,
  loading: boolean
}

class PicturesWall extends React.Component<Props, State> {
  static defaultProps = {
    fileList: [],
    action: mainHost + "/basic/file/upload",
    maxLength: 1
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      loading: false
    }
  }

  beforeUpload = (file: any) => {
    const { type } = file
    const isAcceptImageType = acceptList.includes(type)
    if (!isAcceptImageType) {
      message.error(`仅支持上传 ${acceptList.join(',')}格式`);
    }
    const isLimit1M = file.size / 1024 / 1024 < 1;
    if (!isLimit1M) {
      message.error('图片不能大于 1MB!');
    }
    return isAcceptImageType && isLimit1M;
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file:any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    })
  }    
  render() {
    const { previewVisible, previewImage, previewTitle, loading } = this.state
    const {action, maxLength, handleChange, fileList } = this.props;
    const list = fileList.map((item: any)=> {
      return {
        url: item,
        status: 'done',
      }
    })
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );

    const onSuccess = (file: any) => {
      this.props.onSuccess({file, fileList: list})
    }

    const obj: any = {
      onSuccess
    }
    return (
      <>
        <Upload
          {...obj}
          action={action}
          headers={{'authority_token':localStorage.getItem("token") || 'g108GloF3Rd7fWHicU81hNbRqnd8RMW7-31882438-2-1' as any}}
          listType="picture-card"
          onChange={handleChange}
          fileList={list as any}
          onPreview={this.handlePreview}
          beforeUpload={this.beforeUpload}
        >
          {fileList.length >=  maxLength ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  }

}
export default PicturesWall