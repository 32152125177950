type PaginationProps = {
  // 这个 和后台 协商 分页不要超过1000
  total: number;
  pageSize: number;
  current: number;
  onChange?: (pageNo: number, pageSize: number) => void;
  onShowSizeChange?: (pageNo: number, pageSize: number) => void;
};

export const getPagination = (data: PaginationProps): any => {
  return {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSizeOptions: ['20', '50', '100'],
    defaultPageSize: 20,
    showTotal(total: any) {
      return `共 ${total} 条数据`;
    },
    ...data
  };
};
