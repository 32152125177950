import { OrderStatus } from "@/store/OrderDetailStore";
import { OrderItem } from "@/store/OrderListStore";
import LogisticsModal from "@/views/OrderManage/Order/components/LogisticsModal";
import { Button, Col, Space } from "@fle-ui/next";
import React, { FC, useState } from "react";

type Props = {
  // 删格化布局
  span: number;
};
const OrderState: FC<Props & OrderItem> = (props:any) => {
  const { span, status, goods } = props;
  const OrderStatusMap = new Map<number, { name: string; icon: any; color: string }>([
    [1, { name: "待付款", icon: <i className="iconfont icon-dengdaimaijiafukuan"></i>, color: "#FF712C" }],
    [2, { name: "待发货", icon: <i className="iconfont icon-dengdaimaijiafahuo"></i>, color: "#FF712C" }],
    [3, { name: "待收货", icon: <i className="icon-dengdaimaijiaquerenshouhuo"></i>, color: "#FF712C" }],
    [4, { name: "交易成功", icon: <i className="iconfont icon-jiaoyichenggong"></i>, color: "#36C626" }],
    [5, { name: "交易关闭", icon: <i className="iconfont icon-jiaoyiguanbi"></i>, color: "#F42D2D" }],
    [6, { name: "交易成功", icon: <i className="iconfont icon-jiaoyichenggong"></i>, color: "#36C626" }],
    [7, { name: "交易关闭", icon: <i className="iconfont icon-jiaoyiguanbi"></i>, color: "#F42D2D" }],
  ]);

  return (
    <>
      <Col span={span} className="table_vertical_left">
        <Space direction={"vertical"} align="start">
          <span style={{ color: OrderStatusMap.get(status)?.color }}>
            {status == 2 && goods.some((item: any) => item.isDelivery == 2)
              ? "部分发货"
              : OrderStatusMap.get(status)?.name}
          </span>
        </Space>
      </Col>
    </>
  );
};

export default OrderState;
