import React from "react";
import { createFromIconfontCN } from "@ant-design/icons";
// import "./index.less";
// @fle-ui/next 字体图标
const IconFont = createFromIconfontCN({
  scriptUrl: ["//at.alicdn.com/t/c/font_3607457_9mqom3a3lk.js"]
});

interface MainProps {
  [key: string]: any;
  type: string;
}
const Icon: React.FC<MainProps> = (props) => <IconFont {...props} />;
export default Icon;