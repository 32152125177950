import React, { FC } from 'react';
import { Spin } from "@fle-ui/next";
const Loading: FC = () => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <Spin size="large" />
    </div>
  );
}

export default Loading;
