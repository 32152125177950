import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";

// 按条件查询商品
export const getGoodsListBySiteStatus = (data: any) => {
  // const assignObj = Object.assign({}, data);
  // for (const key in assignObj) {
  //   if (!assignObj[key] || assignObj[key].length === 0) {
  //     delete assignObj[key];
  //   }
  // }

  // if (assignObj.siteStatus === 'isShield') {
  //   assignObj.isShield = 1;
  //   assignObj.siteStatus = '10'
  // } else if (assignObj.siteStatus === '10') {
  //   assignObj.isShield = 0;
  // }

  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/merchant/site/site-goods/pageList",
    method: "post",
    data: JSON.stringify(data),
  });
};

// 按商品ID下架商品
export const removeGoodsByGoodsId = (siteId: number, goodsIds: number[]) => {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/merchant/site/site-goods/removeGoods",
    method: "post",
    data: JSON.stringify({ goodsIds, siteId }),
  });
};

// 批量下架 status 1表示上架，2表示下架
export const batchUpdateByGoodsId = (
  siteId: number,
  goodsIds: number[],
  status: number
) => {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/merchant/site/site-goods/batchUpdateGoodsStatus",
    method: "post",
    data: JSON.stringify({ goodsIds, siteId, status }),
  });
};

// 批量改价
export const changePricePickGoods = (
  goodsIdList: any,
  isPickGoods: number,
  priceRuleParams: any[],
  siteId: number
) => {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/merchant/site/site-goods/changePricePickGoods",
    method: "post",
    data: JSON.stringify({
      goodsIdList,
      isPickGoods,
      isKeepPrice: 0,
      isKeepStatus: 1,
      priceRuleParams,
      siteId,
    }),
  });
};

// 获取所有地域信息
export const getBasicArea = (parentCode: number) => {
  return request({
    method: "post",
    url: mainHost + "/basic/area/areas",
    data: JSON.stringify({
      parentCode,
    }),
  });
};

/* // 获取所有商品信息
export  const getCategory = (parentId: number) => {
  return request({
    method: 'post',
    url: mainHost + '/fleshopy/category/getList',
    data: JSON.stringify({
      parentId
    })
  })
} */

export const getCategory = () => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/category/getTreeList",
    data: JSON.stringify({
      page: 1,
      pageSize: 100,
      parentId: 0,
    }),
  });
};

export const getSiteShareConfig = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getSite",
    params: { siteId },
  });
};

// 获取京东库存
export const getStockJdBatch = (
  thirdList: any[]
  // provinceId: number | string | null,
  // cityId: number | string | null
) => {
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/stock/getStockBatch",
    data: JSON.stringify({ provinceId: 15, cityId: 1213, idList:thirdList }),
  });
};

// 获取分组下拉列表
export const getGroupSelects = (siteId: string, source: string) => {
  return request({
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/site/goods/group/list",
    params: {
      siteId,
      source,
    },
  });
};

// 获取分组下拉列表
export const updateGroup = (siteId: string, data: any) => {
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/site/goods/group/transfer",
    data: JSON.stringify({ siteId, ...data }),
  });
};

export const checkBeforeAddGoods = (
  goodsIdList: any,
  isPickGoods: number,
  priceRuleParams: any[],
  siteId: number
) => {
  return request({
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/fleshopy/merchant/site/site-goods/checkBeforeAddGoods",
    method: "post",
    data: JSON.stringify({
      goodsIdList,
      isPickGoods,
      isKeepPrice: 0,
      isKeepStatus: 1,
      priceRuleParams,
      siteId,
    }),
  });
};
