import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Modal,
} from "@fle-ui/next";
import "./index.scss";

const OneDetail = (props: any) => {
  const { handleOk, handleCancel, title, visible } = props;

  return (
    <div className="detail">
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="detail"
        width={990}
      >
        <div className="tit">
          <div className="tit_left tit_con">
            <p className="start">
              <span>创建用户总数：</span>1
            </p>
            <p>
              <span>成功创建用户数：</span>1
            </p>
            <p>
              <span>失败创建用户数：</span>0
            </p>
          </div>
          <div className="tit_center tit_con">
            <p>
              <span>现金账户余额总计：</span>
              0.00
            </p>
            <p>
              <span>成功现金账户余额：</span>
              0.00
            </p>
            <p>
              <span>失败现金账户余额：</span>
              0.00
            </p>
          </div>
          <div className="tit_right tit_con">
            <p>
              <span>积分账户余额总计：</span>0
            </p>
            <p>
              <span>成功积分账户余额：</span>0
            </p>
            <p>
              <span>失败积分账户余额：</span>0
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(OneDetail);
