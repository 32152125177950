import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "mobx-react";
import zhCN from "@fle-ui/next/lib/locale-provider/zh_CN";
import { ConfigProvider, Empty } from "@fle-ui/next";
import "moment/locale/zh-cn";

import * as stores from "@/store/index";
import "./App.scss";
import { parse } from "qs";
import Store from "@/config/storage";
import Main from "@/views/Main";
import { FleErrorBoundary } from "@fle-ui/pro-error-boundary";


const customizeRenderEmpty = () => <Empty description={<span>暂无数据</span>} />;

const App = () => {
  const siteId = window.location.pathname.split("/")[1];

  if (siteId) {
    sessionStorage.setItem("siteId", siteId);
  }
  useEffect(() => {
    const store = new Store();
    const params: any = parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params.token) {
      store.setItem("token", params.token);
    }
    if (params.from) {
      store.setItem("from", params.from);
    } else {
      localStorage.removeItem("from");
    }
  }, []);

  return (
    <FleErrorBoundary>
      <Provider {...stores} siteId={siteId}>
        <ConfigProvider
          getPopupContainer={(node) => {
            return document.querySelector(".pro-layout-content") || document.body;
          }}
          locale={zhCN}
          renderEmpty={customizeRenderEmpty}
        >
          <Router basename={siteId}>
            <Switch className="c-body-wrap">
              <Route path="/" component={Main} />
            </Switch>
          </Router>
        </ConfigProvider>
      </Provider>
    </FleErrorBoundary>
  );
};

export default App;
