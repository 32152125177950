import React from "react";
// import { Icon } from "components";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import httpClient from "@/utils/httpClient";

export enum OrderCloseType {
  买家超时未支付 = 1,
  卖家主动取消,
  买家主动取消,
}

export enum ThirdType {
  平台 = 0,
  京东 = 10,
  苏宁 = 20,
  网易严选 = 30,
}

export enum OrderStatus {
  待付款 = 1,
  待发货,
  待收货,
  交易成功,
  交易关闭,
}

export enum PaymentMethod {
  预存货款扣款 = 1,
  对公汇款,
}

export enum DeductionOfInventory {
  非现货采购,
  现货采购,
}

export enum DeliveryStatus {
  默认状态,
  待发货,
  已发货,
}

export enum ReceiptStatus {
  默认状态,
  待收货,
  已收货,
}

export enum IsContainsTax {
  不含税,
  含税,
}

interface OrderPayInfoProps {
  // 实际需支付费用
  actualPaymentPrice: number;
  // 运费现价（订单含待确认商品时为0，订单无需支付运费时为0）
  currentFreightPrice: number;
  // 订单现价（不含运费）
  currentOrderPrice: number;
  // 支付方式：1、预存货款扣款，2、对公汇款
  paymentMethod: 1 | 2;
  // 支付时间
  paymentTime: string;
  // 收款凭证地址(只有对公汇款会有数据)
  receivePaymentImage: string;
  // 收款备注
  receivePaymentRemark: string;
  // 创建时间
  createTime: string;
  // 支付订单号
  payBillNo: string;
}

type OrderReceivingInfoProps = {
  // 详细地址
  address: string;
  // 地区码
  areaCode: string;
  // 备注
  buyerRemark: string;
  // 省
  province: string;
  // 市
  city: string;
  // 区
  district: string;
  // 镇
  town: string;
  // 收货人
  consignee: string;
  id: "";
  // 收货联系方式
  mobileNumber: string;
};

export interface DetailGoodItemProp extends Omit<DetailGroupDetailGoodItemProp, "goodsList"> {
  // 商品图片地址
  goodsImage: string;
  // 商品名称
  goodsName: string;
  // 商品 id
  id: string;
  // skuId
  skuId: string;
  //0、不含税，1、含税
  isContainsTax: 0 | 1;
  // 商品税率(不含税为0)
  taxRate: number;
  // 商品规格
  specList: string;
  // 购买总数
  quantity: number;
  // 0、不扣减库存 非现货，1、扣减库存 现货
  isDeductionOfInventory: 0 | 1;
  // 最终销售价 -> 小计
  finalSalePrice: number;
  // 是否改价过：0、未改价，1、已改价
  isChangePrice: 0 | 1;
  // 订单金额（最终销售价*购买数量）
  orderPrice: number;
  // 收货状态：0-默认状态，1-待收货，2-已收货
  receiptStatus: 0 | 1 | 2;
  // 发货状态：0-默认状态，1-待发货，2-已发货
  deliveryStatus: 0 | 1 | 2;
  // 发货方式：1、快递，2、物流
  deliveryMethod: 1 | 2;
  // 可发货数
  freeNum: number;
  // 已发货数
  deliveryNum: number;
  // 计量单位 1按个销售 2按箱销售
  measureUnit: 1 | 2;
  // 箱规
  boxNumber: number;
  // 单位
  unit: string;
  // orderNo
  orderNo: string;
  //
  shopId: "";
  //orderGoodsFreightId
  orderGoodsFreightId: string;
  // 快递号
  expressNo: string;
  expressCompany: any;

  freightRules: "";
  // 运费类型：1、运费模板运费，2、运费说明，3、卖家自定运费（卖家修改运费）
  freightType: 1 | 2 | 3 | null;
  // 运费说明
  freightDescription: string;

  // 0平台 10京东 20苏宁 30网易严选
  thirdType: 0 | 10 | 20 | 30;
  // 是否自有商品 1是 0否
  isSelf: 1 | 0;

  rowSpan?: number;
  // 运费 id  为 null 说名未确认运费
  freightId?: string | null;
  NoPrice: boolean;
}

export type DetailGroupDetailGoodItemProp = {
  goodsList: DetailGoodItemProp[];
  // 最终运费
  finalFreightPrice: number;
  // 运费 ID
  id: string;
  //运费 是否改价过：0、未改价，1、已改价
  isChange: 0 | 1;

  freight: number;
};

/**
 * 售后状态
 */
 export const statusMap: Map<number, { text: string; parentText: string; color: string; icon: any }> = new Map([
  [
    11,
    {
      text: "售后申请提交成功",
      parentText: "等待审核",
      color: "#FF712C",
      icon: <i className="iconfont icon-daishenhe" />,
    },
  ],

  [
    21,
    {
      text: "售后申请审核已完成",
      parentText: "等待商家收货",
      color: "#FF712C",
      icon: <i className="iconfont icon-dengdaimaijiaquerenshouhuo" />,
    },
  ],
  [
    22,
    {
      text: "寄件运单提交成功",
      parentText: "等待商家收货",
      color: "#FF712C",
      icon: <i className="iconfont icon-dengdaimaijiaquerenshouhuo" />,
    },
  ],
  [
    23,
    {
      text: "售后申请审核已完成，等待商家上门取件",
      parentText: "等待商家收货",
      color: "#FF712C",
      icon: <i className="iconfont icon-dengdaimaijiaquerenshouhuo" />,
    },
  ],
  [
    24,
    {
      text: "售后申请审核已完成，等待买家送货至站点",
      parentText: "等待商家收货",
      color: "#FF712C",
      icon: <i className="iconfont icon-dengdaimaijiaquerenshouhuo" />,
    },
  ],

  [
    31,
    {
      text: "商家已经收货完成，正在处理中",
      parentText: "等待商家处理",
      color: "#FF712C",
      icon: <i className="iconfont icon-daichuli" />,
    },
  ],
  [
    32,
    {
      text: "商家已经完成取件，正在处理中",
      parentText: "等待商家处理",
      color: "#FF712C",
      icon: <i className="iconfont icon-daichuli" />,
    },
  ],
  [
    33,
    {
      text: "站点已完成收货，正在处理中",
      parentText: "等待商家处理",
      color: "#FF712C",
      icon: <i className="iconfont icon-daichuli" />,
    },
  ],

  [
    41,
    {
      text: "商品已处理完成，请等待商家退款",
      parentText: "处理完成",
      color: "#36C626",
      icon: <i className="iconfont icon-chuliwancheng" />,
    },
  ],
  [
    42,
    {
      text: "商品退款审核未通过，商品将会发送到返件地址",
      parentText: "处理完成",
      color: "#36C626",
      icon: <i className="iconfont icon-chuliwancheng" />,
    },
  ],
  [
    43,
    {
      text: "商品处理完成，商品将会发送到返件地址",
      parentText: "处理完成",
      color: "#36C626",
      icon: <i className="iconfont icon-chuliwancheng" />,
    },
  ],
  [
    44,
    {
      text: "等待象铺卖家退款",
      parentText: "处理完成",
      color: "#36C626",
      icon: <i className="iconfont icon-chuliwancheng" />,
    },
  ],
  [
    45,
    {
      text: "等待买家会员退款",
      parentText: "处理完成",
      color: "#36C626",
      icon: <i className="iconfont icon-chuliwancheng" />,
    },
  ],

  [
    51,
    {
      text: "商家已发货",
      parentText: "等待用户确认",
      color: "#FF712C",
      icon: <i className="iconfont icon-daiyonghuqueren" />,
    },
  ],
  [
    52,
    {
      text: "商品退款审核未通过，商家已发货，收到商品后请尽快确认收货，在发出7日之后将会自动确认收货",
      parentText: "等待用户确认",
      color: "#FF712C",
      icon: <i className="iconfont icon-daiyonghuqueren" />,
    },
  ],

  [
    61,
    {
      text: "退款已到账",
      parentText: "已完成",
      color: "#36C626",
      icon: <i className="iconfont icon-jiaoyichenggong" />,
    },
  ],
  [
    62,
    {
      text: "商品已签收",
      parentText: "已完成",
      color: "#36C626",
      icon: <i className="iconfont icon-jiaoyichenggong" />,
    },
  ],
  [
    63,
    {
      text: "退款已到账",
      parentText: "已完成",
      color: "#36C626",
      icon: <i className="iconfont icon-jiaoyichenggong" />,
    },
  ],

  [
    91,
    {
      text: "订单已关闭，售后申请审核未通过",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
  [
    92,
    {
      text: "订单已关闭，未在规定时间内发货",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
  [
    93,
    {
      text: "订单已关闭，商家未在规定时间内取件",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
  [
    94,
    {
      text: "订单已关闭，未在规定时间内送货至指定地点",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
  [
    95,
    {
      text: "用户将售后工单关闭",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
  [
    96,
    {
      text: "卖家拒绝退款，售后已关闭",
      parentText: "已取消",
      color: "#F42D2D",
      icon: <i className="iconfont icon-shouhouyiquxiao" />,
    },
  ],
]);

export type OrderDetail = {
  afterSaleApplyTime: string;
  afterSaleImages: [];
  afterSaleOrderNo: string;
  afterSaleReasonDesc: string;
  afterSaleStatus: number;
  afterSaleType: number;
  applyReason: string;
  applicantAccount: number;
  cancelTime: string;
  contacter: string;
  confirmTime: string;
  orderNo: string;
  siteName: string;
  closeReason: string;
  freightInfo: {
    freightCompanyName: string | number;
    freightFee: number;
    freightNo: string | number;
    mobileNum: string | number;
    recipient: string | number;
    recipientMobile: string | number;
    returnAddress: string | number;
    sendOutTime: string | number;
    takeOffAddress: string | number;
    takeOffTime: string | number;
    userName: string | number;
  };
  freightWay: number;
  goodsInfo: {
    afterSaleGoodsImage: string;
    afterSaleGoodsName: string;
    afterSaleGoodsPrice: number;
    afterSaleType: number;
    applyNum: number;
    customerPayment: number;
    discountWays: [
      {
        id: string;
        name: string;
        value: number;
      }
    ];
    freightFee: number;
    returnPaymentInfos: [
      {
        name: string;
        type: number;
        value: number;
      }
    ];
    totalReturnAmount: number;
    [key: string]: any;
  };
  phoneNum: string;
  takeOverAddress: string;
  takeAddress: {
    address: string;
    areaCode: string;
    city: string;
    consignee: string;
    district: string;
    fullAddress: string;
    mobileNumber: string;
    province: string;
    town: string;
  };
  expectTime: string;
  returnAddress: {
    address: string | number;
    areaCode: string | number;
    city: string | number;
    consignee: string | number;
    district: string | number;
    fullAddress: string | number;
    mobileNumber: string | number;
    province: string | number;
    town: string | number;
  };
};

interface GetOrderDetailProps {
  afterSaleOrderNo: string | number;
  source: string | number;
  siteId: string | number;
}

interface GetOrderReturnProps {
  afterSaleOrderNo: string | number;
}

interface OrderDetailStoreProps {
  orderDetail: OrderDetail;
  // 所有商品详情都用这个
  // ajaxGetOrderDetail: (params: GetOrderDetailProps) => void;
}

class OrderDetailStore implements OrderDetailStoreProps {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  orderDetail: OrderDetail = {
    afterSaleApplyTime: "",
    afterSaleImages: [],
    afterSaleOrderNo: "",
    afterSaleReasonDesc: "",
    afterSaleStatus: 0,
    afterSaleType: 0,
    applyReason: "",
    applicantAccount: 0,
    cancelTime: "",
    contacter: "",
    confirmTime: "",
    orderNo: "",
    siteName: "",
    closeReason: "",
    freightInfo: {
      freightCompanyName: "",
      freightFee: 0,
      freightNo: "",
      mobileNum: "",
      recipient: "",
      recipientMobile: "",
      returnAddress: "",
      sendOutTime: "",
      takeOffAddress: "",
      takeOffTime: "",
      userName: "",
    },
    freightWay: 0,
    goodsInfo: {
      afterSaleGoodsImage: "",
      afterSaleGoodsName: "",
      afterSaleGoodsPrice: 0,
      afterSaleType: 0,
      applyNum: 0,
      customerPayment: 0,
      discountWays: [
        {
          id: "",
          name: "",
          value: 0,
        },
      ],
      freightFee: 0,
      returnPaymentInfos: [
        {
          name: "",
          type: 0,
          value: 0,
        },
      ],
      totalReturnAmount: 0,
    },
    phoneNum: "",
    takeOverAddress: "",
    takeAddress: {
      address: "",
      areaCode: "",
      city: "",
      consignee: "",
      district: "",
      fullAddress: "",
      mobileNumber: "",
      province: "",
      town: "",
    },
    expectTime: "",
    returnAddress: {
      address: "",
      areaCode: "",
      city: "",
      consignee: "",
      district: "",
      fullAddress: "",
      mobileNumber: "",
      province: "",
      town: "",
    },
  };

  @action
  async ajaxGetOrderDetail(params: GetOrderDetailProps) {
    const res: any = await httpClient.post("/fleshopy/order/after/v1/detail", params);
    if (res?.success) {
      runInAction(() => {
        this.orderDetail = res.data;
      });
    }
  }

  ajaxGetReturnAmount(params: GetOrderReturnProps) {
    return httpClient.post("/fleshopy/order/after/v1/return/amount", params);
  }
}

export default new OrderDetailStore();
