import React, { FC, useState, useEffect } from "react";
import { Tabs } from "@fle-ui/next";
import Optional from "./Optional/index";
import { useHistory } from "react-router-dom";

import "./index.scss";
import { PageContainer } from "@fle-ui/pro-layout";
const { TabPane } = Tabs;
const Commodity: FC = () => {
  const [poolSaasStatus, setPoolSaasStatus] = useState("10");
  const history = useHistory();
  const onChange = (activeKey: any) => {
    setPoolSaasStatus(activeKey);
  };

  const commodityTypes = [
    {
      name: "已上架",
      key: "10",
    },
    {
      name: "已下架",
      key: "-10",
    },
  ];

  useEffect(() => {
    if (history?.location?.state && history?.location?.state?.length > 0) {
      setPoolSaasStatus(history.location.state[0])
    }
  }, []);

  return (
    <PageContainer className="mine-commodiy-container">
      <div className="tabs-wrap">
        <Tabs defaultActiveKey="10" activeKey={poolSaasStatus} onChange={onChange}>
          {commodityTypes.map((item: any) => {
            const { key, name } = item;
            return (
              <TabPane tab={name} key={key}>
                <Optional poolSaasStatus={poolSaasStatus}></Optional>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </PageContainer>


  );
};
export default Commodity;
