import React, {FC, useState, useEffect} from 'react'
import './index.scss'
import ListItem from  '@/components/ListItem'
import EditUserInfoForm from './EditUserInfoForm/index'
import InfoList from '@/components/InfoList/index'
import { Table, Form, message } from "@fle-ui/next"

import AccountDetailModal from './AccountDetailModal'

import { parse } from 'qs'

import { getUserInfoByUserId, updateUserInfoByUserId } from './store'

import {formatTime} from '@/utils/common'

import moment from 'moment';

const { Column } = Table

const data = [
  {
    key: '1',
    firstName: 'John',
    lastName: 'Brown',
    age: '飞象企服',
    address: '12345678901',
    address1: '3310811',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    firstName: 'Jim',
    lastName: 'Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    firstName: 'Joe',
    lastName: 'Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
]

const UserDetail: FC = (props) => {
  const [editForm] = Form.useForm()
  const [userData, setUserData] = useState<any>({})
  const [isShowAccountDetailModal, setIsShowAccountDetailModal] = useState<boolean>(false) // TODO
  const [isShowEditUserInfo, setIsShowEditUserInfo] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<number>(0)
  const [current, setCurrent] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [id, setId] = useState<string>('')
  const [addressTableData, setAddressTableData] = useState<any[]>([]) // TODO
  const [orderTableData, setOrderTableData] = useState<any[]>([]) // TODO
  const query = parse(window.location.search, {ignoreQueryPrefix: true})
  const { userId }: any = query
  const[groupId, setGroupId] = useState<string>('')
  const siteId = window.location.pathname.split('/')[1]
  /**
   * @description 分页页码发生改变的回调
   * @param {number} pageNo 当前第几页
   * @param {number} limit 当前每一页显示几条
   * @returns {void}
   */
  const handleChangStart = (current: number) => {
    setCurrent(current)
    // TODO
}

/**
 * @description 页数发生改变的回调
 * @param  {number} start 
 * @param {number} pageSize
 * @returns {void}
 */
  const handleChangSize = (currenet: number, pageSize: number) => {
    setPageSize(pageSize)
    // TODO
  }
  const onCancel = () => {
    setIsShowAccountDetailModal(false)
  }
  const getAddressTableData =() =>{
  // TODO
  }
  const getOrderTableData = () => {
    // TODO
  }
  const handleSubmitEditForm = async ()=> {
    editForm.validateFields().then(async (values: any) => {
      const { birthday, groupName, resetPassword } = values

      const credential = resetPassword === 0 ? '' : values.credential
      const last = groupName[groupName.length - 1]

      const {success} = await updateUserInfoByUserId(
        {...editForm.getFieldsValue(),
          id,
          groupId: last , 
          birthday: formatTime(birthday, 'YYYY-MM-DD')
        }, credential) as any

      if (success) {
        message.success('修改成功')
        setIsShowEditUserInfo(false)
        getUserInfoById(userId)
      }
    })
    /* const { credential, birthday, groupName } = editForm.getFieldsValue() */
    
  }

  const handleCancleEditForm = () => {
    setIsShowEditUserInfo(false)

  }
  // 设置分页
const pagination = {
  pageSize,
  total,
  current,
  pageSizeOptions:['10','20'],
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: (total: number) => `共${total}页,第${current}页`,
  onChange:handleChangStart,
  onShowSizeChange: handleChangSize
}
const getUserInfoById = async (id: string)=> {
  if (!id) {
    return
  }
  const {data, success} = await getUserInfoByUserId(id) as any
  if (success) {
    setId(id)
    // data.favoriteCategory = '333'
    setUserData(data)
    setGroupId(data.groupId)
    editForm.setFieldsValue(
      {...data,
        credential: '123456',
        resetPassword: 0,
        birthday: data.birthday ?  moment(data.birthday) : ''
      })
  }
}
useEffect(()=> {
  getUserInfoById(userId)
},[userId])


  return (
    <div className='user-detail'>
      <div className='user-detail-edit'>
        <div className='edit-wrap'>
          <div>
            {userData.headshot && <img  alt='' src= {userData.headshot} style={{width: '80px', height: '80px'}}></img>}
            <div className='edit-btn' onClick={()=> setIsShowEditUserInfo(true)}>编辑</div>
          </div>
        </div>
          <div className='list-item-wrap'>
            <ListItem lable='用户ID' content={userData.id}></ListItem>
            <ListItem lable='性别' content= {
              userData.gender === 0 
                ? '保密' : 
                (userData.gender === 1 
                  ? '男' 
                  : '女')
            }></ListItem>
            <ListItem lable='喜欢的分类' content={userData.favoriteCategory}></ListItem>
            <ListItem lable='最近登录时间' content={formatTime(userData.lastLoginTime, 'YYYY-MM-DD HH:mm:ss')}></ListItem>
            {/**备注不用实现 */}
            <ListItem lable='备注' content= ''></ListItem>
          </div>
          <div className='list-item-wrap'>
            <ListItem lable='姓名' content={userData.name}></ListItem>
            <ListItem lable='生日' content={userData.birthday}></ListItem>
            <ListItem lable='注册时间' content={formatTime(userData.createTime, 'YYYY-MM-DD HH:mm:ss')}></ListItem>
            {/**消费时间不用实现 */}
            <ListItem lable='上次消费时间' content=''></ListItem>
            <ListItem lable='登陆状态' content= {
              userData.status === 0 
              ? '注销' : 
              (userData.status === 1 
                ? '启用' 
                : '禁用')
            }></ListItem>
          </div>
      </div>
        <EditUserInfoForm
          visible={isShowEditUserInfo}
          title='编辑信息'
          siteId={siteId}
          groupId={groupId}
          form={editForm}
          onOk={handleSubmitEditForm}
          onCancel={handleCancleEditForm}
        />
        {/*
        <div>
          <div className='info-list-title'>统计信息</div>
          <div className='info-list-wrapper'>
            <InfoList lable='累计消费金额' content='¥2000.00+423元宝'/>
            <InfoList lable='订单数量' content='¥2000.00+423元宝'/>
            <InfoList lable='积分账户余额' content='¥2000.00+423元宝'/>
            <InfoList lable='现金账户余额' content='¥2000.00+423元宝'/>
            <InfoList lable='优惠券（张）' content='¥2000.00+423元宝'/>
            <InfoList lable='代金券（张）' content='¥2000.00+423元宝'/>
            <InfoList lable='收藏商品数' content='¥2000.00+423元宝'/>
            <InfoList lable='累计登录次数' content='¥2000.00+423元宝'/>
            <InfoList lable='客单价' content='¥2000.00+423元宝'/>
            <InfoList lable='退货记录' content='¥2000.00+423元宝'/>
            <InfoList lable='累计消费金额' content='¥2000.00+423元宝'/>
            <InfoList lable='累计退款金额' content='¥2000.00+423元宝'/>
            <InfoList lable='累计退款金额' content='¥2000.00+423元宝'/>
            <InfoList lable='累计退款金额' content='¥2000.00+423元宝'/>
            <InfoList lable='累计退款金额' content='¥2000.00+423元宝'/>
          </div>
        </div>
        <div>
          <div className='info-list-title'>收货地址</div>
          <div style={{margin:'12px'}}>
            <Table  dataSource={data} pagination={false} bordered>
              <Column title="姓名" dataIndex="age" key="age" align='center' width='200px'/>
              <Column title="手机号码" dataIndex="address" key="address" width='200px'align='center' />
              <Column title="邮政编码" dataIndex="address" key="address1" width='200px' align='center' />
              <Column title="详细地址" dataIndex="address" key="address"/>
              <Column title="默认地址" dataIndex="address" key="address2" />
            </Table>
          </div>
          </div>
        <div>
          <div className='info-list-title'>订单记录</div>
          <div style={{margin:'12px'}}>
            <Table  dataSource={data} pagination={pagination} bordered>
            <Column title="订单编号" dataIndex="age" key="age" align='center' width='200px'/>
              <Column title="提交时间" dataIndex="address" key="address" width='200px'align='center' />
              <Column title="用户账号" dataIndex="address" key="address1" width='200px' align='center' />
              <Column title="订单金额" dataIndex="address" key="address" align='center' />
              <Column title="订单状态" dataIndex="address" key="address2" align='center'  />
              <Column title="查看订单" dataIndex="address" key="address2"  align='center' />
            </Table>
          </div>
        </div>
        <AccountDetailModal 
          id={2222} 
          tittle='积分账户明细' 
          visible={isShowAccountDetailModal}
          onCancle={onCancel}
          /> */}
    </div>
  )
}
export default UserDetail