import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Cascader,
  Button,
  Upload,
  message,
} from "@fle-ui/next";
import { mainHost } from "@/config/index";

import AddGroupTree, {
  TreeNodeDataType,
  AddTreeModalType,
} from "@/components/AddGroupTree/index";
import {
  batchList,
  cacheList,
  batchDelete,
  batchOneDelete,
  excelValidation,
  templateDownload,
  addUserByGroupId,
  getUserGroups,
  addGroupSubsByParentId,
  addGroup,
  updateGroupSubsById,
  getUserListByGroupId,
  sendMessageToUser,
} from "../store";
import Cookies from "js-cookie";
import { ExportFile, downloadFile } from "../../../../tools/ExportFileTs";
import "./addBulkUser.scss";
const { Item } = Form;

const AddBulkFormRender = (props: any) => {
  const {
    handleOk,
    handleCancel,
    title,
    visible,
    getBatchList,
    pageNo,
    pageSize,
  } = props;
  const [form] = Form.useForm();
  const siteId = window.location.pathname.split("/")[1];
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileName, setFileName] = useState(""); //文件名称
  const [inputValue, setinputValue] = useState(""); //备注输入框内容

  const [callBackData, setCallBackData] = useState();
  const uploadProps = {
    action: mainHost + "/fleshopy/merchant/site-user/import/batch",
    accept: ".csv,.xls,.xlsx",
    headers: {
      authority_token:
        localStorage.getItem("token") || (Cookies.get("token") as any),
    },
    data: {
      siteId,
      notes: inputValue,
    },

    fileList: [],
    async beforeUpload(file: any, fileList: any) {
      try {
        await form.validateFields();
        return true;
      } catch (error) {
        return false;
      }
    },
    onSuccess(info: any) {
      form
        .validateFields()
        .then((values) => {
          setUploadLoading(true);
          if (info.success) {
            setUploadLoading(false);
            setCallBackData(info.data);
            handleCancel();
            getBatchList(siteId, pageNo, pageSize);
          } else {
            setUploadLoading(false);
            handleCancel();
          }
          handleOk(info);
          form.resetFields();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };

  // 下载模板
  const downloadTem = async () => {
    ExportFile(
      "/fleshopy/merchant/site-user/template/download",
      {},
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "vnd.ms-excel",
      "",
      () => {
        message.success("导出成功");
      }
    );
  };
  // 备注输入框
  const inputOnchange = (e: any) => {
    setinputValue(e.target.value);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="addBulk"
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item>
              <div className="title">第一步：下载表格导入模板</div>
              <Button onClick={downloadTem}>下载表格模板</Button>
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item>
              <div className="title">第二步：编辑表格内容</div>
              <div className="content">
                <p>根据表格中的内容，进行填充内容</p>
                <p>
                  *请不要修改/删除表格中的表头文字/内容，且不要修改表格的格式；
                </p>
                <p>
                  *表格数据不可超过<span>10000条</span> 。
                </p>
                <p>*表格中的手机号不可重复，也不可以与商城现有的手机号重复。</p>
                <p>
                  *手机号是必填项，限11位数；其他字段非必填，若未填写初始密码，系统自动生成初始密码：123456；若填写分组内容，
                  务必保持和商城分组命名一致，否则用户默认进入未分组。
                </p>
                <p>
                  *单个用户积分和现金账户余额都低于100万，单次导入用户积分和现金账户余额总和都低于1亿。
                </p>
              </div>
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item>
              <div className="title">第三步：填写备注</div>
              <Item
                noStyle
                name="notes"
                rules={[{ max: 100, message: "不能超过100个字符" }]}
              >
                <Input
                  // maxLength={100}
                  onChange={inputOnchange}
                  placeholder="请填写备注便于追踪查看"
                />
              </Item>
            </Item>
          </Col>
          <Col span={24}>
            <Item>
              <Upload {...uploadProps}>
                <Button type="primary" loading={uploadLoading}>
                  上传表格
                </Button>
              </Upload>
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default observer(AddBulkFormRender);
