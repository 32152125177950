import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";
import md5 from "js-md5";

// 获取站点分组
export const getUserGroups = (siteId: number | string) => {
  return request({
    url: mainHost + "/fleshopy/merchant/group/groups",
    method: "get",
    params: {
      siteId,
    },
  });
};

// 根据父分组id添加子分组
export const addGroupSubsByParentId = (
  parentId: number | string,
  siteId: number | string,
  data: any[]
) => {
  return request({
    url: mainHost + "/fleshopy/merchant/group/add-sub",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ parentId, siteId, ...data }),
  });
};

export const updateGroupSubsById = (
  id: number | string,
  siteId: number | string,
  data: any[]
) => {
  return request({
    url: mainHost + "/fleshopy/merchant/group/update/by-id",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ id, siteId, ...data }),
  });
};

// 根据父分组id添加子分组
export const addGroup = (data: any) => {
  return request({
    url: mainHost + "/fleshopy/merchant/group/add-sub",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify(data),
  });
};

// 根据分组id获取用户列表
export const getUserListByGroupId = (
  groupId: string | number,
  siteId: string | number,
  pageNo: number,
  pageSize: any,
  searchForm?: any,
  advanceSearchForm?: any
) => {
  return request({
    url: mainHost + "/fleshopy/merchant/site-user/site/search-user/conditions",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({
      ...searchForm,
      ...advanceSearchForm,
      siteId,
      groupId,
      pageNo,
      pageSize,
    }),
  });
};

/// 根据站点和手机号码向用户发送短信

export const sendMessageToUser = (
  siteId: string | number,
  mobile: string | number
) => {
  return request({
    url: mainHost + "/fleshopy/merchant/site-user/site/send-code",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ siteId, mobile }),
  });
};

/* ------批量添加用户相关接口 ------*/
// C用户创建批次列表
export const batchList = (
  siteId: string | number,
  pageNo: number,
  pageSize: any
) => {
  return request({
    url: mainHost + "/fleshopy/person/batch/list",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ siteId, pageNo, pageSize }),
  });
};

// 批次id查询C用户缓存数据列表
export const cacheList = (
  batchId: string | number,
  pageNo: number,
  pageSize: any
) => {
  return request({
    url: mainHost + "/fleshopy/person/cache/list",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ batchId, pageNo, pageSize }),
  });
};

// 批量移除批次下创建失败的C用户缓存数据
export const batchDelete = (batchId: string | number) => {
  return request({
    url: mainHost + "/fleshopy/person/cache/batchDelete",
    method: "get",
    params: {
      batchId,
    },
  });
};

// 移除C用户缓存数据
export const batchOneDelete = (id: string | number) => {
  return request({
    url: mainHost + "/fleshopy/person/cache/delete",
    method: "get",
    params: {
      id,
    },
  });
};

// 上传表格校验
export const excelValidation = (
  siteId: string | number,
  companyId: string | number,
  creatorId: string | number,
  creatorName: string,
  file: string,
  notes: string,
  userLoginWay: string | number
) => {
  return request({
    url: mainHost + "/fleshopy/merchant/site-user/import/batch",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ siteId }),
  });
};

// 根据分组id添加用户
export const addUserByGroupId = (
  groupId: string | number,
  siteId: number | string,
  data: any
) => {
  const { password } = data;
  return request({
    url: mainHost + "/fleshopy/merchant/site-user/site/add-user",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ groupId, ...data, siteId, password: md5(password) }),
  });
};

// 下载表格模板
export const templateDownload = () => {
  return request({
    url: mainHost + "/fleshopy/merchant/site-user/template/download",
    method: "get",
    params: {
      
    },
  });
};

// c用户余额充值
export const userRecharge = (
  amount: number,
  memo: string,
  personId: string,
  rechargeType:1,
  siteId: string,
) => {
  return request({
    url: mainHost + "/fleshopy/person/account/recharge",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ siteId, amount, memo,personId,rechargeType }),
  });
};

// C用户充值记录
export const getRechargeList = (
  data:any
) => {
  return request({
    url: mainHost + "/fleshopy/person/account/recharge/record/page",
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: JSON.stringify({ ...data }),
  });
};
