import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";
import { ENV } from '@/config/index'

// 查询站点风格设置
export const getSiteConfig = () => {
  return request({
    url: mainHost + "/fleshopy/style/siteBottomConfig/getSiteConfig",
    method: "get",
    params: { siteId: sessionStorage.getItem("siteId") },
  });
};

// 更新底部导航样式
export const updateBottomStyle = (params: any) => {
  return request({
    url: mainHost + "/fleshopy/style/siteBottomConfig/updateBottomStyle",
    method: "post",
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem("siteId"),
    }),
  });
};

// 更新底部导航内容
export const updateBottomConfig = (params: any) => {
  return request({
    url: mainHost + "/fleshopy/style/siteBottomConfig/updateBottomConfig",
    method: "post",
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem("siteId"),
    }),
  });
};

// 保存站点风格
export const saveStyle = (params: any) => {
  return request({
    url: mainHost + "/fleshopy/style/siteStyleConfig/saveStyle",
    method: "post",
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem("siteId"),
    }),
  });
};

// 保存配色方案
export const saveColor = (params: any) => {
  return request({
    url: mainHost + "/fleshopy/style/siteStyleConfig/saveColor",
    method: "post",
    data: JSON.stringify({
      ...params,
      siteId: sessionStorage.getItem("siteId"),
    }),
  });
};

// 获取h5页面
export const copyJudge = (siteId?: number) => {
  let _url = "";
  switch (ENV) {
    case "dev":
      _url = `https://${siteId
      }.dev-shop.fleshopy.com`;
      break;
    case "qa":
      _url = `https://${
        siteId
      }.qa-shop.fleshopy.com`;
      break;
    default:
      _url = `https://${
        siteId
      }.shop.fleshopy.com`;
  }
  return _url;
};
