import React, { useState, FC, useEffect, useMemo } from "react";

import "./index.scss";

import BreakLineGraph from "../BreakLineGraph/index";
// import Pie from "../Pie/index";
import Produce from "../ShowProduce/index";
import ProImage from "@fle-ui/pro-image";

import { observer, inject } from "mobx-react";

import { Button, Table, Tooltip,Modal } from "@fle-ui/next";

import { QuestionCircleOutlined } from "@ant-design/icons";

import { formatTime } from "@/utils/common";
import { CommonStore } from "@/store/index";
import { toJS } from "mobx";

import {
  getSiteBrowseBySiteId,
  getTopGoods,
  getStrikeAmount,
  getDataGroupList,
  getStrikeAmountTopGoods,
  getLineOrderData,
  getCircularOrderData,
} from "./store";

import { AxiosPromise } from "axios";

import { sortRecord, color, breakLineRecord, sortKeyRecord } from "./dataBasa";
interface MenuItem {
  text: string;
  icon?: string;
  key: string | number;
  id: number;
  dateCycle?: string;
}

// 定义订单数据传惨类型
export interface DateType {
  startDate: string;
  endDate: string;
}

interface CbsType<T> {
  [key: string]: T;
}

type Top5Type = (
  siteId: number,
  dateCycle: string | DateType,
  sortKey?: string
) => AxiosPromise<any> | undefined;

// top5数据获取接口
const cbs: CbsType<Top5Type> = {
  accessing: getTopGoods,
  order: getCircularOrderData,
  knockdown: getStrikeAmountTopGoods,
};

// 定义订单数据columnes
const orderNumColumns: any[] = [
  {
    title: "时间",
    dataIndex: "title",
    align: "center",
    render: (text: string, record: any) => {
      const { type } = record;
      let name: string = "";
      switch (type) {
        case "today":
          name = "今日实时";
          break;
        case "yesterday":
          name = "昨日累计";
          break;
        case "week":
          name = "本周累计";
          break;
        case "month":
          name = "本月累计";
          break;
        default:
          name = "今日实时";
      }
      return (
        <div>
          <span>{name}</span>
        </div>
      );
    },
  },
  {
    title: "订单数",
    dataIndex: "customerPayedCount",
    align: "center",
  },
  {
    title: "消耗金额(元）",
    align: "center",
    dataIndex: "memberPayedAmount",
    render: (text: string, record: any) => {
      const { memberPayedAmount } = record;
      return memberPayedAmount / 100;
    },
  },
  {
    title: "访客",
    align: "center",
    dataIndex: "uv",
  },
  {
    title: "浏览量",
    align: "center",
    dataIndex: "pv",
  },
];

// 定义 menu
const menus: MenuItem[] = [
  {
    text: "访问数据",
    key: "accessing",
    id: 1,
  },
  {
    text: "订单数据",
    key: "order",
    id: 2,
  },
  {
    text: "成交金额",
    key: "knockdown",
    id: 4,
  },
];

const getEndDateByStartDate = (
  beforeDay: number,
  formmat = "yyyy-MM-DD"
): DateType => {
  const currenetDate = Date.now();
  const berforeOneDay = 1000 * 60 * 60 * 24 * 1;
  const endDate = formatTime(currenetDate, formmat);
  const beforeThreeDay = 1000 * 60 * 60 * 24 * beforeDay;
  const startDate = formatTime(Date.now() - beforeThreeDay, formmat);
  return {
    startDate,
    endDate,
  };
};
const date: any = {
  yesterday: -1,
  week: -7,
  month: -30,
};

const Display: FC<{ [key: string]: any }> = (props) => {
  const { siteId } = props;
  const categoryId = toJS(CommonStore).appId;

  // state
  const [menuKey, setMenuKey] = useState<string>("accessing");
  const [current, setCurrent] = useState<string>("yesterday");
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState<any>([]);
  const [orderTableData, setOrderTableData] = useState<any[]>([]);
  const [pieData, setPieData] = useState([]);
  const [sortKey, setSortKey] = useState<string>(sortKeyRecord.accessing);
  const [sortKeyList, setSortKeyList] = useState<any>(sortRecord.accessing);
  const [startDay, setStartDay] = useState<number>(0);
  const [endDay, setEndDay] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [breakMap, setBreakMap] = useState<any>(breakLineRecord["accessing"]);
  const [transValue, setTransValue] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [previewData,setPreviewData] = useState<any>({})

  const MemoBreakLineGraph = useMemo(() => {
    return <BreakLineGraph data={data} color={color} breakMap={breakMap} />;
  }, [data]);

  // 监听事件点击折线图切换
  const handleMenuItemClick = (key: string) => {
    setMenuKey(key);
    // 区分数据来源
    switch (key) {
      case "accessing":
        getAccessingData(siteId);
        break;
      case "order":
        getStrikeAmountData(siteId, getLineOrderData);
        setSortKey("payNum");
        break;
      case "integral":
        break;
      case "knockdown":
        getStrikeAmountData(siteId, getStrikeAmount);
        setSortKey("consumePrice");
        break;
      default:
        getAccessingData(siteId);
    }
    setCurrent("yesterday");
    setSortKeyList(sortRecord[key]);
    setSortKey(sortKeyRecord[key]);
    setBreakMap(breakLineRecord[key]);
    getGoosTop5RenderPie(
      siteId,
      key,
      "yesterday",
      sortKeyRecord[key],
      cbs[key]
    );
  };

  const handleChangeRenderPie = (key: any) => {
    setCurrent(key);
    getGoosTop5RenderPie(siteId, menuKey, key, sortKey as string, cbs[menuKey]);
  };

  // const handleClickChangeSortKey = (key: string) => {
  //   setSortKey(key);
  //   getGoosTop5RenderPie(siteId, menuKey, current, key as string, cbs[menuKey]);
  // };
  // 渲染menu
  const renderMenuList = (menus: MenuItem[]) => {
    return (
      <div className="fx-menu-wrap">
        {categoryId !== 1 &&
          menus.map((item: any) => {
            const { key, text, id } = item;
            return (
              <div
                className={`fx-menu-item ${
                  key === menuKey ? "fx-menu-item--active" : null
                }`}
                key={key}
                onClick={() => handleMenuItemClick(key)}
              >
                {text}
                <div className="fx-menu-item-dot"></div>
              </div>
            );
          })}
          {categoryId == 1 && <div className="fx-menu-item">访问数据</div>}
      </div>
    );
  };

  // 渲染图表排序按钮
  // const renderSortBtns = (arrs: any) => {
  //   return (
  //     <div>
  //       {arrs.map(({ key, text }: any) => (
  //         <span
  //           key={key}
  //           className={`sort-btn ${
  //             key === sortKey ? "sort-btn--active" : null
  //           }`}
  //           onClick={() => {
  //             handleClickChangeSortKey(key);
  //           }}
  //         >
  //           {text}
  //           <span className="sort-btn-dot"></span>
  //         </span>
  //       ))}
  //     </div>
  //   );
  // };

  // 获取一个月的数据
  const getMonthData = (data: any): void => {
    const end = data.length;
    // 默认展示从当前时间往前推7天
    const start = end - 10;
    setEndDay(end);
    setStartDay(start);
    // 将所有数据存储起来
    setAllData(data);
    setData(data.slice(start, end));
  };

  // 获取折线图访问量
  const getAccessingData = async (siteId: number) => {
    const { data, success } = (await getSiteBrowseBySiteId(siteId)) as any;

    if (success) {
      getMonthData(data);
    }
  };

  // 获取折线图成交金额(公用订单数据和消耗金额接口)
  const getStrikeAmountData = async (siteId: number, cb: Function) => {
    const { data, success } = (await cb(
      siteId,
      getEndDateByStartDate(29)
    )) as any;
    if (success) {
      let dataChildArr = Object.keys(data[0]);
      if (dataChildArr.length === 4) {
        let newData = data.map((item: any, index: any) => {
          item.customerPayed = item.customerPayed / 100;
          item.memberNotPayed = item.memberNotPayed / 100;
          item.memberPayed = item.memberPayed / 100;
          return item;
        });
        getMonthData(newData);
      } else {
        getMonthData(data);
      }
    }
  };
  // 获取饼图top5并且按照访客和流浏览量排序
  const getGoosTop5RenderPie = async (
    siteId: number,
    key: string,
    dateCycle: string,
    sortKey: string,
    cb: Top5Type
  ) => {
    let data = [];
    let success: boolean = true;
    if (key === "accessing") {
      const result = (await cb(siteId, date[dateCycle], sortKey)) as any;
      data = result.data || [];
      success = result.success;
    } else {
      const result = (await cb(siteId, date[dateCycle])) as any;
      data = result.data || [];
      success = result.success;
    }
    if (success === false) {
      return;
    }
    // table表格不需要渲染其他，所以需要剔除其他
    const omitOthers = data.filter((item: any) => item.name !== "其他");
    // 获取商品列表top5
    setTableData(omitOthers);

    setPieData(data);
  };

  // 获取支付信息
  const getPayTabelDataList = async (siteId: string) => {
    try {
      const { data, success } = (await getDataGroupList(siteId)) as any;
      if (success) {
        // 处理一下后端字段能符合column格式
        const jsontext = JSON.stringify(data);
        const dataList = JSON.parse(jsontext);
        const sumAllList = [];
        dataList.today.type = "today";
        dataList.yesterday.type = "yesterday";
        dataList.week.type = "week";
        dataList.month.type = "month";
        for (const key in dataList) {
          sumAllList.push(dataList[key]);
        }
        setOrderTableData(sumAllList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderDateMenu = () => {
    const dateMenu = [
      {
        key: "yesterday",
        title: "昨天",
      },
      {
        key: "week",
        title: "7天",
      },
      {
        key: "month",
        title: "30天",
      },
    ];
    return (
      <div className="fx-date-menu">
        {dateMenu.map((item) => (
          <div
            key={item.key}
            className={`fx-date-menu-item ${
              current == item.key ? "fx-date-menu-item-active" : ""
            }`}
            onClick={() => handleChangeRenderPie(item.key)}
          >
            {item.title}
          </div>
        ))}
      </div>
    );
  };

  const renderOmitOthersList = () => {
    const colorObj = {
      0: "#4B7EFF",
      1: "#87E2BD",
      2: "#53CE46",
      3: "#F7C638",
      4: "#FF8181",
    };
    if (tableData.length == 0) {
      return (
        <div className="pie-list-nodata">
          <ProImage
            width="72px"
            preview={false}
            src="https://oss.elebuys.com/tmpdir/202207011712480000184351.png"
          />
          暂无数据
        </div>
      );
    }
    return tableData.map((item: any, index: any) => (
      <div className="pie-list-item" key={index}>
        <div className="pie-list-item-left">
          <div
            className="pie-list-item-dot"
            style={{ backgroundColor: `${colorObj[index]}` }}
          ></div>
          <div className="pie-list-item-name">{item.goodsName}</div>
        </div>
        <div className="pie-list-item-right">
          <div className="mr48">{item.isEntity}</div>
          <div className="uv">
            {item.uvCount}/{item.pvCount}
          </div>
        </div>
      </div>
    ));
  };

  const toHelpCenter = () => {
    window.open("https://fxqifu.yuque.com/docs/share/76065271-3371-4190-9480-43b22651393a?#");
  };

  const transLeft = () => {
    if(transValue >= 0){
      setTransValue(0)
      return
    }
    let value = transValue + 535
    setTransValue(value >= 0 ? 0 : value)
  }

  const transRight = () => {
    let value = transValue - 535
    if(value > -1606){
      setTransValue(value)
      return
    }
    setTransValue(0)
  }

  const handlePreviewClick = (type:any) => {
    const data = [
      {
        header:'节日福利-中秋节',
        title:'精选优质供应商，提供高品质大闸蟹，中秋送礼佳品，支持提货券、多选一和商城模式。',
        subTitle:'阳澄湖大闱蟹为首选，各种规則自己指定。',
        img:'https://oss.elebuys.com/tmpdir/202209081740480000206773.png',
        code:'https://oss.elebuys.com/tmpdir/202209081619160000206763.png'
      },
      {
        header:'金融行业- VIP回馈',
        title:'VIP客户的尊贵体验，通过权益性展示，高端的设计风格，彰显VIP客户的尊贵，结合线上兑换的便利性，实显优质服务。',
        subTitle:'高端设计风格，精选专展兑换礼品。',
        img:'https://oss.elebuys.com/tmpdir/202209081741450000206774.png',
        code:'https://oss.elebuys.com/tmpdir/202209081730060000206767.png'
      },
      {
        header:'品类卡-大闸蟹',
        title:'精选优质供应商，提供高品质大闸蟹，中秋送礼佳品，支持提货券、多选一和商城榄式。',
        subTitle:'阳澄湖大闱蟹为首选，各种规则自己指定。',
        img:'https://oss.elebuys.com/tmpdir/202209081742430000206775.png',
        code:'https://oss.elebuys.com/tmpdir/202209081731140000206769.png'
      },
      {
        header:'行政办公',
        title:'数据统计，采购审批，批量采购， 在线结算，种类齐全，多样选品。',
        subTitle:'提供行政办公用品、办公设施设备等， 海量品牌挑选，规格齐全、品质保障。',
        img:'https://oss.elebuys.com/tmpdir/202209081740280000206772.png',
        code:'https://oss.elebuys.com/tmpdir/202209081732500000206771.png'
      },
    ]
    setPreviewData(data[type])
    setVisible(true)
  }

  useEffect(() => {
    if (startDay < 0 || endDay > allData.length) {
      return;
    }
    setData(allData.slice(startDay, endDay));
  }, [startDay, endDay, allData]);

  useEffect(() => {
    handleMenuItemClick("accessing");
    getAccessingData(siteId);
    getPayTabelDataList(siteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  return (
    <div className="fx-right-wrapper">
      <div className="fx-right-header-status">
        <div>
          <Produce siteId={siteId} />
        </div>
      </div>
      <div className="fx-right-header">
        <div className="fx-right-header-menu">
          <header className="fx-menu-header">
            {renderMenuList(menus)}
            <div
              style={{
                color: "#A9A9A9",
                fontSize: "12px",
              }}
            >
              更新时间: {formatTime(new Date())}
            </div>
          </header>
          <div className="fx-break-line-wrap">
            <div style={{ display: "flex" }}>
              {sortKeyList.map((item: any, index: number) => {
                const { key, explain, text } = item;
                return (
                  <div className="circle-wrapper" key={key}>
                    <div
                      className="circle"
                      style={{ background: color[index] }}
                    ></div>
                    <span>{text}</span>
                    <Tooltip placement="topLeft" title={explain}>
                      <QuestionCircleOutlined className="question" />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
            {MemoBreakLineGraph}
          </div>
        </div>
        <div className="rightA">
          <div className="rightA-header">
            <div className="text1">帮助中心</div>
            <div className="text2" onClick={toHelpCenter}>
              详情
            </div>
          </div>
          <div className="rightA-main" onClick={toHelpCenter}>
            <div className="rightA-main-item">
              <span className="new">NEW</span>
              如何快速创建商城
            </div>
            <div className="rightA-main-item">
              站点端重大更新，优先体验最新模板功能
            </div>
          </div>
        </div>
      </div>
      <div className="display-B-wrap">
        <div className="left">
          <div className="display-B-left-header">
            <div className="text1">案例展示</div>
            {/* <div className="text2">更多</div> */}
          </div>
          <div className="display-B-left-main">
            <div className="display-B-left-nav iconfont2 icon-jiantou" onClick={transLeft}></div>
            <div className="display-B-left-main-wrapper" style={{
              transform: `translate3d(${transValue}px,0px,0px)`
            }}>
              <div className="display-B-left-item">
                <img
                  className="item-img"
                  src="https://oss.elebuys.com/tmpdir/202209081131510000206730.png"
                ></img>
                <div className="item-wrap">
                  <div className="item-title">节日福利-中秋节</div>
                  <div className="item-tag-wrap">
                    <div className="item-tag">优秀案例</div>
                    <div className="item-tag">节日商城</div>
                  </div>
                  <div className="item-desc">
                    精选优质供应商，提供高品质大闸蟹，中秋送礼佳品，支持提货券、多选一和商城模式。
                  </div>
                  <Button type="primary" ghost onClick={() => handlePreviewClick(0)}>
                    查看完整案例
                  </Button>
                </div>
              </div>
              <div className="display-B-left-item">
                <img
                  className="item-img"
                  src="https://oss.elebuys.com/tmpdir/202209081133480000206731.png"
                ></img>
                <div className="item-wrap">
                  <div className="item-title">金融行业-VIP回馈</div>
                  <div className="item-tag-wrap">
                    <div className="item-tag">优秀案例</div>
                    <div className="item-tag">福利商城</div>
                  </div>
                  <div className="item-desc">
                    VIP客户的尊贵体验，通过权益性展示，高端的设计风格，影显VIP客户的尊贵，结合线上兑换的便利性，实显优质服务。
                  </div>
                  <Button type="primary" ghost onClick={() => handlePreviewClick(1)}>
                    查看完整案例
                  </Button>
                </div>
              </div>
              <div className="display-B-left-item">
                <img
                  className="item-img"
                  src="https://oss.elebuys.com/tmpdir/202209081134350000206732.png"
                ></img>
                <div className="item-wrap">
                  <div className="item-title">品类卡-大闸蟹</div>
                  <div className="item-tag-wrap">
                    <div className="item-tag">优秀案例</div>
                    <div className="item-tag">节日商城</div>
                  </div>
                  <div className="item-desc">
                    选优质供应商，提供高品质大闸蟹，中秋送礼佳品，支持提货券、多选一和商城榄式。
                  </div>
                  <Button type="primary" ghost onClick={() => handlePreviewClick(2)}>
                    查看完整案例
                  </Button>
                </div>
              </div>
              <div className="display-B-left-item">
                <img
                  className="item-img"
                  src="https://oss.elebuys.com/tmpdir/202209081134530000206733.png"
                ></img>
                <div className="item-wrap">
                  <div className="item-title">行政办公</div>
                  <div className="item-tag-wrap">
                    <div className="item-tag">优秀案例</div>
                    <div className="item-tag">节日商城</div>
                  </div>
                  <div className="item-desc">
                    数据统计，采购审批，批量采购，在线结算，种类齐全，多样选品。
                  </div>
                  <Button type="primary" ghost onClick={() => handlePreviewClick(3)}>
                    查看完整案例
                  </Button>
                </div>
              </div>
            </div>
            <div className="display-B-right-nav iconfont2 icon-jiantou" onClick={transRight}></div>
          </div>
        </div>
        <div className="right">
          <ProImage
            width="222px"
            height="161px"
            preview={false}
            src="https://oss.elebuys.com/tmpdir/202208241709160000204545.png"
          />
          <div className="text1">飞象设计服务</div>
          <div className="text2">一站式解决运营问题</div>
          <Button
            type="primary"
            onClick={() => window.open("https://100029.site.flesaas.com/")}
          >
            一键询问
          </Button>
        </div>
      </div>
      {/* <div className="fx-rightB-content">
        <div className="fx-content-header">
          <div>
            {menuKey === "accessing" && (
              <div className="sort-button">{renderSortBtns(sortKeyList)}</div>
            )}
            {menuKey === "order" && (
              <div className="sort-button">商品支付件数TOP5占比</div>
            )}
          </div>
          {renderDateMenu()}
        </div>
        <div className="fx-content-main">
          <div className="pie-wrap">
            {pieData.length ? (
              <Pie
                data={pieData}
                sortKey={
                  sortKey === "uvCount" || sortKey === "pvCount"
                    ? sortKey
                    : "count"
                }
              />
            ) : (
              <div className="pie-wrap"></div>
            )}
          </div>
          <div className="pie-list">{renderOmitOthersList()}</div>
        </div>
      </div> */}
      {/* {categoryId !== 1 && (
        <div className="order-quantity-table">
          <Table
            columns={orderNumColumns}
            dataSource={orderTableData}
            pagination={false}
            rowKey="type"
          />
        </div>
      )} */}
      {visible && <Modal
        width={993}
        onCancel={() => setVisible(false)}
        className='display-preview-modal'
        footer={null}
        visible={visible}
        centered
      >
        <div className='preview-main'>
          <div className="main-header">{previewData.header}</div>
          <div className="main-content">
            <div className="main-left">
              {/* <div className="main-left-img-wrap"> */}
                <img className="main-left-img" src={previewData.img} alt="" />
              {/* </div> */}
            </div>
            <div className="main-left-tip">
              <img className="main-left-tip-img" src="https://oss.elebuys.com/tmpdir/202209081820350000206784.png"></img>
              <div className="main-left-tip-title">滚动查看</div>
            </div>
            <img className="main-center" src="https://oss.elebuys.com/tmpdir/202209081608500000206761.png"></img>
            <div className="main-right">
              <div className="title">业务解决方案</div>
              <div className="sub-title mb40">{previewData.title}</div>
              <div className="title">商城特色</div>
              <div className="sub-title">{previewData.subTitle}</div>
              <div className="main-right-code">
                <img className="code" src={previewData.code} alt="" />
                <div>扫码体验</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>}
    </div>
  );
};

export default inject("siteId")(observer(Display));
