import { OrderStatus } from "@/store/OrderDetailStore";
import { OrderItem } from "@/store/AfterSaleListStore";
import { Col, Space } from "@fle-ui/next";
import React, { FC } from "react";

type Props = {
  // 删格化布局
  span: number;
};
const OrderType: FC<Props & OrderItem> = (props) => {
  const { span, afterSaleType, returnedAmount } = props;
  return (
    <>
      <Col span={span} className="table_vertical_left">
        <Space direction={"vertical"} align={"start"}>
          {afterSaleType == 10 && <span>退货</span>}
          {afterSaleType == 20 && <span>换货</span>}
          {afterSaleType == 30 && <span>维修</span>}
          {afterSaleType == 40 && <span>退款</span>}
          {(afterSaleType == 10 || afterSaleType == 40) && returnedAmount && (
            <span style={{ color: "#a9a9a9" }}>退款总额：￥ {(returnedAmount / 100).toFixed(2)}</span>
          )}
        </Space>
      </Col>
    </>
  );
};

export default OrderType;
