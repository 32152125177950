import React from "react";
import "./index.scss";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { PageContainer } from "@fle-ui/pro-layout";
import { withRouter } from "react-router-dom";

import Commodity from "./Commodity";
import CommodityGroup from "./CommodityGroup";
import CommodiNewtyGroup from "./CommodityNewGroup/list";
import CommodiNewtyGroupCreate from "./CommodityNewGroup/create"

const MineCommodity = (props: any) => {
  return (
    // <ProLayout className="pro-layout-wrap" siderWidth={192} menuList={routers} location={props.location} offsetTop={50}>
    // <PageContainer className="mine-commodiy-container">
      <div className="route-layout">
        <Switch>
          <Route path="/mineCommodity/commodity" exact component={Commodity} />
          <Route path="/mineCommodity/group" exact component={CommodityGroup} />
          <Route path="/mineCommodity/newGroup" exact component={CommodiNewtyGroup} />
          <Route path="/mineCommodity/newGroup/create" exact component={CommodiNewtyGroupCreate} />
          <Route path="/mineCommodity/newGroup/edit" exact component={CommodiNewtyGroupCreate} />

          {/* 重定向 */}
          <Redirect from="/mineCommodity" exact to="/mineCommodity/commodity" />
        </Switch>
      </div>
    // </PageContainer>
  );
};

export default withRouter(MineCommodity);
