import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal } from "@fle-ui/next";
import "./index.scss";

const FailModal = (props: any) => {
  const { handleOk, handleCancel, title, visible, loadTotal } = props;

  return (
    <div className="detail">
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="detail"
        cancelText={"我知道了"}
        okText={"下载错误内容"}
      >
        <div className="tit_modal">
          <img
            src="https://oss.elebuys.com/tmpdir/202107222021430003982008.png"
            alt=""
          />
          <h4>表格导入失败</h4>
          <div>
            <p>表格共包含 {loadTotal} 条数据</p>
            <p>数据中包含多项错误或重复，请修改或删除后重新上传</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(FailModal);
