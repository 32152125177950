import React, { FC, useState, useEffect } from "react";
import { message, Upload } from "@fle-ui/next";

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Cookies from "js-cookie";
import { mainHost } from "@/config/index";

import './index.scss'


import { unstable_batchedUpdates } from "react-dom";


const { Dragger } = Upload;
export interface MediaProps {
  value?: any;
  // onChange?: any;
  form?: any
  setMobileImg?: any
}

const Media: FC<MediaProps> = (props) => {

  const { value, form,setMobileImg } = props;

  const [imageList, setImageList] = useState<any>([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Array.isArray(value)) {
      setImageList(value);
    }
  }, [value]);

  const beforeUpload = (file: any) => {
    if (imageList?.length >= 1) {
      message.error('最多可上传1个附件')
      return false
    }

    const isLt20M = file.size / 1024 / 1024 < 2
    if (!isLt20M) {
      message.error('文件大小不能超过2MB!')
    }
    return isLt20M
  }



  // 上传触发的回调
  const onChange = (info: any) => {
    const { status } = info.file
    if (status === 'uploading') {
      setLoading(true)
    }
  }


  const onSuccess = (info: any) => {
    const { success, data } = info
    if (success === true) {
      message.success('上传成功')
      const list: any[] = [...imageList]

      list.push({
        contentLength: data.contentLength,
        id: data.id,
        type: data.type,
        originName: data.originName,
        url: data.url,
      })

      unstable_batchedUpdates(() => {
        setImageList(list)
        setLoading(false)
      })

      form.setFieldsValue(
        {
          groupImage: list?.map((item) => ({ url: item.url, uid: item.id, status: 'done', name: item.originName || item.name }))
        }
      )
      setMobileImg(data.url,)
      return
    }
  }

  const onError = (error: any) => {
    message.error('上传失败，请重新上传')
    setLoading(false)
  }

  const handleDeleteFile = (index: number) => {
    const list: any[] = [...imageList]
    list.splice(index, 1)
    setImageList(list)
    form.setFieldsValue(
      {
        groupImage: list?.map((item) => ({ url: item.url, uid: item.id, status: 'done', name: item.originName || item.name }))
      }
    )
  }


  const uploadProps: any = {
    maxCount: 1,
    showUploadList: true,
    withCredentials: true,
    onChange,
    onSuccess,
    onError,
    beforeUpload,
    onRemove: handleDeleteFile,
  }


  const uploadButton = () => {
    return <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传</div>
    </div>
  }

  return (
    <div className="media-content" id="media-content-id">
      <Upload
        {...uploadProps}
        style={{ width: 464 }}
        accept=".jpg,.jpeg,.png"
        name="file"
        action={`${mainHost}/basic/new/file/upload`}
        listType="picture-card"
        headers={{
          authority_token: localStorage.getItem("token") ||
            Cookies.get("seller_token") ||
            window.localStorage.getItem("seller_token") ||
            "",
        }}
        fileList={imageList}
      >
        {/* {uploadButton()} */}
        {imageList.length > 0 ? null : uploadButton()}
      </Upload>
    </div>
  );
};

export default Media;
