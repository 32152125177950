import Store from '@/config/storage'
import { observable,action } from 'mobx'
import { makeAutoObservable, configure } from 'mobx'
interface SiteParamType {
  setSiteId(siteId: number): void
  getSiteId(): number
}

configure({ enforceActions: 'observed' })
const store = new Store()
class SiteParama implements SiteParamType {
  private siteId: number
  constructor() {
    this.siteId = store.getItem('siteId')
    makeAutoObservable(this)
  }
  setSiteId(siteId: number): void {
    this.siteId = siteId
    store.setItem('siteId', siteId)

  }
  getSiteId(): number {
    return this.siteId
  }
  // 更新state
}
const siteParama = new SiteParama()
export default observable(siteParama,{ setSiteId: action})


