import React, { FC, useEffect, useState, useCallback } from "react";
import AddGroupTree, {
  TreeNodeDataType,
  AddTreeModalType,
} from "@/components/AddGroupTree/index";
import {
  getUserGroups,
  addGroupSubsByParentId,
  addGroup,
  updateGroupSubsById,
  getUserListByGroupId,
  sendMessageToUser,
  addUserByGroupId,
  userRecharge,
} from "./store";
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  DatePicker,
  Table,
  message,
  Cascader,
  Space,
  Dropdown,
  Menu,
  InputNumber,
} from "@fle-ui/next";
import { DownOutlined } from '@ant-design/icons';
import UserSearchForm from "./UserSearchForm/index";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { formatTime, getPathByKey } from "@/utils/common";
import "./index.scss";

// 渲染树title
const titleRender = (nodeData: any) => {
  const { name, personalCount } = nodeData;
  return (
    <span style={{ color: "#4C4C4C", fontSize: "14px" }}>
      {name}
      {personalCount > 0 && (
        <span style={{ paddingLeft: "6px" }}>({personalCount})</span>
      )}
    </span>
  );
};

const { Item } = Form;
const { RangePicker } = DatePicker;

// 递归给树增加key
const treeMap = (tree: any): any => {
  if (!Array.isArray(tree)) {
    return;
  }
  return tree.map((item: any) => {
    const { id, name, haveSub, tree } = item;
    return {
      ...item,
      key: id,
      value: id,
      label: name,
      children: treeMap(tree),
      title: name,
      isLeaf: haveSub === 1 ? false : true,
    };
  });
};
// 渲染组织树
const getUserGroupsBytreeId = async (siteId: number | string) => {
  const { data, success } = (await getUserGroups(siteId)) as any;
  if (success) {
    return treeMap(data);
  }
};
// 渲染高级搜索
const AdvanceFormRender = (props: AddTreeModalType) => {
  const { handleOk, handleCancel, title, visible, cancelText, form } = props;
  return (
    <Modal
      title={title}
      visible={visible}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item label="用户手机号" name="mobile">
              <Input placeholder="请输入用户手机号" />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item label="用户姓名" name="name">
              <Input placeholder="请输入用户姓名" />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item label="注册时间" name="createTime">
              <RangePicker placeholder={["请输入开始时间", "请输入结束时间"]} />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item label="最后登陆时间" name="lastLoginTime">
              <RangePicker placeholder={["请输入开始时间", "请输入结束时间"]} />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
// 渲染新增用户表单
const AddUserFormRender = (props: AddTreeModalType) => {
  const { handleOk, handleCancel, title, options, visible, form } = props;
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="用户手机号"
              validateTrigger={["onSubmit"]}
              rules={[
                { required: true, message: "手机号码必填" },
                {
                  validator: (rule: any, value: any, callback: any) => {
                    if (/^1[3456789]\d{9}$/.test(value)) {
                      callback();
                    } else {
                      callback(new Error("手机号码格式不正确"));
                    }
                  },
                },
              ]}
              name="mobile"
            >
              <Input placeholder="请输入用户手机号" />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              validateTrigger={["onSubmit"]}
              label="用户姓名"
              rules={[
                { required: true, message: "分组名称不能为空" },
                { max: 20, message: "不能超过20个字符" },
              ]}
              name="name"
            >
              <Input placeholder="请输入用户姓名" />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="用户分组"
              validateTrigger={["onSubmit"]}
              rules={[{ required: true, message: "用户分组必填" }]}
              name="groupName"
            >
              <Cascader
                options={options}
                placeholder="请选择用户分组"
                changeOnSelect
                allowClear={false}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="初始密码"
              validateTrigger={["onSubmit"]}
              rules={[
                {
                  required: true,
                  message: "用户密码必填",
                },
                {
                  validator: (rule: any, value: string, callback: any) => {
                    if (/\w{6,20}/.test(value)) {
                      callback();
                    } else {
                      callback(new Error("密码须为6-20位字母或数字"));
                    }
                  },
                },
              ]}
              name="password"
            >
              <Input placeholder="请输入初始密码" />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
const AddSubsGroupFormRender = (props: AddTreeModalType) => {
  const { handleOk, handleCancel, title, visible, form, options, parentId } =
    props;

  useEffect(() => {
    form.setFieldsValue({
      parentName: getPathByKey(parentId, "id", options),
    });
  }, [form, options, parentId]);

  useEffect(() => {
    form.setFieldsValue({
      name: "",
    });
  }, [visible]);
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        labelCol={{ span: 8 }}
        form={form}
        wrapperCol={{ span: 14 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="上级分组"
              rules={[{ required: true, message: "请输入上级分组名" }]}
              name="parentName"
            >
              <Cascader
                options={options}
                changeOnSelect
                placeholder="请选择用户分组"
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="分组名称"
              rules={[
                { required: true, message: "请输入分组名称" },
                { max: 20, message: "分组名称不能超过20个字符" },
              ]}
              name="name"
            >
              <Input placeholder="20个字符以内" />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
const EditSubsGroupFormRender = (props: AddTreeModalType) => {
  const { handleOk, handleCancel, title, visible, form, name } = props;

  useEffect(() => {
    form.setFieldsValue({ name });
  }, [visible]);
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Item
              label="分组名称"
              rules={[
                { required: true, message: "请输入分组名称" },
                { max: 20, message: "分组名称不能超过20个字符" },
              ]}
              name="name"
            >
              <Input placeholder="20个字符以内" />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
const UserInfo: FC<{ [key: string]: any }> = (props) => {
  const siteId = window.location.pathname.split("/")[1];
  const [treeData, setTreeData] = useState<TreeNodeDataType[]>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [selectedKeys, setSelectKeys] = useState<any>([]);
  const history = useHistory();
  const [tableData, setTableData] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [advanceFormVisible, setAdvanceFormVisible] = useState<boolean>(false);
  const [advanceLoading, setAdvancdeLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [addTreeFormEl] = Form.useForm();
  const [treeParentData, setTreeParentData] = useState<{
    name: string;
    personalCount: number;
  }>({ name: "未命名分组", personalCount: 0 });
  const [advanceFormEl] = Form.useForm();
  const [searchFormEl] = Form.useForm();
  const [addSubsGroupFormEl] = Form.useForm();
  const [selectTreeKey, setSelectTreeKey] = useState<any>([]);
  const [addUserFormEl] = Form.useForm();
  const [treeParentId, setTreeParentId] = useState<number | string>("");
  const [treeParentName, setTreeParentName] = useState<string>("全部");
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [editSubsGroupFormEl] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [addUserFormVisible, setAddUserFormVisible] = useState<boolean>(false);
  const [addSubsGroupFormVisible, setAddSubsGroupFormVisible] =
    useState<boolean>(false);
  const [editSubsGroupFormVisible, setEditSubsGroupFormVisible] =
    useState<boolean>(false);

  // 余额充值开始
  const [rechangeform] = Form.useForm();
  const [currentId, setCurrentId] = useState('')
  const [rechargeModalOpen, setRechargeModalOpen] = useState(false)
  const handleBalanceAdd = () => {
    setRechargeModalOpen(true)
  }
  const handleRechargeOk = async () => {
    const { price, desc } = rechangeform.getFieldsValue()
    if (Number(price) <= 0 || !price) {
      message.warning('请输入充值金额')
      return
    }
    rechangeform.resetFields();

    const { success } = (await userRecharge(price * 100, desc, currentId, 1, siteId)) as any;
    if (success) {
      message.success('充值成功')
      getUserList(treeParentId, siteId, 1, 10);
      // 刷新列表
      setRechargeModalOpen(false)
    }
  }
  // 余额充值结束

  const menu: any = (
    <Menu items={[
      {
        key: '1',
        label: (
          <Button type="link" onClick={handleBalanceAdd}>
            充值
          </Button>
        ),
      },
    ]}>
    </Menu>
  )

  // 重置普通搜索表单
  const resetSearchForm = () => {
    searchFormEl.setFieldsValue({
      keyword: undefined,
      word: "",
      status: "",
    });
  };

  // 重置高级搜索表单吧
  const resetAdvanceSearchForm = () => {
    advanceFormEl.setFieldsValue({
      mobile: "",
      name: "",
      createTime: "",
      lastLoginTime: "",
    });
  };

  const handleChangStart = (start: number, limit?: number) => {
    setPageNo(start);
    getUserList(treeParentId, siteId, start, limit as number);
  };

  // 选择页数发生改变

  const handleChangSize = (start: number, limit?: number) => {
    setPageSize(limit as number);
    // getUserList(treeParentId, siteId, start, limit as number)
  };

  const pagination = {
    pageSize,
    total,
    currenet: pageNo,
    pageSizeOptions: ["10", "20"],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共${total}条,第${pageNo}页`,
    onChange: handleChangStart,
    onShowSizeChange: handleChangSize,
  };
  // 跳转详情路由
  const handleDetailClick = (id: number) => {
    history.push({
      pathname: `/user/userManagement/userDetail/`,
      search: `?userId=${id}`,
    });
  };

  // 渲染表格
  const columns = [
    {
      title: "用户ID",
      dataIndex: "id",
      key: "id",
      algin: "center",
    },
    {
      title: "用户手机号",
      dataIndex: "mobile",
      key: "mobile",
      algin: "center",
    },
    {
      title: "用户姓名",
      dataIndex: "name",
      key: "name",
      algin: "center",
    },
    {
      title: "积分账户余额",
      key: "integralAccountBalance",
      dataIndex: "integralAccountBalance",
      algin: "center",
    },
    {
      title: "现金账户余额",
      key: "cashAccountBalance",
      dataIndex: "cashAccountBalance",
      algin: "center",
      render: (text: string, record: any) => {
        const { cashAccountBalance } = record;
        return cashAccountBalance / 100;
      },
    },
    {
      title: "状态",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        const { status } = record;
        let statusName = "正常";
        switch (status) {
          case 0:
            statusName = "注销";
            break;
          case 1:
            statusName = "启用";
            break;
          case 2:
            statusName = "禁用";
            break;
          default:
            statusName = "启用";
        }
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              color: status === 2 ? "red" : "black",
            }}
          >
            <span
              style={{
                marginRight: "4px",
                width: "4px",
                height: "4px",
                borderRadius: "50%",
                backgroundColor: `${status === 2 ? "#F42D2D" : "#2C68FF"}`,
              }}
            ></span>
            {statusName}
          </span>
        );
      },
      algin: "center",
    },
    {
      title: "操作",
      key: "action",
      dataIndex: "action",
      algin: "center",
      render: (text: string, record: any) => {
        const { id } = record;
        return (
          <Dropdown overlay={menu} onVisibleChange={() => setCurrentId(id)}>
            <a onClick={e => e.preventDefault()}>
              <Space size="small">
                <span onClick={() => handleDetailClick(id)}>详情</span>
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    getUserGroupsBytreeId(siteId).then((data) => {
      setTreeData(data);
    });
  }, []);

  useEffect(() => {
    getUserList(treeParentId, siteId, 1, 10);
  }, [siteId, treeParentId]);

  // 获取用户列表信息
  const getUserList = async (
    treeParentId: string | number,
    siteId: string | number,
    pageNo: number,
    pageSize: any,
    searchForm: any = searchFormEl.getFieldsValue()
  ) => {
    const { keyword, word, status } = searchForm;
    let searchObj: any = { ...searchForm };
    if (keyword) {
      searchObj = {
        [keyword]: word,
        status,
      };
    }
    setTableLoading(true);
    const { data = {}, success } = (await getUserListByGroupId(
      treeParentId,
      siteId,
      pageNo,
      pageSize,
      searchObj
    )) as any;
    const { list = [], total = 0 } = data;
    if (success) {
      setTableLoading(false);
      setTableData(list);
      setTotal(total);
    }
  };

  // 点击新分组显示新增表单

  const handleAddtreeBtn = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    addTreeFormEl.setFieldsValue({
      name: "",
      parentName: "根目录",
    });
    addTreeFormEl.resetFields(["name"]);
    setVisible(true);
  };

  // 提交表单搜索

  const handleSearchFormClick = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    await getUserList("", siteId, 1, pageSize);
    setPageNo(1);
    setTreeParentName("搜索结果");
    setSelectKeys([""]);
    setDisabled(true);
  };

  useEffect(() => {
    setTreeParentData({ name: treeParentName, personalCount: total });
  }, [total, treeParentName]);

  // 提交添加用户

  const handleSubmitAddUserForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    // addUserFormEl.submit()
    addUserFormEl.validateFields().then(async (values: any) => {
      const { isSendeMessage, mobile, name, groupName } = values;
      const last = groupName[groupName.length - 1];
      addSubsGroupFormEl.submit();
      const { success } = (await addUserByGroupId(last, siteId, values)) as any;
      if (success) {
        const treeList = await getUserGroupsBytreeId(siteId);
        /* setTableData(origin => updateTreeData(origin, last, treeList)) */
        setTreeData(treeList);
        setAddUserFormVisible(false);
        message.success("新增用户成功");
        getUserList(treeParentId, siteId, pageNo, pageSize);
        if (isSendeMessage === true) {
          const { success } = (await sendMessageToUser(siteId, mobile)) as any;
          if (success) {
            message.success(`已向${name}发送短信通知`);
          }
        }
        // setConfirmLoading(false)
      }
    });
  };
  // 新增子分组
  const handleSubmitAddSubsGroupForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    addSubsGroupFormEl.validateFields().then(async (values: any) => {
      const { parentName } = values;
      const length = parentName.length;
      const last = parentName[length - 1];
      // setConfirmLoading(true)
      setAddSubsGroupFormVisible(false);
      addSubsGroupFormEl.submit();
      const { success } = (await addGroupSubsByParentId(
        last,
        siteId,
        values
      )) as any;
      if (success) {
        /* getUserGroupsBytreeId(siteId, '').then(data => {
          setTreeData(data)
        }) */
        const treeList = await getUserGroupsBytreeId(siteId);
        setTreeData(treeList);
        addSubsGroupFormEl.setFieldsValue({ name: "" });
        setAddSubsGroupFormVisible(false);
        // setConfirmLoading(false)
        message.success("新增成功");
      }
    });
  };

  // 修改子分组

  const handleSubmitEditSubsGroupForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    editSubsGroupFormEl.submit();
    const segment = selectTreeKey[0] ? selectTreeKey[0].split("-") : [];
    const length = segment.length;
    editSubsGroupFormEl.validateFields().then(async (values: any) => {
      //  setConfirmLoading(true)
      editSubsGroupFormEl.submit();
      const { success } = (await updateGroupSubsById(
        treeParentId,
        siteId,
        values
      )) as any;
      if (success) {
        const treeList = await getUserGroupsBytreeId(siteId);
        setTreeData(treeList);
        setExpandedKeys(expandedKeys);
        editSubsGroupFormEl.setFieldsValue({ name: "" });
        message.success("修改成功");
        setEditSubsGroupFormVisible(false);
        //  setConfirmLoading(false)
      }
    });
  };
  // 取消提交添加用户

  // 提交添加用户

  const handleCancelAddUserForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAddUserFormVisible(false);
  };

  // 取消添加子分组

  const handleCancelAddSubsGroupForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAddSubsGroupFormVisible(false);
  };

  // 取消编辑子分组

  const handleCancelEditSubsGroupForm = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setEditSubsGroupFormVisible(false);
  };

  // 点击显示高级搜素项

  const handleAdvanceSearchFormClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAdvanceFormVisible(true);
    setSelectKeys([""]);
    advanceFormEl.setFieldsValue({
      name: "",
      mobile: "",
      createTime: [],
      lastLoginTime: [],
    });
  };

  const addTreeModal: AddTreeModalType = {
    visible,
    title: "添加分组",
    handleOk() {
      addTreeFormEl.validateFields().then(async (values) => {
        addTreeFormEl.submit();
        const { success } = (await addGroup({ siteId, ...values })) as any;
        if (success) {
          message.success("新增分组成功");
          setVisible(false);
          getUserGroupsBytreeId(siteId).then((data) => {
            setTreeData(data);
          });
          return;
        }
      });
    },
    handleCancel() {
      setVisible(false);
    },
  };

  const handleSelectTree = (selectedKey: any, e: any) => {
    const { node } = e;
    /* // const {props} = node
    const key: any = []
    while (node.props) {
      key.unshift(node.key)
    } */
    // setSelectTreeKey(selectedKey)
    if (node.isLeaf === true) {
      const result = getPathByKey(node.key, "key", treeData);
    }
  };

  // 渲染分组表单

  const renderForm = () => {
    const { Item } = Form;
    return (
      <Form
        form={addTreeFormEl}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        name="basic"
        initialValues={{ parentName: "根目录" }}
      >
        <Item label="上级分组" name="parentName">
          <Input disabled />
        </Item>
        <Item
          label="分组名称"
          name="name"
          rules={[
            { required: true, message: "分组名称不能为空" },
            { max: 20, message: "不能超过20个字符" },
          ]}
        >
          <Input placeholder="20个字符以内" />
        </Item>
      </Form>
    );
  };

  // 提交高级搜索表单

  const handleSubmitAdvanceForm = () => {
    const obj = advanceFormEl.getFieldsValue();
    if (Array.isArray(obj.createTime)) {
      obj.createTimeStart = formatTime(
        new Date(obj.createTime[0]).setHours(0, 0, 0, 0)
      );
      obj.createTimeEnd = formatTime(
        new Date(obj.createTime[1]).setHours(23, 59, 59, 0)
      );
    }
    if (Array.isArray(obj.lastLoginTime)) {
      obj.lastLoginTimeStart = formatTime(
        new Date(obj.lastLoginTime[0]).setHours(0, 0, 0, 0)
      );
      obj.lastLoginTimeEnd = formatTime(
        new Date(obj.lastLoginTime[1]).setHours(23, 59, 59, 0)
      );
    }
    getUserList("", siteId, 1, pageSize, obj);
    setAdvanceFormVisible(false);
    setPageNo(1);
    setSelectKeys([""]);
    setTreeParentName("搜索结果");
  };
  // 撤回提交高级搜素表单
  const handleCancelAdvanceForm = () => {
    setAdvanceFormVisible(false);
    advanceFormEl.setFieldsValue({
      mobile: "",
      name: "",
      lastLoginTime: [],
      createTime: [],
    });
    getUserList("", siteId, 1, 10);
    setPageNo(1);
    setPageSize(10);
    setSelectKeys([""]);
  };

  // 添加用户按钮

  const handleAddUserClick = () => {
    setAddUserFormVisible(true);
    setSelectKeys([""]);
    addUserFormEl.setFieldsValue({
      name: "",
      password: "",
      groupName: [],
      mobile: "",
      isSendeMessage: false,
    });
    addUserFormEl.resetFields();
  };

  const showAddSubsGroupForm = () => {
    setAddSubsGroupFormVisible(true);
  };

  // 点击树触发的回调

  const onClick = (e: React.MouseEvent, node: any) => {
    const { id, name, personalCount, key } = node;
    setSelectKeys([key]);
    /*  setTreeParentData({name, personalCount }) */
    setTreeParentId(id);
    setTreeParentName(name);
    setDisabled(false);
    resetAdvanceSearchForm();
    resetSearchForm();
    setPageNo(1);
    setPageSize(10);
  };

  const onExpand = (expandedKeys: any) => {
    setExpandedKeys(expandedKeys);
  };

  return (
    <div className="tree_table userManagement_userInfo">
      <AddGroupTree
        treeData={treeData}
        btnName="添加新分组"
        groupName="用户分组"
        onClick={onClick}
        expandedKeys={expandedKeys}
        selectedKeys={selectedKeys}
        onExpand={onExpand}
        handleClick={handleAddtreeBtn}
        renderForm={renderForm}
        titleRender={titleRender}
        onSelect={handleSelectTree}
        addTreeModal={addTreeModal}
        handleEdit={() => {
          setEditSubsGroupFormVisible(true);
        }}
        handleAddChild={showAddSubsGroupForm}
      />
      <div className="user-table-form">
        <div className="search-form" style={{ marginBottom: 10 }}>
          <UserSearchForm
            handleAdvanceSearchFormClick={handleAdvanceSearchFormClick}
            form={searchFormEl}
            handleSearchFormClick={handleSearchFormClick}
          />
          <AddUserFormRender
            title="添加用户"
            form={addUserFormEl}
            siteId={siteId}
            options={treeData}
            selectTreeKey={selectTreeKey}
            visible={addUserFormVisible}
            confirmLoading={advanceLoading}
            handleCancel={handleCancelAddUserForm}
            handleOk={handleSubmitAddUserForm}
          />
          <AddSubsGroupFormRender
            title="添加子分组"
            form={addSubsGroupFormEl}
            selectTreeKey={selectTreeKey}
            parentId={treeParentId}
            name={treeParentName}
            visible={addSubsGroupFormVisible}
            siteId={siteId}
            options={treeData}
            confirmLoading={advanceLoading}
            handleCancel={handleCancelAddSubsGroupForm}
            handleOk={handleSubmitAddSubsGroupForm}
          />
          <EditSubsGroupFormRender
            title="修改分组"
            form={editSubsGroupFormEl}
            name={treeParentName}
            visible={editSubsGroupFormVisible}
            confirmLoading={advanceLoading}
            handleCancel={handleCancelEditSubsGroupForm}
            handleOk={handleSubmitEditSubsGroupForm}
          />
          <AdvanceFormRender
            title="高级搜索"
            form={advanceFormEl}
            cancelText="重置"
            visible={advanceFormVisible}
            confirmLoading={advanceLoading}
            handleCancel={handleCancelAdvanceForm}
            handleOk={handleSubmitAdvanceForm}
          />
        </div>
        <div className="table-wrap">
          <Table
            columns={columns}
            pagination={pagination}
            loading={tableLoading}
            dataSource={tableData}
            rowKey="id"
          />
        </div>
        {/* 余额充值 */}
        <Modal title="账户余额充值" visible={rechargeModalOpen} onOk={handleRechargeOk} onCancel={() => {
          rechangeform.resetFields();
          setRechargeModalOpen(false)
        }}>
          <Form
            form={rechangeform}
            name="rechange"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}>
            <Form.Item
              label="余额类型"
              name="type"
            >
              现金账户
            </Form.Item>
            <Form.Item
              label="充值金额"
              name="price"
              rules={[{ required: true, message: '请输入充值金额!' }]}
            >
              <InputNumber min={0} max={999999} style={{ width: '100%' }} parser={(value:any) => {
                return value!
                  .replace(new RegExp(/[^0-9.-]/, "g"), "")
                  .replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
              }} formatter={value => {
                return (
                  `${value}`
                    .replace(/\B(?=(\d{3})+(?!\d))/g, "")
                    .replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
                );
              }} />
            </Form.Item>
            <Form.Item
              label="备注"
              name="desc"
            >
              <Input.TextArea showCount maxLength={100} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default inject("siteId")(observer(UserInfo));
