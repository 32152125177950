import { useEffect, useState } from 'react';

export default () => {
  const [query, setQuery] = useState<any>();
  useEffect(() => {
    const curQuery = getQuery();
    setQuery(curQuery);
  }, [window.location.href]);

  return [query];
};

export const getQuery = () => {
  const url:any = new URL(window.location.href);
  return [...url.searchParams.entries()].reduce((pre, [name, value]) => {
    return { ...pre, [name]: value };
  }, {});
};
