import { Icon } from "@/components";
import React from "react";

const routerAllList = [
  {
    name: "数据概览",
    badgeText: "",
    path: "/survey",
    key: "/survey",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
  {
    name: "商城",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key: "/order",
    icon: <Icon type="icon-shangcheng" />,
    children: [
      {
        name: "装修管理",
        badgeText: "",
        path: "/ownStore",
        key: "/ownStore",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "商品",
        badgeText: "",
        key: "/mineCommodity",
        disabled: false,
        showMenu: true,
        icon: "",
        children: [
          {
            name: "我的商品",
            badgeText: "",
            path: "/mineCommodity/commodity",
            key: "/mineCommodity/commodity",
            disabled: false,
            showMenu: true,
            icon: "",
          },
          // {
          //   name: "商品分组",
          //   badgeText: "",
          //   path: "/mineCommodity/group",
          //   key: "/mineCommodity/group",
          //   disabled: false,
          //   showMenu: true,
          //   icon: "",
          // },
          {
            name: "商品分组",
            badgeText: "",
            path: "/mineCommodity/newGroup",
            key: "/mineCommodity/newGroup",
            disabled: false,
            showMenu: true,
            icon: "",
          }
        ],
      },
      {
        name: "订单管理",
        badgeText: "",
        path: "/order/orderManage",
        key: "/order/orderManage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "售后管理",
        badgeText: "",
        path: "/order/afterSaleManage",
        key: "/order/afterSaleManage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
    ],
  },
  {
    name: "用户",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key:'/user',
    icon: <Icon type="icon-yonghu" />,
    children: [
      {
        name: "用户信息",
        badgeText: "",
        path: "/user/userManagement/userInfo",
        key: "/user/userManagement/userInfo",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "创建用户",
        badgeText: "",
        path: "/user/userManagement/userAdd",
        key: "/user/userManagement/userAdd",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "充值记录",
        badgeText: "",
        path: "/user/userManagement/rechangeList",
        key: "/user/userManagement/rechangeList",
        disabled: false,
        showMenu: true,
        icon: "",
      },
    ],
  },
  {
    name: "设置",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key:'/site',
    icon: <Icon type="icon-shezhi" />,
    children: [
      {
        name: "基础设置",
        badgeText: "",
        path: "/site/setting",
        key: "/site/setting",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "界面设置",
        badgeText: "",
        path: "/site/interface",
        key: "/site/interface",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "经营设置",
        badgeText: "",
        path: "/site/manage",
        key: "/site/manage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "协议设置",
        badgeText: "",
        path: "/site/agreement",
        key: "/site/agreement",
        disabled: false,
        showMenu: true,
        icon: "",
      },
    ],
  },
  {
    name: "佣金插件",
    badgeText: "",
    path: "/distribution",
    key: "/distribution",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
];

const routerType1List = [
  {
    name: "数据概览",
    badgeText: "",
    path: "/survey",
    key: "/survey",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
  {
    name: "商城",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key: "/order",
    icon: <Icon type="icon-shangcheng" />,
    children: [
      {
        name: "装修管理",
        badgeText: "",
        path: "/ownStore",
        key: "/ownStore",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "商品",
        badgeText: "",
        key: "/mineCommodity",
        disabled: false,
        showMenu: true,
        icon: "",
        children: [
          {
            name: "我的商品",
            badgeText: "",
            path: "/mineCommodity/commodity",
            key: "/mineCommodity/commodity",
            disabled: false,
            showMenu: true,
            icon: "",
          },
          {
            name: "商品分组",
            badgeText: "",
            path: "/mineCommodity/newGroup",
            key: "/mineCommodity/newGroup",
            disabled: false,
            showMenu: true,
            icon: "",
          }
        ],
      },
    ],
  },
  {
    name: "佣金插件",
    badgeText: "",
    path: "/distribution",
    key: "/distribution",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
];

const routerType4List = [
  {
    name: "数据概览",
    badgeText: "",
    path: "/survey",
    key: "/survey",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
  {
    name: "商城",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key: "/order",
    icon: <Icon type="icon-shangcheng" />,
    children: [
      {
        name: "装修管理",
        badgeText: "",
        path: "/ownStore",
        key: "/ownStore",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "商品",
        badgeText: "",
        key: "/mineCommodity",
        disabled: false,
        showMenu: true,
        icon: "",
        children: [
          {
            name: "我的商品",
            badgeText: "",
            path: "/mineCommodity/commodity",
            key: "/mineCommodity/commodity",
            disabled: false,
            showMenu: true,
            icon: "",
          },
          {
            name: "商品分组",
            badgeText: "",
            path: "/mineCommodity/newGroup",
            key: "/mineCommodity/newGroup",
            disabled: false,
            showMenu: true,
            icon: "",
          }
        ],
      },
      {
        name: "订单管理",
        badgeText: "",
        path: "/order/orderManage",
        key: "/order/orderManage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "售后管理",
        badgeText: "",
        path: "/order/afterSaleManage",
        key: "/order/afterSaleManage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
    ],
  },
  {
    name: "设置",
    badgeText: "",
    disabled: false,
    showMenu: true,
    key:'/site',
    icon: <Icon type="icon-shezhi" />,
    children: [
      {
        name: "基础设置",
        badgeText: "",
        path: "/site/setting",
        key: "/site/setting",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "界面设置",
        badgeText: "",
        path: "/site/interface",
        key: "/site/interface",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "经营设置",
        badgeText: "",
        path: "/site/manage",
        key: "/site/manage",
        disabled: false,
        showMenu: true,
        icon: "",
      },
      {
        name: "协议设置",
        badgeText: "",
        path: "/site/agreement",
        key: "/site/agreement",
        disabled: false,
        showMenu: true,
        icon: "",
      },
    ],
  },
  {
    name: "佣金插件",
    badgeText: "",
    path: "/distribution",
    key: "/distribution",
    disabled: false,
    showMenu: true,
    icon: <Icon type="icon-shujugailan" />,
  },
];



export {
  routerAllList,
  routerType1List,
  routerType4List
};
