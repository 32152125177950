
import { parseQueryString } from '@/utils/common';
import httpClient from '@/utils/httpClient';
import Api from '@/config/api';
const { fitment, common } = Api;
const { genre = 'site' } = parseQueryString();

// 各端接口映射
const apiMap = {
  'template': {
    get_fitment_content: async (id) => {
      try {
        const { tinyId } = parseQueryString();
        let params = { templateId: id };
        if (tinyId) {
          params.templateDecorationId = tinyId;
        }
        const { data } = await httpClient.post(fitment.get_fitment_content, params);
        return {
          content: data.savedContent,
          savedShare: data.savedShare,
          savedPageName: data.savedPageName,
        }
      } catch (ex) {
        console.warn(ex);
      }

    },
    updata_fitment_content: async (params) => {
      return httpClient.post(fitment.update_fitment_content, params);
    },
    getSelectGoods: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_select_goods_list, { ...params, templateId: customId });
    },
    get_select_special_list: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_select_special_list, { ...params, templateId: customId });
    },
    get_goods_list: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_goods_list, { ...params, templateId: customId });
    },
    get_share: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.get(fitment.get_share_template, { params: { ...params, templateId: customId } })
    },
    update_share_config: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(fitment.update_share_config_template, { templateId: customId, ...params });
    },
    get_fitment_info: async (params = {}) => {
      try {
        let customId = params.customId;
        delete params.customId;
        const result = await httpClient.get(fitment.get_template_info, { params: { templateId: customId, ...params } });
        return { ...result, data: { ...result.data, name: result.data.templateName }, };
      } catch (e) {

      }
    },
    get_fitment_tiny_list: async (params) => {
      try {
        let customId = params.customId;
        delete params.customId;
        return httpClient.get(fitment.get_fitment_tem_tiny_list, { params: { templateId: customId } });
      } catch (ex) {
        console.warn(ex);
      }
    },

    // 微页面
    get_tiny_info: async (params = {}) => {
      try {
        let customId = params.customId;
        delete params.customId;

        let customDecorationIds = params.customDecorationIds;
        delete params.customDecorationIds;

        return httpClient.post(common.get_tem_tiny_info, { ...params, templateId: customId, templateDecorationIds: customDecorationIds });
      } catch (ex) {
        console.warn(ex);
      }
    },

    // 专题
    get_special_info: async (params = {}) => {
      try {
        return httpClient.post(common.get_tem_special_info, params);
      } catch (ex) {
        console.warn(ex);
      }
    }
  },

  'site': {
    get_fitment_content: async (id) => {
      try {

        const { tinyId } = parseQueryString();
        let params = { siteId: id };
        if (tinyId) {
          params.siteDecorationId = tinyId;
        }

        const { data } = await httpClient.post(fitment.get_site_content, params);
        return {
          // content: data.savedContent,
          // id: data.id,
          // siteId: data.siteId,
          // coverImage: data.coverImage,

          content: data.savedContent,
          savedShare: data.savedShare,
          savedPageName: data.savedPageName,

        }
      } catch (e) {
        return {};
      }
    },
    updata_fitment_content: async (params) => {
      return httpClient.post(fitment.updata_site_content, params);
    },
    getSelectGoods: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_select_goods_list_site, { ...params, siteId: customId });
    },
    get_select_goods_from_mall: async (params) => {
      return httpClient.post(fitment.get_select_pageList_for_mall, params);
    },
    get_select_special_list: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_select_special_list, { ...params, siteId: customId });
    },
    get_goods_list: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(common.get_goods_list_site, { ...params, siteId: customId });
    },
    get_share: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.get(fitment.get_share_site, { params: { ...params, siteId: customId } })
    },
    update_share_config: async (params) => {
      let customId = params.customId;
      delete params.customId;
      return httpClient.post(fitment.update_share_config_site, { siteId: customId, ...params });
    },
    get_fitment_info: async (params) => {
      try {
        let customId = params.customId;
        delete params.customId;
        const result = await httpClient.get(fitment.get_site_info, { params: { siteId: customId, ...params } });
        return { ...result, data: { ...result.data, name: result.data.siteName }, };;
      } catch (e) {

      }

    },
    get_fitment_tiny_list: async (params) => {
      try {
        let customId = params.customId;
        delete params.customId;
        return httpClient.get(fitment.get_fitment_site_tiny_list, { params: { siteId: customId } });
      } catch (ex) {
        console.warn(ex);
      }
    },
    

    // 专题
    get_special_info: async (params = {}) => {
      try {
        return httpClient.get(common.get_site_special_info, { params: { topicId: params.id } });
      } catch (ex) {
        console.warn(ex);
      }
    }
  },
}

export const getApi = (api, parmas) => {
  return apiMap[genre][api](parmas);

}