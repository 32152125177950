import WhiteSpace from "@/components/WhiteSpace";
import OrderListStore, { OrderItem } from "@/store/OrderListStore";
import { Modal, Tabs, Timeline } from "@fle-ui/next";
import React, { FC, useEffect, useState } from "react";

type Props = { isModalVisible: boolean; handleCancel: () => void };
const deliveryMethodMap = ["", "快递", "物流"];
export const prefixCls = "mall-order-logistics";

const { TabPane } = Tabs;
const LogisticsModal: FC<Props & OrderItem> = (props) => {
  const { isModalVisible, handleCancel, goods } = props;

  const [expressData, setExpressData] = useState<any>({});

  const [active, setActive] = useState<string>("0");

  const [logistic, setLogistic] = useState<any>();
  // const [expressNo, setExpressNo] = useState<any>("");

  useEffect(() => {
    if (!isModalVisible) {
      return;
    }
    const orderNo = props?.orderId;
    OrderListStore.ajaxQueryLogisticByOrderNo({ orderNo })
      .then((res) => {
        res && setLogistic(res);
        setActive("0");
      })
      .catch((error) => {
        setLogistic({});
      });
  }, [isModalVisible]);

  useEffect(() => {
    if (logistic) {
      const deliveryMethod = logistic[active]?.deliveryMethod;
      const expressNo = logistic[active]?.expressNo;
      if (deliveryMethod === 1 && expressNo.length !== 0) {
        OrderListStore.ajaxQueryLogisticByExpressNo({ expressNo })
          .then((res) => {
            res && setExpressData(res);
          })
          .catch((error) => {
            setExpressData({});
          });
      }
    }
  }, [active, logistic]);


  return (
    <>
      <Modal
        title="物流跟踪"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs onChange={setActive} type="card" activeKey={active}>
          {logistic?.map((item: any, index: any) => (
            <TabPane tab={"包裹" + (index + 1)} key={index} />
          ))}
        </Tabs>

        <div style={{ textAlign: "left", lineHeight: 2 }}>
          {logistic &&
            logistic[active]?.goodsInfos?.map((item: any, index: any) => (
              <img
                width={68}
                style={{ marginRight: 10 }}
                src={item?.goodsImage}
                alt=""
                key={index}
              />
            ))}
          {logistic && logistic[active]?.expressNo ? (
            <>
              <div>
                <span>发货方式：&nbsp;&nbsp;</span>
                {deliveryMethodMap[logistic[active]?.deliveryMethod]}
              </div>
              <div>
                <span>物流公司：&nbsp;&nbsp;</span>
                {logistic[active]?.deliveryMethod === 1
                  ? expressData?.expressName
                  : logistic[active]?.expressCompany}
              </div>
              <div>
                <span>运单号码：&nbsp;&nbsp;</span>
                {logistic[active]?.expressNo}
              </div>
              <div className="track">
                <div>物流跟踪：&nbsp;&nbsp;</div>
                <WhiteSpace height={10} />
                {logistic[active]?.deliveryMethod === 1 ? (
                  <div className="track_con">
                    <Timeline>
                      {expressData?.expressInfo?.map((item: any) => (
                        <Timeline.Item key={item.time}>
                          {item.time}:{item.context}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                ) : (
                  <div>暂无物流信息！</div>
                )}
              </div>
            </>
          ) : (
            <div>暂无运单号！</div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default LogisticsModal;
