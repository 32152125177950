import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Space, Col, Button, Typography } from "@fle-ui/next";
import "./OrderDeal.scss";
import LogisticsModal from "./LogisticsModal";
import { Link } from "react-router-dom";
import { OrderItem } from "@/store/OrderListStore";

type Props = {
  // 删格化布局
  span: number;
  // reloadPage: () => void;
};
const { Text } = Typography;
const OrderState: FC<Props & OrderItem> = (props) => {
  const history = useHistory();
  const { span, orderId, goods } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Col span={span} className="order-deal table_vertical_left">
      <Space align="start" size="large">
        {goods.some((item: any) => item.deliveryCode) && (
          <span style={{ fontSize: 12, color: "#919191" }}>
            <Button type="link" onClick={() => setIsModalVisible(true)} style={{ margin: 0, padding: 0 }}>
              查看物流
            </Button>
          </span>
        )}
        <Button
          type="link"
          onClick={() => {
            history.push("/order/orderManage/detail/" + orderId);
          }}
          style={{ margin: 0, padding: 0 }}
        >
          详情
        </Button>
      </Space>
      <LogisticsModal isModalVisible={isModalVisible} handleCancel={() => setIsModalVisible(false)} {...props} />
    </Col>
  );
};

export default OrderState;
