import React, { useState, FC, useEffect } from "react";
import "./index.scss";
import { Form, Radio, Button, message, Select, Switch } from "@fle-ui/next";
import {
  goSitebusinessConfig,
  getSitebusinessConfig,
  goNoteList,
} from "./store";
import { inject, observer } from "mobx-react";

const { Item } = Form;
const { Group } = Radio;
const { Option } = Select;

const SiteManage: FC<{ [key: string]: any }> = (props) => {
  const { siteId } = props;
  const [submitForm] = Form.useForm();
  const [smsDeliver, setSmsDeliver] = useState<boolean>();
  const [smsOrder, setSmsOrder] = useState<boolean>();
  const [noteListVaule, setnoteListVaule] = useState<any[]>();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 10 },
  };

  useEffect(() => {
    getSiteInfo(siteId);
    getNoteList();
  }, [siteId]);

  //note选择列表
  const noteList = noteListVaule?.map((item: any, index: any) => {
    return (
      <Option key={item.id} value={item.id}>
        {item.signatureName}
      </Option>
    );
  });

  const onFinish = (values: any) => {
    const { smsDeliver, smsOrder } = values;

    const data = {
      ...values,
      smsDeliver: smsDeliver == (true || 1) ? 1 : 0,
      smsOrder: smsOrder == (true || 1) ? 1 : 0,
    };
    const cb = () => {
      goSitebusinessConfig({ ...data, id: siteId }).then(({ success }: any) => {
        if (success) {
          message.success("提交成功");
          // getSiteInfo(siteId);
        } else {
          message.error("提交失败");
        }
      });
    };
    cb();
  };

  // 获取初始化数据
  const getSiteInfo = async (siteId: number) => {
    const { data, success } = (await getSitebusinessConfig(siteId)) as any;
    if (success) {
      const { status, smsDeliver, smsOrder, smsSignId } = data;
      submitForm.setFieldsValue({
        status,
        smsSignId,
        smsOrder,
        smsDeliver,
      });
      if (smsDeliver) {
        setSmsDeliver(true);
      } else {
        setSmsDeliver(false);
      }
      if (smsOrder) {
        setSmsOrder(true);
      } else {
        setSmsOrder(false);
      }
    }
  };

  // 获取note列表接口数据
  const getNoteList = async () => {
    const res: any = await goNoteList({ pageNo: 1, pageSize: 999 });
    const { data, success } = res;
    let noteList: any[] = [];
    // 可以用Map
    if (success) {
      data?.list.forEach((item: any, index: any) => {
        let note: any = {};
        note.id = item.id;
        note.signatureName = item.signatureName;
        noteList.push(note);
      });
    }
    setnoteListVaule(noteList);
  };

  // switch点击函数
  const switchOrder = (checked: boolean, event: Event) => {
    setSmsOrder(checked);
  };
  const switchDeliver = (checked: boolean, event: Event) => {
    setSmsDeliver(checked);
  };

  return (
    <div className="manage-form-wrap">
      <div className="manage-form-wrap-header">经营设置</div>
      <Form
        {...layout}
        name="basic"
        form={submitForm}
        onFinish={onFinish}
        className="site_manage_form"
      >
        <Item
          label="商城状态"
          name="status"
          extra={
            "个性短信签名，请前往财务管理 - 短信管理 - 签名管理进行申请。短信通知将消耗短信额度，额度不足时将不发送短信。"
          }
          style={{marginBottom:'32px'}}
          rules={[{ required: true, message: "商城状态必选" }]}
        >
          <Group>
            <Radio value={1}>上线</Radio>
            <Radio value={2}>下线</Radio>
          </Group>
        </Item>
        <Item label="选择短信签名" style={{marginBottom:'32px'}} name="smsSignId">
          <Select placeholder="选择短信签名">{noteList}</Select>
        </Item>
        <Item style={{marginBottom:'32px'}} label="系统短信验证码发送">
          短信内容:【短信签名】验证码：888888，10分钟内有效，若非本人操作，请忽略。
        </Item>
        <Item
          label="下单成功短信通知"
          extra={
            "短信内容:【短信签名】尊敬的[收货人姓名]，您好！您的订单[订单号]已支付，将尽快为您安排发货。"
          }
          name="smsOrder"
          style={{marginBottom:'32px'}}
        >
          <Switch
            checkedChildren="开"
            unCheckedChildren="关"
            checked={smsOrder}
            onChange={switchOrder}
          />
        </Item>
        <Item
          label="发货成功短信通知"
          extra={
            "短信内容:【短信签名】尊敬的[收货人姓名]，您好！您的商品已发货，快递公司为[快递名称]，快递运单号为[运单号]，请您注意查收，有什么问题可以联系我们哦！"
          }
          name="smsDeliver"
          style={{marginBottom:'32px'}}
        >
          <Switch
            checkedChildren="开"
            unCheckedChildren="关"
            checked={smsDeliver}
            onChange={switchDeliver}
          />
        </Item>

        {/* <Item
          label="短信通知"
          style={{ marginLeft: 12 }}
          className="site_manage_con"
        >
          <Item label="选择短信签名" name="smsSignId">
            <Select placeholder="选择短信签名" style={{ width: 120 }}>
              {noteList}
            </Select>
          </Item>
          <div className="site_manage_note_con">
            <span className="site_manage_note_con_tit">系统短信验证码发送</span>
            <p>
              短信内容:【短信签名】验证码：888888，10分钟内有效，若非本人操作，请忽略。
            </p>
          </div>
          <Item label="下单成功短信通知" name="smsOrder">
            <Switch
              checkedChildren="开"
              unCheckedChildren="关"
              checked={smsOrder}
              onChange={switchOrder}
            />
          </Item>
          <p className="site_manage_note_con_des">
            短信内容:【短信签名】尊敬的[收货人姓名]，您好！您的订单[订单号]已支付，将尽快为您安排发货。
          </p>
          <Item label="发货成功短信通知" name="smsDeliver">
            <Switch
              checkedChildren="开"
              unCheckedChildren="关"
              checked={smsDeliver}
              onChange={switchDeliver}
            />
          </Item>
          <p className="site_manage_note_con_des">
            短信内容:【短信签名】尊敬的[收货人姓名]，您好！您的商品已发货，快递公司为[快递名称]，快递运单号为[运单号]，请您注意查收，有什么问题可以联系我们哦！
          </p>
        </Item> */}

        <Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            确认保存
          </Button>
        </Item>
      </Form>
    </div>
  );
};

export default inject("siteId")(observer(SiteManage));
