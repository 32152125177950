import React, { FC, useState, useEffect, useRef } from "react";
import { ColorResult, Color } from "react-color";
import { ColorSelect } from "@/components/index";
import {
  Radio,
  Table,
  Image,
  Space,
  Button,
  message,
  Modal,
} from "@fle-ui/next";
import "./SettingTabs.scss";
import { getSiteConfig, updateBottomStyle, updateBottomConfig } from "./store";
import SiteTabEditModal from "./component/siteTabEdit";

interface bottomConfigItem {
  bottomName: string;
  defaultName: string;
  iconNormal: number;
  iconNormalAddr: string;
  iconSelect: number;
  iconSelectAddr: string;
  id: number;
  isHidden: boolean;
  linkName: string;
  linkType: string;
  linkValue: string;
  orderNum: number;
  pageName: string;
  siteId: number;
  siteType: number;
}
interface bottomConfig {
  backgroundColor: string;
  bottomConfigList: bottomConfigItem[];
  bottomType: number;
  id: number;
  siteId: number;
  siteStyleType: string;
  textColor: string;
  siteColorScheme: string;
}

// 重置时，颜色取当前主题默认颜色
const defaultThemeMap = new Map([
  [
    "green",
    {
      backgroundColor: "#75B455",
      textColor: "#75B455",
    },
  ],
  [
    "red",
    {
      backgroundColor: "#FF0C3D",
      textColor: "#FF0C3D",
    },
  ],
  [
    "blue",
    {
      backgroundColor: "#2785FF",
      textColor: "#2785FF",
    },
  ],
  [
    "orange",
    {
      backgroundColor: "#FF7518",
      textColor: "#FF7518",
    },
  ],
  [
    "violet",
    {
      backgroundColor: "#821EC4",
      textColor: "#821EC4",
    },
  ],
]);
// 默认选中图文样式
const defaultStyleCheckedObj = {
  bgChecked: true,
  textChecked: false,
};

export const SettingTabs: FC = () => {
  const [bgColorCode, setBgColorCode] = useState<string>("#FFFFFF"); // 背景色
  const [textColorCode, setTextColorCode] = useState<string>("#FFFFFF"); // 文字色
  const [styleCheckedObj, setStyleCheckedObj] = useState<
    typeof defaultStyleCheckedObj
  >(defaultStyleCheckedObj); // 图文/纯文字
  const [tableData, setTableData] = useState([]); //tab列表
  const [modalVisible, setModalVisible] = useState<boolean>(false); //编辑弹窗
  const baseData = useRef<any>({
    backgroundColor: "",
    textColor: "",
    siteId: 0,
    bottomConfigList: [],
  }); //初始数据
  const [modalEditData, setModalEditData] = useState([]);
  const [tinyValueList, setTinyValueList] = useState<any>([]);
  const [systemValueList, setSystemValueList] = useState<any>([]);

  const bodyDom = document.body;
  const setBodyProp = (color: any) => {
    bodyDom.style.setProperty("--themeBgc", `rgba(${color},.01)`);
  };

  const tableColumns = [
    {
      title: "底部导航名称",
      dataIndex: "bottomName",
      key: "bottomName",
      className: "table-style",
    },
    {
      title: "icon图标",
      dataIndex: "iconSelect",
      key: "iconSelect",
      className: "table-style",
      render: (_: string, record: any) => {
        return (
          <Space size={8}>
            <Image preview={false} width={30} src={record.iconNormalAddr} />
            <Image preview={false} width={30} src={record.iconSelectAddr} />
          </Space>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "isHidden",
      key: "isHidden",
      className: "table-style",
      render: (_: string, record: any) => {
        const { isHidden } = record;
        return isHidden ? "隐藏" : "显示";
      },
    },
    {
      title: "排序",
      dataIndex: "orderNum",
      key: "orderNum",
      className: "table-style",
    },
    {
      title: "操作",
      width:100,
      render: (_: string, record: any) => {
        const { isHidden, defaultName } = record;
        /* tab显示隐藏 */
        const handleTabToggle = async (hidden: boolean) => {
          const { success } = (await updateBottomConfig({
            ...record,
            isHidden: hidden,
          })) as any;
          if (success) {
            message.success("修改成功");
            getData();
          }
        };
        /* 编辑tab */
        const handleTabItemEdit = () => {
          setModalEditData(record);
          setModalVisible(true);
        };
        const handleHide = (hidden: boolean) => {
          Modal.confirm({
            title: "确认隐藏?",
            content: "隐藏后，导航将不展示这个入口！",
            okText: "确定",
            cancelText: "我再想想",
            onOk: () => {
              const data = (
                baseData.current.bottomConfigList as bottomConfigItem[]
              ).filter((item) => item.isHidden == false);
              if (data.length <= 2) {
                Modal.warning({
                  title: "操作失败!",
                  content: "底部导航TAB至少显示2个！",
                  okText: "确定",
                });
                return;
              }
              handleTabToggle(true);
            },
          });
        };
        return (
          <Space className="tabs-ant-btn-wrapper">
            <Button type="link" onClick={handleTabItemEdit}>
              修改
            </Button>
            {isHidden ? (
              <Button style={{border:'none'}} type="link" onClick={() => handleTabToggle(false)}>
                显示
              </Button>
            ) : (
              <Button
                type="link"
                style={{border:'none'}}
                onClick={() => handleHide(true)}
                disabled={defaultName === "首页"}
              >
                隐藏
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  /* 变更/重置 背景颜色 */
  const handleBgColorChange = (result: ColorResult) => {
    setBgColorCode(result.hex);
    setBodyProp(result.hex);
  };
  const handleBgColorReset = () => {
    const defaultObj = defaultThemeMap.get(baseData.current.siteStyleType);
    if (defaultObj) {
      setBgColorCode(defaultObj.backgroundColor);
      setBodyProp(defaultObj.backgroundColor);
    }
  };
  /* 变更/重置 文字颜色 */
  const handleTextColorChange = (result: ColorResult) => {
    setTextColorCode(result.hex);
  };
  const handleTextColorReset = () => {
    const defaultObj = defaultThemeMap.get(baseData.current.siteStyleType);
    if (defaultObj) {
      setTextColorCode(defaultObj.textColor);
    }
  };

  /* 获取主题数据 */
  const getData = async () => {
    const { success, data } = (await getSiteConfig()) as any;
    if (success) {
      baseData.current = data;
      setTableData(data.bottomConfigList);
      setStyleCheckedObj({
        bgChecked: data.bottomType === 0,
        textChecked: data.bottomType === 1,
      });
      setBgColorCode(data.backgroundColor);
      setTextColorCode(data.textColor);
      setBodyProp(data.backgroundColor);
      // 统计系统/微页面链接
      const systemList: any = [];
      const tinyList: any = [];
      (data.bottomConfigList as bottomConfigItem[]).forEach((item) => {
        if (item.linkType === "系统页面") {
          systemList.push(item.linkValue);
        } else if (item.linkType === "微页面") {
          tinyList.push(item.linkValue);
        }
      });
      setSystemValueList(systemList);
      setTinyValueList(tinyList);
    }
  };

  /* 更新底部样式 */
  const updateBtnStyle = async () => {
    const { success } = (await updateBottomStyle({
      siteId: baseData.current.siteId,
      backgroundColor: bgColorCode,
      textColor: textColorCode,
      bottomType: styleCheckedObj.bgChecked ? 0 : 1,
    })) as any;
    if (success) {
      message.success("设置成功");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="setting-tab-wrapper">
      <div className="setting-tab-left">
        <div className="setting-tab-left-title">导航预览：</div>
        <div className={`tab-style-display ${styleCheckedObj.bgChecked ? '':'op5'}`}>
          {(baseData.current.bottomConfigList as bottomConfigItem[]).map(
            (item, index) =>
              item.isHidden ? null : (
                <div className="img-style-display" key={item.id}>
                  <Image
                    preview={false}
                    width={item.defaultName == "购物车" ? 28 : 24}
                    src={index == 0 ? item.iconSelectAddr : item.iconNormalAddr}
                  />
                  <div
                    className="img-style-text"
                    style={{
                      color: index == 0 ? textColorCode : "#7F7F7F",
                    }}
                  >
                    {item.bottomName}
                  </div>
                </div>
              )
          )}
        </div>
        <div className={`tab-style-display ${styleCheckedObj.bgChecked ? 'op5':''}`}>
          {(baseData.current.bottomConfigList as bottomConfigItem[]).map(
            (item, index) =>
              item.isHidden ? null : (
                <div
                  className="img-style-display"
                  style={{
                    color: index == 0 ? textColorCode : "#7F7F7F",
                  }}
                  key={item.id}
                >
                  {item.bottomName}
                </div>
              )
          )}
        </div>
      </div>
      <div className="setting-tab-right">
        <div className="setting-tab-right-top">
        <div className="setting-tab-tip">
          <span className="setting-tab-tip-title">底部导航样式</span>
          Tips：设置个性导航后，若进行全局风格设置使用一键换肤，所有个性设置将失效，建议先确定风格模版后再进行底部导航设置
        </div>
        <div>
          <div className="setting-tab-title setting-tab-title-wrapper">
            <span style={{paddingRight:'8px'}}>导航样式：</span>
            <div className="tab-style-item">
              <Radio
                checked={styleCheckedObj.bgChecked}
                onChange={(e) =>
                  setStyleCheckedObj({
                    bgChecked: e.target.checked,
                    textChecked: false,
                  })
                }
              >
                图文样式
              </Radio>
            </div>
            <div className="tab-style-item">
              <Radio
                checked={styleCheckedObj.textChecked}
                onChange={(e) =>
                  setStyleCheckedObj({
                    bgChecked: false,
                    textChecked: e.target.checked,
                  })
                }
              >
                纯文字样式
              </Radio>
            </div>
          </div>
          <div className="tab-style-wrapper">
            {styleCheckedObj.bgChecked && <div className="tab-style-item">
              <div className="tab-style-set">
                <span className="tab-style-set-title">背景颜色：</span>
                {/* <span className="color-code">
                  {bgColorCode.toLocaleUpperCase()}
                </span> */}
                <ColorSelect
                  defaultColorCode={bgColorCode}
                  handleColorChange={handleBgColorChange}
                  handleColorReset={handleBgColorReset}
                />
              </div>
              <div className="tab-style-set">
                <span className="tab-style-set-title">文字颜色：</span>
                <ColorSelect
                  defaultColorCode={textColorCode}
                  handleColorChange={handleTextColorChange}
                  handleColorReset={handleTextColorReset}
                />
              </div>
            </div>}
            {styleCheckedObj.textChecked && <div className="tab-style-item">
              <div className="tab-style-set">
                <span className="tab-style-set-title">背景颜色：</span>
                <ColorSelect
                  defaultColorCode={bgColorCode}
                  handleColorChange={handleBgColorChange}
                  handleColorReset={handleBgColorReset}
                />
              </div>
              <div className="tab-style-set">
                <span className="tab-style-set-title">文字颜色：</span>
                <ColorSelect
                  defaultColorCode={textColorCode}
                  handleColorChange={handleTextColorChange}
                  handleColorReset={handleTextColorReset}
                />
              </div>
            </div>}
          </div>
        </div>
        </div>
        <div className="setting-tab-right-bottom">
          <div className="setting-table-title">配置导航内容</div>
          <Table
            rowKey="id"
            dataSource={tableData}
            columns={tableColumns}
            pagination={false}
          />
        </div>
      </div>
      <SiteTabEditModal
        editData={modalEditData}
        visible={modalVisible}
        tinyValueList={tinyValueList}
        systemValueList={systemValueList}
        onCancel={() => setModalVisible(false)}
        refresh={getData}
      />

      <div className="setting-tab-savebtn">
        <Button type="primary" onClick={updateBtnStyle}>
          保存
        </Button>
      </div>
    </div>
  );
};
