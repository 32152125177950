// import { OrderApis } from 'api/orderApis';
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { Moment } from "moment";
// import { DetailGoodItemProp } from 'stores/OrderDetailStore';
import httpClient from "@/utils/httpClient";
import { ListDetailProps, PageProps } from "../types/commom";
import { getFakeTotal } from '@/utils/common';

export interface GetOrderListParams extends PageProps {
  [key: string]: any;
}

export interface OrderItem {
  // 订单创建时间
  createTime: string;
  // 下单账号
  createrId: string;
  goods: any[];
  // 订单号
  orderId: string;
  orderStatus: 1 | 2 | 3 | 4 | 5;
  shopId: string;
  shopName: string;
  // 创建人姓名
  userName: string;
  // 订单确认时间
  confirmTime: string;
  // 发货单笔数
  deliveryCount: number;

  [key: string]: any;
}

interface OrderStatusCountParams {
  companyId?: "";
  // 订单类型：1、集采批发订单，2、集采代发订单，3、印刷订单
  orderType?: 1 | 2 | 3;
  userId?: "";
  siteId?: number;
}

interface OrderStatusCountResult {
  // 待付款订单数量
  paymentCount: 0;
  // 待发货订单数量
  sendCount: 0;
  // 已发货订单数量
  shippedCount: 0;
}

export interface CancelOptionProps {
  key: string;
  value: string;
}

interface OrderListStoreProps {
  orderList: ListDetailProps<OrderItem>;
  // 集采批发订单
  // ajaxGetPurWholesaleOrderList: (params: Partial<GetOrderListParams>) => void;
  // ajaxGetOrderStatusCount: (params: OrderStatusCountParams) => void;
  // ajaxGetReason: () => Promise<CancelOptionProps[]>;
}

class OrderListStore implements OrderListStoreProps {
  constructor() {
    makeAutoObservable(this);
  }
  @observable
  orderList: ListDetailProps<OrderItem> = {
    list: [],
    pageNo: 1,
    pageSize: 20,
    total: 0,
  };

  @action
  async ajaxGetPurWholesaleOrderList(params: Partial<GetOrderListParams>) {
    // params.companyId= '202012171703410003916278'
    const res: any = await httpClient.post(
      "/fleshopy/management/order/v1/list",
      params
    );
    if (res?.success) {
      runInAction(() => {
        this.orderList = {
          ...res.data,
          total: getFakeTotal(params.pageSize,res?.data?.total),
        };
      });
    }
  }

  // @action
  // async ajaxGetPurProxyOrderList(params: Partial<GetOrderListParams>) {
  //   params.source = 2;
  //   params.orderType = 2;
  //   params.orderStatus = formatParams(params);
  //   const res = await httpClient.post(OrderApis.get('getOrderList'), params);
  //   if (res?.success) {
  //     runInAction(() => {
  //       this.orderList = res.data;
  //     });
  //   }
  // }

  @observable
  orderStatusCount = [];

  @action
  async ajaxGetOrderStatusCount(params: OrderStatusCountParams) {
    const res: any = await httpClient.get(
      "/fleshopy/management/order/v1/statistics/site",
      { params }
    );
    if (res?.success) {
      runInAction(() => {
        this.orderStatusCount = res.data;
      });
    }
  }

  @action
  async ajaxQueryLogisticByExpressNo(params: { expressNo: any }): Promise<any> {
    const res: any = await httpClient.get(
      "/fleshopy/cuser/order/v1/queryLogisticByExpressNo",
      { params }
    );
    return res?.success && res.data;
  }

  @action
  async ajaxQueryLogisticByOrderNo(params: { orderNo: any }): Promise<any> {
    const res: any = await httpClient.get(
      "/fleshopy/cuser/order/v1/queryLogisticByOrderNo",
      { params }
    );
    return res?.success && res.data;
  }
}

export default new OrderListStore();
