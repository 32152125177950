import axios from "axios";
import { notification } from "@fle-ui/next";
import { mainHost, DATA_CODE, ENV, yunyingHost, getLoginAdminHost } from "../config";
import { Modal } from "@fle-ui/next"
import Store from "@/config/storage";
// 该token用于测试接口

const errorMap = new Map();

const service = axios.create({
  baseURL: mainHost,
  timeout: 10000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    const token = localStorage.getItem("token");
    
    config.headers["authority_token"] = token;
    /* if (config['method'] === 'get') {
      config.params._date = new Date().getTime()
    } else if (config['method'] === 'post') {
      config.data._date = new Date().getTime()
    } */
    config.url = `${config.url}?__date=${new Date().getTime()}`;
    return config;
  },
  (err) => {
    notification.error({
      title: "发送请求失败",
      message: err,
    });
    return false;
  }
);
/*异常统一处理通过success 和 code进行区分 */
service.interceptors.response.use(
  (res) => {
    const { status, data, statusText } = res;
    return new Promise((resolve, reject) => {
      if (status < 200 || status > 300) {
        notification.error({
          message: `请求错误: ${status}`,
          description: statusText,
        });
      } else if (!data.success) {
        errorMap.set(data.code, true);

        if (data.code === DATA_CODE.LOGIN_ERROR) {
          Modal.warning({
            title: "登录失效",
            content: "点击确定重新登录。",
            okText: "确定",
            onOk: () => {
              // let url = ''
              // const from = localStorage.getItem('from')
              // if (from === 'peliten'){
              //   const loginLink = localStorage.getItem('loginLink')
              //   url = `${getLoginAdminHost(
              //     loginLink || "fle"
              //   )}/login/index?redirectUrl=${encodeURIComponent(
              //     window.location.origin + window.location.pathname
              //   )}`;
              // } else {
              //   url = yunyingHost
              // }
              // window.location.href = url
              const loginLink = localStorage.getItem('loginLink')
              let url = `${getLoginAdminHost(
                loginLink || "www"
              )}/login/index?redirectUrl=${encodeURIComponent(
                window.location.origin + window.location.pathname
              )}`;
              window.location.href = url
            },
          });
          return
        }

        notification.error({
          message: "提示",
          description: data.message,
        });
        return reject(data);
      } else {
        // 目的是如果data为null,则返回整个响应头
        return resolve(data || res);
      }
      return reject();
    });
  },
  (err) => {
    notification.error({
      title: "提示",
      message: "系统错误",
    });
    return false;
  }
);
export const getMarketBase = () => {
  let _url = "";
  switch (ENV) {
    case "dev":
      _url = `http://dev-fle.peliten.com`;
      break;
    case "test":
      _url = `https://qa-fle.peliten.com`;
      break;
    default:
      _url = `https://fle.peliten.com`;
  }
  return _url + "/login/index";
};

export default service;
