import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Radio,
  Upload,
  Select,
  RadioChangeEvent,
  message,
  Image,
} from "@fle-ui/next";
import { mainHost } from "@/config/index";
import Cookies from "js-cookie";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "../style/siteTabEditModal.scss";
import { getTinyPageList } from "../../store";
import { updateBottomConfig } from "../store";

const { Option } = Select;
const { useForm } = Form;

interface SiteTabEditModalProps {
  visible: boolean;
  editData: any;
  tinyValueList: any;
  systemValueList: any;
  onCancel: () => void;
  refresh: () => void;
}

interface TinyList {
  browseNum: number;
  browseUserNum: number;
  createTime: string;
  id: number;
  isHomepage: number;
  releasedPageName: string;
  templateDecorationId: number;
  title: string;
  useFlag: number;
}

const SiteTabEditModal: FC<SiteTabEditModalProps> = (props) => {
  const {
    visible,
    onCancel,
    refresh,
    editData,
    tinyValueList,
    systemValueList,
  } = props;
  const [linkSelectValueA, setLinkSelectValueA] = useState<string | number>(
    "classify"
  ); // 系统页面
  const [linkSelectValueB, setLinkSelectValueB] = useState<string | number>(""); // 微页面
  const [linkSelectValueC, setLinkSelectValueC] = useState<string>(""); // 自定义链接
  const [routeValue, setRouteValue] = useState<string>("系统页面"); // 跳转链接类型
  const [tabImgLoading, setTabImgLoading] = useState<boolean>(false); //tab图片上传loading
  const [form] = useForm();
  const [tinyList, setTinyList] = useState<TinyList[]>([]); // 微页面列表
  const [imageUrlList, setImageUrlList] = useState({
    normal: "",
    select: "",
    selectFileId: "",
    normalFileId: "",
  }); // tab图片地址

  /* 上传组件配置 */
  const uploadProps = {
    maxCount: 1,
    showUploadList: false,
    className: "tab-uploader",
    accept: ".jpg,.jpeg,.png,.gif",
    action: mainHost + "/basic/new/file/upload",
    headers: {
      authority_token:
        localStorage.getItem("token") || (Cookies.get("token") as any),
    },
  };

  /* 跳转链接类型 */
  const handleRouteChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setRouteValue(value);
    /* 跳转链接默认值 */
    if (value === "微页面" && !linkSelectValueB) {
      setLinkSelectValueB(tinyList[0]?.id);
    }
  };

  /* 更新tab图片 */
  const uploadButton = (
    <div>
      {tabImgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("请上传JPG、PNG、GIF格式的文件");
    }
    const isLt2M = file.size / 1024 < 100;
    if (!isLt2M) {
      message.error("请上传小于100kb的图片");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleNormalChange = (info: any) => {
    if (info.file.status === "uploading") {
      setTabImgLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setTabImgLoading(false);
      if (info.file.response.success) {
        setImageUrlList({
          ...imageUrlList,
          normal: info.file.response.data.url,
          normalFileId: info.file.response.data.id,
        });
      }
    }
  };
  const handleSelectChange = (info: any) => {
    if (info.file.status === "uploading") {
      setTabImgLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setTabImgLoading(false);
      if (info.file.response.success) {
        setImageUrlList({
          ...imageUrlList,
          select: info.file.response.data.url,
          selectFileId: info.file.response.data.id,
        });
      }
    }
  };
  /* 各类跳转链接的对应选项 */
  const renderModelSelect = (type: string) => {
    switch (type) {
      case "系统页面":
        return (
          <Select value={linkSelectValueA} onChange={setLinkSelectValueA}>
            <Option value="classify">全部分类</Option>
            <Option value="userInfo">个人中心</Option>
            <Option value="car">购物车</Option>
            <Option value="order">订单列表</Option>
          </Select>
        );
      case "微页面":
        return (
          <Select value={linkSelectValueB} onChange={setLinkSelectValueB}>
            {tinyList.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.releasedPageName}
              </Option>
            ))}
          </Select>
        );
      case "自定义链接":
        return (
          <Input
            value={linkSelectValueC}
            onChange={(e) => setLinkSelectValueC(e.target.value)}
          />
        );
    }
  };

  // 获取微页面列表
  const getTinyPageListBySiteId = async () => {
    const siteId = sessionStorage.getItem("siteId");
    if (siteId) {
      const { data, success } = (await getTinyPageList(siteId)) as any;
      if (success) {
        setTinyList(data);
      }
    }
  };

  /* 编辑提交 */
  const handleEditOk = async () => {
    const { bottomName, orderNum, pageName } = form.getFieldsValue();
    const dataObj = {
      id: editData.id,
      siteId: editData.siteId,
      isHidden: editData.isHidden,
      iconNormal: imageUrlList.normalFileId,
      iconSelect: imageUrlList.selectFileId,
      linkType: editData.linkType,
      linkName: editData.linkName,
      linkValue: editData.linkValue,
      orderNum: Number(orderNum),
      pageName,
      bottomName,
    };
    if (editData.defaultName !== "首页") {
      let { linkName, linkValue } = returnLinkObj();
      if (routeValue === "自定义链接" && linkValue == "") {
        message.error("自定义链接不可为空");
        return;
      } else if (
        routeValue === "系统页面" &&
        systemValueList.includes(linkValue) &&
        linkValue != editData.linkValue
      ) {
        message.error("系统页面不可重复设置");
        return;
      } else if (
        routeValue === "微页面" &&
        tinyValueList.includes(String(linkValue)) &&
        linkValue != editData.linkValue
      ) {
        message.error("微页面不可重复设置");
        return;
      }
      dataObj.linkType = routeValue;
      dataObj.linkName = linkName;
      dataObj.linkValue = linkValue;
    }
    const { success } = (await updateBottomConfig(dataObj)) as any;
    if (success) {
      message.success("修改成功");
      onCancel();
      refresh();
    }
  };

  /* 返回跳转链接参数 */
  const returnLinkObj = () => {
    if (routeValue === "系统页面") {
      return {
        linkValue: linkSelectValueA,
        linkName: {
          classify: "全部分类",
          userInfo: "个人中心",
          car: "购物车",
          order: "订单列表",
        }[linkSelectValueA],
      };
    } else if (routeValue === "微页面") {
      return {
        linkValue: linkSelectValueB,
        linkName: tinyList.filter((item) => item.id == linkSelectValueB)[0]
          .releasedPageName,
      };
    } else {
      return {
        linkValue: linkSelectValueC,
        linkName: "",
      };
    }
  };

  useEffect(() => {
    getTinyPageListBySiteId();
  }, []);

  // 设置初始值
  useEffect(() => {
    if (visible === true) {
      form.setFieldsValue({
        bottomName: editData.bottomName,
        pageName: editData.pageName,
        orderNum: editData.orderNum,
      });
      setRouteValue(editData.linkType);
      setImageUrlList({
        normal: editData.iconNormalAddr,
        select: editData.iconSelectAddr,
        selectFileId: editData.iconSelect,
        normalFileId: editData.iconNormal,
      });

      if (editData.linkType === "系统页面") {
        setLinkSelectValueA(editData.linkValue || "classify");
      } else if (editData.linkType === "微页面") {
        setLinkSelectValueB(Number(editData.linkValue));
      } else if (editData.linkType === "自定义链接") {
        setLinkSelectValueC(editData.linkValue);
      }
    } else {
      // 重置
      setLinkSelectValueA("classify");
      setLinkSelectValueB("");
      setLinkSelectValueC("");
    }
  }, [visible]);

  return (
    <Modal
      className="tab-edit-modal"
      title="编辑底部导航"
      visible={visible}
      onOk={handleEditOk}
      onCancel={onCancel}
    >
      <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
        <Form.Item label="底部导航名称" name="bottomName">
          <Input maxLength={5} />
        </Form.Item>
        <Form.Item label="ICON图片">
          <div className="img-edit-wrapper">
            <div className="img-edit-content">
              <div>
                <span className="img-title">常规状态图</span>
                <Upload
                  {...uploadProps}
                  listType="picture-card"
                  beforeUpload={beforeUpload}
                  onChange={handleNormalChange}
                >
                  {imageUrlList.normal ? (
                    <Image
                      preview={false}
                      width={60}
                      src={imageUrlList.normal}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              <div>
                <span className="img-title">选中状态图</span>
                <Upload
                  {...uploadProps}
                  listType="picture-card"
                  beforeUpload={beforeUpload}
                  onChange={handleSelectChange}
                >
                  {imageUrlList.select ? (
                    <Image
                      preview={false}
                      width={60}
                      src={imageUrlList.select}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
            </div>
            <div className="img-tip">
              请上传图片分辨率 100*100；大小不超过100K；支持jpg，png，gif格式
            </div>
          </div>
        </Form.Item>
        {editData.defaultName !== "首页" && (
          <Form.Item label="跳转链接" className="mt-10">
            <div className="mt-5">
              <Radio.Group onChange={handleRouteChange} value={routeValue}>
                <Radio value={"系统页面"}>系统页面</Radio>
                <Radio value={"微页面"}>微页面</Radio>
                <Radio value={"自定义链接"}>自定义链接</Radio>
              </Radio.Group>
              <div className="mt-10">{renderModelSelect(routeValue)}</div>
            </div>
          </Form.Item>
        )}
        <Form.Item label="排序" name="orderNum">
          <Input maxLength={32} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SiteTabEditModal;
