import React, { useState, useEffect,useRef } from "react";
import { PageContainer } from "@fle-ui/pro-layout";
import {
    Table,
    Row,
    Col,
    DatePicker,
    Button,
    Input,
} from "@fle-ui/next";
import "./index.less";
import {
    getRechargeList,
} from "../UserInfo/store";
import { SelectUser } from "@/components";
import moment from "moment";

const { RangePicker } = DatePicker;


const RechangeList = () => {
    const siteId = window.location.pathname.split("/")[1];
    const initSearchParams = {
        siteId: siteId,
        time: undefined,
        personId:''
    }
    const [searchParams, setSearchParams] = useState<any>({...initSearchParams})
    const [total, setTotal] = useState<number>(0);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [searchFlag, setSearchFlag] = useState<number>(0); // 获取列表数据标志位
    const [list, setList] = useState([]);


    const onPageChange = (
        page: number = 1,
        size: number = pageSize,
        type: string = "page"
    ): void => {
        if (type === "page" && size === pageSize) {
            setPageNo(page);
            setSearchFlag(searchFlag + 1);
        } else if (type === "size") {
            setPageNo(1);
            setPageSize(size);
            setSearchFlag(searchFlag + 1);
        }
    };

    const columns: any = [
        {
            title: "操作时间",
            dataIndex: "rechargeTime",
        },
        {
            title: "充值金额(元)",
            dataIndex: "amount",
            render: (text: string, record: any) => {
                return (
                    <div>{record.amount / 100}</div>
                );
            },
        },
        {
            title: "账户类型",
            dataIndex: "rechargeType",
            width: 100,
            render: (text: string, record: any) => {
                return (
                    // @ts-ignore
                    <div>{{
                        1: '现金账户',
                        2: '积分账户',
                    }[record.rechargeType]}</div>
                );
            },
        },
        {
            title: "用户手机号",
            dataIndex: "mobile",
            ellipsis: true,
            width: 150,
        },
        {
            title: "用户姓名",
            dataIndex: "personName",
            ellipsis: true,
            width: 120,
            render: (text: string, record: any) => {
                return (
                    <div>{record.personName || '--'}</div>
                );
            },
        },
        {
            title: "用户ID",
            dataIndex: "personId",
            ellipsis: true,
            width: 120,
        },
        {
            title: "操作人",
            dataIndex: "operatorUserName",
            ellipsis: true,
            width: 120,
        },
        {
            title: "备注",
            dataIndex: "memo",
            ellipsis: true
        },
    ];

    const showTime = (data: any, fmtType: string = 'YYYY-MM-DD HH:mm:ss') =>
        data ? moment(data).format(fmtType) : '';

    const getList = async () => {
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            ...searchParams
        }
        if (searchParams.time?.length) {
            params.startTime = showTime(
                searchParams.time[0],
                "YYYY-MM-DD HH:mm:ss"
            );
            params.endTime = showTime(
                new Date(searchParams.time[1]).getTime(),
                "YYYY-MM-DD HH:mm:ss"
            );
        }
        delete params.time;
        const { data = {}, success } = (await getRechargeList(params)) as any;
        const { list = [], total = 0 } = data;
        if (success) {
            setList(list);
            setTotal(total);
        }
    }

    const changeSearchParams = (obj: any) => {
        console.log('changeSearchParams',obj)
        setSearchParams({
            ...searchParams,
            ...obj,
        });
    };

    const handleSearch = () => {
        setPageNo(1);
        setSearchFlag(searchFlag + 1);
    };

    const handleReset = () => {
        selectDom.current.clear()
        setSearchParams({ ...initSearchParams });
        handleSearch();
    };

    const handleSelectUser = (value:any) => {
        setSearchParams({
            ...searchParams,
            personId:value,
        });
    }

    const selectDom = useRef(null) as any;

    useEffect(() => {
        getList()
    }, [searchFlag])

    return <PageContainer className="rechange" margin={0}>

        <div className="ww-search">
            <Row gutter={[24, 24]}>
                <Col span={8}>
                    <div className="ww-search-flex">
                        注册时间：
                        <RangePicker
                            value={searchParams.time}
                            style={{ flex: 1 }}
                            allowClear
                            onChange={(e) => changeSearchParams({ time: e })}
                            showTime={{
                                hideDisabledOptions: true,
                            }}
                            format="YYYY-MM-DD HH:mm:ss"
                            placeholder={["开始时间", "结束时间"]}
                        />
                    </div>
                </Col>
                {/* <Col span={8}>
                    <div className="ww-search-flex">
                        用户ID：
                        <Input
                            value={searchParams.personId}
                            style={{ flex: 1 }}
                            allowClear
                            onChange={(e) => changeSearchParams({ personId: e.target.value })}
                        />
                    </div>
                </Col> */}
                <Col span={8}>
                    <div className="ww-search-flex">
                        用户：
                        <SelectUser ref={selectDom} onClick={handleSelectUser}></SelectUser>
                    </div>
                </Col>
                         
                <Col span={8}>
                    <div className="ww-search-flex">
                        <Button type="primary" onClick={handleSearch}>
                            查询
                        </Button>
                        <Button style={{ marginLeft: 8 }} onClick={handleReset}>
                            重置
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>

        <Table
            dataSource={list}
            columns={columns}
            bordered
            pagination={{
                current: pageNo,
                position: ["bottomRight"],
                total,
                showQuickJumper: true,
                showSizeChanger: true,
                defaultPageSize: pageSize || 10,
                onChange: (page, size) => {
                    onPageChange(page, size, "page");
                },
                onShowSizeChange: (page, size) => {
                    onPageChange(page, size, "size");
                },
                showTotal: (total) => `共 ${total} 条记录`,
            }}
            rowKey="rechargeTime"
            loading={false}
            style={{ marginTop: "20px", backgroundColor: '#ffffff' }}
            scroll={{ x: 1160 }}
        />
    </PageContainer>
}

export default RechangeList