import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";
import moment from "moment";
import { DateType } from "./index";

// 获取访问数据折线图
export const getSiteBrowseBySiteId = (
  siteId: number,
  data?: DateType | string
) => {
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/buriedpoint/saas/site/puv/linechart",
    // url: mainHost + "/fleshopy/site/report/siteBrowse",
    data: JSON.stringify({
      siteId,
      startTime: moment()
        .startOf("day")
        .subtract(29, "days")
        .format("YYYY-MM-DD HH:mm:ss"),
      endTime: moment()
        .endOf("day")
        .subtract(0, "days")
        .format("YYYY-MM-DD HH:mm:ss"),
    }),
  });
};

// 获取成交金额折线图
export const getStrikeAmount = (siteId: number, data: DateType | string) => {
  if (typeof data === "string") {
    return;
  }
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/datacenter/site/linechart/order/sum/linechart",
    data: JSON.stringify({ siteId, ...data }),
  });
};

// 获取订单折线图
export const getLineOrderData = (siteId: number, data: DateType | string) => {
  if (typeof data === "string") {
    return;
  }
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/datacenter/site/linechart/order/count/linechart",
    data: JSON.stringify({ siteId, ...data }),
  });
};

// 获取站点模版消耗金额饼图数据
export const getStrikeAmountTopGoods = (
  siteId: number,
  dateCycle: DateType | string
) => {
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/datacenter/saas/site/order/money/top",
    data: JSON.stringify({ siteId, days: dateCycle }),
  });
};

// 获取站点模版订单饼图数据
export const getCircularOrderData = (
  siteId: number,
  dateCycle: DateType | string
) => {

  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/datacenter/saas/site/order/quantity/top",
    data: JSON.stringify({ siteId, days: dateCycle}),
  });
};

// 获取站点模版访问量饼图数据
export const getTopGoods = (
  siteId: number,
  dateCycle: DateType | string,
  sortKey?: string
) => {
  return request({
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    url: mainHost + "/buriedpoint/saas/site/puv/top",
    data: JSON.stringify({
      siteId,
      days: dateCycle,
      sortColumn: sortKey,
    }),
  });
};

// 获取站点数据汇总(如订单数目,消费金额等)
export const getDataGroupList = (siteId: string) => {
  return request({
    method: "get",
    url: mainHost + "/datacenter/site/sumAll",
    params: {
      siteId,
    },
  });
};
