import { Col, Form, Row, Input, Select, DatePicker, Space, Button } from '@fle-ui/next'
import React, { FC, useEffect, useState } from 'react'
import "./index.less"
interface Iprops {
  onSearch: () => any
  onReset: () => any
  form: any
}
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const {Option} = Select
const { RangePicker } = DatePicker;

const Index: FC<Iprops> = (props) => {
  const { onSearch, onReset, form } = props
   
  const onHandelSearch = () => {    
    onSearch()
  };

  const onHandelReset = () => {
    onReset()
  }

  return (
    <div className='record_filtration_container'>
      <Form
        form={form}
        {...layout}
        
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={`groupName`}
              label={`分组名称`}
            >
              <Input placeholder='请输入'/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Space>
              <Button type="primary" onClick={onHandelSearch}>查询</Button>
              <Button onClick={onHandelReset}>重置</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Index