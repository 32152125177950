import { FleSentryInit } from "@fle-ui/pro-error-boundary";
import { ENV } from "../src/config/index";

FleSentryInit({
  dsn: "https://db04af4f021148a9a737c1c622c519a4@sentry.fxqifu.com/11",
  enabled: false,
  debug: false,
  name: "FX-SAAS-SITE",
  release: "v2.0.0",
  environment: ENV,
});
