import React, { FC } from "react";
import { Space, Col } from "@fle-ui/next";

type Props = {
  // 删格化布局
  span: number;
};
const OrderSource: FC<Props & any> = (props: any) => {
  const { span, siteId, siteName } = props;
  return (
    <Col span={span} className="table_vertical_left">
      <Space direction={"vertical"} align={"start"}>
        <div style={{ textAlign: "left" }}>{siteName}</div>
        <div style={{ color: "#4C4C4C" }}>({siteId})</div>
      </Space>
    </Col>
  );
};

export default OrderSource;
