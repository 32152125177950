import "./manage.scss";

import React, { useEffect, FC, useState } from "react";

import { inject, observer } from "mobx-react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Button, message, Modal } from "@fle-ui/next";
import { CommonStore } from "@/store/index";
import { getSiteH5Url } from "@/config/index";

import {
  getSitePreview,
  getTinyPageList,
  addTinyPage,
  updateSetupHomePage,
  deleteTinyPage,
  getSiteUrl,
  updateSetTab,
} from "./store";

import AddTinyModal from "./AddTinyModal";

const { confirm } = Modal;

// 是否确认删除
const showConfim = (successCb: Function) =>
  confirm({
    title: `确定删除?`,
    icon: <ExclamationCircleOutlined />,
    cancelText: "我再想想",
    okText: `确认删除`,
    onOk() {
      successCb();
    },
  });

const Manage: FC = (props: any) => {
  const { siteId } = props;
  const { categoryId, appId } = CommonStore;

  const [visible, setVisible] = useState<boolean>(false);
  const [addTabsVisible, setAddTabsVisible] = useState<boolean>(false);
  const [tinyPageInfo, setTinyPageInfo] = useState<any>({});
  const [isShow, setIsShow] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<any>("");
  const [active,setActive] = useState<any>(0)

  // 提交表单
  const handleSubmitForm = async (
    e: React.MouseEvent<HTMLElement>,
    values: any
  ) => {
    const { success } = (await addTinyPage(siteId, values)) as any;
    if (success === false) {
      return;
    }
    message.success("新增微页面成功");
    getTinyPageListBySiteId();
    setVisible(false);
  };

  // 取消提交
  const handleCancelClick = () => {
    setVisible(false);
  };

  // 新增微页面按钮
  const handleAddTinyClick = () => {
    setVisible(true);
    setTinyPageInfo({ pageName: "", title: "" });
  };

  const handleSetupHomeClick = async (id: string, index: number) => {
    const { success } = (await updateSetupHomePage(siteId, id)) as any;
    if (success) {
      message.success("设置成功");
      getTinyPageListBySiteId();
    }
  };

  // 删除微页面
  const handleDeleteTinyClick = (id: string) => {
    const cb = async () => {
      const { success } = (await deleteTinyPage(siteId, id)) as any;
      if (success) {
        getTinyPageListBySiteId();
      }
    };
    showConfim(cb);
  };

  // 获取微页面列表
  const getTinyPageListBySiteId = async () => {
    setLoading(true);
    const { data, success } = (await getTinyPageList(siteId)) as any;
    if (success) {
      setTableData(data);
      setUrl(getSiteH5Url(siteId, data[0].id));
      setActive(0)
      if (data[0].isHomepage === 1) {
        setIsShow(false);
      } else {
        setIsShow(true);
      }
      setLoading(false);
    }
  };

  // 复制链接
  const handleCopyTinyUrlClick = (tinyId: number) => {
    const divElm = document.createElement("div");
    divElm.style.setProperty("opacity", "0");
    divElm.style.setProperty(" transform", "scale(0)");
    divElm.innerHTML = getSiteUrl(siteId, tinyId);
    document.body.appendChild(divElm);
    const range: Range = document.createRange();
    range.selectNode(divElm as HTMLSpanElement);
    const selection: Selection = getSelection() as Selection;
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }

    selection.addRange(range);
    document.execCommand("copy");
    message.success("复制成功");
    document.body.removeChild(divElm);
  };

  // 跳转到装修微页面
  const handleOpenTinyFimentClick = (id: number) => {
    window.open(getSitePreview(siteId, id));
  };

  // 营销云设为首页 底层是首页和展厅
  const handleSetIndex = async (id: string) => {
    const { success } = (await updateSetTab(siteId, id, 1)) as any;
    if (success) {
      message.success("设置成功");
      getTinyPageListBySiteId();
      setAddTabsVisible(false);
    }
  };

  useEffect(() => {
    getTinyPageListBySiteId();
  }, []);


  const handleTableItemClick = (item:any,index:any) => {
    if(active == index){
      return
    }
    setActive(index)
    setUrl(getSiteH5Url(siteId, item.id))
  }

  const renderTableItem = () => {
    return tableData.map((item, index) => {
      return (
        <div
          className={`store-table-item ${active == index ? 'store-table-item-active':''}`}
          key={item.id}
          onClick={() => handleTableItemClick(item,index)}
        >
          <div className="table-item-top">
            <div className="top-wrap">
              <span>
                {item.title}
                {appId !== 1 && item.isHomepage === 1 && (
                  <span style={{ paddingLeft: "6px" }}>
                    <span style={{ color: "#FFAB00" }}>（商城首页）</span>
                  </span>
                )}

                {appId === 1 && (item.useFlag === 1 || item.isHomepage === 1) && (
                  <span style={{ paddingLeft: "6px" }}>
                    <span style={{ color: "#FFAB00" }}>（首页）</span>
                  </span>
                )}
              </span>
            </div>
            <div className="handle-wrapper">
              <Button
                type="link"
                onClick={() => handleOpenTinyFimentClick(item.id)}
              >
                装修
              </Button>
              <Button
                type="link"
                onClick={() => handleCopyTinyUrlClick(item.id)}
              >
                复制链接
              </Button>
              <Button
                type="link"
                disabled={
                  item.isHomepage === 1 ||
                  item.templateDecorationId ||
                  item.useFlag
                }
                onClick={() => handleDeleteTinyClick(item.id)}
              >
                删除
              </Button>
              {appId === 1 && (
                <Button
                  type="link"
                  disabled={
                    item.useFlag === 1 ||
                    item.useFlag === 2 ||
                    item.isHomepage === 1
                  }
                  onClick={() => handleSetIndex(item.id)}
                >
                  设为首页
                </Button>
              )}
              {appId !== 1 && (
                <Button
                  type="link"
                  disabled={item.useFlag || item.isHomepage === 1}
                  onClick={() => {
                    handleSetupHomeClick(item.id, index);
                  }}
                >
                  设为首页
                </Button>
              )}
            </div>
          </div>
          <div className="table-item-bottom">
            <div className="mr32 item-line"><span className="iconfont2 icon-yemianmingcheng mr4"></span>页面名称：{item.releasedPageName}</div>
            <div className="item-line"><span className="iconfont2 icon-gengxinshijian mr4"></span>更新时间：{item.createTime}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="own-store">
      <div className="own-store-wrap">
        <div className="store-left">
          <div className="store-left-title">界面预览：</div>
          <img className="model-iframe-header" src="https://oss.elebuys.com/tmpdir/202208301630480000205724.png" alt="" />
          <div className="page-view-model-iframe">
            <iframe
              title="预览"
              src={url}
              allowFullScreen
              frameBorder="0"
              width="100%"
              height="567"
            />
          </div>
        </div>
        <div className="store-right">
          <div className="add-tiny-page-wrapper">
            <div className="add-tiny-left">商城页面</div>
            <Button type="primary" onClick={handleAddTinyClick}>
              新增微页面
            </Button>
          </div>
          <div className="store-table-wrapper">{renderTableItem()}</div>
        </div>
      </div>
      <AddTinyModal
        handleSubmitForm={handleSubmitForm}
        visible={visible}
        info={tinyPageInfo}
        handleCancelClick={handleCancelClick}
      />
    </div>
  );
};
export default inject("siteId")(observer(Manage));
