import React, { FC, useState, useEffect, useRef } from "react";
import { Button, Space, Modal, message, Carousel, Image } from "@fle-ui/next";
import "./SettingTheme.scss";
import { getSiteConfig, saveStyle, saveColor, copyJudge } from "./store";
import QRCode from "qrcode.react";

/* 主题列表 */
const themes = [
  {
    image: "https://oss.elebuys.com/tmpdir/202109231137540007509690.png",
    describe: "红色喜庆风格",
    describe2: "适用于节庆日春节",
    type: "red",
  },
  {
    image: "https://oss.elebuys.com/tmpdir/202109231138080007509691.png",
    describe: "绿色清新风格",
    describe2: "适用于端午夏季",
    type: "green",
  },
  {
    image: "https://oss.elebuys.com/tmpdir/202109231139070007509693.png",
    describe: "紫色促销风格",
    describe2: "适用于节日大促",
    type: "violet",
  },
  {
    image: "https://oss.elebuys.com/tmpdir/202109231138390007509692.png",
    describe: "蓝色安全风格",
    describe2: "适用于日常商城",
    type: "blue",
  },
  {
    image: "https://oss.elebuys.com/tmpdir/202109231139210007509694.png",
    describe: "橙色活力风格",
    describe2: "适用于通用商城",
    type: "orange",
  },
];
/* 配色方案列表 */
const styleDisplay = [
  {
    color1: "#75B455",
    color2: "#F3F9FB",
  },
  {
    color1: "#FF7518",
    color2: "#FFF9F5",
  },
  {
    color1: "#821EC4",
    color2: "#F9F7FE",
  },
  {
    color1: "#2785FF",
    color2: "#F7FAFF",
  },
  {
    color1: "#FF0C3D",
    color2: "#FFF7F9",
  },
  {
    color1: "#FF2871",
    color2: "#FFF7F9",
  },
  {
    color1: "#C8A85D",
    color2: "#FFF7F9",
  },
  {
    color1: "#FFC800",
    color2: "#FFF7F9",
  },
  {
    color1: "#2BCAAB",
    color2: "#FFF7F9",
  },
];
/* 配色方案映射 */
const styleMap = new Map([
  [
    "0",
    {
      color: "#75B455",
    },
  ],
  [
    "1",
    {
      color: "#FF7518",
    },
  ],
  [
    "2",
    {
      color: "#682DE7",
    },
  ],
  [
    "3",
    {
      color: "#2785FF",
    },
  ],
  [
    "4",
    {
      color: "#FF0C3D",
    },
  ],
  [
    "5",
    {
      color: "#FF2871",
    },
  ],
  [
    "6",
    {
      color: "#C8A85D",
    },
  ],
  [
    "7",
    {
      color: "#FFC800",
    },
  ],
  [
    "8",
    {
      color: "#2BCAAB",
    },
  ],
]);
const previewABgcMap = new Map([
  ["red", "https://oss.elebuys.com/tmpdir/202109231541250007509730.png"],
  ["green", "https://oss.elebuys.com/tmpdir/202109231541430007509731.png"],
  ["blue", "https://oss.elebuys.com/tmpdir/202109241803400007510194.png"],
  ["orange", "https://oss.elebuys.com/tmpdir/202109231542140007509733.png"],
  ["violet", "https://oss.elebuys.com/tmpdir/202109231542300007509734.png"],
]);
/* 主题预览图 */
const previewImgMap = new Map([
  [
    "red",
    [
      "https://oss.elebuys.com/tmpdir/202109231713550007509743.jpg",
      "https://oss.elebuys.com/tmpdir/202109231714110007509745.png",
      "https://oss.elebuys.com/tmpdir/202109231714300007509746.png",
      "https://oss.elebuys.com/tmpdir/202109231714430007509747.png",
      "https://oss.elebuys.com/tmpdir/202109231714570007509748.png",
      "https://oss.elebuys.com/tmpdir/202109231715110007509749.jpg",
    ],
  ],
  [
    "blue",
    [
      "https://oss.elebuys.com/tmpdir/202109231715490007509750.jpg",
      "https://oss.elebuys.com/tmpdir/202109231716030007509751.png",
      "https://oss.elebuys.com/tmpdir/202109231716190007509752.png",
      "https://oss.elebuys.com/tmpdir/202109231716340007509754.png",
      "https://oss.elebuys.com/tmpdir/202109231716480007509755.png",
      "https://oss.elebuys.com/tmpdir/202109231717030007509756.jpg",
    ],
  ],
  [
    "orange",
    [
      "https://oss.elebuys.com/tmpdir/202109231718120007509757.jpg",
      "https://oss.elebuys.com/tmpdir/202109231718250007509758.png",
      "https://oss.elebuys.com/tmpdir/202109231718390007509759.png",
      "https://oss.elebuys.com/tmpdir/202109231718560007509761.png",
      "https://oss.elebuys.com/tmpdir/202109231719140007509762.png",
      "https://oss.elebuys.com/tmpdir/202109231719270007509763.jpg",
    ],
  ],
  [
    "green",
    [
      "https://oss.elebuys.com/tmpdir/202109231720030007509766.jpg",
      "https://oss.elebuys.com/tmpdir/202109231720190007509767.png",
      "https://oss.elebuys.com/tmpdir/202109231720330007509768.png",
      "https://oss.elebuys.com/tmpdir/202109241041440007509841.png",
      "https://oss.elebuys.com/tmpdir/202109231721010007509771.png",
      "https://oss.elebuys.com/tmpdir/202109231721140007509773.jpg",
    ],
  ],
  [
    "violet",
    [
      "https://oss.elebuys.com/tmpdir/202109231721460007509775.jpg",
      "https://oss.elebuys.com/tmpdir/202109231721590007509776.png",
      "https://oss.elebuys.com/tmpdir/202109231722130007509778.png",
      "https://oss.elebuys.com/tmpdir/202109241042590007509842.png",
      "https://oss.elebuys.com/tmpdir/202109231722410007509782.png",
      "https://oss.elebuys.com/tmpdir/202109231722530007509783.jpg",
    ],
  ],
]);

export const SettingThemes: FC = () => {
  const [h5PreviewModalVisible, setH5PreviewModalVisible] =
    useState<boolean>(false); // h5预览弹窗
  const [themePreviewModalVisible, setThemePreviewModalVisible] =
    useState<boolean>(false); // 主题预览弹窗
  const [baseData, setBaseData] = useState<any>({
    siteId: 0,
  }); //初始数据
  const [activeColorIndex, setActiveColorIndex] = useState<number>(); // 当前选中配色
  const [activeColor, setActiveColor] = useState<any>("#fff"); // 当前选中配色
  const [previewImgList, setPreviewImgList] = useState<any>([]); // 各主题预览图
  const [activeTheme, setActiveTheme] = useState<string>(""); // 当前选中主题
  const carouselRef = useRef();

  /* 获取主题数据 */
  const getData = async () => {
    const { success, data } = (await getSiteConfig()) as any;
    if (success) {
      setBaseData({
        ...data,
      });
      // 设置选中配色，主题
      setActiveColorIndex(data.siteColorScheme);
      setActiveColor(styleMap.get(data.siteColorScheme)?.color);
      setActiveTheme(data.siteStyleType);
    }
  };

  // 切换主题
  const handleSaveStyle = async (type: any) => {
    const { success } = (await saveStyle({
      siteStyleType: type,
    })) as any;
    if (success) {
      // 获取最新数据
      getData();
      // 换肤成功预览弹窗
      setH5PreviewModalVisible(true);
    }
  };

  // 切换配色
  const handleColorChange = (index: number) => {
    setActiveColorIndex(index);
    setActiveColor(styleMap.get(String(index))?.color);
  };

  // 保存配色
  const saveColorChange = async () => {
    const { success } = (await saveColor({
      siteColorScheme: activeColorIndex,
    })) as any;
    if (success) {
      message.success("保存成功");
    }
  };

  // 主题预览
  const handleThemePreview = (type: string) => {
    setPreviewImgList(previewImgMap.get(type));
    setThemePreviewModalVisible(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="setting-theme-wrapper">
        <div className="setting-theme-left">
          <div className="setting-theme-left-tip">界面预览：</div>
          <div className="setting-preview-wrapper">
            <div className="setting-preview">
              <div
                className="preview-box previewA"
                style={{
                  backgroundImage: `url(${previewABgcMap.get(
                    baseData.siteStyleType
                  )})`,
                }}
              >
                <div
                  className="A-btn"
                  style={{ color: activeColor, borderColor: activeColor }}
                >
                  立即付款
                </div>
                <div className="A-num" style={{ color: activeColor }}>
                  ¥33.00
                </div>
              </div>
              <div className="preview-box previewB">
                <div className="B-btn" style={{ borderColor: activeColor }}>
                  <div
                    className="left"
                    style={{
                      color: activeColor,
                      backgroundColor: `rgba(${activeColor},.05)`,
                    }}
                  >
                    加入购物车
                  </div>
                  <div
                    className="right"
                    style={{ backgroundColor: activeColor }}
                  >
                    立即购买
                  </div>
                </div>
                <div className="B-num" style={{ backgroundColor: activeColor }}>
                  13
                </div>
                <div className="B-addr" style={{ color: activeColor }}>
                  京东
                </div>
                <div className="B-price" style={{ color: activeColor }}>
                  ¥340
                </div>
              </div>
              {/* <div className="preview-box previewC">
                <div className="C-btn" style={{ backgroundColor: activeColor }}>
                  确定
                </div>
                <div className="C-num1" style={{ color: activeColor }}>
                  10
                </div>
                <div className="C-num2" style={{ color: activeColor }}>
                  ¥38383.90
                </div>
                <div className="C-num3" style={{ color: activeColor }}>
                  ¥38383.90
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="setting-theme-right">
          <div className="setting-theme-tip mb-16">
            <span className="title">风格模版</span>
            <span className="tip">
              切换风格模版后，将对页面的ICON
              及色系同步变更；在风格基础上，可以再重新选择配色方案；
            </span>
          </div>
          <div className="setting-theme-display">
            {themes.map((item, index) => (
              <div className={`theme-item ${activeTheme == item.type ? 'theme-item-active':''}`} key={index}>
                <div className="theme-image-wrap">
                  <Image
                    className="theme-image"
                    placeholder
                    preview={false}
                    src={item.image}
                  />
                  <div className="theme-image-preview" onClick={() => handleThemePreview(item.type)}>
                    <div className="image-preview">预览</div>
                  </div>
                </div>
                {activeTheme == item.type && (
                  <div className="theme-select-img"></div>
                )}
                <div className="btn-wrapper">
                  <div className="btn-wrapper-title">{item.describe}</div>
                  <div>{item.describe2}</div>
                  <div className="btn-right">
                    <div>
                      <Space>
                        <Button
                          className="theme-btn"
                          onClick={() => {
                            Modal.confirm({
                              title: "温馨提示！",
                              width: "550px",
                              content:
                                "一键换肤后，皮肤风格立即生效 （含底部导航及装修组件的背景颜色）若有个性设置均被替换且无法还原，请确认您要换肤？",
                              okText: "确认换肤",
                              cancelText: "我再想想",
                              onOk: () => {
                                handleSaveStyle(item.type);
                              },
                            });
                          }}
                        >
                          一键换肤
                        </Button>
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="setting-theme-tip mt-20 mb-25">
            <span className="title">配色方案</span>
            <span className="tip">
              切换配色方案后，将对页面及按钮的颜色同步变更
            </span>
          </div>
          <div className="setting-display-wrapper mb-16">
            <div className="title">选择配色方案：</div>
            <div className="setting-style-display">
              {styleDisplay.map((item, index) => (
                <div
                  className={`style-item ${
                    index == activeColorIndex ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => handleColorChange(index)}
                >
                  <div
                    style={{
                      backgroundColor: item.color1,
                      borderColor: item.color1,
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="setting-style-savebtn">
          <Button type="primary" onClick={() => saveColorChange()}>
            保存
          </Button>
        </div>
      </div>
      <Modal
        title="换肤成功！"
        footer={[
          <p>
            <Button
              type="primary"
              onClick={() => setH5PreviewModalVisible(false)}
            >
              我知道了
            </Button>
          </p>,
        ]}
        width="500px"
        visible={h5PreviewModalVisible}
        onCancel={() => setH5PreviewModalVisible(false)}
      >
        <p>微信扫码预览商城</p>
        <p className="mt-15">
          <QRCode
            value={copyJudge(baseData.siteId)}
            size={200}
            fgColor="#333"
          />
        </p>
      </Modal>

      <Modal
        title="预览"
        footer={null}
        width="1000px"
        visible={themePreviewModalVisible}
        onCancel={() => setThemePreviewModalVisible(false)}
      >
        <div className="preview-modal-wrapper">
          <div
            className="iconfont icon-fangxiang arrow-left"
            onClick={() => (carouselRef.current as any).prev()}
          ></div>
          <Carousel
            dots={{ className: "preview-dot" }}
            ref={carouselRef as any}
          >
            <div className="preview-modal-content">
              {previewImgList.slice(0, 3).map((item: any, index: any) => (
                <div
                  className="preview-modal-item"
                  key={index}
                  style={{
                    backgroundImage: `url(${item})`,
                  }}
                ></div>
              ))}
            </div>
            <div className="preview-modal-content">
              {previewImgList.slice(3).map((item: any, index: any) => (
                <div
                  className="preview-modal-item"
                  key={index}
                  style={{
                    backgroundImage: `url(${item})`,
                  }}
                ></div>
              ))}
            </div>
          </Carousel>
          <div
            className="iconfont icon-fangxiang arrow-right"
            onClick={() => (carouselRef.current as any).next()}
          ></div>
        </div>
      </Modal>
    </>
  );
};
