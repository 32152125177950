import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";

// 界面设置上传图片
export const getExtInfoConfig = (data: any) => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/merchant/site/extInfoConfig",
    data: JSON.stringify(data),
  });
};
// 回显图片
export const getSiteConfig = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getSite",
    params: { siteId },
  });
};


