const routes = [
  {
    id: 2,
    key: "survey",
    path: "survey",
    name: "概况",
    status: 1,
    icon: "",
    badgeText: "",
    roleKey: "member:survey",
    router: "/survey",
    isTopMenu: true,
    children: [],
  },
  {
    id: 4,
    key: "mineCommodity",
    name: "商品",
    path: "mineCommodity",
    status: 1,
    icon: "",
    isTopMenu: true,
    badgeText: "",
    roleKey: "member:mineCommodity",
    router: "/mineCommodity",
    children: [],
  },
  {
    id: 6,
    key: "ownStore",
    path: "ownStore",
    name: "微页面",
    status: 1,
    icon: "",
    badgeText: "",
    roleKey: "member:ownStore",
    router: "/ownStore",
    children: [],
    isTopMenu: true,
  },
  {
    id: 3,
    key: "user",
    path: "user",
    name: "用户",
    status: 1,
    isTopMenu: true,
    icon: "",
    badgeText: "",
    roleKey: "member:user",
    router: "/user",
    children: [
      {
        id: 3 - 1,
        name: "用户管理",
        status: 0,
        key: "/user/userManagement",
        roleKey: "member:user:userManagement",
        router: "/user/userManagement",
        icon: "",
        children: [
          {
            id: 3 - 1 - 1,
            name: "用户信息",
            status: 0,
            key: "/user/userManagement/userInfo",
            roleKey: "member:user:userManagement: userInfo",
            router: "/user/userManagement/userinfo",
            icon: "",
          },
          {
            id: 3 - 1 - 2,
            name: "创建用户",
            status: 0,
            key: "/user/userManagement/userAdd",
            roleKey: "member:user:userManagement: userAdd",
            router: "/user/userManagement/userAdd",
            icon: "",
          },
        ],
      },
      {
        title: "用户忠诚度",
        id: 2,
        path: "userLoyalty",
        children: [
          {
            title: "等级管理",
            path: "",
            id: "2-1",
            children: [],
          },
          {
            title: "任务管理",
            id: "2-2",
            path: "taskManagement",
            children: [],
          },
        ],
      },
      {
        title: "用户运营",
        path: "userOperator",
        id: 3,
        children: [
          {
            title: "人员运营",
            path: "staffOperator",
            id: "3-1",
            children: [],
          },
          {
            title: "场景运营",
            id: "3-2",
            path: "sceneOperator",
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    key: "order",
    path: "order",
    name: "订单",
    status: 1,
    icon: "",
    badgeText: "",
    roleKey: "member:order",
    router: "/order",
    isTopMenu: true,
    children: [
      {
        id: 51,
        name: "订单管理",
        status: 0,
        key: "orderManage",
        roleKey: "member:order:orderManage",
        router: "/order/orderManage",
        isTopMenu: true,
        icon: "https://oss.elebuys.com/tmpdir/202108201620440006368900.svg",
        children: [
          {
            id: 511,
            name: "订单详情",
            status: 0,
            key: "/order/orderManage/detail",
            roleKey: "member:order:orderManage:detail",
            router: "/order/orderManage/detail",
            icon: "",
          },
        ],
      },
      {
        id: 52,
        name: "界面设置",
        status: 0,
        key: "afterSaleManage",
        roleKey: "member:order:afterSaleManage",
        router: "/order/afterSaleManage",
        isTopMenu: true,
        icon: "icon-jiemianshezhi",
      },
      
    ],
  },
  {
    id: 1,
    key: "site",
    path: "site",
    name: "设置",
    status: 1,
    icon: "",
    badgeText: "",
    roleKey: "member:site",
    router: "/site",
    isTopMenu: true,
    children: [
      {
        id: 12,
        name: "基础设置",
        status: 0,
        key: "setting",
        roleKey: "member:site:setting",
        router: "/site/setting",
        isTopMenu: true,
        icon: "https://oss.elebuys.com/tmpdir/202108201620440006368900.svg",
      },
      {
        id: 12,
        name: "界面设置",
        status: 0,
        key: "Interface",
        roleKey: "member:site:interface",
        router: "/site/interface",
        isTopMenu: true,
        icon: "icon-jiemianshezhi",
      },
      {
        id: 12,
        name: "经营设置",
        status: 0,
        key: "manage",
        roleKey: "member:site:manage",
        router: "/site/manage",
        icon: "icon-jingyingshezhi",
      },
      {
        id: 12,
        name: "协议设置",
        status: 0,
        key: "agreement",
        roleKey: "member:site:agreement",
        router: "/site/agreement",
        icon: "icon-xieyishezhi",
      },
    ],
  },
];

export default routes;
