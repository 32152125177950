import request from "@/utils/httpClient";
import { mainHost } from "@/config/index";

export const goSitebusinessConfig = (data: any) => {
  return request({
    method: "post",
    url: mainHost + "/fleshopy/merchant/site/businessConfig",
    data: JSON.stringify(data),
  });
};

export const getSitebusinessConfig = (siteId: number) => {
  return request({
    method: "get",
    url: mainHost + "/fleshopy/merchant/site/getBusinessConfig",
    params: { siteId },
  });
};
// /elebuys/member/sms-signature/sms-signature-list/by-company-id
export const goNoteList = (data: any) => {
  return request({
    method: "post",
    url:
      mainHost +
      "/elebuys/member/sms-signature/sms-signature-list/by-company-id",
    data: JSON.stringify(data),
  });
};
