import React, { useState, useEffect, FC } from "react";
import "./index.scss";
import { Form, Button, message } from "@fle-ui/next";
import { inject, observer } from "mobx-react";
import Upload from "@/components/Upload";
import { getExtInfoConfig, getSiteConfig } from "./store";

const { Item } = Form;

// 获取图片上传的回调
const handleChangeAntdImage = (
  { fileList, file }: any,
  cb: (fileList: any) => any
): void => {
  const { size, status } = file;
  const isLmt1M: boolean = size / 1024 / 1024 < 1;

  /*  if (!isLmt1M) {
    message.error('上传失败,图片大于1M')
    return
  } */
  if (status === "done" || status === "removed") {
    cb(
      fileList
        .filter((item: any) => {
          return item.response;
        })
        .map((item: any) => {
          const {
            response: { data },
          } = item;
          return data[0];
        })
    );
  }
};

const onSuccess = ({ file, fileList }: any, cb: any) => {
  cb(
    [...fileList, file.data].map((item: any) => {
      return item;
    })
  );
};

const RenderUpload = (props: any) => {
  const { upLoads } = props;
  return (
    <>
      {upLoads.map((item: any, index: any) => {
        const { title, image, handleChange, suggestText, remove } = item;
        return (
          <Item label={title} name="" key={index} style={{marginBottom:'32px'}}>
            <div className="upload-wrap">
              <Upload
                handleChange={handleChange}
                fileList={image}
                onSuccess={remove}
              />
              {suggestText}
            </div>
          </Item>
        );
      })}
    </>
  );
};

const SiteSetting: FC<any> = (props) => {
  const { siteId } = props;
  const [adminPageLogo, setAdminPageLogo] = useState<any>([]);
  const [mobileLoginPageImage, setMobileLoginPageImage] = useState<any>([]);
  useState<any>([]);

  const [submitForm] = Form.useForm();

  useEffect(() => {
    getLogoData();
  }, []);
  // 回显保存的图片
  const getLogoData = async () => {
    try {
      const res: any = await getSiteConfig(siteId);
      const { data, success } = res;
      if (success) {
        const { adminPageLogo, mobileLoginPageImage } = data;
        if (adminPageLogo) {
          setAdminPageLogo([adminPageLogo]);
        }
        if (mobileLoginPageImage) {
          setMobileLoginPageImage([mobileLoginPageImage]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 获取后台工作台logo
  const handleChangeAdminPageLogo = ({ fileList, file }: any): void => {
    handleChangeAntdImage({ fileList, file }, setAdminPageLogo);
  };

  const removeAdminPageLogo = ({ fileList, file }: any): void => {
    onSuccess({ fileList, file }, setAdminPageLogo);
  };
  // 获取手机登陆页海报
  const handleChangeMobileLoginPageImage = ({ fileList, file }: any): void => {
    handleChangeAntdImage({ fileList, file }, setMobileLoginPageImage);
  };

  const removeMobileLoginPageImage = ({ fileList, file }: any): void => {
    onSuccess({ fileList, file }, setMobileLoginPageImage);
  };

  // 进行数据组装
  const upLoads = [
    {
      title: "手机端登录页海报",
      image: mobileLoginPageImage,
      handleChange: handleChangeMobileLoginPageImage,
      remove: removeMobileLoginPageImage,
      suggestText: suggestText("1、建议尺寸 750px*400px"),
    },
    {
      title: "后台工作台logo",
      image: adminPageLogo,
      handleChange: handleChangeAdminPageLogo,
      remove: removeAdminPageLogo,
      suggestText: suggestText("1、建议尺寸 100px*100px"),
    },
  ];

  const onFinish = async (values: any) => {
    try {
      const data = {
        ...values,
        operate: 1,
        mobileLoginPageImage: mobileLoginPageImage.toString(),
        adminPageLogo: adminPageLogo.toString(),
        id: siteId,
      };
      const res:any = await getExtInfoConfig(data);
      if (res.success) {
        message.success("保存成功", 1, () => {
          window.location.reload();
        });
      } else {
        message.error("保存失败");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="site-setting">
      <div className="siteinterface-setting-header">界面设置</div>
      <Form
        name="basic"
        form={submitForm}
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
      >
        { <RenderUpload upLoads={upLoads} />}
        <Item wrapperCol={{ offset: 8, span: 12 }}>
          <Button type="primary" htmlType="submit" className="submit-form">
            保存
          </Button>
        </Item>
      </Form>
    </div>
  );
};

const suggestText = (text: string) => (
  <div className="suggest-text-wrap">
    <span>{text}</span>
    <span>2、支持JPG、PNG、GIF、JPEG格式的图片，每张图片不大于1MB</span>
  </div>
);

export default inject("siteId")(observer(SiteSetting));
