import useUrlState from "@ahooksjs/use-url-state";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Image,
} from "@fle-ui/next";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import "./OrderListForm.scss";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const { Option } = Select;
type Props = {
  onSearch: (values: any) => void;
  setPage: (values: any) => void;
  page: {
    pageNo: number;
    pageSize: number;
  };
  orderStatsList: any[];
};

type GetOrderListParams = any;

const OrderListForm = (props: Props) => {
  const { onSearch, setPage, page } = props;
  const [form] = Form.useForm();
  const [urlParams, setUrlParams] = useUrlState(
    { tab: "default" },
    { navigateMode: "replace" }
  );
  const [showSearch,setShowSearch] = useState(false)

  const formStatusMap = new Map([
    [10, [11]],
    [20, [21, 22, 23, 24]],
    [30, [31, 32, 33]],
    [40, [41, 42, 43, 44, 45]],
    [50, [51, 52]],
    [60, [61, 62, 63]],
    [90, [91, 92, 93, 94, 95, 96]],
  ]);

  const onFinish = useCallback(async () => {
    try {
      const values: Partial<GetOrderListParams> = await form.validateFields();
      if (values.date) {
        const [start, end] = values.date;
        values.applyStartTime = moment(start).format("YYYY-MM-DD 00:00:00");
        values.applyEndTime = moment(end).format("YYYY-MM-DD 23:59:59");
      }
      // 售后状态
      if (urlParams.tab !== "default") {
        values.afterSaleStatus = [urlParams.tab];
      } else {
        if (values.afterSaleStatus !== "") {
          values.afterSaleStatus = formStatusMap.get(
            Number(values.afterSaleStatus)
          );
        } else {
          values.afterSaleStatus = [];
        }
      }

      if (values.orderName == 20) {
        values.orderNo = values.afterSaleOrderNo;
        delete values.afterSaleOrderNo;
      }
      delete values.date;
      delete values.orderName;
      onSearch(values);
      setPage({ ...page, pageNo: 1 });
    } catch (e) {
      console.log(e);
    }
  }, [onSearch, form, urlParams]);

  const setForm = async () => {
    form.resetFields();
    // setUrlParams({ tab: "default" });
    await onFinish();
  };

  useEffect(() => {
    onFinish();
  }, [form, onFinish]);

  return (
    <div className="after_sale_form">
      <Form form={form} name="searchOrder">
        <div style={{ display: "flex" }}>
          <Row gutter={16} style={{ flex: 1 }}>
            <Col span={8}>
              <Input.Group compact>
                <Form.Item name="orderName" noStyle initialValue="10">
                  <Select placeholder="请选择" style={{ width: "30%" }}>
                    <Option value="10">售后单号</Option>
                    <Option value="20">订单编号</Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle name="afterSaleOrderNo">
                  <Input
                    style={{ width: "70%" }}
                    placeholder="请输入售后单号/订单编号"
                  />
                </Form.Item>
              </Input.Group>
            </Col>
            <Col span={8}>
              <Form.Item label="商品名称" name="orderGoodsName" style={{ marginBottom: 0 }}>
                <Input placeholder="请输入商品名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="申请人号码" name="applicantMobile" style={{ marginBottom: 0 }}>
                <Input placeholder="请输入申请人号码" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="售后类型"
                name="afterSaleType"
                style={{ marginBottom: 0 ,marginTop:16}}
              >
                <Select placeholder="请选择售后类型">
                  <Option value="">全部</Option>
                  <Option value="10">退货</Option>
                  <Option value="20">换货</Option>
                  <Option value="30">维修</Option>
                  <Option value="40">退款</Option>
                </Select>
              </Form.Item>
            </Col>
            {urlParams.tab === "default" && (
              <Col span={8}>
                <Form.Item
                  label="售后状态"
                  name="afterSaleStatus"
                  style={{ marginBottom: 0 ,marginTop:16}}
                >
                  <Select placeholder="请选择售后状态">
                    <Option value="">全部</Option>
                    <Option value="10">等待审核</Option>
                    <Option value="20">等待商家收货</Option>
                    <Option value="30">等待商家处理</Option>
                    <Option value="40">处理完成</Option>
                    <Option value="50">等待用户确认</Option>
                    <Option value="60">已完成</Option>
                    <Option value="90">已取消</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={8}>
              <FormItem
                name={"date"}
                label="申请时间"
                style={{ marginBottom: 0 ,marginTop:16}}
              >
                <RangePicker
                  placeholder={["请输入下单开始时间", "请输入下单结束时间"]}
                  style={{ width: "100%" }}
                />
              </FormItem>
            </Col>
          </Row>
        </div>
        <div className="order-btn">
          <Button
            type={"primary"}
            htmlType={"submit"}
            onClick={async () => {
              await onFinish();
            }}
          >
            搜索
          </Button>
          <Button onClick={setForm} style={{ marginLeft: "8px" }}>
            重置
          </Button>
          {/* <div className="search-btn" onClick={() => setShowSearch(!showSearch)}>
            高级搜索
            <span style={{ color: "#2C68FF", marginLeft: "4px" }}>
              {showSearch ? <UpOutlined /> : <DownOutlined />}
            </span>
          </div> */}
        </div>
      </Form>
    </div>
  );
};
export default observer(OrderListForm);
