import React, { FC } from "react";
import { Form, Row, Col, Select, Input, Button } from "@fle-ui/next";
import "./index.scss";

export interface UserFormType {
  form: any;
  handleSearchFormClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleAdvanceSearchFormClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
}
const UserSerarchForm: FC<UserFormType> = (props) => {
  const { Option } = Select;
  const { Item } = Form;
  const { form, handleAdvanceSearchFormClick, handleSearchFormClick } = props;
  return (
    <div style={{ height: 50, flex: 1, marginBottom: 0 }}>
      <Form
        form={form}
        name="advanced_search"
        initialValues={{ status: "" }}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Item label="状态" name="status">
              <Select>
                <Option value="">全部状态</Option>
                <Option value="1">启用</Option>
                <Option value="2"> 禁用</Option>
              </Select>
            </Item>
          </Col>
          {/* <Col span={12}>
            <Item>
              <Input.Group compact>
                <Item noStyle name="keyword">
                  <Select style={{width:'50%'}} placeholder="请选择关键字">
                    <Option value="name">姓名</Option>
                    <Option value="mobile">手机号</Option>
                  </Select>
                </Item>
                <Item noStyle name="word">
                  <Input style={{width:'50%'}} placeholder="请输入关键词" />
                </Item>
              </Input.Group>
            </Item>
          </Col> */}
          <Col span={6}>
            <Item name="keyword">
              <Select placeholder="请选择关键字">
                <Option value="name">姓名</Option>
                <Option value="mobile">手机号</Option>
              </Select>
            </Item>
          </Col>
          <Col span={6}>
            <Item name="word">
              <Input placeholder="请输入关键词" />
            </Item>
          </Col>
          <Col span={6}>
            <Button type="primary" onClick={handleSearchFormClick}>
              搜索
            </Button>
            <Button
              className="advance-btn"
              onClick={handleAdvanceSearchFormClick}
            >
              高级搜索
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserSerarchForm;
