import { makeAutoObservable, runInAction, configure, observable } from "mobx";
import { CommonTypes } from "@/types/store/common.interface";
import httpClient from "@/utils/httpClient";
import Api from '@/config/api';
const { common } = Api;


configure({ enforceActions: "observed" });

class CommonStore implements CommonTypes {
  [key: string]: any;
  constructor() {
    makeAutoObservable(this);
  }
  // 当前选中的顶部菜单的key
  public topMenu: string = "site";
  public homepageDecorationId: number = 0;
  public categoryId: number = 0;
  public appId: number = 0;
  public siteId: number = 0;
  public provinceId: number | string | null = null;
  public cityId: number | string | null = null;
  public tabs: any[] = [];

  // 历史路由
  public historyRouterList = JSON.parse(
    sessionStorage.getItem("historyRouter") || "[]"
  );
  public filterRouter = ["/site"];

  setHistoryRouter = async (key: string) => {
    const historyRouter = sessionStorage.getItem("historyRouter")
      ? JSON.parse(sessionStorage.getItem("historyRouter") || "")
      : [];

    if (
      this.filterRouter.findIndex((router) => router === key) === -1 &&
      historyRouter.findIndex((routerKey: string) => routerKey === key) === -1
    ) {
      let newHistoryRouter =
        historyRouter.findIndex((routerKey: string) => routerKey === key) > 0
          ? historyRouter
          : [
              ...historyRouter.filter((routerKey: string) => routerKey !== key),
              key,
            ];

      this.checkHistoryRouter(newHistoryRouter);
    }
  };

  deleteHistoryRouter = async (key: string) => {
    const historyRouter = sessionStorage.getItem("historyRouter")
      ? JSON.parse(sessionStorage.getItem("historyRouter") || "")
      : [];

    if (
      historyRouter.findIndex((routerKey: string) => routerKey === key) >= 0
    ) {
      let newHistoryRouter = historyRouter.filter(
        (routerKey: string) => routerKey !== key
      );
      const routerIndex = historyRouter.findIndex(
        (routerKey: string) => routerKey === key
      );

      this.checkHistoryRouter(newHistoryRouter);

      const prevRouter =
        newHistoryRouter.length >= 1
          ? newHistoryRouter[routerIndex ? routerIndex - 1 : routerIndex]
          : "/site";

      return prevRouter;
    }
  };

  checkHistoryRouter = async (router: any) => {
    const newHistoryRouter = router.length > 9 ? router.slice(1, 10) : router;

    sessionStorage.setItem("historyRouter", JSON.stringify(newHistoryRouter));
    this.historyRouterList = newHistoryRouter;
  };

  // 更新state
  updateState = (params: CommonTypes) => {
    for (let [key, value] of Object.entries(params)) {
      this[key] = value;
    }
  };

  setHomepageDecorationId(homepageDecorationId: number) {
    runInAction(() => {
      this.homepageDecorationId = homepageDecorationId;
    });
  }
  setCategoryId(categoryId: number) {
    runInAction(() => {
      this.categoryId = categoryId;
    });
  }
  setAppId(appId: number) {
    runInAction(() => {
      this.appId = appId;
    });
  }
  setSiteId(siteId: number) {
    runInAction(() => {
      this.siteId = siteId;
    });
  }
  setProvinceId(provinceId: number | string | null) {
    runInAction(() => {
      this.provinceId = provinceId;
    });
  }
  setCityId(cityId: number | string | null) {
    runInAction(() => {
      this.cityId = cityId;
    });
  }
  
  /**
   * 获取地址
   */
   getAddress = async (params: any) => {
    let res = await httpClient.post(common.get_address, params) as any;
    if (res && res.success) {
      return res.data;
    }
  };

  /**
   * 获取分类树状
   */

   categorThreeList = [];

   getCategorThreeList = async (params: any = { parentId: 0 }) => {
 
     try {
       const { data } = await httpClient.post(common.get_categor_tree_list, params) as any;
       this.categorThreeList = data || [];
       return data;
     } catch (e) {
 
     }
   };

}

export default new CommonStore();
