import { Button, Drawer, Input, message, Space, Modal } from '@fle-ui/next'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ReactSortable } from "react-sortablejs";
import { commodityNewGroupStore } from "@/store"

import "./index.scss";
import useUrlSearchParams from '@/tools/useUrlSearchParams';
import { ExclamationCircleOutlined } from '@ant-design/icons';
interface Iprops {
  setIsModalOpenGoods: any
  isModalOpenGoods: any
  list: any[]
  setList: any
  setGoodsIds: any
  goodsIds: any
  refresh: any
  setShow?: any
}

const { confirm } = Modal;


const Index = (props: Iprops, ref: any) => {
  const { isModalOpenGoods, setIsModalOpenGoods, list, setList, goodsIds, setGoodsIds, refresh, setShow } = props
  const [query] = useUrlSearchParams();

  const [filtrationList, setFiltrationList] = useState<any>([]) //过滤的数组
  const [val, setVal] = useState('') //输入框val 
  const [loading, setLoading] = useState(false)
  const onClose = () => {
    setIsModalOpenGoods(false);
  };

  const handleSetList = (newList: any, sortable: any, store: any) => {
    if (!!store.dragging) {
      setList(newList);
    }
  };


  const onDel = (item: any) => {
    confirm({
      title: '确认删除？',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const index = list.findIndex((el: any) => el.goodsId === item.goodsId)
        const arr = [...list]
        arr.splice(index, 1)
        setList([...arr])

        //过滤后的数组也需要处理
        const filtrationIndex = filtrationList.findIndex((item: any) => item.goodsId === item.goodsId)
        const filtrationArr = [...filtrationList]
        filtrationArr.splice(filtrationIndex, 1)
        setFiltrationList([...filtrationArr])

        //当删除把goodsIds也删除，也就是回显
        let goodsIdsArr: any[] = [...goodsIds]
        goodsIdsArr = goodsIdsArr.filter((el: any) => el !== item.goodsId)
        setGoodsIds([...goodsIdsArr])
      },
      onCancel() {
        console.log('Cancel');
      },
      
    });


  }

  const onUp = (el: any) => {
    const index: any = list.findIndex((item: any) => item.goodsId === el.goodsId)
    const arr = [...list]
    arr.unshift(arr.splice(index, 1)[0])
    setList([...arr])

    //过滤后的数组也需要处理
    const filtrationIndex = filtrationList.findIndex((item: any) => item.goodsId === el.goodsId)
    const filtrationArr = [...filtrationList]
    filtrationArr.unshift(filtrationArr.splice(filtrationIndex, 1)[0])
    setFiltrationList([...filtrationArr])

    message.success(`置顶成功`)
  }



  const onHandleChange = (e: any) => {
    setVal(e.target.value)
    let arr: any = [...list]
    arr = arr.filter((item: any) => item.saasPoolVo?.name.includes(e.target.value))
    setFiltrationList([...arr])
  }

  const handleOk = async () => {
    const arr = [...list]
    const params = {
      groupId: query?.id,
      list: arr.map((item: any, index: any) => ({ sort: index, goodsId: item.goodsId }))
    }
    setLoading(true)
    const res: any = await commodityNewGroupStore.addGroupGoods({ ...params })
    setLoading(false)

    if (res?.success) {
      setIsModalOpenGoods(false)
      refresh()
    }
  }

  useImperativeHandle(ref, () => ({
    handleOk,
  }));

  return (
    <Drawer title="商品预览"
      placement="right"
      onClose={onClose}
      className='drawer-container'
      visible={isModalOpenGoods}
      width={680}
      footer={[
        <Space>
          <Button key="back"
            onClick={() => setShow(true)}
          >
            添加商品
          </Button>
          <Button
            key="link"
            type="primary"
            onClick={handleOk}
            disabled={list.length === 0}
            loading={loading}
          >
            确定
          </Button>
        </Space>
      ]}

    >
      <Input placeholder='搜索商品名称' onChange={onHandleChange} />
      <div className='list-container'>
        {
          val.length === 0 && <ReactSortable
            list={list}
            setList={handleSetList}
            className="media-img-list-sort-content"
          >
            {
              list?.map((item: any, index: any) => <div className='item' key={index}>
                <div className='item-l'>
                  <div className='icon'>
                    <img src="https://oss.elebuys.com/tmpdir/202211210849550000216588.png" alt="" />
                  </div>
                  <div className='info'>
                    <img className='img' src={item.mainImage || item?.saasPoolVo?.mainImage} alt="" />
                    <div className='good'>
                      <p className='name'>{item.name || item?.saasPoolVo?.name}</p>
                      <p className='sales-price'>市场价：¥{((item.marketPriceMax / 100) || (item?.saasPoolVo?.marketPriceMax / 100).toFixed(2))}</p>
                      <p className='market-price'>销售价：¥{((item.sitePriceMax / 100) || (item?.saasPoolVo?.sitePriceMax / 100)).toFixed(2)}</p>
                    </div>
                  </div>
                </div>
                <div className='item-r'>
                  <Space>
                    <a onClick={() => onUp(item)}>置顶</a>
                    <a onClick={() => onDel(item)}>删除</a>
                  </Space>
                </div>
              </div>)
            }
          </ReactSortable>
        }
        {
          val.length > 0 &&
          filtrationList?.map((item: any, index: any) => <div className='item' key={index}>
            <div className='item-l'>
              {/* <div className='icon'>
                <img src="https://oss.elebuys.com/tmpdir/202211210849550000216588.png" alt="" />
              </div> */}
              <div className='info'>
                <img className='img' src={item.mainImage || item?.saasPoolVo?.mainImage} alt="" />

                <div className='good'>
                  <p className='name'>{item.name || item?.saasPoolVo?.name}</p>
                  <p className='sales-price'>市场价：¥{((item.marketPriceMax / 100) || (item?.saasPoolVo?.marketPriceMax / 100).toFixed(2))}</p>
                  <p className='market-price'>销售价：¥{((item.sitePriceMax / 100) || (item?.saasPoolVo?.sitePriceMax / 100)).toFixed(2)}</p>

                </div>
              </div>
            </div>
            <div className='item-r'>
              <Space>
                <a onClick={() => onUp(item)}>置顶</a>
                <a onClick={() => onDel(item)}>删除</a>
              </Space>
            </div>
          </div>)

        }

      </div>
    </Drawer>
  )
}

export default forwardRef(Index)