import React, { FC, useEffect } from 'react'

import { AddForm } from './store'

import {  Modal, Row, Col, Form, Input, Radio } from "@fle-ui/next"
const { Item, useForm } = Form
const { Group } = Radio
export interface AddGroupModalProps {
  visible: boolean,
  handleSubmitForm?: (e: React.MouseEvent<HTMLElement>, values: any) => void
  handleCancelClick?: React.MouseEventHandler<HTMLElement>,
  okText?: string,
  title?: string,
  addFormContent?: AddForm,
  groupInfo: any
  
}

const AddGroupModal:FC<AddGroupModalProps> = (props) => {

  const { 
    handleSubmitForm, 
    visible, 
    handleCancelClick, 
    okText, 
    title, 
    addFormContent, 
    groupInfo 
  } = props

  const [form] = useForm()

  // 提交表单
  const onCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (handleCancelClick) {
      handleCancelClick(e)
    }
  }

  // 取消提交表单
  const onOk = (e: React.MouseEvent<HTMLElement>) => {
    form.validateFields().then((values: any) => {
      if (handleSubmitForm) {
        handleSubmitForm(e, {...groupInfo , ...values })
      }
    })
  }

  useEffect(() => {
    // 情况数据
    if (visible === true) {
      form.setFieldsValue(addFormContent)
      if (groupInfo === null) {
        form.resetFields(['groupName'])
      }
    }
  }, [visible, form])
  
  return (
    <>
      <Modal
        title={title}
        visible={visible}
        okText={okText}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          labelCol={{ span: 8 }}
          wrapperCol = {{ span: 14 }}>
          {/* <Row gutter={24}>
          <Col span={24}>
              <Item
                label="商品来源"
                name="source">
                  <Group>
                    <Radio value='fx' disabled={title === '编辑分组'}>精选商品</Radio>
                    <Radio value='jd' disabled={title === '编辑分组'}>京东商品</Radio>
                    <Radio value='self' disabled={title === '编辑分组'}>自有商品</Radio>
                  </Group>
              </Item>
              </Col>
          </Row> */}
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="分组名称"
                rules={
                  [{ required: true, message: '分组名称必填' }, 
                  {max: 10, message: '最多不能超过10个字符'}]
                }
                name="groupName">
                <Input  placeholder='请输入分组名称'/>
              </Item>
              </Col>
          </Row>
          </Form>
      </Modal>
    </>
  )
}

export default AddGroupModal