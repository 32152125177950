import React, { FC,useState, useEffect } from 'react'

import { 
  Form, 
  Input,
  Button, 
  Modal, 
  Row,
  Col, 
  Radio, 
  DatePicker,  
  Cascader } from "@fle-ui/next"

import { EditUserInfoFormPropsType } from './editUserInfoForm.interface'

import { getUserGroups } from './store'

import { getPathByKey } from '@/utils/common'

const {Item} = Form
const EditUserInfoForm: FC<EditUserInfoFormPropsType> = (props) => {
  const {
    title,
    visible,
    onCancel,
    onOk,
    siteId,
    groupId,
    form

  } = props
  const [disabled, setDisabled] = useState<boolean>(true)

  const [options, setOptions] = useState<any[]>([])
      // 渲染组织树
      const treeMap = (tree: any): any => {
        if (!Array.isArray(tree)) {
          return 
        }
        return tree.map((item: any) => {
          const { id, name, haveSub, tree } = item
          return {
            ...item,
          key: id,
          value: id,
          label: name,
          children: treeMap(tree),
          title: name,
          isLeaf: haveSub === 1 ? false : true
          }
        })
      }
      // 渲染组织树
      const getUserGroupsBytreeId = async (siteId: number) => {
        const { data, success } = await getUserGroups(siteId) as any
        if (success) {
          return treeMap(data)
        }
      } 

      // 是否需要重置密码
      const handleChangeResetPassword = (e: any) => {
        const value = e.target.value
        if (value === 1) {
          setDisabled(false)
          form.setFieldsValue({
            credential: '',
            
          })
        } else {
          setDisabled(true)
          form.setFieldsValue({
            resetPassword: value,
            credential: '123456'
          })
        }
      }
    useEffect(() => {
      getUserGroupsBytreeId(siteId).then(data=> {
        setOptions(data)
      })
    },[])

  useEffect(()=> {
    setDisabled(true)
    form.setFieldsValue({
      groupName: getPathByKey( groupId, 'id', options),
    })
  },[groupId, form, options])

  useEffect(()=> {
    setDisabled(true)
  },[visible])

  return (
    <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          labelCol={{ span: 8 }}
          wrapperCol = {{ span: 14 }}>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户手机号"
                rules={[{required: true, message: '手机号码必填'}, 
                  {
                    validator:(rule: any, value: any, callback: any) => {
                      if (/^1[3456789]\d{9}$/.test(value)) {
                        callback()
                      } else {
                        callback(new Error('手机号码格式不正确'))
                      }
                    }
                  }
              ]}
                name="mobile">
                <Input  placeholder='请输入用户手机号'/>
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户姓名"
                rules={
                  [{ required: true, message: '用户姓名不能为空' }, 
                  {max: 20, message: '不能超过20个字符'}]
                }
                name="name">
                <Input  placeholder='请输入用户姓名'/>
              </Item>
              </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户分组"
                rules={
                  [{ required: true, message: '用户分组必填' }]
                }
                name="groupName">
                  <Cascader options={options}   changeOnSelect placeholder='请选择用户分组' allowClear={false}/>
              </Item>
              </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户性别"
                name="gender">
                  <Radio.Group>
                    <Radio value={0}>保密</Radio>
                    <Radio value={1}>男</Radio>
                    <Radio value={2}>女</Radio>
                  </Radio.Group>
              </Item>
              </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="用户生日"
                name="birthday">
                <DatePicker  placeholder='请选择生日' />
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="新密码"
                validateTrigger={['onSubmit']}
                rules={[
                  {
                    required: true,
                    message: '用户密码必填'
                  },
                  {
                    validator: (rule: any, value: string, callback: any) => {
                      if (/\w{6,20}/.test(value)) {
                        callback()
                      } else {
                        callback(new Error('密码须为6-20位字母或数字'))
                      }
                    }
                  }
                ]}
                name="credential">
                  <Input.Password  disabled={disabled}/>
              </Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
            <Item
                label="重置密码"
                name="resetPassword">
                  <Radio.Group onChange={handleChangeResetPassword}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
              </Item>
            </Col>
          </Row>
          <Row gutter={24} >
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Item
                label="账户状态"
                name="status">
                  <Radio.Group>
                    <Radio value={1}>启用</Radio>
                    <Radio value={2}>禁用</Radio>
                  </Radio.Group>
              </Item>
              </Col>
          </Row>
          </Form>
      </Modal>

  )

}

export default EditUserInfoForm