import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal } from "@fle-ui/next";
import "./index.scss";

const SuccessModal = (props: any) => {
  const { handleOk, handleCancel, title, visible, loadTotal } = props;

  return (
    <div className="detail">
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={"继续导入"}
        okText={"查看详情"}
        className="detail"
      >
        <div className="tit_modal">
          <img
            src="https://oss.elebuys.com/tmpdir/202107222022190003982009.png"
            alt=""
          />
          <h4>表格导入成功</h4>
          <div>
            <p>
              表格共包含 {loadTotal} 条数据，成功导入 {loadTotal}{" "}
              条数据，创建结果请查看详情
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(SuccessModal);
