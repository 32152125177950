import React, { useEffect, useState } from 'react'
import ProLayout, { PageContainer } from '@fle-ui/pro-layout'
import "./index.scss"
import { Button, Form, message, Modal, Space, Table } from '@fle-ui/next'
import Filtration from "../components/filtration"
import { unstable_batchedUpdates } from 'react-dom'
import { commodityNewGroupStore } from "@/store"
import { useHistory } from 'react-router-dom'
import GroupingGoodsListModal from "../components/groupingGoodsListModal"
import CreateGroup from "../components/createGroup"
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons'
const { confirm } = Modal;
const index = () => {
  const [search, setSearch] = useState<any>({})
  const [form] = Form.useForm()
  const [datasource, setDataSource] = useState([])

  const [isGroupingModal, setIsGroupingModal] = useState(false) //详情分组里的商品弹窗

  const [total, setTotal] = useState<number>(0);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [groupId, setGroupId] = useState<number>(0) //点击详情用到了
  const [createGroupModal, setCreateGroupModal] = useState<boolean>(false) //点击新建 只适用于创建分组信息的弹窗显示


  const getList = async () => {
    try {
      const params = {
        siteId: Number(sessionStorage.getItem('siteId')),
        ...search,
        pageNo,
        pageSize,
      }
      setLoading(true)
      const res = await commodityNewGroupStore.getList({ ...params })
      setLoading(false)
      setDataSource(res.data.list)
      setTotal(res.data.total)
    } catch (err) {
      console.warn(err)
      setLoading(false)
    }

  }

  useEffect(() => {
    getList();
  }, [pageSize, pageNo, search]);

  const columns = [
    {
      title: '分组名称',
      dataIndex: 'groupName',
      key: 'groupName',
      fixed: 'left',
    },
    {
      title: '商品数量',
      dataIndex: 'goodsCount',
      key: 'goodsCount',
      render: (_: any, record: any) => <a onClick={() => onGoDetails(record)}>{record.goodsCount || 0}</a>

    },
    {
      title: '分组介绍',
      dataIndex: 'groupIntroduce',
      key: 'groupIntroduce',
    },
    {
      title: '更新时间',
      dataIndex: 'modifyTime',
      key: 'modifyTime',
      render: (_: any, record: any) => moment(record.modifyTime).format('YYYY-MM-DD HH:mm:ss')

    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (_: any, record: any) => moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      render: (_: any, record: any) => <Space>
        <a onClick={() => onGoDetails(record)}>详情</a>
        <a onClick={() => history.push(`/mineCommodity/newGroup/edit?id=${record.groupId}`)}>编辑</a>
        <a onClick={() => onDel(record.groupId)}>删除</a>
      </Space>


    },
  ];

  const onGoDetails = (record: any) => {
    setGroupId(record.groupId)
    setIsGroupingModal(true)
  }

  const onDel = async (id: string | number) => {
    confirm({
      title: '确认删除该商品分组吗？',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const res: any = await commodityNewGroupStore.deleteGroup({ groupId: id })
        if (res?.success) {
          message.success('删除成功')
          onReset()
        }
      },
    });
  }

  const onSearch = () => {
    const data = form.getFieldsValue();
    unstable_batchedUpdates(() => {
      setSearch(data)
      setPageNo(1);
    })
  }

  const onReset = () => {
    form.resetFields();
    const data = form.getFieldsValue();
    unstable_batchedUpdates(() => {
      setSearch(data)
      setPageNo(1)
      sessionStorage.removeItem('supplierInfo')
    })
  }

  // pageSize 变化的回调
  const onChange = (page: number) => {
    setPageNo(page);
  };

  const onShowSizeChange = (_: number, size: number): void => {
    setPageSize(size)
  };

  const showTotal = () => {
    return `共有${total}条记录，当前第${pageNo}页`;
  };

  return (
    <PageContainer className="mine-commodiy-container">

      <div className='commodity-new-group'>
        <div className='filtration-con'>
          <Filtration onSearch={onSearch} onReset={onReset} form={form} />
        </div>
        <div className='btn-con'>
            <Button type="primary" onClick={() => setCreateGroupModal(true)}>新增分组</Button>
          </div>
        <div className='table-con'>
          <Table
            dataSource={datasource}
            columns={columns as any}
            bordered
            loading={loading}
            pagination={
              {
                showQuickJumper: true,
                showSizeChanger: true,
                total: total,
                current: pageNo,
                pageSize: pageSize,
                onChange,
                onShowSizeChange,
                showTotal,
              }
            }
            rowKey="groupId"
            // rowSelection={rowSelection}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
      <GroupingGoodsListModal setIsGroupingModal={setIsGroupingModal} isGroupingModal={isGroupingModal} groupId={groupId} />
      <CreateGroup createGroupModal={createGroupModal} setCreateGroupModal={setCreateGroupModal} onReset={onReset} />
    </PageContainer>
  )
}

export default index