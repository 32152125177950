import React, { useState, useEffect, useCallback } from "react";
import E from "wangeditor";
import { mainHost as MAIN_HOST } from "@/config";
import { getExtInfoConfig, getProtocol } from "./store";
import { message, Button, Modal } from "@fle-ui/next";
import Cookies from "js-cookie";
import "./wangEditor.scss";
const WangEditor = (props: any) => {
  const { siteId } = props;
  const [htmlCon, setHtmlCon] = useState<string>("");
  const [dataCount, setDataCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  let editor: any = null;

  useEffect(() => {
    editor = new E("#wang_editor");
    editor.config.height = 560; //高度
    editor.config.zIndex = 100; //富文本z-index
    editor.config.uploadImgMaxSize = 1 * 1024 * 1024; // 上传图片大小1M
    editor.config.uploadImgServer = MAIN_HOST + "/basic/new/file/upload"; // 路径
    editor.config.uploadImgMaxLength = 1; // 限制一次最多上传 1 张图片
    editor.config.customUploadImg = function (files: any, insert: any) {
      // files 是 input 中选中的文件列表
      if (files[0]) {
        const formData = new window.FormData();
        formData.append("file", files[0], "cover.jpg");
        fetch(MAIN_HOST + "/basic/new/file/upload", {
          method: "POST",
          headers: {
            authority_token:
              Cookies.get("token") ||
              (window.localStorage.getItem("token") as string),
          },
          body: formData,
        })
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            const data = res.data;
            if (data) {
              // 上传代码返回结果之后，将图片插入到编辑器中
              insert(data.url);
            }
          });
      } else {
        message.info("请选择要上传的图片");
      }
    }; //上传图片函数
    editor.config.menus = [
      "head", // 标题
      "bold", // 粗体
      "fontSize", // 字号
      "fontName", // 字体
      "italic", // 斜体
      "underline", // 下划线
      "strikeThrough", // 删除线
      "foreColor", // 文字颜色
      "backColor", // 背景颜色
      "link", // 插入链接
      "list", // 列表
      "justify", // 对齐方式
      "quote", // 引用
      "emoticon", // 表情
      "image", // 插入图片
      "table", // 表格
      "code", // 插入代码
      "undo", // 撤销
      "redo", // 重复
    ]; //菜单配置

    editor.config.onchange = function (html: any, text: any) {
      // html 即变化之后的内容
      setHtmlCon(html);
      setDataCount(editor.txt.text().length);
    } as any;
    editor.config.onchangeTimeout = 1000; // 单位 ms
    /**配置完成创建富文本编辑器**/
    editor.create();

    getProtocolData();
    return () => {
      // 组件销毁时销毁编辑器
      editor.destroy();
    };
  }, []);

  useCallback(() => getExtInfoConfig, []);

  // 保存富文本内容
  const saveExtInfoConfig = async () => {
    try {
      setLoading(true);
      if (dataCount < 1000) {
        Modal.error({
          title: "保存失败",
          content: (
            <>
              <div>用户协议不得少于1000字！</div>
              <div>请明确阐述您提供的服务条款及明细、客户的权利及义务。</div>
            </>
          ),
        });
        setLoading(false);
      } else if (dataCount > 10000) {
        Modal.error({
          title: "保存失败",
          content: (
            <>
              <div>用户协议不得大于10000字！</div>
            </>
          ),
        });
        setLoading(false);
      } else {
        const res:any = await getExtInfoConfig({
          id: siteId,
          operate: 2,
          protocolType: 2,
          protocol: htmlCon,
        });
        if (res.success) {
          message.success("保存成功");
        } else {
          message.error("保存失败");
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      message.error("保存失败");
      setLoading(false);
    }
  };
  // 回显富文本内容
  const getProtocolData = async () => {
    try {
      const res:any = await getProtocol(siteId);
      if (res.success) {
        editor.txt.html(res.data.protocol);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="wang_wrap">
      <div id="wang_editor" data-count={dataCount + "/10000"}></div>
      <div className="save_wrap">
        <Button type="primary" onClick={saveExtInfoConfig} loading={loading}>
          保存
        </Button>
      </div>
    </div>
  );
};

export default WangEditor;
