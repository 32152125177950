import { makeAutoObservable, configure, runInAction } from 'mobx'
import Request from '@/utils/httpClient'
// import { objClone } from '@/utils/common'
import { SiteTypes } from '@/types/store/site.interface';
configure({ enforceActions: 'observed' })

class SiteStore implements SiteTypes {
  [key: string]: any
  constructor() {
    makeAutoObservable(this);
  }

  public storeName: string = 'site'

  // 更新state
  updateState = (params: SiteTypes) => {
    for (let [key, value] of Object.entries(params)) {
      [key] = value
    }
  }
}

export default new SiteStore()
