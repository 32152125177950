import { Col, Row } from '@fle-ui/next'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import "./index.scss"
import InfiniteScroll from 'react-infinite-scroller';
import { commodityNewGroupStore } from "@/store"
import useUrlSearchParams from '@/tools/useUrlSearchParams';


const Index = (props: any, ref: any) => {
  const { mobileImg } = props
  const [query] = useUrlSearchParams();
  const [InfiniteScrollKey, setInfiniteScrollKey] = useState(() => Math.random().toString(36));

  const [state, setStata] = useState<any>({
    hasMore: true,
    total: 1000,
    data: []
  });

  const getMore = async (page = 1) => {
    try {
      const params = {
        pageNo: page,
        groupId: Number(query?.id),
        pageSize: 10,
        siteId: Number(sessionStorage.getItem('siteId'))
      };

      const res = await commodityNewGroupStore.getGroupGoodsList(params)
      const list = page === 1 ? res.data.list : [...state.data, ...res.data.list]
      // 去除删除/下架等状态等商品
      const targetList = list.filter((item:any) => item.saasPoolVo)
      setStata({
        data: targetList,
        hasMore: list.length < res.data.total,
        total: res.data.total
      });
    } catch (err) {
      console.warn(err)
    }

  };

  useEffect(() => {
    if (query?.id) {
      getMore();
    }
  }, [query]);


  const refresh = () => {
    setInfiniteScrollKey(Math.random().toString(36));
    getMore();
  };
  useImperativeHandle(ref, () => ({
    refresh,
  }));

  return (
    <div className='moblie-prview-container'>
     
      <div className='con'>
        <InfiniteScroll
          initialLoad={false} // 不让它进入直接加载
          pageStart={1} // 设置初始化请求的页数
          loadMore={getMore}
          hasMore={state.hasMore}
          threshold={10}
          useWindow={false}
          loader={<div className="loader" key={0}>Loading ...</div>}
          key={InfiniteScrollKey}
        >
           <div className="banner">
            <img src={mobileImg || undefined} alt="" />
          </div>
          <div className='scroll-con'>
            {
              state.data.map((item: any) =>
                <div className='item' key={item.saasPoolVo?.goodsId}>
                  <div className='item-con'>
                  <img src={item.saasPoolVo?.mainImage} alt="" width={105} height={105} />
                  <div className='info'>
                    <p className='name'>{item.saasPoolVo?.brandName}</p>
                    <p className='price-color'>¥{(item.saasPoolVo?.marketPriceMax / 100).toFixed(2)}</p>
                  </div>

                  </div>
                </div>)
            }
          </div>
        </InfiniteScroll>
      </div>

    </div>
  )
}

export default forwardRef(Index)