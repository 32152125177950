import React, { FC } from "react";
import { Col, Statistic } from "@fle-ui/next";

type Props = {
  // 栅格
  span: number;
  // 运费
  fright: number;
  // 金额
  mount: number;
  // 售后单状态
  afterSaleType: string | number;
};
const OrderPrice: FC<Props> = (props) => {
  const { span, fright, mount, afterSaleType } = props;
  return (
    <Col span={span} className="table_vertical_left" style={{ color: "#4C4C4C !important" }}>
      {afterSaleType == 10 || afterSaleType == 40 ? (
        <>
          <Statistic
            valueStyle={{
              fontSize: 16,
            }}
            value={mount / 100}
            precision={2}
            prefix={"￥"}
          />
          <span style={{ color: "#4C4C4C" }}>含运费：￥ {(fright / 100).toFixed(2)}</span>
        </>
      ) : (
        <>---</>
      )}
    </Col>
  );
};

export default OrderPrice;
