import React, { useState, forwardRef, useEffect } from "react";
import { Table, Button, Tooltip, message, Modal, Popover } from "@fle-ui/next";
import "./index.scss";
import { observer } from "mobx-react";
import AlterPrice from "@/components/alterPrice";
import AlterPriceMarket from "@/components/alterPrice/marketAlter";
import { GoodsChoose } from "@/components/chooseGoodsExtra";
import {
  QuestionCircleFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ShopTypeStore, fitmentStore } from "@/store/index";
import { formatPrice } from "@/utils/common";
import { CommonStore } from "@/store/index";
import { toJS } from "mobx";
import ChooseMallGoods from '@fle-ui/pro-choose-mall-goods'
import { ENV } from "@/config/index"

const renderMaxOrMinPrice = (
  minPrice: number,
  maxPrice: number,
  split: string = "¥"
): JSX.Element => {
  if (minPrice && maxPrice) {
    if (minPrice === maxPrice) {
      return (
        <span>
          {split}
          {maxPrice.toFixed(2)}
        </span>
      );
    }
    return (
      <span>
        {split}
        {minPrice.toFixed(2)}~{split}
        {maxPrice.toFixed(2)}
      </span>
    );
  } else if (minPrice) {
    return (
      <span>
        {split}
        {minPrice.toFixed(2)}
      </span>
    );
  } else if (maxPrice) {
    return (
      <span>
        {split}
        {maxPrice.toFixed(2)}
      </span>
    );
  } else {
    if (!split) {
      return <span>0</span>;
    }
    return <span>0</span>;
  }
};
const TableList = (props: any, ref: any) => {
  const { handleChangeGoodsSearchStatus } = fitmentStore;

  const [record, setRecord] = useState({});
  const provinceId = toJS(CommonStore).provinceId;
  const cityId = toJS(CommonStore).cityId;
  const appId = toJS(CommonStore).appId;
  const {
    tableData,
    onChange,
    showeEditGroupModal,
    pagination,
    handleRemoveGoods,
    handleSoldOutGoods,
    poolSaasStatus,
    handleOk,
    siteId,
    showModal,
    rows,
    confirmLoading,
    loading,
    visiblePrice,
    visiblePriceMarket,
    handleCancelPrice,
    disabled,
    thirdTypeRow,
    handleSearchFormClick,
    handleSubmitClick,
  } = props;
  // 渲染表格
  const typeStatus = ShopTypeStore.getTypeStatus();

  const [goodsIsOpend, setGoodsIsOpend] = useState(false);

  const columns: any = [
    {
      title: "商品",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => {
        const { name, goodsId, mainImage, thirdSku } = record;
        return (
          <div className="shop-wrap">
            <div className="shop-image-wrap">
              <img alt="" src={mainImage} />
            </div>
            <div className="text-wrap">
              <div className="text">
                {/* <span onClick={() => handleClick(goodsId)}>{name}</span> */}
                <span>{name}</span>
              </div>
              <span>
                {thirdSku === null
                  ? `商品ID:${goodsId}`
                  : `商品sku:${thirdSku}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "集采市场价",
      width: 120,
      dataIndex: "purchaseMarketPriceMin",
      sorter: true,
      key: "purchaseMarketPriceMin",
      render: (text: any, record: any) => {
        if (
          record?.purchaseInfo?.marketPriceMin ===
          record?.purchaseInfo?.marketPriceMax
        ) {
          return (
            <div>
              {record?.purchaseInfo?.marketPriceMin && (
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.marketPriceMin)}
                </div>
              )}
            </div>
          );
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.purchaseInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.purchaseInfo?.marketPriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "代发市场价",
      width: 120,
      dataIndex: "onceMarketPriceMin",
      sorter: true,
      key: "onceMarketPriceMin",
      render: (text: any, record: any) => {
        if (
          record?.onceInfo?.marketPriceMin === record?.onceInfo?.marketPriceMax
        ) {
          return (
            <div>
              {record?.onceInfo?.marketPriceMin && (
                <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMin)}</div>
              )}
            </div>
          );
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "集采价",
      width: 120,
      dataIndex: "purchaseSalePriceMin",
      key: "purchaseSalePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isPurchase === 1) {
          if (record?.purchaseInfo?.salesType === 2) {
            if (
              record?.purchaseInfo?.salePriceMin ===
              record?.purchaseInfo?.salePriceMax
            ) {
              return (
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMin)}
                </div>
              );
            }
            return (
              <>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMin)}
                </div>
                <div>~</div>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMax)}
                </div>
              </>
            );
          }

          if (record?.purchaseInfo?.salesType === 1) {
            return record?.purchaseInfo?.stairList.map(
              (item: any, index: any) => {
                return (
                  <div key={index}>
                    {`¥${formatPrice(item.salePrice)}（${
                      index === record?.purchaseInfo?.stairList?.length - 1
                        ? `≥${item?.minNum}`
                        : `${item.minNum}-${item.maxNum}`
                    })`}
                  </div>
                );
              }
            );
          }

          return null;
        }

        return null;
      },
    },
    {
      title: "代发价",
      width: 120,
      dataIndex: "onceSalePriceMin",
      key: "onceSalePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.salePriceMin === record?.onceInfo?.salePriceMax
          ) {
            return (
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMin)}</div>
            );
          }
          return (
            <>
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMin)}</div>
              <div>~</div>
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMax)}</div>
            </>
          );
        }

        return null;
      },
    },
    {
      title: "集采销售价",
      width: 120,
      dataIndex: "purchaseSitePriceMin",
      key: "purchaseSitePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isPurchase === 1) {
          if (record?.purchaseInfo?.salesType === 2) {
            if (
              record?.purchaseInfo?.sitePriceMin ===
              record?.purchaseInfo?.sitePriceMax
            ) {
              return (
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMin)}
                </div>
              );
            }
            return (
              <>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMin)}
                </div>
                <div>~</div>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMax)}
                </div>
              </>
            );
          }

          if (record?.purchaseInfo?.salesType === 1) {
            return record?.purchaseInfo?.stairList.map(
              (item: any, index: any) => {
                return (
                  <div key={index}>
                    {`¥${formatPrice(item.sitePrice)}（${
                      index === record?.purchaseInfo?.stairList?.length - 1
                        ? `≥${item?.minNum}`
                        : `${item.minNum}-${item.maxNum}`
                    })`}
                  </div>
                );
              }
            );
          }

          return null;
        }

        return null;
      },
    },
    {
      title: "代发销售价",
      width: 120,
      dataIndex: "onceSitePriceMin",
      key: "onceSitePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.sitePriceMin === record?.onceInfo?.sitePriceMax
          ) {
            return (
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMin)}</div>
            );
          }
          return (
            <>
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMin)}</div>
              <div>~</div>
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMax)}</div>
            </>
          );
        }
        return null;
      },
    },
    {
      title: () => {
        return (
          <div>
            集采利润率(%){" "}
            <Tooltip
              placement="topLeft"
              title="集采利润率=（集采销售价-集采价）/集采销售价*100%"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "purchaseSiteProfitMin",
      width: 180,
      key: "purchaseSiteProfitMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (
          record?.purchaseInfo?.siteProfitMin ===
          record?.purchaseInfo?.siteProfitMax
        ) {
          return (
            <div>
              {Number.isFinite(record?.purchaseInfo?.siteProfitMax)
                ? `${record?.purchaseInfo?.siteProfitMax}%`
                : ""}
            </div>
          );
        }

        return (
          <>
            <div>{"¥" + record?.purchaseInfo?.siteProfitMin}%</div>
            <div>~</div>
            <div>{"¥" + record?.purchaseInfo?.siteProfitMax}%</div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            代发利润率(%){" "}
            <Tooltip
              placement="topLeft"
              title="代发利润率=（代发销售价-代发价）/代发销售价*100%"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "onceSiteProfitMin",
      width: 180,
      key: "onceSiteProfitMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.siteProfitMin === record?.onceInfo?.siteProfitMax
          ) {
            return (
              <div>
                {Number.isFinite(record?.onceInfo?.siteProfitMin)
                  ? `${record?.onceInfo?.siteProfitMin}%`
                  : ""}
              </div>
            );
          }

          return (
            <>
              <div>{record?.onceInfo?.siteProfitMin}%</div>
              <div>~</div>
              <div>{record?.onceInfo?.siteProfitMax}%</div>
            </>
          );
        }

        return null;
      },
    },
    {
      title: "库存",
      width: 80,
      dataIndex: "stock",
    },
    {
      title: "可售状态",
      width: 70,
      dataIndex: "poolSaasAvailableStatus",
      fixed: "right",
      render: (text: any, record: any) => poolSaasAvailableStatus(text, record),
    },
    {
      title: "操作",
      width: 120,
      algin: "center",
      fixed: "right",
      render: (text: any, record: any) => {
        const { poolSaasStatus, goodsId, id } = record;
        return (
          <div className="action-btn">
            <span
              onClick={(e) => {
                showModal(e, goodsId);
                setRecord(record);
              }}
            >
              改价
            </span>
            <span onClick={() => handleSoldOutGoods(poolSaasStatus, [goodsId])}>
              {poolSaasStatus == 10 ? "下架" : "上架"}
            </span>
            <span onClick={() => handleRemoveGoods(goodsId)}>移除</span>
          </div>
        );
      },
    },
  ];
  const columnsOnce: any = [
    {
      title: "商品",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => {
        const { name, goodsId, mainImage, thirdSku } = record;
        return (
          <div className="shop-wrap">
            <div className="shop-image-wrap">
              <img alt="" src={mainImage} />
            </div>
            <div className="text-wrap">
              <div className="text">
                {/* <span onClick={() => handleClick(goodsId)}>{name}</span> */}
                <span>{name}</span>
              </div>
              <span>
                {thirdSku === null
                  ? `商品ID:${goodsId}`
                  : `商品sku:${thirdSku}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "代发市场价",
      width: 120,
      dataIndex: "onceMarketPriceMin",
      sorter: true,
      key: "onceMarketPriceMin",
      render: (text: any, record: any) => {
        if (
          record?.onceInfo?.marketPriceMin === record?.onceInfo?.marketPriceMax
        ) {
          return (
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMin)}</div>
          );
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "代发价",
      width: 120,
      dataIndex: "onceSalePriceMin",
      key: "onceSalePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.salePriceMin === record?.onceInfo?.salePriceMax
          ) {
            return (
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMin)}</div>
            );
          }
          return (
            <>
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMin)}</div>
              <div>~</div>
              <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMax)}</div>
            </>
          );
        }

        return null;
      },
    },
    {
      title: "代发销售价",
      width: 120,
      dataIndex: "onceSitePriceMin",
      key: "onceSitePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.sitePriceMin === record?.onceInfo?.sitePriceMax
          ) {
            return (
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMin)}</div>
            );
          }
          return (
            <>
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMin)}</div>
              <div>~</div>
              <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMax)}</div>
            </>
          );
        }
        return null;
      },
    },
    {
      title: () => {
        return (
          <div>
            代发利润率(%){" "}
            <Tooltip
              placement="topLeft"
              title="代发利润率=（代发销售价-代发价）/代发销售价*100%"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "onceSiteProfitMin",
      width: 180,
      key: "onceSiteProfitMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isOnce === 1) {
          if (
            record?.onceInfo?.siteProfitMin === record?.onceInfo?.siteProfitMax
          ) {
            return <div>{record?.onceInfo?.siteProfitMin}%</div>;
          }

          return (
            <>
              <div>{record?.onceInfo?.siteProfitMin}%</div>
              <div>~</div>
              <div>{record?.onceInfo?.siteProfitMax}%</div>
            </>
          );
        }

        return null;
      },
    },
    {
      title: "库存",
      width: 80,
      dataIndex: "stock",
    },
    {
      title: "可售状态",
      width: 70,
      fixed: "right",
      dataIndex: "poolSaasAvailableStatus",
      render: (text: any, record: any) => poolSaasAvailableStatus(text, record),
    },
    {
      title: "操作",
      width: 120,
      fixed: "right",
      algin: "center",
      render: (text: any, record: any) => {
        const { poolSaasStatus, goodsId, id } = record;
        return (
          <div className="action-btn">
            <span
              onClick={(e) => {
                showModal(e, goodsId);
                setRecord(record);
              }}
            >
              改价
            </span>
            <span onClick={() => handleSoldOutGoods(poolSaasStatus, [goodsId])}>
              {poolSaasStatus == 10 ? "下架" : "上架"}
            </span>
            <span onClick={() => handleRemoveGoods(goodsId)}>移除</span>
          </div>
        );
      },
    },
  ];
  const columnsPurchase: any = [
    {
      title: "商品",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => {
        const { name, goodsId, mainImage, thirdSku } = record;
        return (
          <div className="shop-wrap">
            <div className="shop-image-wrap">
              <img alt="" src={mainImage} />
            </div>
            <div className="text-wrap">
              <div className="text">
                {/* <span onClick={() => handleClick(goodsId)}>{name}</span> */}
                <span>{name}</span>
              </div>
              <span>
                {thirdSku === null
                  ? `商品ID:${goodsId}`
                  : `商品sku:${thirdSku}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "集采市场价",
      width: 120,
      dataIndex: "purchaseMarketPriceMin",
      sorter: true,
      key: "purchaseMarketPriceMin",
      render: (text: any, record: any) => {
        if (
          record?.purchaseInfo?.marketPriceMin ===
          record?.purchaseInfo?.marketPriceMax
        ) {
          return (
            <div>{"¥" + formatPrice(record?.purchaseInfo?.marketPriceMin)}</div>
          );
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.purchaseInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.purchaseInfo?.marketPriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "集采价",
      width: 120,
      sorter: true,
      dataIndex: "purchaseSalePriceMin",
      key: "purchaseSalePriceMin",
      render: (text: any, record: any) => {
        if (record?.isPurchase === 1) {
          if (record?.purchaseInfo?.salesType === 2) {
            if (
              record?.purchaseInfo?.salePriceMin ===
              record?.purchaseInfo?.salePriceMax
            ) {
              return (
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMin)}
                </div>
              );
            }
            return (
              <>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMin)}
                </div>
                <div>~</div>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.salePriceMax)}
                </div>
              </>
            );
          }

          if (record?.purchaseInfo?.salesType === 1) {
            return record?.purchaseInfo?.stairList.map(
              (item: any, index: any) => {
                return (
                  <div key={index}>
                    {`¥${formatPrice(item.salePrice)}（${
                      index === record?.purchaseInfo?.stairList?.length - 1
                        ? `≥${item?.minNum}`
                        : `${item.minNum}-${item.maxNum}`
                    })`}
                  </div>
                );
              }
            );
          }

          return null;
        }
        return null;
      },
    },
    {
      title: "集采销售价",
      width: 120,
      dataIndex: "purchaseSitePriceMin",
      key: "purchaseSitePriceMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.isPurchase === 1) {
          if (record?.purchaseInfo?.salesType === 2) {
            if (
              record?.purchaseInfo?.sitePriceMin ===
              record?.purchaseInfo?.sitePriceMax
            ) {
              return (
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMin)}
                </div>
              );
            }
            return (
              <>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMin)}
                </div>
                <div>~</div>
                <div>
                  {"¥" + formatPrice(record?.purchaseInfo?.sitePriceMax)}
                </div>
              </>
            );
          }

          if (record?.purchaseInfo?.salesType === 1) {
            return record?.purchaseInfo?.stairList.map(
              (item: any, index: any) => {
                return (
                  <div key={index}>
                    {`¥${formatPrice(item.sitePrice)}（${
                      index === record?.purchaseInfo?.stairList?.length - 1
                        ? `≥${item?.minNum}`
                        : `${item.minNum}-${item.maxNum}`
                    })`}
                  </div>
                );
              }
            );
          }

          return null;
        }

        return null;
      },
    },
    {
      title: () => {
        return (
          <div>
            集采利润率(%){" "}
            <Tooltip
              placement="topLeft"
              title="集采利润率=（集采销售价-集采价）/集采销售价*100%"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "purchaseSiteProfitMin",
      width: 180,
      key: "purchaseSiteProfitMin",
      sorter: true,
      render: (text: any, record: any) => {
        if (
          record?.purchaseInfo?.siteProfitMin ===
          record?.purchaseInfo?.siteProfitMax
        ) {
          return <div>{record?.purchaseInfo?.siteProfitMax}%</div>;
        }
        return (
          <>
            <div>{record?.purchaseInfo?.siteProfitMin}%</div>
            <div>~</div>
            <div>{record?.purchaseInfo?.siteProfitMax}%</div>
          </>
        );
      },
    },
    {
      title: "库存",
      width: 80,
      dataIndex: "stock",
    },
    {
      title: "可售状态",
      width: 70,
      fixed: "right",
      dataIndex: "poolSaasAvailableStatus",
      render: (text: any, record: any) => poolSaasAvailableStatus(text, record),
    },
    {
      title: "操作",
      width: 120,
      fixed: "right",
      algin: "center",
      render: (text: any, record: any) => {
        const { poolSaasStatus, goodsId, id } = record;
        return (
          <div className="action-btn">
            <span
              onClick={(e) => {
                showModal(e, goodsId);
                setRecord(record);
              }}
            >
              改价
            </span>
            <span onClick={() => handleSoldOutGoods(poolSaasStatus, [goodsId])}>
              {poolSaasStatus == 10 ? "下架" : "上架"}
            </span>
            <span onClick={() => handleRemoveGoods(goodsId)}>移除</span>
          </div>
        );
      },
    },
  ];
  const card_app_2_columns: any = [
    {
      title: "商品",
      width: 200,
      fixed: "left",
      render: (text: any, record: any) => {
        const { name, goodsId, mainImage, thirdSku } = record;
        return (
          <div className="shop-wrap">
            <div className="shop-image-wrap">
              <img alt="" src={mainImage} />
            </div>
            <div className="text-wrap">
              <div className="text">
                {/* <span onClick={() => handleClick(goodsId)}>{name}</span> */}
                <span>{name}</span>
              </div>
              <span>
                {thirdSku === null
                  ? `商品ID:${goodsId}`
                  : `商品sku:${thirdSku}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "市场价",
      dataIndex: "marketPriceMax",
      key: "onceMarketPriceMin",
      align: "center",
      width: 98,
      sorter: true,
      render: (text: any, record: any) => {
        if (
          record?.onceInfo?.marketPriceMin === record?.onceInfo?.marketPriceMax
        ) {
          return (
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMax)}</div>
          );
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.onceInfo?.marketPriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "会员价", // 销售价
      dataIndex: "salePriceMax",
      key: "onceSalePriceMin",
      align: "center",
      sorter: true,
      width: 86,
      render: (text: any, record: any) => {
        if (record?.onceInfo?.salePriceMin === record?.onceInfo?.salePriceMax) {
          return <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMax)}</div>;
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.onceInfo?.salePriceMax)}</div>
          </>
        );
      },
    },
    {
      title: "销售价", // 站点价
      dataIndex: "sitePriceMax",
      key: "onceSitePriceMin",
      align: "center",
      width: 86,
      sorter: true,
      render: (text: any, record: any) => {
        if (record?.onceInfo?.sitePriceMin === record?.onceInfo?.sitePriceMax) {
          return <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMax)}</div>;
        }
        return (
          <>
            <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMin)}</div>
            <div>~</div>
            <div>{"¥" + formatPrice(record?.onceInfo?.sitePriceMax)}</div>
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <Tooltip
            title={
              <>
                <p>（非自有商品）利润率=（销售价-会员价）/销售价*100%</p>
                <p>（自有商品）利润率=（销售价-采购价）/销售价*100%</p>
              </>
            }
          >
            <div className="sort">
              利润率(%) <QuestionCircleOutlined className="ml-3" />
            </div>
          </Tooltip>
        );
      },
      dataIndex: "siteProfitMin",
      key: "onceSiteProfitMin",
      align: "center",
      width: 130,
      sorter: true,
      render: (text: any, record: any) => {
        if (
          record?.onceInfo?.siteProfitMin === record?.onceInfo?.siteProfitMax
        ) {
          return <div>{record?.onceInfo?.siteProfitMax}%</div>;
        }

        return (
          <>
            <div>{record?.onceInfo?.siteProfitMin}%</div>
            <div>~</div>
            <div>{record?.onceInfo?.siteProfitMax}%</div>
          </>
        );
      },
    },
    {
      title: "销量",
      width: 80,
      sorter: true,
      key: "siteSalesNum",
      dataIndex: "siteSalesNum",
    },
    {
      title: "库存",
      width: 80,
      dataIndex: "stock",
    },
    {
      title: "状态",
      width: 120,
      datatIndex: "status",
      render: (record: any) => {
        const { poolSaasStatus } = record;
        if (poolSaasStatus == 10) {
          return <span>销售中</span>;
        } else {
          return <span>已下架</span>;
        }
      },
    },
    {
      title: "可售状态",
      width: 70,
      fixed: "right",
      dataIndex: "poolSaasAvailableStatus",
      render: (text: any, record: any) => poolSaasAvailableStatus(text, record),
    },
    {
      title: "操作",
      width: 120,
      fixed: "right",
      algin: "center",
      render: (text: any, record: any) => {
        const { poolSaasStatus, goodsId, id } = record;
        return (
          <div className="action-btn">
            <span
              onClick={(e) => {
                showModal(e, goodsId);
                setRecord(record);
              }}
            >
              改价
            </span>
            <span onClick={() => handleSoldOutGoods(poolSaasStatus, [goodsId])}>
              {poolSaasStatus == 10 ? "下架" : "上架"}
            </span>
            <span onClick={() => handleRemoveGoods(goodsId)}>移除</span>
          </div>
        );
      },
    },
  ];

  // 可售状态
  const poolSaasAvailableStatus = (text: any, record: any) => {
    const content = record?.isSelf ? (
      <div>
        <p>商品因会造成亏本售卖被冻结，冻结的商品将会无法进行交易，</p>
        <p>
          销售价格大于成本价格且小于市场价格时会自动解冻。也可以通过修改商品销售价格进行解冻。
        </p>
      </div>
    ) : (
      <div>
        <p>商品因会造成亏本售卖被冻结，冻结的商品将会无法进行交易，</p>
        <p>
          销售价格大于会员价格且小于市场价格时会自动解冻。也可以通过修改商品销售价格进行解冻。
        </p>
      </div>
    );

    return (
      <>
        {text == 10 && (
          <div className="status-item-wrapper">
            <span
              style={{ backgroundColor: "#36C626" }}
              className="status-dot"
            ></span>
            有效
          </div>
        )}
        {text == -10 && (
          <div className="status-item-wrapper">
            <span style={{ color: "#F59A23" }} className="status-dot"></span>
            冻结&nbsp;
            <Popover content={content} trigger="hover">
              <QuestionCircleFilled />
            </Popover>
          </div>
        )}
        {text == -20 && (
          <div className="status-item-wrapper">
            <span style={{ color: "#A9A9A9" }} className="status-dot"></span>
            失效
          </div>
        )}
      </>
    );
  };

  const handleChangeGoods = (goods: any) => {
    /* if (genre !== 'site') { setGoodsIsOpend(false) }
    if (JSON.stringify(goods) === JSON.stringify(goodsList)) {
      getVGoodsList({ pageSize: goods.length, goodsIdList: goods });
    }
    handleChangeEditData({ 'goodsList': goods }); */
  };

  const handleClick = (goodsId: number) => {
    window.open(`/${siteId}/commodityDetail/?goodsId=${goodsId}`);
  };

  const RenderGroupPrice = (props: any) => {
    const { groupPriceMin, groupPriceMax, groupPrice } = props;
    const handleClick = () => {
      setGroupPrice(
        renderMaxOrMinPrice(groupPriceMin / 100, groupPriceMax / 100)
      );
    };
    const [groupPrice1, setGroupPrice] = useState(groupPrice);
    return (
      <div className="price-btn" onClick={handleClick}>
        {groupPrice1}
      </div>
    );
  };

  // 新版挑选商品 by 微微 begin
  const [chooseGoodsShow, setChooseGoodsShow] = useState<boolean>(false);
  const onChangePriceOk = () => {
    message.success('挑选成功')
    handleChangeGoodsSearchStatus(1)
    setChooseGoodsShow(false)
  }
  // 新版挑选商品 by 微微 end

  return (
    <div className="table-wrap">
      <div className="btn-list">
        <div className="btn-list-con">
          <Button
            type="primary"
            onClick={(e) => {
              // showeEditGroupModal(e);
              // setRecord("");
              // setGoodsIsOpend(true); // 废弃
              setChooseGoodsShow(true)
            }}
          >
            挑选商品
          </Button>
          <Button
            // type="text"
            onClick={(e) => {
              showeEditGroupModal(e);
              setRecord("");
            }}
          >
            修改分组
          </Button>
          <Button
            // type="primary"
            disabled={disabled}
            onClick={(e) => {
              const goodsValue = thirdTypeRow();
              if (goodsValue[0] == 1) {
                showModal(e);
                setRecord(goodsValue[1]);
              } else {
                Modal.warning({
                  content: (
                    <div style={{ paddingTop: 30, marginLeft: 60 }}>
                      <p style={{ textAlign: "left" }}>
                        仅支持单个商品渠道商品批量改价，
                      </p>
                      <p style={{ textAlign: "left" }}>请重新选择商品！</p>
                    </div>
                  ),
                });
              }
            }}
          >
            批量改价
          </Button>
          {poolSaasStatus == 10 ? (
            <Button
              // type="primary"
              disabled={disabled}
              onClick={() => handleSoldOutGoods(10)}
            >
              批量下架
            </Button>
          ) : (
            <Button
              // type="primary"
              disabled={disabled}
              onClick={() => handleSoldOutGoods(-10)}
            >
              批量上架
            </Button>
          )}
        </div>
      </div>
      <div style={{height: 20, padding: '0 20px', background: '#fff'}}>
        <div style={{background: "#fff", width: '100%', height: '100%'}}></div>
      </div>
      <div className="table-wrap-container">
      <Table
        columns={
          appId === 1
            ? typeStatus == "1"
              ? columnsPurchase
              : typeStatus == "3"
              ? columns
              : columnsOnce
            : card_app_2_columns
        }
        scroll={{ x: 1300 }}
        size="middle"
        pagination={pagination}
        sticky={true}
        loading={loading}
        dataSource={tableData}
        rowSelection={{ onChange, type: "checkbox", selectedRowKeys: rows }}
        rowKey="goodsId"
        className="mine-commodiy-table"
        onChange={(pagination, filters, sorter: any) => {
          const selectValue = sorter.columnKey;
          let order = sorter?.order?.replace(/end$/gim, "");
          handleSearchFormClick(selectValue, order, pagination);
        }}
      />
      </div>
     

      <AlterPriceMarket
        title="改价"
        visible={visiblePriceMarket}
        handleOk={handleOk}
        siteId={siteId}
        siteGoodsRule={record}
        confirmLoading={confirmLoading}
        onCancel={handleCancelPrice}
      />

      <AlterPrice
        title="改价"
        visible={visiblePrice}
        handleOk={handleOk}
        siteId={siteId}
        siteGoodsRule={record}
        confirmLoading={confirmLoading}
        onCancel={handleCancelPrice}
      />

      {/* <GoodsChoose
        goodsIds={[]} //挑选商品到模板验证（装修的逻辑，站点这里不需要）
        visible={goodsIsOpend}
        onChange={handleChangeGoods}
        onCancel={() => {
          setGoodsIsOpend(false);
        }}
      /> */}

      {chooseGoodsShow && (
        <ChooseMallGoods
          env={ENV}
          token={localStorage.getItem('token') as any}
          app={CommonStore.appId}
          siteId={CommonStore.siteId}
          onClose={() => {
            setChooseGoodsShow(false)
          }}
          multiple
          isChangePrice
          onChangePriceOk={onChangePriceOk}
        />
      )}
    </div>
  );
};

export default observer(forwardRef(TableList));
