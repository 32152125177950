import { Spin, Card, Tabs, Timeline, Table, Space, Tag, Typography } from "@fle-ui/next";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import React, { FC, useEffect, useState, useRef, useMemo, useCallback } from "react";
import WhiteSpace from "@/components/WhiteSpace";
import { useHistory, useParams } from "react-router-dom";
import OrderListStore, { OrderItem } from "@/store/OrderListStore";
import OrderDetailStore, { OrderCloseType } from "@/store/OrderDetailStore";
import { ThirdType } from "@/store/OrderDetailStore";
import { formatPrice } from "@/utils/common";
import "./index.scss";
import { PageContainer } from "@fle-ui/pro-layout";

const { TabPane } = Tabs;
const { Text } = Typography;

type Props = {};
const deliveryMethodMap = ["", "快递", "物流"];
const OrderDetail: FC<Props> = () => {
  const history = useHistory() as any;
  const { orderId } = useParams();
  const { orderStatus } = OrderDetailStore.orderDetail || {};
  // const orderId = history.location.search.split("=")[1];
  const list = OrderDetailStore.orderDetail;
  const goods = OrderDetailStore.orderDetail.orderGoods;

  const [loading, setLoading] = useState<any>(false);
  const [expressData, setExpressData] = useState<any>({});
  const [active, setActive] = useState<string>("0");
  const [msgTime, setMsgTime] = useState<any>("");
  const [count, setCount] = useState<any>();

  const [logistic, setLogistic] = useState<any>();
  

  useEffect(() => {
    const orderNo = orderId;
    OrderListStore.ajaxQueryLogisticByOrderNo({ orderNo })
      .then((res) => {
        res && setLogistic(res);
        setActive("0");
      })
      .catch((error) => {
        setLogistic({});
      });
  }, []);

  useEffect(() => {
    if (logistic) {
      const deliveryMethod = logistic[active]?.deliveryMethod;
      const expressNo = logistic[active]?.expressNo;
      if (deliveryMethod === 1 && expressNo.length !== 0) {
        OrderListStore.ajaxQueryLogisticByExpressNo({ expressNo })
          .then((res) => {
            res && setExpressData(res);
          })
          .catch((error) => {
            setExpressData({});
          });
      }
    }
  }, [active, logistic]);

  const columns = [
    {
      title: "商品图片",
      dataIndex: "goodsImage",
      key: "goodsImage",
      render: (text: any, record: any) => {
        return <img src={text} alt="" style={{ width: 68 }} />;
      },
    },
    {
      title: "商品名称",
      dataIndex: "goodsName",
      key: "goodsName",
      render: (text: any, record: any) => goodsData(record),
    },
    {
      title: "单价",
      dataIndex: "salePrice",
      key: "salePrice",
      render: (text: any, record: any) => {
        return <span>￥{formatPrice(record.salePrice)}</span>;
      },
    },
    {
      title: "销售价",
      dataIndex: "salePrice",
      key: "salePrice",
      render: (text: any, record: any) => {
        return <span>￥{formatPrice(record.salePrice)}</span>;
      },
    },
    {
      title: "数量",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "小计",
      dataIndex: "subtotal",
      key: " subtotal",
      render: (text: any, record: any) => {
        return (
          <span>￥{formatPrice(record.salePrice * record.quantity)}</span>
        );
      },
    },
    {
      title: "优惠",
      dataIndex: "discountName",
      key: "discountName",
      width: 160,
      render: (text: any, record: any) => preferentialShare(record),
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        if (text == 0) {
          return "待发货";
        } else if (text == 1) {
          return "待收货";
        } else if (text == 2) {
          return <span style={{ color: "#36C626" }}>交易成功</span>;
        } else if (text == 3) {
          return "已退款";
        } else if (text == 4) {
          return "售后中";
        } else if (orderStatus == 5) {
          return "交易关闭";
        }
      },
      /*  // 为isDelivery字段时的处理逻辑，后端变更了字段，有风险再变回来（保留备用）
      render: (text: any, record: any) => {
        if (text == 0) {
          return "待发货";
        } else if (text == 1) {
          return "待发货";
        } else if (text == 2) {
          return "已发货";
        } else if (text == 3) {
          return <span style={{ color: "#4DC636" }}>交易成功</span>;
        } else if (orderStatus == 5) {
          return "交易关闭";
        }
      }, */
    },
  ];

  const OrderStatusMap = new Map<number, { name: string; icon: any; color: string }>([
    [1, { name: "待付款", icon: <i className="iconfont icon-dengdaimaijiafukuan"></i>, color: "#FF712C" }],
    [2, { name: "待发货", icon: <i className="iconfont icon-dengdaimaijiafahuo"></i>, color: "#FF712C" }],
    [3, { name: "待收货", icon: <i className="icon-dengdaimaijiaquerenshouhuo"></i>, color: "#FF712C" }],
    [4, { name: "交易成功", icon: <i className="iconfont icon-jiaoyichenggong"></i>, color: "#36C626" }],
    [5, { name: "交易关闭", icon: <i className="iconfont icon-jiaoyiguanbi"></i>, color: "#F42D2D" }],
    [6, { name: "交易成功", icon: <i className="iconfont icon-jiaoyichenggong"></i>, color: "#36C626" }],
    [7, { name: "交易关闭", icon: <i className="iconfont icon-jiaoyiguanbi"></i>, color: "#F42D2D" }],
  ]);

  useEffect(() => {
    setLoading(true);
    OrderDetailStore.ajaxGetOrderDetail({
      orderId: orderId,
      source: 2,
    }).finally(() => {
      setLoading(false);
    });
  }, [orderId]);

  const callback = (key: any) => {
    console.log(key);
  };
  // 商品数据展示逻辑
  const goodsData = (record: any) => {
    const { goodsImage, specList, goodsName, isSelf, thirdType } = record;
    const data = JSON.parse(specList || "[]")
      .map((item: { name: string; value: string }) => `${item["name"]}:${item["value"]}`)
      .join("");
    return (
      <>
        <Space align={"start"}>
          {/* <img width={68} src={goodsImage} alt="" /> */}
          <Space direction={"vertical"} style={{ color: "#262626" }}>
            <div>
              <Tag
                color="#F53466"
                style={{
                  width: 38,
                  height: 18,
                  background: "#FF003C",
                  borderRadius: 4,
                  lineHeight: "18px",
                  fontSize: 12,
                }}
              >
                {isSelf === 1 ? "自有" : ThirdType[thirdType]}
              </Tag>
              <span>{goodsName}</span>
            </div>
            <div style={{ color: "#a9a9a9" }}>{data}</div>
          </Space>
        </Space>
      </>
    );
  };

  // 优惠分摊逻辑
  const preferentialShare = (record: any) => {
    const { couponInfos } = record;
    return couponInfos?.map((item: any, index: any) => {
      return (
        <div key={item.cardNo}>
          {item.cardName}：-￥{formatPrice(item.couponFee)}
        </div>
      );
    });
  };
  // 订单状态逻辑
  // const orderStatusCallback = (params: any) => {
  //   if (params === 1) {
  //     return <span style={{ color: "#FF003C" }}>待付款</span>;
  //   } else if (params === 2) {
  //     // return "待发货";
  //     return goods?.some((item: any) => item.isDelivery === 2) ? "部分发货" : "待发货";
  //   } else if (params === 3) {
  //     return "待收货";
  //   } else if (params === 4 || params === 6) {
  //     return <span style={{ color: "#4DC636" }}>交易成功</span>;
  //   } else if (params === 5 || params === 7) {
  //     return "交易关闭";
  //   }
  // };

  const orderStatusCallback = (params: any) => {
    if (params === 2 && goods.some((item: any) => item.isDelivery === 2)) {
      return "部分发货";
    } else {
      return OrderStatusMap.get(params)?.name;
    }
  };
  // 订单状态说明逻辑
  const orderStatusDes = (params: any) => {
    if (params === 1) {
      return count;
    } else if (params === 2) {
      return "如果没有收到货，或收货后出现问题，请联系卖家";
    } else if (params === 3) {
      return "如果没有收到货，或收货后出现问题，请联系卖家";
    } else if (params === 4 || params === 6) {
      return "如果没有收到货，或收货后出现问题，请联系卖家";
    } else if (params === 5 || params === 7) {
      // return list?.closeReason;
      return `原因：${OrderCloseType[list?.closeType]}  ${list?.closeReason}`;
    }
  };
  // 订单未支付倒计时逻辑
  useEffect(() => {
    let time = Math.floor(Number(list?.remainTime) / 1000);
    const timer = setInterval(() => {
      let h = Math.floor((time / 60 / 60) % 24);
      let m = Math.floor((time / 60) % 60);
      let s = Math.floor(time % 60);
      if (time > 0) {
        setCount(h + "小时" + m + "分" + s + "秒后未付款，订单将自动取消");
      } else {
        setCount("交易关闭");
      }
      --time;
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [list?.remainTime]);

  // 商品总计逻辑
  const goodsAllPrice = (params: any) => {
    let priceArry: number[] = [];
    params?.forEach((item: any, index: any) => {
      priceArry.push(item?.salePrice * item?.quantity);
    });
    return formatPrice(
      priceArry.reduce(function (prev, cur, index, arr) {
        return prev + cur;
      })
    );
  };

  // 支付方式逻辑
  const payMethodCallback = () => {
    const payMethodArray = list?.payMethod?.split(",");
    if (payMethodArray?.length == 1) {
      if (payMethodArray[0] == "12") {
        return "余额支付";
      } else if (payMethodArray[0] == "32" || payMethodArray[0] == "34") {
        return "微信支付";
      } 
    } else if (Number(payMethodArray?.length) > 1) {
      return "组合支付";
    } else {
      return "";
    }
  };

  // 多卡支付逻辑
  const cardInfos = () => {
    return list?.cardInfos.map((item: any, index: any) => {
      return (
        <div>
          <span>礼品卡{index + 1}：</span>
          <p>-￥{formatPrice(item?.couponPayment)}</p>
          <p>
            （卡券名称：{item?.couponName} 卡号：{item?.couponId}）
          </p>
        </div>
      );
    });
  };

  return (
    <PageContainer padding={0} vesselStyle={{ background: "#eff1f6" }} className='order-details-container'>
      <Spin spinning={loading}>
        <div className="order_detail">
          <Card>
            <div className="order_detail_tit">
              <p className="order_detail_status" style={{ color: OrderStatusMap.get(Number(orderStatus))?.color }}>
                {OrderStatusMap.get(Number(orderStatus))?.icon}&nbsp;{orderStatusCallback(orderStatus)}
              </p>
              <p className="order_detail_tips">{orderStatusDes(orderStatus) as any}</p>
            </div>
          </Card>

          <Card>
            <div className="order_infor">
              <div className="order_infor_tit">
                <h4>订单信息</h4>
              </div>

              <div className="order_infor_con_wrap">
                <div className="order_infor_con order_infor_con_left">
                  <p>
                    <span>订单编号：</span>
                    {list?.orderNo}
                  </p>
                  <p>
                    <span>支付流水号：</span>
                    {list?.payBillId}
                  </p>
                  <p>
                    <span>订单来源：</span>站点ID {list?.siteId}
                    &nbsp;&nbsp;{list?.siteName}
                  </p>
                  {
                    list?.orderShip?.shipType === 1 && <p>
                      <span>预定发货时间：</span>
                      {list?.orderShip?.expectShipTime}
                    </p>
                  }
                  {
                    list?.orderShip?.shipType === 2 && <p>
                      <span>期望配送时间：</span>
                      {list?.orderShip?.shipDate} {list?.orderShip?.shipTime} 
                    </p>
                  }
                  {
                    list?.orderShip?.shipType === 3 && <p>
                      <span>自提门店：</span>
                      {list?.orderShip?.shopName}
                    </p>
                  }
                </div>
                <div className="order_infor_con order_infor_con_center">
                  <p>
                    <span>下单时间：</span>
                    {list?.createTime}
                  </p>
                  <p>
                    <span>付款时间：</span>
                    {list?.payTime}
                  </p>
                  <p>
                    <span>支付方式：</span> {payMethodCallback()}
                  </p>
                  {
                    list?.orderShip?.shipType === 3 && <p>
                      <span>自提门店地址：</span>
                      {list?.orderShip?.shopAddress}
                    </p>
                  }
                </div>
                <div className="order_infor_con order_infor_con_right">
                  <p style={{ textAlign: "left", display: "flex" }}>
                    <span>收货地址：</span>
                    <Text
                      style={{ width: "300px", color: "#262626" }}
                      ellipsis={{
                        tooltip: (
                          <>
                            {list?.receiveInfo?.name +
                              "，" +
                              list?.receiveInfo?.mobile +
                              "，" +
                              list?.receiveInfo?.province +
                              " " +
                              list?.receiveInfo?.city +
                              " " +
                              list?.receiveInfo?.district +
                              " "}
                            {list?.receiveInfo?.town ? list?.receiveInfo?.town : "" + " "}
                            {list?.receiveInfo?.address}
                          </>
                        ),
                      }}
                    >
                      {list?.receiveInfo?.name +
                        "，" +
                        list?.receiveInfo?.mobile +
                        "，" +
                        list?.receiveInfo?.province +
                        " " +
                        list?.receiveInfo?.city +
                        " " +
                        list?.receiveInfo?.district +
                        " "}
                      {list?.receiveInfo?.town ? list?.receiveInfo?.town : "" + " "}
                      {list?.receiveInfo?.address}
                    </Text>
                  </p>
                  <p>
                    <span>买家留言：</span>
                    {list?.remark ? list?.remark : "---"}
                  </p>
                  {
                    list?.orderShip && <p>
                      <span>配送方式：</span>
                      {list?.orderShip.shipType === 1 && '快递'}
                      {list?.orderShip.shipType === 2 && '商家配送'}
                      {list?.orderShip.shipType === 3 && '门店自提'}
                    </p>
                  }
                  {
                    list?.orderShip?.shipType === 3 && <p>
                      <span>自提时间：</span>
                      {list?.orderShip?.shipDate} {list?.orderShip?.shipTime} 
                    </p>
                  }
                </div>
              </div>
            </div>
          </Card>
          <Card>
            <div className="goods_infor">
              <div className="goods_infor_tit">
                <h4>商品信息</h4>
              </div>
              <div className="table">
                <Table columns={columns} dataSource={list.orderGoods} bordered pagination={false} rowKey="goodsName" />
              </div>
              <div className="goods_infor_con">
                <div className="goods_infor_con_first">
                  <p>
                    <span>商品总计：</span>
                    <p>￥{goodsAllPrice(list?.orderGoods)}</p>
                  </p>
                </div>
                <div className="goods_infor_con_second">
                  <p>
                    <span>运费：</span>￥{formatPrice(list?.actualFreight)}
                  </p>
                  <p>
                    <span>优惠券：</span>-￥{formatPrice(list?.discountPayment)}
                  </p>
                  {cardInfos()}
                  <p>
                    <span>实付总额：</span>
                    <p style={{ color: "#FF003C", fontWeight: 500 }}>￥{formatPrice(list?.actualAccount)}</p>
                  </p>
                </div>
              </div>
            </div>
          </Card>

          <Card>
            <div className="track">
              <div className="track_tit">
                <h4>物流信息</h4>
              </div>
              <div className="track_con">
                <Tabs onChange={setActive} type="card" activeKey={active}>
                  {logistic?.map((item: any, index: any) => (
                    <TabPane tab={"包裹" + (index + 1)} key={index} />
                  ))}
                </Tabs>

                <div style={{ textAlign: "left", lineHeight: 2 }}>
                  {logistic &&
                    logistic[active]?.goodsInfos?.map((item: any, index: any) => (
                      <img width={68} style={{ marginRight: 10 }} src={item?.goodsImage} alt="" key={index} />
                    ))}
                  {logistic && logistic[active]?.expressNo ? (
                    <>
                      <div>
                        <span>发货方式：&nbsp;&nbsp;</span>
                        {deliveryMethodMap[logistic[active]?.deliveryMethod]}
                      </div>
                      <div>
                        <span>物流公司：&nbsp;&nbsp;</span>
                        {logistic[active]?.deliveryMethod === 1
                          ? expressData?.expressName
                          : logistic[active]?.expressCompany}
                      </div>
                      <div>
                        <span>运单号码：&nbsp;&nbsp;</span>
                        {logistic[active]?.expressNo}
                      </div>
                      <div className="track">
                        <div>物流跟踪：&nbsp;&nbsp;</div>
                        <WhiteSpace height={10} />
                        {logistic[active]?.deliveryMethod === 1 ? (
                          <div className="track_con">
                            <Timeline>
                              {expressData?.expressInfo?.map((item: any) => (
                                <Timeline.Item key={item.context}>
                                  {item.time}:{item.context}
                                </Timeline.Item>
                              ))}
                            </Timeline>
                          </div>
                        ) : (
                          <div>暂无物流信息！</div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div>暂无运单号！</div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Spin>
    </PageContainer>
  );
};

export default observer(OrderDetail);
