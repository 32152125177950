import { mainHost } from '@/config/index'
import request from '@/utils/httpClient'
import md5 from 'js-md5'
// 根据用户id获取用户信息
export const getUserInfoByUserId = (id: string) => {
  return request({
    method:'get',
    url: mainHost +  '/fleshopy/merchant/site-user/user-info/select',
    params:{
      id
    }
  })
} 

// 根据用户id获取用户信息
export const updateUserInfoByUserId = (data: any, credential: string) => {
  return request({
    method:'post',
    headers: {
      'Content-Type': 'application/json'
    },
    url: mainHost +  '/fleshopy/merchant/site-user/user/update',
    data:JSON.stringify({ ...data,credential: credential ? md5(credential): credential })
  })
} 