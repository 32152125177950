import React, { useState, FC, useEffect } from "react";

import { observer, inject } from "mobx-react";

import "./index.scss";

import Display from "../Display/index";
import { withRouter } from "react-router-dom";


import { Tooltip, Button, Input, message, Image } from "@fle-ui/next";

// import { EditOutlined } from "@ant-design/icons";

// import app from "../../../assets/image/template/app.jpg"; // app图表
// import wechat from "../../../assets/image/template/wechat.jpg";
// import chengxu from "../../../assets/image/template/chengxu.jpg";

import {
  getSiteShareConfig,
  updateSiteNameUpdate,
  getSitePreview,
  getUserInfo,
} from "./store";
interface EntranceItem {
  url: string;
  text: string;
  alt: string;
  id: number;
}

const Template: FC<any> = (props) => {
  const { siteId } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [coverImage, setCoverImage] = useState("");
  const [releasedPageName, setReleasedPageName] = useState("");
  const [storeName, setStoreName] = useState("");
  const [oldStoreName, setoldStoreName] = useState("");
  //const { homepageDecorationId } = CommonStore;
  const [homepageDecorationId, setHomepageDecorationId] = useState<number>(0);
  const [userInfo,setUserInfo] = useState<any>({})

  // 渲染潜入客户端入口
  const getSiteInfo = async (siteId: number) => {
    const { data, success } = (await getSiteShareConfig(siteId)) as any;
    if (success) {
      const {
        coverImage,
        siteName,
        homepageDecorationId,
        homepageReleasedPageName = "后端未传首页名称",
      } = data;
      setReleasedPageName(homepageReleasedPageName);
      setCoverImage(coverImage);
      setStoreName(siteName);
      setoldStoreName(siteName);
      setHomepageDecorationId(homepageDecorationId);
    }
  };

  useEffect(() => {
    if(siteId){
      getSiteInfo(siteId);
    }
  }, [siteId]);

  useEffect(() => {
    const getInfo = async () => {
      const { data, success } = (await getUserInfo()) as any;
      if (success) {
        if (data.loginLink) {
          setUserInfo(data)
        }
      }
    };
    getInfo();
  }, []);

  // useEffect(() => {
  //   try {
  //     const userInfo = sessionStorage.getItem('userInfo')
  //     if(userInfo) {
  //       setUserInfo(JSON.parse(userInfo || ''))
  //     }
  //   } catch (error) {
      
  //   }
  // }, []);



  // const renderClientEntrance = (enters: EntranceItem[]) => {
  //   return (
  //     <div className="fx-entrance-wrap">
  //       {enters.map(({ text, url, alt, id }) => (
  //         <div className="fx-entrance--circle" key={id}>
  //           <img src={url} alt={alt} />
  //           <span>{text}</span>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  // const renderEditInput = (isEdit: boolean) => {
  //   if (isEdit === false) {
  //     return (
  //       <header>
  //         <div className="fx-header-wrap">
  //           <Tooltip placement="topLeft" title={storeName}>
  //             {storeName}
  //           </Tooltip>
  //         </div>
  //         <div className="edit-out-lined">
  //           <EditOutlined
  //             onClick={() => {
  //               setIsEdit(true);
  //               setStoreName("");
  //             }}
  //           />
  //         </div>
  //       </header>
  //     );
  //   }

  //   return (
  //     <header>
  //       <Input
  //         value={storeName}
  //         placeholder="请输入商城名称"
  //         autoFocus
  //         onBlur={async (e) => {
  //           setIsEdit(false);
  //           if (e.target.value) {
  //             const { success } = (await updateSiteNameUpdate(
  //               siteId,
  //               e.target.value
  //             )) as any;
  //             if (success) {
  //               message.success("修改成功");
  //               setStoreName(e.target.value);
  //             }
  //           } else {
  //             setStoreName(oldStoreName);
  //           }
  //         }}
  //         onChange={(e) => setStoreName(e.target.value)}
  //         maxLength={20}
  //       ></Input>
  //     </header>
  //   );
  // };

  // const entrances: EntranceItem[] = [
  //   { text: "公众号", url: wechat, alt: "", id: 1 },
  //   { text: "小程序", url: chengxu, alt: "", id: 2 },
  //   { text: "App", url: app, alt: "", id: 3 },
  // ];

  const locationPreview = (type: any) => {
    window.location.href = getSitePreview(siteId, type, homepageDecorationId);
  };

  const getTimeState = () => {
    // 获取当前时间
    let timeNow = new Date();
    // 获取当前小时
    let hours = timeNow.getHours();
    // 设置默认文字
    let state= ``;
    // 判断当前时间段
    if (hours >= 0 && hours <= 10) {
        state = `早上好`;
    } else if (hours > 10 && hours <= 14) {
        state= `中午好`;
    } else if (hours > 14 && hours <= 18) {
        state= `下午好`;
    } else if (hours > 18 && hours <= 24) {
        state= `晚上好`;
    }
    return state;
  }

  return (
      <div className="fx-template-wrap">
        <div className="fx-template-header">
          <div className="header-left">
            <Image
              preview={false}
              className="header-left-img"
              src={userInfo?.logoImgUrl}
            />
            <div>
              <div className="text1">{getTimeState()}{userInfo?.name}</div>
              <div className="text2">欢迎使用百礼腾</div>
            </div>
          </div>
          <div className="header-right">
            <Button
              className="mr16"
              onClick={() => {
                locationPreview("saas/preview");
              }}
            >
              手机预览
            </Button>
            <Button
              type="primary"
              onClick={() => {
                locationPreview("fitment");
              }}
            >
              装修商城
            </Button>
          </div>
        </div>
        {/* <div className='fx-template--edit'>
              {renderEditInput(isEdit)}
          <section>
            <div className="fx-button-group">
              <Button onClick={() => { locationPreview('saas/preview') }}>手机预览</Button>
              <Button type="primary" onClick={() => { locationPreview('fitment') }}>装修商城</Button>
            </div>
            <div className='fx-screeshot-wrap'>
            <span className='releasedPageName'>{releasedPageName}</span>
            <img
              width="100%"
              src='https://oss.elebuys.com/resource/elebuys/202011181608200003768336.png'
              alt='手机导航栏'
              className='fx-screestop--img'
            >
            </img>
            { coverImage && 
            <img 
              src= { coverImage } alt=''  className='fx-screeshot--img'>
              </img> }
            </div>
            <div style= {{textAlign: 'center', marginTop: '33px'}}>嵌入客户端</div>
            {renderClientEntrance(entrances)}
          </section>
      </div> */}
        <div className="fx-display-wrap">
          <Display />
        </div>
      </div>
  );
};

export default inject("siteId")(withRouter(Template));
