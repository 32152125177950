import React, { FC } from 'react'
import { SketchPicker, ColorChangeHandler, Color } from 'react-color';
import { Popover } from "@fle-ui/next";
import './index.scss'

interface ColorSelectProps {
    defaultColorCode: any,
    handleColorChange: ColorChangeHandler,
    handleColorReset: () => void,
}

const ColorSelect:FC<ColorSelectProps> = (props) => {
    const {defaultColorCode, handleColorChange, handleColorReset} = props
    const content = (
        <div>
          <SketchPicker
            className="color-select-picker"
            color={defaultColorCode}
            onChange={handleColorChange}
          />
        </div>
      );
    return <div className="color-select-wrapper">
        <Popover content={content} trigger="click" placement="right">
            <div className="select-btn">
                <div className="select-btn-bg" style={{backgroundColor:defaultColorCode}}></div>
            </div>
        </Popover>
        <div className="select-reset" onClick={handleColorReset}>重置</div>
    </div>
}

export default ColorSelect