import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { formatPrice } from "@/utils/common";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Cascader,
  Button,
  Upload,
  Space,
  Table,
  message,
} from "@fle-ui/next";
import AddGroupTree, {
  TreeNodeDataType,
  AddTreeModalType,
} from "@/components/AddGroupTree/index";
import {
  batchList,
  cacheList,
  batchDelete,
  batchOneDelete,
  excelValidation,
  templateDownload,
  addUserByGroupId,
  getUserGroups,
  addGroupSubsByParentId,
  addGroup,
  updateGroupSubsById,
  getUserListByGroupId,
  sendMessageToUser,
} from "../../store";
import "./index.scss";
const { Item } = Form;

const Detail = (props: any) => {
  const { handleOk, handleCancel, title, visible, batchId, counter } = props;
  // 详情列表
  const [loading, setLoading] = useState<any>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [titData, setTitData] = useState<any>();

  const columns = [
    {
      title: "序号",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 60,
      // render:(text:any,record:any)=>{
      //   return
      // }
    },
    {
      title: "*用户手机号",
      dataIndex: "mobile",
      key: "mobile",
      width: 120,
    },
    {
      title: "初始密码",
      dataIndex: "password",
      key: "password",
      render: (text: any, record: any) => {
        return "******";
      },
    },
    {
      title: "分组",
      key: "groupName",
      dataIndex: "groupName",
    },
    {
      title: "现金账户余额",
      dataIndex: "cashAccountBalance",
      key: "cashAccountBalance",
      render: (text: any, record: any) => formatPrice(text),
    },
    {
      title: "积分账户余额",
      dataIndex: "integralAccountBalance",
      key: "integralAccountBalance",
      render: (text: any, record: any) => IntegralCallback(text),
    },
    {
      title: "用户姓名",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text: any, record: any) => {
        if (text == 1) {
          return "创建中";
        } else if (text == 2) {
          return "创建成功";
        } else if (text == 3) {
          return "创建失败";
        } else {
          return "";
        }
      },
    },
    {
      title: "失败原因",
      key: "failReason",
      dataIndex: "failReason",
      width: 220,
      textWrap: "word-break",
      ellipsis: true,
    },
    {
      title: "操作",
      key: "action",
      render: (text: any, record: any) => {
        if (record.status == 3) {
          return (
            <Space size="middle">
              <Button
                type="link"
                block
                onClick={() => handleOneDeleteClick(record)}
              >
                移除
              </Button>
            </Space>
          );
        } else {
          return <Space size="middle"></Space>;
        }
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (batchId) {
      getBatchDetailList(batchId, 1, 10).finally(() => {
        setLoading(false);
      });
    }

    return () => {
      setPageNo(1);
      setPageSize(10);
    };
  }, [batchId, counter]);

  const getBatchDetailList = async (
    siteId: string | number,
    pageNo: number,
    pageSize: any
  ) => {
    const { success, data } = (await cacheList(
      siteId,
      pageNo,
      pageSize
    )) as any;
    if (success) {
      data?.personalCacheList?.list?.forEach((item: any, index: any) => {
        item.serialNumber =
          index +
          (data?.personalCacheList?.pageNo - 1) *
            data?.personalCacheList?.pageSize +
          1;
      });
      setTableData(data?.personalCacheList?.list);
      setTitData(data);
      setTotal(data?.personalCacheList?.total);
      setPageSize(data?.personalCacheList?.pageSize);
    }
  };
  const handleChangStart = (start: number, limit?: number) => {
    setPageNo(start);
    getBatchDetailList(batchId, start, limit as number);
  };
  // 选择页数发生改变
  const handleChangSize = (start: number, limit?: number) => {
    setPageSize(limit as number);
  };
  const pagination = {
    pageSize,
    total,
    current: pageNo,
    pageSizeOptions: ["10", "20"],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total: number) => `共${total}条,第${pageNo}页`,
    onChange: handleChangStart,
    onShowSizeChange: handleChangSize,
  };
  const handleDeleteClick = async () => {
    const res:any = await batchDelete(batchId);
    if (res?.success) {
      message.success("已为你成功移除 “创建失败”的用户" + res?.data + "条");
      getBatchDetailList(batchId, pageNo, pageSize);
    } else {
      getBatchDetailList(batchId, pageNo, pageSize);
    }
  };
  const handleOneDeleteClick = async (record: any) => {
    const { id } = record;
    const res:any = await batchOneDelete(id);
    if (res?.success) {
      message.success("移除成功");
      getBatchDetailList(batchId, pageNo, pageSize);
    } else {
      getBatchDetailList(batchId, pageNo, pageSize);
    }
  };

  const IntegralCallback = (params: number | string | null) => {
    if (params == null) {
      return "0";
    } else {
      return params;
    }
  };

  return (
    <div className="detail">
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="detail"
        width={1300}
      >
        <div className="tit">
          <div className="tit_left tit_con">
            <p className="start">
              <span>创建用户总数：</span>
              {titData?.createUserCount}
            </p>
            <p>
              <span>成功创建用户数：</span>
              {titData?.createUserSuccess}
            </p>
            <p>
              <span>失败创建用户数：</span>
              {titData?.createUserFail}
            </p>
          </div>
          <div className="tit_center tit_con">
            <p>
              <span>现金账户余额总计：</span>
              {formatPrice(titData?.addCashBalance)}
            </p>
            <p>
              <span>成功现金账户余额：</span>
              {formatPrice(titData?.addCashSuccess)}
            </p>
            <p>
              <span>失败现金账户余额：</span>
              {formatPrice(titData?.addCashFail)}
            </p>
          </div>
          <div className="tit_right tit_con">
            <p>
              <span>积分账户余额总计：</span>
              {IntegralCallback(titData?.addIntegralBalance)}
            </p>
            <p>
              <span>成功积分账户余额：</span>
              {IntegralCallback(titData?.addIntegralSuccess)}
            </p>
            <p>
              <span>失败积分账户余额：</span>
              {IntegralCallback(titData?.addIntegralFail)}
            </p>
          </div>
        </div>
        <div className="table_tit">
          <div className="creat_table">
            <h4>创建列表</h4>
            <p>
              温馨提示：本列表用户不支持再次导入，创建失败的用户建议及时移除！
            </p>
          </div>
          <Button type="primary" onClick={handleDeleteClick}>
            一键移除
          </Button>
        </div>

        <Table
          columns={columns}
          pagination={pagination}
          loading={loading}
          dataSource={tableData}
          rowKey="serialNumber"
        />
      </Modal>
    </div>
  );
};

export default observer(Detail);
