import { ThirdType } from "@/store/OrderDetailStore";
import { OrderItem } from "@/store/AfterSaleListStore";
import { Space, Tag, Row, Col, Typography } from "@fle-ui/next";
import React, { FC } from "react";

const GoodDesc: FC<OrderItem> = (props: any) => {
  const { afterSaleGoodsImage, afterSaleGoodsName } = props;
  const { Paragraph } = Typography;
  return (
    <Row style={{ paddingLeft: 28 }}>
      <Col span={8} style={{ display: "flex", alignItems: "center" }}>
        <img width={46} src={afterSaleGoodsImage} alt="" style={{ height:46 }} />
      </Col>
      <Col span={2}></Col>
      <Col span={14} style={{ display: "flex", alignItems: "center", paddingRight: 16 }}>
        <Paragraph
          ellipsis={{
            rows: 2,
            tooltip: <>{afterSaleGoodsName}</>,
          }}
          style={{ width: "100%", paddingTop: 14, color: "#4C4C4C", textAlign: "left" }}
        >
          {afterSaleGoodsName}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default GoodDesc;
