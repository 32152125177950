
class Store {
  private static storage: any
  constructor() {
    Store.storage = localStorage
  }
  getItem(key: string | number): any {
    return Store.storage.getItem(key)
  }
  setItem(key: string | number, val: any): void {
    Store.storage.setItem(key, val)
  }
  removeItem(key: string| number): void {
    Store.storage.removeItem(key)
  }
  clear(): void {
    Store.storage.clear()
  }
  getKey(key: string | number): string | number {
    return  Store.storage.key(key)
  }
}
export default Store


