import OrderDetailStore, { statusMap } from "@/store/AfterSaleListDetailStore";
import { OrderItem } from "@/store/AfterSaleListStore";
import { Button, Col, Modal, Space, message } from "@fle-ui/next";
import { toJS } from "mobx";
import React, { FC, useState } from "react";

type Props = {
  // 删格化布局
  span: number;
  setPage: (values: any) => void;
  page: {
    pageNo: number;
    pageSize: number;
  };
};
const OrderState: FC<Props & OrderItem> = (props) => {
  const { span, afterSaleStatus } = props;

  return (
    <>
      <Col span={span} className="table_vertical_left" style={{ paddingRight: 12 }}>
        <Space direction={"vertical"} align="start">
          <div style={{ color: statusMap.get(Number(afterSaleStatus))?.color }}>
            {statusMap.get(Number(afterSaleStatus))?.parentText}
          </div>
          <div style={{ textAlign: "left", color: "#4C4C4C" }}>{statusMap.get(Number(afterSaleStatus))?.text}</div>
        </Space>
      </Col>
    </>
  );
};

export default OrderState;
